/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
import React from 'react';


const SmallDetailedCard = ({
  categoryField, onSeriesClick,
  heading, content,rawContent
}) => (
  <React.Fragment>
    <div
      className="dashboard__samll-display-card round-border"
      style={{ height: '85px', position: 'relative' }}
      onClick={() => onSeriesClick({ categoryField, value: heading })}
    >
      <div className="dashboard_samll-detail-card">

        <div className="dashboard_samll-detail-card-header">
          <span style={{ float: 'left' }} title={heading}>
            {heading}
          </span>
        </div>
        <div className="dashboard_samll-detail-card-content" title={rawContent}>
          {content}
        </div>
      </div>
    </div>
  </React.Fragment>
);

export default SmallDetailedCard;
