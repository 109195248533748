import React from 'react';
import { Modal, TransvoiceButton, Translate } from '../../components';
import './style.scss';
import { bool, func, string } from 'prop-types';

const propTypes = {
  isVisible: bool,
  onCloseClicked: func,
  onOverlayClick: func,
  onConfirmClick: func,
  message: string,
};

const defaultProps = {
  isVisible: false,
  onCloseClicked: () => {},
  onOverlayClick: () => {},
  onConfirmClick: () => {},
  message: Translate({ content: 'RouteLeavingGuard.cancelMessage' }),
};

const RouteLeavingGuard = ({
  isVisible,
  onCloseClicked,
  onOverlayClick,
  onConfirmClick,
  message,
}) => {
  return (
    <div>
      <Modal
        isVisible={isVisible}
        onCloseClicked={() => {
          onCloseClicked();
        }}
        onOverlayClick={() => {
          onCloseClicked();
        }}
        className=" tv-route-leaving-guard-modal"
        headerClassName="tv-cancelassignment_modal__header"
      >
        <div className="tv-route-leaving-guard-modal-area">
          <div className="tv-route-leaving-guard-modal-area-title">
            {Translate({
              content: 'RouteLeavingGuard.title',
            })}
          </div>
          <div className="tv-route-leaving-guard-modal-area-text mt-3 mb-4">
            {message}
          </div>
        </div>
        <div className="tv-route-leaving-guard-modal__buttons tv-display-flex">
          <TransvoiceButton
            type="line-primary"
            onClick={() => {
              onCloseClicked();
            }}
            text={Translate({
              content: 'RouteLeavingGuard.gobackButton',
            })}
            className="tv-route-leaving-guard-modal-area-backBtn"
          />
          <TransvoiceButton
            type="secondary"
            text={Translate({
              content: 'RouteLeavingGuard.confirmButton',
            })}
            onClick={() => {
              onConfirmClick();
            }}
            className="tv-route-leaving-guard-modal-area-submitBtn"
          />
        </div>
      </Modal>
    </div>
  );
};

RouteLeavingGuard.propTypes = propTypes;
RouteLeavingGuard.defaultProps = defaultProps;

export default RouteLeavingGuard;
