import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DateRange } from 'react-date-range';
import { FaRegCalendar } from 'react-icons/fa';
import IfComponent from '../IfComponent';
import './styles.scss';

const SingleCalendarDateRangePicker = ({
  range,
  onDateRangeChange,
  isTouched,
  error,
}) => {
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const containerRef = useRef(null);
  const fromDateRef = useRef(null);
  const toDateRef = useRef(null);
  const [focusedRange, setFocusedRange] = useState([0, 0]);

  useEffect(() => {
    if (!showDateRangePicker && fromDateRef.current && toDateRef.current) {
      fromDateRef.current.blur();
      toDateRef.current.blur();
    }
  }, [showDateRangePicker]);

  const handleOutsideClick = e => {
    const { currentTarget } = e;
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        setShowDateRangePicker(false);
      }
    }, 300);
  };

  const updateClassNames = ({ startDate, endDate }) => {
    const dayElements = document.querySelectorAll('[data-iso-date]');

    dayElements.forEach(e => {
      const dateVal = e.getAttribute('data-iso-date');
      const day = new Date(dateVal);

      if (day.getTime() < startDate.getTime()) {
        e.parentNode.parentNode.setAttribute('data-class-name', 'before');
      } else if (day.getTime() > endDate.getTime()) {
        e.parentNode.parentNode.setAttribute('data-class-name', 'after');
      } else if (
        day.getTime() >= startDate.getTime() &&
        day.getTime() <= endDate.getTime()
      ) {
        e.parentNode.parentNode.setAttribute('data-class-name', 'in-between');
      }
    });
  };

  useEffect(() => {
    if (showDateRangePicker) {
      if (fromDateRef.current && focusedRange[1] === 0) {
        fromDateRef.current.focus();
      }
      if (toDateRef.current && focusedRange[1] === 1) {
        toDateRef.current.focus();
      }
    }
  }, [focusedRange]);

  const handleDateClick = (isEnd = false) => {
    if (
      isEnd &&
      !showDateRangePicker &&
      range.startDate.getTime() === range.endDate.getTime()
    ) {
      setTimeout(() => {
        setFocusedRange([0, 1]);
      }, 200);
    }
    setShowDateRangePicker(show => !show);
    setTimeout(() => {
      updateClassNames(range);
    }, 200);
  };

  const handleDateChange = (ranges, currentFocusedRange) => {
    const { range1 } = ranges;
    let newRange = { ...range1 };
    let shouldCloseDatePicker = true;

    if (
      newRange?.startDate?.getTime() < range?.startDate?.getTime() &&
      currentFocusedRange[1] === 1
    ) {
      newRange = {
        ...range1,
        startDate: range1.startDate,
        endDate: range1.startDate,
      };
      setTimeout(() => {
        setFocusedRange(currentFocusedRange);
      }, 300);
      shouldCloseDatePicker = false;
    }
    if (newRange.startDate.getTime() === newRange.endDate.getTime()) {
      shouldCloseDatePicker = false;
    }
    updateClassNames(newRange);
    onDateRangeChange(newRange);

    if (shouldCloseDatePicker) {
      setTimeout(() => {
        setShowDateRangePicker(false);
      }, 500);
    }
  };

  const handleRangeFocusChange = nextFocusRange => {
    setFocusedRange(nextFocusRange);
  };

  const formatDate = date => {
    if (!date) return '-';
    return moment(date).format('DD/MM - YY');
  };

  const [startDate, endDate] = useMemo(() => {
    const start = formatDate(range.startDate);
    let end = '';

    if (range.startDate.getTime() !== range.endDate.getTime()) {
      end = formatDate(range.endDate);
    }

    return [start, end];
  }, [range, isTouched]);

  const customDayContent = day => (
    <span data-iso-date={day.toISOString() || ''}>{day.getDate()}</span>
  );

  return (
    <div
      className="tv-date-range-picker-container"
      ref={containerRef}
      onBlur={handleOutsideClick}
    >
      <div className="tv-date-range-picker-fields">
        <div className="tv-date-range-picker-fields-section">
          <div className="tv-date-range-picker-fields-section-title">Från</div>
          <button
            className={`tv-date-range-picker-fields-section-date ${
              error.startDate ? 'error' : ''
            }`}
            onClick={handleDateClick}
            ref={fromDateRef}
          >
            {startDate || <span className="placeholder">Från dag</span>}
            <div className="tv-date-range-picker-fields-section-icon">
              <FaRegCalendar />
            </div>
          </button>
        </div>
        <div className="tv-date-range-picker-fields-section">
          <div className="tv-date-range-picker-fields-section-title">Till</div>
          <button
            className={`tv-date-range-picker-fields-section-date ${
              error.endDate ? 'error' : ''
            }`}
            onClick={() => handleDateClick(true)}
            ref={toDateRef}
          >
            {endDate || <span className="placeholder">Till dag</span>}
            <div className="tv-date-range-picker-fields-section-icon">
              <FaRegCalendar />
            </div>
          </button>
        </div>
      </div>
      <IfComponent
        condition={showDateRangePicker}
        whenTrue={
          <div className="tv-date-range-picker-container-picker calendar-small">
            <DateRange
              showDateDisplay={false}
              ranges={[range]}
              onChange={newRanges => handleDateChange(newRanges, focusedRange)}
              rangeColors={['#E1E7FE']}
              focusedRange={focusedRange}
              onRangeFocusChange={handleRangeFocusChange}
              dayContentRenderer={d => customDayContent(d)}
              onShownDateChange={() =>
                setTimeout(() => {
                  updateClassNames(range);
                }, 200)
              }
            />
          </div>
        }
      />
    </div>
  );
};

export default SingleCalendarDateRangePicker;
