import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { GLOBAL_DATE_FORMAT } from '../../../Share/constants';
import DropdownMenu from '../DropdownMenu';
import CalendarSmall from '../CalendarSmall';

import './style.scss';

const propTypes = {
  className: PropTypes.string,
  onEventChange: PropTypes.func,
  ranges: PropTypes.shape({}),
  enabledEarlierDates: PropTypes.bool,
  disabledWeeky: PropTypes.bool,
};

const defaultProps = {
  className: '',
  onChange: () => {},
  ranges: {
    startDate: moment(),
    endDate: moment(),
    key: 'selection',
  },
  displayFormat: '',
  enabledEarlierDates: false,
  disabledWeeky: false,
};

const INITAL_STATES = {
  isChecked: false,
};

class TransvoiceDateRangePicker extends Component {
  constructor(props) {
    super(props);
    this.state = INITAL_STATES;
  }
  handleToggleSwitchChange = () => {
    const { isChecked } = this.state;
    this.setState({
      isChecked: !isChecked,
    });
  };
  render() {
    const {
      className,
      onChange,
      ranges,
      enabledEarlierDates,
      displayFormat,
      disabledWeeky,
    } = this.props;
    const { isChecked } = this.state;
    const { startDate, endDate } = ranges;
    const buttondisplayFormat =
      displayFormat !== '' ? displayFormat : GLOBAL_DATE_FORMAT;
    const button = (
      <div className="tv-form__date-field">
        {moment(startDate).isSame(endDate, 'day')
          ? endDate.format(buttondisplayFormat)
          : `${startDate.format(buttondisplayFormat)} to ${endDate.format(
              buttondisplayFormat,
            )}`}
      </div>
    );
    return (
      <DropdownMenu
        className={`tv-date-range-picker__container ${className}`}
        ref={el => {
          this.filterElement = el;
        }}
        button={button}
      >
        <div className="tv-date-range-picker__dialog">
          <div className="calendar-small">
            <CalendarSmall
              className="calendar-small"
              ranges={ranges}
              isMinDate={true}
              onEventChange={e => onChange(e, this.filterElement)}
              isChecked={isChecked}
              minDateSet={
                enabledEarlierDates
                  ? moment().subtract(20, 'month').toDate()
                  : moment().startOf('day').toDate()
              }
              maxDateSet={moment().add(12, 'month').toDate()}
              showToggle={false}
              onToggleSwitchChange={this.handleToggleSwitchChange}
              {...(enabledEarlierDates === true
                ? {
                    isMinDate: false,
                  }
                : {})}
              {...(disabledWeeky === true
                ? {
                    showToggle: false,
                  }
                : {})}
            />
          </div>
        </div>
      </DropdownMenu>
    );
  }
}

TransvoiceDateRangePicker.propTypes = propTypes;
TransvoiceDateRangePicker.defaultProps = defaultProps;

export default TransvoiceDateRangePicker;
