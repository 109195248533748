import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import TimePicker from './rc-time-picker';
import './rc-time-picker/assets/index.scss';

const propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  handleClick: PropTypes.func,
  handleValueChange: PropTypes.func,
  defaultValue: PropTypes.shape({}),
  rest: PropTypes.shape({}),
};

const defaultProps = {
  className: '',
  isDisabled: false,
  handleClick: () => {},
  handleValueChange: () => {},
  defaultValue: moment(),
  rest: {},
};

const TransvoiceTimePicker = ({ className, isDisabled, handleClick, defaultValue, handleValueChange, ...rest }) => {
  return (
    <TimePicker
      {...rest}
      defaultValue={defaultValue}
      showSecond={false}
      disabled={isDisabled}
      className={className}
      minuteStep={5}
      onChange={handleValueChange}
    />
  );
};

TransvoiceTimePicker.propTypes = propTypes;
TransvoiceTimePicker.defaultProps = defaultProps;

export default TransvoiceTimePicker;
