import React, { useEffect, useState } from 'react';
import MessageServiceForm from './MessageServiceForm';
import './style.scss';
import { useSelector } from 'react-redux';
import MessageServicePreview from './MessageServicePreview';
import { createDefaultMessageService } from '../../../Share/utils';

function MessageServiceModal({ isVisible, onCloseClicked }) {
  const [skills, setSkills] = useState([]);
  const [state, setState] = useState(createDefaultMessageService());
  const [displayForm, setDisplayForm] = useState(isVisible);
  const [displayPreview, setDisplayPreview] = useState(false);
  const languages = useSelector(state => state.skill.skills);

  useEffect(() => {
    if (languages.length > 0) {
      let list = [];
      languages.forEach(item => {
        const language = {
          name: item.SkillName,
          value: item.SkillIdentifier,
        };
        list.push(language);
      });
      setSkills([...list]);
    }
  }, [languages]);

  const handleChange = event => {
    setState({ ...state, ...event });
  };

  const handleSubmit = () => {
    setDisplayForm(false);
    setDisplayPreview(true);
  };

  const onContinueBooking = () => {
    setDisplayPreview(false);
    setDisplayForm(true);
    setState(createDefaultMessageService());
  };

  return (
    <div>
      <MessageServiceForm
        skills={skills}
        item={state}
        setState={setState}
        handleChange={handleChange}
        isVisible={displayForm}
        onCloseClicked={onCloseClicked}
        handleSubmit={handleSubmit}
      />
      <MessageServicePreview
        item={state}
        isVisible={displayPreview}
        onCloseClicked={onCloseClicked}
        onContinueBooking={onContinueBooking}
      />
    </div>
  );
}

export default MessageServiceModal;
