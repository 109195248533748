import * as actionTypes from '../actions/ActionTypes';
import { LOCAL_STORE_LOCATION_ONSITE_ID, LOCAL_STORE_LOCATION_PHONE_ID } from '../../Share/constants';

const INITIAL_STATE = {
  phone: localStorage.getItem(LOCAL_STORE_LOCATION_PHONE_ID),
  onSite: localStorage.getItem(LOCAL_STORE_LOCATION_ONSITE_ID),
};

function localRemove() {
  localStorage.removeItem(LOCAL_STORE_LOCATION_PHONE_ID);
  localStorage.removeItem(LOCAL_STORE_LOCATION_ONSITE_ID);
}

const locationType = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.RESET_APP:
      localRemove();
      return INITIAL_STATE;

    case actionTypes.UPDATE_ARTICLE:
    {
      const { phone, onSite } = action;
      localStorage.setItem(LOCAL_STORE_LOCATION_PHONE_ID, phone);
      localStorage.setItem(LOCAL_STORE_LOCATION_ONSITE_ID, onSite);
      return {
        ...state,
        phone,
        onSite,
      };
    }

    default:
      return state;
  }
};

export default locationType;
