/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import moment from 'moment';
import 'moment/locale/sv';
import { availabilityStatus } from '../../constants';
import Translate from '../Translate';
import EventItem from '../../components/WeeklyCalendar/EventItem';
import IfComponent from '../IfComponent';
import CloseButton from '../CloseButton';
import {
  get24HoursInday,
  generateUniqueId,
  // getDateRangeFromWeek,
  // getRangeOfDates,
  // filterEventInDay,
  // filterEventInWeek,
  // filterAvailabilityInDay,
} from '../../utils';
import './style.scss';

import { LOCAL_STORE_LANGUAGE } from '../../constants/localStorage';
import { TimeSlotEditor } from '..';
import { isEqual } from 'lodash';

let lang = localStorage.getItem(LOCAL_STORE_LANGUAGE);
switch (lang) {
  case 'en':
    lang = 'en';
    break;
  case 'se':
    lang = 'sv';
    break;
  default:
    lang = 'sv';
    break;
}

const propTypes = {
  dayAvailability: PropTypes.arrayOf(PropTypes.shape({})),
  eventList: PropTypes.arrayOf(PropTypes.shape({})),
  selectedCalendarDay: PropTypes.shape({}),
  isVisible: PropTypes.bool,
  className: PropTypes.string,
  onCloseClicked: PropTypes.func,
  onOverlayClick: PropTypes.func,
  onEventClick: PropTypes.func,
  onAvailabilityClick: PropTypes.func,
  onExceptionItemEdit: PropTypes.func,
  onDeleteExceptionClick: PropTypes.func,
};

const defaultProps = {
  showAvailabilityDetail: false,
  onExceptionItemEdit: () => {},
  selectedCalendarDay: moment(),
  dayAvailability: [],
  eventList: [],
  isVisible: false,
  className: '',
  onDeleteExceptionClick: () => {},
  onCloseClicked: () => {},
  onOverlayClick: () => {},
  onEventClick: () => {},
  onAvailabilityClick: () => {},
};

const INITIAL_STATE = {
  showDeleteView: false,
  dayAvailabilityExceptions: [],
  dayAvailabilitySchedules: [],
  showAvailabilityDetail: false,
};

const calculateEventAttributes = (event, heightBase = 50) => {
  let top = 0;
  let height = 0;
  if (event) {
    const { from, to } = event;
    const startDay = from.clone().startOf('day');

    if (from.isSame(to, 'day')) {
      height = moment.duration(to.diff(from)).asHours() * heightBase;
      top = moment.duration(from.diff(startDay)).asHours() * heightBase; // add a row in top calendar for > 1 day
    } else {
      // const newToData = from.clone();
      height = 25;
      top = 0;
    }
    return {
      top,
      height,
    };
  }
};
const calculateAvailabilityAttributes = (
  availability,
  selectedCalendarDay,
  heightBase = 50,
) => {
  let top = 0;
  let height = 0;
  if (availability) {
    const { DatetimeFrom, DatetimeTo } = availability;
    const { from, to } = { from: moment(DatetimeFrom), to: moment(DatetimeTo) };
    let [timeFrom, timeTo] = [from.format('HH:mm'), to.format('HH:mm')];
    const startDay = from.clone().startOf('day');
    // add a row in top calendar for > 1 day
    const startSameDay = from
      .clone()
      .startOf('day')
      .isSame(selectedCalendarDay.clone().startOf('day'));
    const endSameDay = to
      .clone()
      .startOf('day')
      .isSame(selectedCalendarDay.clone().startOf('day'));
    if (startSameDay && endSameDay) {
      top = moment.duration(from.diff(startDay)).asHours() * heightBase;
      height = moment.duration(to.diff(from)).asHours() * heightBase;
    } else if (!startSameDay && endSameDay) {
      top = 0;
      height =
        moment
          .duration(to.diff(selectedCalendarDay.clone().startOf('day')))
          .asHours() * heightBase;
      timeFrom = '00:00';
    } else if (startSameDay && !endSameDay) {
      top = moment.duration(from.diff(startDay)).asHours() * heightBase;
      height =
        moment
          .duration(selectedCalendarDay.clone().endOf('day').diff(from))
          .asHours() * heightBase;
      timeTo = '23:59';
    } else if (!startSameDay && !endSameDay) {
      top = 0;
      height = 24 * heightBase;
      timeFrom = '00:00';
      timeTo = '23:59';
    }
    return {
      timeFrom,
      timeTo,
      top,
      height,
    };
  }
};

class DailyPlanner extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
    this.scrollContainer = React.createRef();
    this.hours = get24HoursInday();
  }

  componentDidMount(nextProps) {
    const { isVisible } = this.props;
    this.preventScrollWhenVisible(isVisible);
    this.getExceptionsForTheDay();
    setTimeout(() => {
      document
        .querySelector('div.tk-weekly-calendar__hour-col > div:nth-child(9)')
        .scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }

  onHandlerClose = (e, fn) => {
    this.removeContextualClass();
    if (fn) {
      setTimeout(() => {
        fn(e);
      }, 550);
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      !isEqual(
        nextProps.dayAvailability.sort(),
        prevState.dayAvailabilitySchedules.sort(),
      )
    ) {
      return {
        ...prevState,
        dayAvailabilitySchedules: nextProps.dayAvailability.map(x => ({
          ...x,
          key: `${generateUniqueId()}-scheduleAvailability`,
        })),
      };
    }
    return {
      ...prevState,
    };
  }
  getExceptionsForTheDay = async () => {
    const { selectedCalendarDay, token, getDefaultAvailabilityExceptions } =
      this.props;
    let dayAvailabilityExceptions = [];
    try {
      const dayAvailabilityExceptionsResponse =
        await getDefaultAvailabilityExceptions(
          token,
          selectedCalendarDay
            .clone()
            .startOf('day')
            .format('yyyy-MM-DD HH:mm:ss'),
          selectedCalendarDay
            .clone()
            .endOf('day')
            .format('yyyy-MM-DD HH:mm:ss'),
        );
      dayAvailabilityExceptions =
        dayAvailabilityExceptionsResponse.data?.DefaultAvailabilityExceptions ||
        [];
      this.setState({
        ...this.state,
        dayAvailabilityExceptions,
      });
    } catch {}
  };
  handleavailabilityBarClick = (event, availabilityType) => {
    const availabilityDay = event.target.getAttribute('data-date') || '';
    if (availabilityDay !== '') {
      this.props.onAvailabilityClick(
        event,
        moment(availabilityDay),
        availabilityType,
      );
    }
  };
  removeContextualClass = () => {
    const htmlDom = document.getElementsByTagName('html')[0];
    if (htmlDom.classList.contains('tv-is-clipped2')) {
      htmlDom.classList.remove('tv-is-clipped2');
      htmlDom.classList.remove('js-slide-in2');
    }
  };
  preventScrollWhenVisible = isVisible => {
    const htmlDom = document.getElementsByTagName('html')[0];
    if (isVisible && !htmlDom.classList.contains('tv-tv-is-clipped2')) {
      htmlDom.classList.add('tv-is-clipped2');
      setTimeout(() => {
        htmlDom.classList.add('js-slide-in2');
      }, 10);
    }
  };
  isExceptionCanBeUpdated = excepionItem =>
    excepionItem.CanBeUpdated &&
    moment(excepionItem.DatetimeFrom).diff(moment()) > 0;
  handleValidateTimeSlot = () => ({ isValid: true, errorMessage: '' });
  render() {
    const availabilityInDay = [];
    const dateItem = moment();
    let eventsData = [];
    const itemsInday = [];
    const items = [...this.props.eventList];
    const {
      selectedCalendarDay,
      className,
      isVisible,
      onCloseClicked,
      onOverlayClick,
      onEventClick,
      onExceptionItemEdit,
      onDeleteExceptionClick,
    } = this.props;
    const { dayAvailabilityExceptions, dayAvailabilitySchedules } = this.state;
    let loop = true;
    while (loop) {
      let groupItem = [];
      let saveIndex = [0];
      groupItem = [...groupItem, items[0]];
      items.forEach((val, index) => {
        if (
          items[0].from.isBetween(val.from, val.to, null, '()') ||
          items[0].to.isBetween(val.from, val.to, null, '()') ||
          (items[0].from.isSame(val.from) && items[0].to.isSame(val.to))
        ) {
          if (index > 0) {
            groupItem = [...groupItem, val];
            saveIndex = [...saveIndex, index];
          }
        }
      });
      for (let id = saveIndex.length - 1; id >= 0; id -= 1) {
        items.splice(saveIndex[id], 1);
        if (items.length === 0) {
          loop = false;
        }
      }
      eventsData = [...eventsData, groupItem];
    }
    if (itemsInday.length > 0) {
      eventsData = [...eventsData, ...itemsInday];
    }
    const dateArray = selectedCalendarDay
      .locale(lang)
      .format('D MMMM')
      .split(' ')
      .map(x => x[0].toUpperCase() + x.substring(1));
    const displayDate = `${dateArray.join(' ')}, ${selectedCalendarDay
      .locale(lang)
      .format('dddd')
      .split(' ')
      .map(x => x[0].toUpperCase() + x.substring(1))
      .join(' ')}`;

    return (
      <IfComponent
        condition={isVisible}
        whenTrue={
          <div className={`tv-workassignment__drawer2-wrapper2 ${className}`}>
            <div
              className="tv-workassignment__drawer2-overlay2"
              onClick={e => {
                this.onHandlerClose(e, onOverlayClick);
              }}
              onKeyPress={e => {
                this.onHandlerClose(e, onOverlayClick);
              }}
              role="button"
              tabIndex="-1"
            />
            <div
              className="tv-workassignment__drawer2-holder2"
              style={{ zIndex: '15' }}
            >
              <CloseButton
                onClick={e => {
                  this.onHandlerClose(e, onCloseClicked);
                }}
                className="tv-workassignment__drawer2-close2 tv-buttons__type icon-close"
              />
              <div>
                <div className="tv-youravailability__list-drawer tv-display-flex-coloumn">
                  <div className="mt-2">
                    <h4 style={{ marginLeft: '10px' }}>
                      <span className="tv-youravailability__item-text-datetime">
                        {displayDate}
                      </span>
                    </h4>
                  </div>
                  <div>
                    <Scrollbars
                      ref={this.scrollContainer}
                      className="tk-weekly-calendar__daily-planner-scroll tv-bottom-line"
                      style={{ height: '50vh !important' }}
                    >
                      <div
                        className="tk-weekly__main tk-weekly-calendar__main tv-display-flex"
                        style={{ rightMargin: '5px' }}
                      >
                        <div className="tk-weekly-calendar__hour-col">
                          {this.hours.map((item, i) => (
                            <div
                              key={i.toString()}
                              className="tk-weekly-calendar__item-row hour-name"
                              style={{ borderLeft: '0px', borderTop: '0px' }}
                            >
                              {item}
                            </div>
                          ))}
                        </div>
                        <div className="tk-weekly-calendar__day-col">
                          <div className="tk-weekly-calendar__container">
                            {this.hours.map(() => (
                              <div className="tk-weekly-calendar__item-row" />
                            ))}
                            {eventsData.map((event, indexGroup) => {
                              if (Array.isArray(event)) {
                                return (
                                  <React.Fragment key={indexGroup.toString()}>
                                    {event.map((x, i) => {
                                      const attributesEvent =
                                        calculateEventAttributes(x);
                                      return (
                                        <EventItem
                                          {...x}
                                          {...attributesEvent}
                                          index={i}
                                          length={event.length}
                                          onClick={onEventClick}
                                          key={generateUniqueId()}
                                          indexGroup={indexGroup}
                                          coverElementRef={this.scrollContainer}
                                          dateRangeFromWeekEnd={selectedCalendarDay
                                            .clone()
                                            .endOf('day')}
                                          dateRangeFromWeekStart={selectedCalendarDay
                                            .clone()
                                            .startOf('day')}
                                        />
                                      );
                                    })}
                                  </React.Fragment>
                                );
                              }
                              const attributes =
                                calculateEventAttributes(event);
                              return (
                                <EventItem
                                  {...event}
                                  {...attributes}
                                  onClick={onEventClick}
                                  key={generateUniqueId()}
                                />
                              );
                            })}
                            {dayAvailabilitySchedules.map(available => {
                              const { top, height, timeFrom, timeTo } =
                                calculateAvailabilityAttributes(
                                  available,
                                  selectedCalendarDay,
                                );
                              const { from, to } = {
                                from: moment(available.DatetimeFrom),
                                to: moment(available.DatetimeTo),
                              };
                              const left =
                                available &&
                                (from
                                  .clone()
                                  .startOf('day')
                                  .diff(selectedCalendarDay, 'days') > 0
                                  ? from
                                      .clone()
                                      .startOf('day')
                                      .diff(selectedCalendarDay, 'days') * 100
                                  : 0);
                              const dateItemString =
                                selectedCalendarDay.format('YYYY-MM-DD');
                              const opacityOfBar = 0.5; // (4 * (parseInt(indexgroup, 10) + 1)) / (10 * dayAvailability.length);
                              return (
                                <div
                                  style={{
                                    top,
                                    height,
                                    position: 'absolute',
                                    left,
                                    zIndex: -1,
                                    opacity: opacityOfBar,
                                    backgroundColor:
                                      available.articleType ===
                                      availabilityStatus.UNAVAILABLE
                                        ? 'red'
                                        : '',
                                  }}
                                  key={available.key}
                                  className="tk-weekly-calendar__availability-bar"
                                  data-date={dateItemString}
                                  data-timefrom={available.DatetimeFrom}
                                  data-timeto={available.DatetimeTo}
                                >
                                  {`${timeFrom}-${timeTo}`}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </Scrollbars>
                  </div>
                  <div>
                    <h6 style={{ marginLeft: '10px' }}>
                      <span className="tv-youravailability__item-text-mainlables">
                        <Translate content="defaultAvailability.availabilityExceptionForDay" />
                      </span>
                    </h6>
                    <div className="ml-2 d-flex flex-wrap">
                      {dayAvailabilityExceptions.map(exceptionItem => (
                        <div
                          className={`d-flex flex-column tv-dailyplanner_badge-availability 
                          ${
                            exceptionItem.IsAvailable
                              ? 'available'
                              : 'unavailable'
                          } ${
                            selectedCalendarDay - moment() > 0 ? '' : 'disabled'
                          }`}
                          title="Redigera"
                          onClick={() => {
                            if (this.isExceptionCanBeUpdated(exceptionItem)) {
                              onExceptionItemEdit(exceptionItem);
                            }
                          }}
                        >
                          <span>
                            {moment(
                              exceptionItem.DatetimeFrom,
                              'yyyy-MM-DD HH:mm:ss',
                            ).format('HH:mm')}
                          </span>
                          <span>
                            {moment(
                              exceptionItem.DatetimeTo,
                              'yyyy-MM-DD HH:mm:ss',
                            ).format('HH:mm')}
                          </span>
                          {this.isExceptionCanBeUpdated(exceptionItem) ? (
                            <div
                              className="tv-dailyplanner_badge-availability-delete"
                              title="Ta bort"
                              onClick={e => {
                                e.stopPropagation();
                                onDeleteExceptionClick(
                                  exceptionItem.ExceptionIdentifier,
                                );
                              }}
                            />
                          ) : null}
                        </div>
                      ))}
                      {selectedCalendarDay - moment() > 0 ||
                      moment()
                        .startOf('day')
                        .isSame(selectedCalendarDay.clone().startOf('day')) ? (
                        <div
                          className="ml-2 d-flex flex-column align-self-center py-3 tv-dailyplanner_badge-availability add"
                          onClick={() => {
                            onExceptionItemEdit({
                              DatetimeFrom: selectedCalendarDay.format(
                                'yyyy-MM-DD HH:mm:ss',
                              ),
                              DatetimeTo: selectedCalendarDay
                                .clone()
                                .add(1, 'hours')
                                .format('yyyy-MM-DD HH:mm:ss'),
                              ExceptionIdentifier: null,
                              IsAvailable: true,
                            });
                          }}
                        >
                          +{' '}
                          <Translate content="defaultAvailability.exception" />
                        </div>
                      ) : dayAvailabilityExceptions.length == 0 ? (
                        <div className="d-flex flex-column tv-dailyplanner_badge-availability info">
                          <Translate content="defaultAvailability.noException" />
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        whenFalse={null}
      />
    );
  }
}

DailyPlanner.propTypes = propTypes;
DailyPlanner.defaultProps = defaultProps;

export default DailyPlanner;
