import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import {
  DropdownMenu,
  Translate,
  IfComponent,
} from '../../../Share/components';
import { isIE } from '../../../Share/utils';
import { LOCAL_STORE_CUSTOMER_NUMBER } from '../../../Share/constants';
import { sleepTime } from '../../../Share/utils/helpers';
import { unregisterAirShipChannel } from '../../../Share/utils/pushNotificationUtil';

export default class Avatar extends Component {
  static propTypes = {
    getCustomer: PropTypes.func.isRequired,
    visibleName: PropTypes.bool,
    profile: PropTypes.shape({
      emailAddress: PropTypes.string,
      firstName: PropTypes.string,
      homePhoneNumber: PropTypes.string,
      lastName: PropTypes.string,
      mobilePhoneNumber: PropTypes.string,
      otherPhoneNumber: PropTypes.string,
      postalAddress: PropTypes.object,
      workPhoneNumber: PropTypes.string,
      interpreterId: PropTypes.string,
    }).isRequired,
  };

  componentDidMount() {
    this.props.getCustomer();
  }

  onClickAvatar = () => {
    if (this.avatarElement && this.avatarElement.onHiddenMenu) {
      this.avatarElement.onHiddenMenu();
    }
  };

  onClickLogout = async () => {
    const clientNumber = localStorage.getItem(LOCAL_STORE_CUSTOMER_NUMBER);
    const list = await navigator.serviceWorker.getRegistrations();

    await sleepTime(1000);

    if (list?.length > 0) {
      await unregisterAirShipChannel(clientNumber);
    }

    this.props.logout();
  };

  render() {
    let avatarCircleButton = null;
    if (this.props.profile.image && this.props.profile.image.Image) {
      avatarCircleButton = (
        <React.Fragment>
          <div className="tv-avatar">
            <img
              src={`data:image/${this.props.profile.image.ImageContentType};base64,${this.props.profile.image.Image}`}
              className="tv-avatar__img"
              alt="avatar"
            />
          </div>
          <IfComponent
            condition={this.props.visibleName}
            whenTrue={
              <span
                className={`tv-filter-list__user-name${isIE ? ' ie-fix' : ''}`}
              >
                {this.props.profile.customerName}
              </span>
            }
          />
        </React.Fragment>
      );
    } else {
      avatarCircleButton = (
        <React.Fragment>
          <div className="tv-avatar">
            <img
              src="/images/Avatar.png"
              className="tv-avatar__img"
              alt="avatar"
            />
          </div>
          <IfComponent
            condition={this.props.visibleName}
            whenTrue={
              <span
                className={`tv-filter-list__user-name${isIE ? ' ie-fix' : ''}`}
              >
                {this.props.profile.customerName}
              </span>
            }
          />
        </React.Fragment>
      );
    }

    return (
      <DropdownMenu
        ref={el => {
          this.avatarElement = el;
        }}
        button={avatarCircleButton}
      >
        <div className="tv-avatar-list__menu">
          <div
            className="tv-avatar-list__menu-item tv-avatar-list__menu-item--customer-name"
            tabIndex="-1"
            onKeyDown={() => {}}
          >
            {`${this.props.profile.customerName} `}
          </div>

          <NavLink
            onClick={this.onClickAvatar}
            to="/settings"
            className="tv-avatar-list__menu-item tv-avatar-list__menu-item--settings"
          >
            <Translate content="avatar.settings" />
          </NavLink>
          <NavLink
            onClick={this.onClickAvatar}
            to="/releases-page"
            className="tv-avatar-list__menu-item tv-avatar-list__menu-item--settings"
          >
            <Translate content="avatar.releasesPage" />
          </NavLink>
          <NavLink
            onClick={this.onClickLogout}
            to="/login"
            className="tv-avatar-list__menu-item tv-avatar-list__menu-item--logout"
          >
            <Translate content="avatar.logout" />
          </NavLink>
        </div>
      </DropdownMenu>
    );
  }
}
