import React from "react";
import PropTypes from "prop-types";
import Dialog from "../Dialog";
import IfComponent from "../IfComponent";

import { assignmentTimeFormat } from "../../utils";
import { APP_PORTAL } from "../../constants";

const propTypes = {
    event: PropTypes.shape({}),
    onClick: PropTypes.func,
    displayListItemContactPerson: PropTypes.bool,
    displayListItemContactPerson: PropTypes.bool,
};

const defaultProps = {
    event: null,
    onClick: () => {},
    displayListItemContactPerson: false,
    visiblePrefix: false,
};

const INITAL_STATES = {
    showDialog: false,
};

class EventItem extends React.Component {
    state = INITAL_STATES;

    onDialogClose = (e) => {
        this.setState({ showDialog: false });
        e.stopPropagation();
    };

    onEventClick = (type, identifier, event) => {
        event.stopPropagation();
        const { onClick } = this.props;
        if (type === "custom") {
            this.setState({ showDialog: true });
        } else {
            onClick(type, identifier);
        }
    };

    onBlur = (event) => {
        const { currentTarget } = event;
        setTimeout(() => {
            if (!currentTarget.contains(document.activeElement)) {
                this.setState({ showDialog: false });
                event.persist();
            }
        }, 0);
    };

    // visiblePrefix=function(){
    //     return !this.props.displayListItemContactPerson
    // }
    AssignmentTime = function (from, to,visiblePrefix) {
        var isSameDate=from.format('DD')==(to.format("DD"))

        return assignmentTimeFormat(from, to, "from", visiblePrefix,isSameDate)
    }

    render() {
        const { event, displayListItemContactPerson,visiblePrefix } = this.props;
        if (!event) {
            return null;
        }
        const {
            type,
            identifier,
            from,
            to,
            title,
            location,
            googleEventWithoutTime,
            description,
            orderStatus,
            assignment,
        } = event;
        const { showDialog } = this.state;
        const clientName =
            assignment && assignment.Customer
                ? assignment.Customer.CustomerName
                : "";
        let eventTitle = clientName || title;
        let ContactPersonName = "";

        if (APP_PORTAL === "client-portal") {
            eventTitle =
                assignment && assignment.Skill ? assignment.Skill : title;
            if (displayListItemContactPerson) {
                ContactPersonName =
                    assignment &&
                    assignment.ContactPerson &&
                    assignment.ContactPerson.ContactPersonName
                        ? assignment.ContactPerson.ContactPersonName
                        : title;
            }
        }

        return (
            <div
                role="button"
                tabIndex="-1"
                onBlur={this.onBlur}
                className="tv-position-rel tk-monthly-calendar__item-row"
                style={{ backgroundColor: "white" }}
            >
                <Dialog
                    isVisible={showDialog}
                    onClose={this.onDialogClose}
                    className="tk-weekly-calendar__dialog"
                >
                    <h2 className="tk-weekly-calendar__dialog-title">
                        { eventTitle }
                    </h2>
                    {googleEventWithoutTime || (
                        <div className="tk-weekly-calendar__dialog-time icon-calendar">
                            {assignmentTimeFormat(
                                from,
                                to,
                                "From {from} - To {to}"
                            )}
                        </div>
                    )}
                    <div className="tk-weekly-calendar__dialog-location">
                        {location}
                    </div>
                    <div className="tv-right-news__description">
                        {description}
                    </div>
                </Dialog>
                <div
                    onClick={(e) => this.onEventClick(type, identifier, e)}
                    onKeyPress={(e) => this.onEventClick(type, identifier, e)}
                    tabIndex="-1"
                    className={`tk-monthly-calendar__item-el tk-monthly-calendar__item-el--${orderStatus} tk-monthly-calendar__item-el--${type}`}
                >
                    <div className="tk-monthly-calendar__item-title">
                        {eventTitle}
                    </div>
                    <div className="tk-monthly-calendar__item-time tk-monthly-calendar__item-title">
                        {googleEventWithoutTime || this.AssignmentTime(from, to,visiblePrefix) }
                    </div>

                    <IfComponent
                        condition={displayListItemContactPerson}
                        whenTrue={
                            <div className="tk-monthly-calendar__item-time tk-monthly-calendar__item-title">
                                {ContactPersonName}
                            </div>
                        }
                    />
                </div>
            </div>
        );
    }
}

EventItem.propTypes = propTypes;
EventItem.defaultProps = defaultProps;

export default EventItem;
