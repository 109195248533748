import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'moment/locale/sv';
import IfComponent from '../IfComponent';
import Translate from '../Translate';
import Header from './Header';
import EventItem from './EventItem';
import SingleEvent from './SingleEvent';
import { availabilityStatus } from '../../constants';
import {
  get24HoursInday,
  generateUniqueId,
  getDateRangeFromWeek,
  getRangeOfDates,
  filterEventInDay,
  filterEventInWeek,
  filterAvailabilityInDay,
} from '../../utils';
import './style.scss';
import './responsive.scss';
import { checkIfWeekend } from '../../utils/dateUtil';

const propTypes = {
  className: PropTypes.string,
  date: PropTypes.shape({}),
  selectedEvent: PropTypes.shape({}),
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  onEventClick: PropTypes.func,
  onDayClick: PropTypes.func,
  onCreateOrder: PropTypes.func,
  children: PropTypes.node,
  events: PropTypes.arrayOf(PropTypes.shape({})),
  availability: PropTypes.arrayOf(PropTypes.shape({})),
};

const defaultProps = {
  className: '',
  selectedEvent: {},
  date: moment(),
  onPrevious: () => {},
  onNext: () => {},
  onEventClick: () => {},
  onDayClick: () => {},
  onCreateOrder: () => {},
  children: null,
  events: [],
  availability: [],
};

const calculateEventAttributes = (event, heightBase = 50) => {
  let top = 0;
  let height = 0;
  if (event) {
    const { from, to } = event;
    const startDay = from.clone().startOf('day');

    if (from.isSame(to, 'day')) {
      height = moment.duration(to.diff(from)).asHours() * heightBase;
      top = moment.duration(from.diff(startDay)).asHours() * heightBase + 50; // add a row in top calendar for > 1 day
    } else {
      // const newToData = from.clone();
      height = 25;
      top = 0;
    }
    return {
      top,
      height,
    };
  }
};
const calculateAvailabilityAttributes = (
  availability,
  selectedCalendarDay,
  heightBase = 50,
) => {
  let top = 0;
  let height = 0;
  if (availability) {
    const { DatetimeFrom, DatetimeTo } = availability;
    const { from, to } = {
      from: moment(DatetimeFrom),
      to: moment(DatetimeTo),
    };
    let [timeFrom, timeTo] = [from.format('HH:mm'), to.format('HH:mm')];
    const startDay = from.clone().startOf('day');
    // add a row in top calendar for > 1 day
    const startSameDay = from
      .clone()
      .startOf('day')
      .isSame(selectedCalendarDay.clone().startOf('day'));
    const endSameDay = to
      .clone()
      .startOf('day')
      .isSame(selectedCalendarDay.clone().startOf('day'));
    if (startSameDay && endSameDay) {
      top = moment.duration(from.diff(startDay)).asHours() * heightBase;
      height = moment.duration(to.diff(from)).asHours() * heightBase;
    } else if (!startSameDay && endSameDay) {
      top = 0;
      height =
        moment
          .duration(to.diff(selectedCalendarDay.clone().startOf('day')))
          .asHours() * heightBase;
      timeFrom = '00:00';
    } else if (startSameDay && !endSameDay) {
      top = moment.duration(from.diff(startDay)).asHours() * heightBase;
      height =
        moment
          .duration(selectedCalendarDay.clone().endOf('day').diff(from))
          .asHours() * heightBase;
      timeTo = '23:59';
    } else if (!startSameDay && !endSameDay) {
      top = 0;
      height = 24 * heightBase;
      timeFrom = '00:00';
      timeTo = '23:59';
    }
    return {
      timeFrom,
      timeTo,
      top,
      height,
    };
  }
};
class WeeklyCalendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
    };
    this.scrollContainer = React.createRef();
    this.hours = get24HoursInday();
  }

  componentDidMount() {
    window.addEventListener('resize', () =>
      this.setState({
        windowWidth: window.innerWidth,
      }),
    );
  }
  handleavailabilityBarClick = (event, articleType) => {
    const availabilityDay = event.target.getAttribute('data-date') || '';
    if (availabilityDay !== '') {
      this.props.onAvailabilityClick(
        event,
        moment(availabilityDay),
        articleType,
      );
    }
  };

  render() {
    const {
      className,
      date,
      onPrevious,
      onNext,
      children,
      events,
      selectedEvent,
      onEventClick,
      availability,
      onDayClick,
      onCreateOrder,
      onCreateDirectBooking,
    } = this.props;
    const { week, year } = date;
    const dateRangeFromWeek = getDateRangeFromWeek(week, year);
    const dates = getRangeOfDates(
      dateRangeFromWeek.start,
      dateRangeFromWeek.end,
      'day',
    );
    const eventsAllDays = filterEventInWeek(events, dates).filter(
      x => !x.from.isSame(x.to, 'day'),
    );
    eventsAllDays.map(x => {
      x.isCheck = false;
      return x;
    });
    const eventDataAllDay = [];
    const { windowWidth } = this.state;
    [...eventsAllDays].forEach((x, index) => {
      const groupsAllDays = [];
      [...eventsAllDays].forEach(v => {
        if (
          x.from.isBetween(v.from, v.to, 'day', '[]') ||
          x.to.isBetween(v.from, v.to, 'day', '[]')
        ) {
          if (!v.isCheck) {
            v.isCheck = true;
            v.number = index;
            groupsAllDays.push(v);
          }
        }
      });
      if (groupsAllDays.length > 0) {
        eventDataAllDay.push(groupsAllDays);
      }
    });

    return (
      <div
        className={`tk-calendar__wrapper tk-weekly-calendar__wrapper ${className}`}
      >
        <Header date={date} onPrevious={onPrevious} onNext={onNext}>
          {children}
        </Header>
        <div className="tk-weekly-calendar__day-header tv-display-flex tv-display-mobile-hide">
          {dates.map((dateItem, i) => {
            const toDayClass = moment(dateItem).isSame(moment(), 'day')
              ? 'today'
              : '';
            return (
              <div
                className={`tk-weekly-calendar__day-row week-header ${toDayClass}`}
                key={i.toString()}
              >
                <div className="round-dot" />
                {dateItem.locale('sv').format('dddd D')}
              </div>
            );
          })}
        </div>

        <div className="tk-weekly-calendar-events tv-display-flex flex-column flex-md-row">
          {dates.map((dateItem, i) => {
            const eventInDay = filterEventInDay(events, dateItem, false) || [];
            //  eventInDay = eventInDay.sort((a, b) => a.from - b.from);

            const toDayClass = moment(dateItem).isSame(moment(), 'day')
              ? 'today'
              : '';
            return (
              <div
                className={`tk-weekly-calendar__day-row ${
                  checkIfWeekend(dateItem) ? 'weekend-background' : ''
                }`}
                key={i.toString()}
              >
                <div className="tk-weekly-calendar__item-number tv-display-mobile-show">
                  <span className="tk-weekly-calendar__item-text">
                    {dateItem.locale('sv').format('dddd, DD MMMM')}
                  </span>
                </div>

                <IfComponent
                  condition={!!eventInDay.length}
                  whenTrue={
                    <>
                      {eventInDay.map(item => (
                        <SingleEvent
                          key={item.OrderIdentifier}
                          selectedEvent={selectedEvent}
                          onClick={onEventClick}
                          date={dateItem}
                          event={item}
                        />
                      ))}
                    </>
                  }
                  whenFalse={
                    <div className="tk-weekly-calendar__item-empty tv-display-mobile-show">
                      {Translate({
                        content: 'calendar.no_assignments',
                      })}
                    </div>
                  }
                />
                <IfComponent
                  condition={dateItem.diff(moment().startOf('day'), 'day') > 0}
                  whenTrue={
                    <div
                      className="tk-weekly-calendar__item-add tv-display-mobile-hide"
                      onClick={e => {
                        e.stopPropagation();
                        onCreateOrder(dateItem);
                      }}
                    >
                      +{' '}
                      {Translate({
                        content: 'calendar.addNew',
                      })}
                    </div>
                  }
                />
                <IfComponent
                  condition={moment(dateItem).isSame(moment(), 'day')}
                  whenTrue={
                    <div
                      className="tk-weekly-calendar__item-add tv-display-mobile-hide"
                      onClick={e => {
                        e.stopPropagation();
                        onCreateDirectBooking();
                      }}
                    >
                      +{' '}
                      {Translate({
                        content: 'sideBar.bookNow',
                      })}
                    </div>
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

WeeklyCalendar.propTypes = propTypes;
WeeklyCalendar.defaultProps = defaultProps;

export default WeeklyCalendar;
