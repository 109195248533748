import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import MonthlyCalendar from '../MonthlyCalendar';
import { availabilityStatus, statusMapping } from '../../constants';
import WeeklyCalendar from '../WeeklyCalendar';
import Tabs from './Tabs';
import {
  currentDate,
  getFirstDayAndLastDayOnMonthlyCalendarView,
} from '../../utils/dateUtil';
import Translate from '../Translate';

const propTypes = {
  className: PropTypes.string,
  userEvents: PropTypes.arrayOf(PropTypes.shape({})),
  googleEvents: PropTypes.arrayOf(PropTypes.shape({})),
  onEventClick: PropTypes.func,
  onAvailabilityClick: PropTypes.func,
  onDayClick: PropTypes.func,
  calendar: PropTypes.shape({}),
  selectedEvent: PropTypes.shape({}),
  availability: PropTypes.arrayOf(PropTypes.shape({})),
  onUpdateUserCalendar: PropTypes.func,
  onUpdateUserAvailability: PropTypes.func,
  filterParamater: PropTypes.array,
  displayListItemContactPerson: PropTypes.bool,
  visiblePrefix: PropTypes.bool,
  onAssignmentSummuryPillClicked: PropTypes.func,
  onCreateOrder: PropTypes.func,
  onFilter: PropTypes.func,
  onTabChange: PropTypes.func,
  selectedDate: PropTypes.shape({}),
  quickOrderDateShow: PropTypes.bool,
};

const defaultProps = {
  className: 'tk-calendar__wrapper tv-position-rel',
  events: [],
  googleEvents: [],
  selectedDate: null,
  selectedEvent: {},
  quickOrderDateShow: false,
  onEventClick: () => {},
  onDayClick: () => {},
  onAvailabilityClick: () => {},
  calendar: {
    week: {
      year: moment().year(),
      number: moment().week(),
    },
    month: {
      year: moment().year(),
      number: moment().month(),
    },
    selected: 'week',
  },
  availability: [],
  onUpdateUserCalendar: () => {},
  onUpdateUserAvailability: () => {},
  filterParamater: ['all-assignments'],
  displayListItemContactPerson: false,
  visiblePrefix: false,
  onAssignmentSummuryPillClicked: () => {},
  onCreateOrder: () => {},
  onFilter: () => {},
  onTabChange: () => {},
};

const nextOrPreviousWeekly = (week, action) => {
  const { year, number } = week;
  const dateWeek = moment(`${year}-${number}`, 'gggg-ww')[action](1, 'weeks');
  return {
    week: {
      year: parseInt(dateWeek.format('gggg'), 10),
      number: parseInt(dateWeek.format('ww'), 10),
    },
    month: {
      year: dateWeek.year(),
      number: dateWeek.month(),
    },
    range: {
      from: getFirstDayAndLastDayOnMonthlyCalendarView(
        dateWeek.year(),
        dateWeek.month(),
      ).firstOfMonthlyCalendarView,
      to: getFirstDayAndLastDayOnMonthlyCalendarView(
        dateWeek.year(),
        dateWeek.month(),
      ).lastOfMonthCalendarView,
    },
  };
};

const nextOrPreviousMonthly = (month, action) => {
  const { year, number } = month;
  const date = moment(`${year}`)
    .startOf('month')
    .month(number)
    [action](1, 'months');

  return {
    month: {
      year: date.year(),
      number: date.month(),
    },
    week: {
      year: parseInt(date.format('gggg'), 10),
      number: parseInt(date.format('ww'), 10),
    },
    range: {
      from: getFirstDayAndLastDayOnMonthlyCalendarView(
        date.year(),
        date.month(),
      ).firstOfMonthlyCalendarView,
      to: getFirstDayAndLastDayOnMonthlyCalendarView(date.year(), date.month())
        .lastOfMonthCalendarView,
    },
  };
};

class Calendar extends React.Component {
  onWeeklyPrevious = () => {
    const { calendar, onUpdateUserCalendar } = this.props;
    onUpdateUserCalendar({
      ...calendar,
      ...nextOrPreviousWeekly(calendar.week, 'subtract'),
    });
  };
  onWeeklyNext = () => {
    const { calendar, onUpdateUserCalendar } = this.props;
    onUpdateUserCalendar({
      ...calendar,
      ...nextOrPreviousWeekly(calendar.week, 'add'),
    });
  };
  onMonthlyPrevious = () => {
    const { calendar, onUpdateUserCalendar } = this.props;
    onUpdateUserCalendar({
      ...calendar,
      ...nextOrPreviousMonthly(calendar.month, 'subtract'),
    });
  };
  onMonthlyNext = () => {
    const { calendar, onUpdateUserCalendar } = this.props;
    onUpdateUserCalendar({
      ...calendar,
      ...nextOrPreviousMonthly(calendar.month, 'add'),
    });
  };
  onTabClick = selected => {
    const {
      calendar,
      onUpdateUserCalendar,
      onUpdateUserAvailability,
      onTabChange,
    } = this.props;
    onTabChange(selected);
    onUpdateUserCalendar({ ...calendar, selected });
    onUpdateUserAvailability();
  };

  render() {
    const {
      className,
      userEvents,
      googleEvents,
      onEventClick,
      selectedDate,
      quickOrderDateShow,
      selectedEvent,
      calendar,
      onDayClick,
      onAvailabilityClick,
      filterParamater,
      displayListItemContactPerson,
      onAssignmentSummuryPillClicked,
      onCreateOrder,
      onFilter,
      visiblePrefix,
      onCreateDirectBooking,
      currentTime: CurrentTime,
    } = this.props;
    let events = [...userEvents, ...googleEvents];
    const tabs = (
      <Tabs selected={calendar.selected} onTabClick={this.onTabClick} />
    );
    const { availability, unavailability } = this.props;
    const assignmentStatus = [...Object.values(statusMapping)];
    let mergedAvailabilities = [];

    if (filterParamater.indexOf('availability') > -1) {
      mergedAvailabilities = [...mergedAvailabilities, ...availability];
    }

    if (filterParamater.indexOf('unavailability') > -1) {
      mergedAvailabilities = [...mergedAvailabilities, ...unavailability];
    }
    if (filterParamater.indexOf('all-assignments') < 0) {
      const tempEvents = [...userEvents, ...googleEvents];
      events = [];

      assignmentStatus.forEach(x => {
        if (filterParamater.indexOf(x) > -1) {
          events = [
            ...events,
            ...tempEvents.filter(y => y.assignment.OrderStatus === x),
          ];
        }
      });
    }

    if (filterParamater.indexOf('all-assignments') > -1) {
      events = [];
    }

    const selectedWeek = moment(
      `${calendar.week.year}-${calendar.week.number}`,
      'gggg-ww',
    ).startOf('week');
    const [displayWeek, displayYear] = [
      selectedWeek.isoWeek(),
      selectedWeek.isoWeekYear(),
    ];
    const selectedCalendar = {
      month: (
        <MonthlyCalendar
          events={events}
          availability={mergedAvailabilities}
          onEventClick={onEventClick}
          onAvailabilityClick={onAvailabilityClick}
          onDayClick={onDayClick}
          selectedDate={selectedDate}
          quickOrderDateShow={quickOrderDateShow}
          onPrevious={this.onMonthlyPrevious}
          onNext={this.onMonthlyNext}
          onAssignmentSummuryPillClicked={onAssignmentSummuryPillClicked}
          onCreateOrder={onCreateOrder}
          filterParamater={filterParamater}
          date={{
            year: calendar.month.year,
            month: calendar.month.number,
          }}
          displayListItemContactPerson={displayListItemContactPerson}
          visiblePrefix={visiblePrefix}
          onCreateDirectBooking={onCreateDirectBooking}
        >
          {
            <>
              {tabs}
              <CurrentTime className="tv_dashboard__current-time tv-display-mobile-hide" />
            </>
          }
        </MonthlyCalendar>
      ),
      week: (
        <WeeklyCalendar
          events={events}
          availability={mergedAvailabilities}
          onCreateOrder={onCreateOrder}
          selectedEvent={selectedEvent}
          onAvailabilityClick={onAvailabilityClick}
          onDayClick={onDayClick}
          onEventClick={onEventClick}
          onPrevious={this.onWeeklyPrevious}
          onNext={this.onWeeklyNext}
          filterParamater={filterParamater}
          date={{
            year: calendar.week.year,
            week: calendar.week.number,
            month: calendar.month.number,
            displayWeek,
            displayYear,
          }}
          onCreateDirectBooking={onCreateDirectBooking}
        >
          {
            <>
              {tabs}
              <CurrentTime className="tv_dashboard__current-time tv-display-mobile-hide" />
            </>
          }
        </WeeklyCalendar>
      ),
    };

    return (
      <>
        <div className={className}>{selectedCalendar[calendar.selected]}</div>
        {/* <div className="tk-calendar__mobile-fixed-bottom ">
          <button onClick={onFilter} className="tk-calendar__btn-filter">
            {Translate({ content: 'calendar.filter' })}
          </button>
          <button
            className="tk-calendar__btn-newbook"
            onClick={() => onCreateOrder(currentDate().add(1, 'day'))}
          >
           <i className="fa fa-plus mr-2"></i>
                {" "} {Translate({
                  content: 'dashboard.addNew',
                })}
          </button>
        </div> */}
      </>
    );
  }
}

Calendar.propTypes = propTypes;
Calendar.defaultProps = defaultProps;
const mapStateToProps = state => ({
  calendar: state.user.calendar,
  userEvents: state.user.events,
  googleEvents: state.user.googleEvents,
  availability: state.user.availability,
  unavailability: state.user.unavailability,
});

export default connect(mapStateToProps)(Calendar);
