import React from 'react';
import { barChartColors } from '../../../Share/constants';
import './percentageChartStyles.scss';

const PercentageBarChart = ({ data = [], title = '' }) => {
  return (
    <div className="percentage-bar-chart">
      <div className="percentage-bar-chart-title">{title}</div>
      <div className="percentage-bar-chart-table">
        {data?.map(({ category, value }, i) => (
          <div className="percentage-bar-chart-table-row" key={category + i}>
            <div className="title">{category}</div>
            <div className="data-container">
              <div className="bar">
                <div
                  className="bar-fill"
                  style={{
                    width: `${value * 100}%`,
                    backgroundColor: barChartColors[i % barChartColors.length],
                  }}
                ></div>
              </div>
              <div className="value">{(value * 100).toFixed()}%</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PercentageBarChart;
