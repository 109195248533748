import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './style.scss';
import './media-query.scss';

import Header from './Header';
import TableCalendar from './TableCalendar';
import { APP_PORTAL, ENV_CLIENT } from '../../constants';
import Scrollbars from 'react-custom-scrollbars';

const propTypes = {
  className: PropTypes.string,
  date: PropTypes.shape({}),
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  onDayClick: PropTypes.func,
  onEventClick: PropTypes.func,
  onAvailabilityClick: PropTypes.func,
  children: PropTypes.node,
  events: PropTypes.arrayOf(PropTypes.shape({})),
  availability: PropTypes.arrayOf(PropTypes.shape({})),
  displayListItemContactPerson: PropTypes.bool,
  visiblePrefix: PropTypes.bool,
  onCreateOrder: PropTypes.func,
  onAssignmentSummuryPillClicked: PropTypes.func,
  selectedDate: PropTypes.shape({}),
  quickOrderDateShow: PropTypes.bool,
};

const defaultProps = {
  className: '',
  date: moment(),
  onPrevious: () => {},
  onNext: () => {},
  onDayClick: () => {},
  onCreateOrder: () => {},
  onAssignmentSummuryPillClicked: () => {},
  onEventClick: () => {},
  children: null,
  events: [],
  onAvailabilityClick: () => {},
  availability: [],
  displayListItemContactPerson: false,
  visiblePrefix: false,
  selectedDate: null,
  quickOrderDateShow: false,
};

class MonthlyCalendar extends Component {
  constructor(props) {
    super(props);
    this.daysOfWeek = moment.weekdays();
    this.scrollContainer = React.createRef();
  }

  render() {
    const {
      className,
      date,
      onPrevious,
      onNext,
      children,
      selectedDate,
      quickOrderDateShow,
      events,
      onEventClick,
      availability,
      onAvailabilityClick,
      onDayClick,
      onCreateOrder,
      displayListItemContactPerson,
      onAssignmentSummuryPillClicked,
      visiblePrefix,
      onCreateDirectBooking,
    } = this.props;
    const { year, month } = date;
    const isClientPortal = APP_PORTAL === ENV_CLIENT;
    return (
      <div
        className={`tk-calendar__wrapper tk-monthly-calendar__wrapper ${className} ${
          isClientPortal ? '' : 'interpreter'
        }`}
      >
        <Header
          date={date}
          onPrevious={onPrevious}
          onNext={onNext}
          onNewOrderClick={onCreateOrder}
        >
          {children}
        </Header>

        <div className="tk-weekly__main tk-monthly-calendar__main tv-display-flex">
          <TableCalendar
            year={year}
            month={month}
            events={events}
            availability={availability}
            onDayClick={onDayClick}
            selectedDate={selectedDate}
            quickOrderDateShow={quickOrderDateShow}
            onCreateOrder={onCreateOrder}
            onAssignmentSummuryPillClicked={onAssignmentSummuryPillClicked}
            onAvailabilityClick={onAvailabilityClick}
            onEventClick={onEventClick}
            displayListItemContactPerson={displayListItemContactPerson}
            visiblePrefix={visiblePrefix}
            onCreateDirectBooking={onCreateDirectBooking}
          />
        </div>
      </div>
    );
  }
}

MonthlyCalendar.propTypes = propTypes;
MonthlyCalendar.defaultProps = defaultProps;

export default MonthlyCalendar;
