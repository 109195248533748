import React from 'react';
import { Modal, Translate, TransvoiceButton } from '../../../Share/components';
import { isIE } from '../../../Share/utils';
import './style.scss';

const DeviationSuccessMessage = ({
  isVisible,
  onCloseClicked,
  onOverlayClick,
}) => (
  <Modal
    className=" tv-reportquality__modal tv-reportquality_align-unset"
    onCloseClicked={onCloseClicked}
    onOverlayClick={onOverlayClick}
    isVisible={isVisible}
    headerClassName={`${
      isIE ? 'ie-fix-width' : ''
    } tv-workassignment__modal-header `}
    headerText={Translate({
      content: 'report.sendDeviation',
    })}
    ieOptionClassName={isIE ? 'ie-fix-height' : ''}
  >
    <div className="tv-deviation-message">
      <div className="tv-deviation-message-title">
        <Translate content="reportQuality.deviationSuccessTitle" />
      </div>
      <div className="tv-deviation-message-paragraph">
        <Translate content="reportQuality.deviationSuccessMessagePart1" />
      </div>
      <div className="tv-deviation-message-paragraph">
        <Translate content="reportQuality.deviationSuccessMessagePart2" />
      </div>
      <TransvoiceButton
        type="line-primary"
        text={Translate({ content: 'reportQuality.clearButton' })}
        onClick={onCloseClicked}
        className="tv-deviation-message-button"
      />
    </div>
  </Modal>
);

export default DeviationSuccessMessage;
