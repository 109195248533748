import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'react-date-range';
import './style.scss';
import ToggleSwitch from '../ToggleSwitch';
import IfComponent from '../IfComponent';
import Translate from '../Translate';
import * as rdrLocales from 'react-date-range/dist/locale';
import { LOCAL_STORE_LANGUAGE } from '../../constants/localStorage';
import moment from 'moment';

const propTypes = {
  className: PropTypes.string,
  onToggleSwitchChange: PropTypes.func,
  ranges: PropTypes.shape({}),
  onEventChange: PropTypes.func,
  isChecked: PropTypes.bool,
  showToggle: PropTypes.bool,
  isMinDate: PropTypes.bool,
  maxDateSet: PropTypes.shape({}),
  minDateSet: PropTypes.shape({}),
};

const defaultProps = {
  className: '',
  ranges: {},
  onEventChange: () => {},
  onToggleSwitchChange: () => {},
  isChecked: false,
  showToggle: true,
  isMinDate: true,
  maxDateSet: null,
  minDateSet: new Date(),
};

let lang = localStorage.getItem(LOCAL_STORE_LANGUAGE);
switch (lang) {
  case 'en':
    lang = 'enUS';
    break;
  case 'se':
    lang = 'sv';
    break;
  default:
    lang = 'sv';
    break;
}
class CalendarSmall extends Component {
  componentDidUpdate() {
    this.checkWeekday();
  }

  checkWeekday = () => {
    const { isChecked } = this.props;
    const htmlDom = document.querySelectorAll('.rdrDayWeekend .rdrInRange');
    const htmlDomWeekend = document.querySelectorAll(
      '.rdrDayWeekend .rdrStartEdge + .rdrDayNumber, .rdrDayWeekend .rdrEndEdge + .rdrDayNumber',
    );
    if (isChecked) {
      htmlDom.forEach(element => {
        if (!element.classList.contains('not-weekend')) {
          element.classList.add('not-weekend');
        }
      });
      htmlDomWeekend.forEach(element => {
        if (!element.classList.contains('not-weekend')) {
          element.classList.add('not-weekend');
        }
      });
    } else {
      htmlDom.forEach(element => {
        if (element.classList.contains('not-weekend')) {
          element.classList.remove('not-weekend');
        }
      });
      htmlDomWeekend.forEach(element => {
        if (element.classList.contains('not-weekend')) {
          element.classList.remove('not-weekend');
        }
      });
    }
  };
  render() {
    const {
      ranges,
      onEventChange,
      onToggleSwitchChange,
      isChecked,
      showToggle,
      isMinDate,
      maxDateSet,
      minDateSet,
    } = this.props;
    return (
      <React.Fragment>
        <div className="calendar-small">
          <IfComponent
            condition={isMinDate}
            whenTrue={
              <DateRangePicker
                onChange={onEventChange}
                showSelectionPreview={false}
                moveRangeOnFirstSelection={false}
                rangedCalendars={false}
                months={1}
                ranges={[
                  {
                    ...ranges,
                    startDate: moment(ranges.startDate).toDate(),
                    endDate: moment(ranges.endDate).toDate(),
                  },
                ]}
                rangeColors={['#E1E6FF']}
                color="#E1E6FF"
                minDate={new Date(minDateSet)}
                maxDate={
                  maxDateSet ||
                  new Date(minDateSet.setMonth(minDateSet.getMonth() + 20))
                }
                locale={rdrLocales[lang]}
                showPreview={false}
                showDateDisplay={false}
                dateDisplayFormat="YYYY-MM-DD HH:mm:ss"
                showMonthAndYearPickers // show month and year
              />
            }
            whenFalse={
              <DateRangePicker
                onChange={onEventChange}
                showSelectionPreview={false}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={[
                  {
                    ...ranges,
                    startDate: moment(ranges.startDate).toDate(),
                    endDate: moment(ranges.endDate).toDate(),
                  },
                ]}
                rangeColors={['#E1E6FF']}
                color="#E1E6FF"
                showPreview={false}
                showDateDisplay={false}
                locale={rdrLocales[lang]}
                dateDisplayFormat="YYYY-MM-DD HH:mm:ss"
                showMonthAndYearPickers // show month and year
              />
            }
          />

          <IfComponent
            condition={showToggle}
            whenTrue={
              <div className="select-weekday tv-display-flex">
                <span className="select-weekday__title">
                  <Translate content="myAvailable.weekdaysOnly" />
                </span>
                <ToggleSwitch
                  onToggleSwitchChange={onToggleSwitchChange}
                  isChecked={isChecked}
                />
              </div>
            }
          />
        </div>
      </React.Fragment>
    );
  }
}

CalendarSmall.propTypes = propTypes;
CalendarSmall.defaultProps = defaultProps;

export default CalendarSmall;
