import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Translate from '../Translate';
import DropdownMenu from '../DropdownMenu';
import FilterItem from '../FilterItem';
import FilterMenu from '../FilterMenu';

const propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

const defaultProps = {
  className: 'tv-youravailability__item-menu-button icon-more',
  onClick: () => { },
};

class MoreAction extends Component {
    onClickFilterItem = () => {
      if (this.filterElement && this.filterElement.onHiddenMenu) {
        this.filterElement.onHiddenMenu();
      }
    }

    render() {
      const {
        onClick, className,
      } = this.props;
      const button = (<span className={className} />);
      return (
        <div className="tv-youravailability__control-panel">
          <DropdownMenu
            ref={(el) => { this.filterElement = el; }}
            button={button}
            className="tv-youravailability__dropdown"
          >
            <FilterMenu>
              <FilterItem
                text={Translate({ content: 'moreAction.remove' })}
                onClick={() => { onClick(); this.onClickFilterItem(); }}
              />
            </FilterMenu>
          </DropdownMenu>
        </div>
      );
    }
}

MoreAction.propTypes = propTypes;
MoreAction.defaultProps = defaultProps;

export default MoreAction;
