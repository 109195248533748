import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { isEmpty } from 'lodash/core';
import DeviationItem from './DeviationItem';
import moment from 'moment';
import { Translate } from '../../../Share/components';

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.array,
  onRowClick: PropTypes.func,
  filterParamater: PropTypes.shape({}),
};

const defaultProps = {
  items: [],
  onRowClick: () => {},
  filterParamater: {},
};

const DeviationList = ({
  items,
  onRowClick,
  filterParamater,
  selectedDeviation,
  sortDateTime,
  dateTimeSorted,
}) => {
  let filteredDeviationList = [];
  filterParamater.map(filter => {
    if (filter !== 'all-assignments') {
      const filteredAssignmentItems = items?.filter(
        item => item.DeviationStatus === filter,
      );
      if (filteredAssignmentItems) {
        filteredAssignmentItems.forEach(fiterItems => {
          filteredDeviationList.push(fiterItems);
        });
      }
    } else {
      filteredDeviationList = [];
    }
  });

  const [showEmptyMessage, setshowEmptyMessage] = useState(false);

  const sortedArray = filteredDeviationList.sort((a, b) => {
    if (dateTimeSorted) {
      return moment(b.DateDeviationArrived).diff(a.DateDeviationArrived);
    }
    return moment(a.DateDeviationArrived).diff(b.DateDeviationArrived);
  });

  useEffect(() => {
    setTimeout(() => {
      setshowEmptyMessage(true);
    }, 1200);
  }, []);

  return (
    <div>
      <>
        <div className="row tv-reportquality__list-item-parent tv-reportquality__list-item tv-reportquality__list-item-header d-none d-lg-flex mb-0">
          <div className="col-12   col-md  col-lg col-xl">
            <div className="tv-reportquality__item-text">Orsak</div>
          </div>
          <div className="col-12   col-md  col-lg col-xl ">
            <div onClick={sortDateTime} className="tv-reportquality__item-text">
              <div>
                Skickad
                {dateTimeSorted ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </div>
            </div>
          </div>
          <div className="col-12   col-md  col-lg col-xl ">
            <div className="tv-reportquality__item-text">Bokningsnr.</div>
          </div>
          <div className="col-12   col-md col-lg col-xl ">
            <div className="tv-reportquality__item-text">
              {' '}
              Ansvarig hos Transvoice
            </div>
          </div>
          <div className="col-12   col-md col-lg col-xl ">
            <div className="tv-reportquality__item-text">Status</div>
          </div>
        </div>
        {isEmpty(sortedArray) && showEmptyMessage && (
          <>
            <div className="tv-workassignment__list-empty d-flex justify-content-center flex-column">
              <div className="tv-workassignment__list-empty-img" />
              <p>
                Tyvärr gav din sökning inget resultat. Försök igen med att ändra
                sökkriterier.
              </p>
            </div>
          </>
        )}
        {!isEmpty(sortedArray) &&
          sortedArray.map(item => (
            <DeviationItem
              key={item.DeviationNumber}
              item={item}
              onClick={onRowClick}
              isSelected={
                selectedDeviation?.DeviationNumber === item.DeviationNumber
              }
            />
          ))}
      </>
    </div>
  );
};

DeviationList.propTypes = propTypes;
DeviationList.defaultProps = defaultProps;

export default DeviationList;
