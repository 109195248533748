import React, { Component } from 'react';

import './style.scss';

class AssignmentTypeSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleDirectBookClick = () => {
    if (
      typeof document !== 'undefined' &&
      typeof document.clarity === 'function'
    ) {
      document.clarity('set', 'My watchlist', 'TolkDirekt');
    }
    this.props.onDirectBook();
  };

  render() {
    const { onDirectBook, onPreBook, onDemand, onMessageService } = this.props;
    return (
      <div className="tv-assignment-type-selection-container">
        <div>
          <div className="selection-section">Boka tolk</div>
          <div
            className="selection-option"
            onClick={onPreBook}
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
          >
            <div className="option-icon prebook">
              <img src="/images/pre-book-logo.svg" alt="pre-book" />
            </div>
            <div className="option-text">
              <h2 className="option-title">Förbokning </h2>
              <p className="option-description">
                Här kan du planera och boka en tolk i förväg, när och var det
                passar dig.
              </p>
            </div>
            <div className="option-arrow">
              <i className="fa fa-chevron-right" aria-hidden="true" />
            </div>
          </div>
          <div
            className="selection-option"
            onClick={this.handleDirectBookClick}
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
          >
            <div className="option-icon directbook">
              <img src="/images/direct-book-logo.svg" alt="direct-book" />
            </div>
            <div className="option-text">
              <h2 className="option-title">TolkDirekt </h2>
              <p className="option-description">
                Tolkningen sker per telefon och vi matchar ihop dig med rätt
                tolk inom 10 sekunder.
              </p>
            </div>
            <div className="option-arrow">
              <i className="fa fa-chevron-right" aria-hidden="true" />
            </div>
          </div>
          <div
            className="selection-option"
            onClick={onDemand}
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
          >
            <div className="option-icon prebook">
              <img src="/images/ondemand-logo.png" alt="ondemand-book" />
            </div>
            <div className="option-text">
              <h2 className="option-title">On-Demand </h2>
              <p className="option-description">
                Med On-Demand erbjuder vi direkttolkning på de tio vanligaste
                språken.
              </p>
            </div>
            <div className="option-arrow">
              <i className="fa fa-chevron-right" aria-hidden="true" />
            </div>
          </div>
        </div>
        <div className="pt-3">
          <div className="selection-section">Boka tjänst</div>
          <div
            className="selection-option"
            onClick={onMessageService}
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
          >
            <div className="option-icon prebook">
              <img src="/images/message-service.png" alt="message-service" />
            </div>
            <div className="option-text">
              <h2 className="option-title">Meddelandeservice </h2>
              <p className="option-description">
                Skicka meddelanden på mottagarens språk med meddelandeservice
              </p>
            </div>
            <div className="option-arrow">
              <i className="fa fa-chevron-right" aria-hidden="true" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AssignmentTypeSelection;
