import moment from 'moment';
import * as actionTypes from '../actions/ActionTypes';
import {
  LOCAL_STORE_IS_SYNC_WITH_GOOGLE_CALENDAR,
  LOCAL_STORE_CONTACT_PERSONS,
} from '../../Share/constants';
import { getFirstDayAndLastDayOnMonthlyCalendarView } from '../../Share/utils/dateUtil';

function localRemove() {
  localStorage.removeItem(LOCAL_STORE_CONTACT_PERSONS);
  localStorage.removeItem('releaseMessageDisabled');
}

const initIsSyncWithGoogleCalendar =
  localStorage.getItem(LOCAL_STORE_IS_SYNC_WITH_GOOGLE_CALENDAR) === 'true';
const initContactPersons = JSON.parse(
  localStorage.getItem(LOCAL_STORE_CONTACT_PERSONS),
)
  ? JSON.parse(localStorage.getItem(LOCAL_STORE_CONTACT_PERSONS))
  : [];

const INITIAL_STATE = {
  report: JSON.parse(localStorage.getItem('USER_DATA')) || {
    name: '',
    agreements: [{ id: 4 }, { id: 22 }],
  },
  profile: {
    customerIdentifier: '',
    customerName: '',
    customerNumber: '',
    department: '',
    organisationNumber: '',
    phoneNumberDirect: '',
    phoneNumberSwitchboard: '',
    customerAddress: {},
  },
  calendar: {
    week: {
      year: moment().year(),
      number: moment().week(),
    },
    month: {
      year: moment().year(),
      number: moment().month(),
    },
    range: {
      from: moment().startOf('month'),
      to: moment().endOf('month'),
    },
    selected: 'week',
  },
  events: [],
  duration: {
    fromDuration: moment().startOf('month'),
    toDuration: moment().endOf('month'),
  },
  googleEvents: [],
  isSyncWithGoogleCalendar: initIsSyncWithGoogleCalendar,
  settings: {},
  loggedIn: true,
  contactPersons: initContactPersons,
  bookDirectServiceID: '',
};

const app = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.RESET_APP: {
      localRemove();
      return {
        ...state,
        contactPersons: null,
      };
    }
    case actionTypes.UPDATE_DURATION: {
      const { duration } = action;

      return {
        ...state,
        duration,
      };
    }
    case actionTypes.RESET_DURATION: {
      return {
        ...state,
        duration: {
          fromDuration: moment().startOf('month'),
          toDuration: moment().endOf('month'),
        },
      };
    }
    case actionTypes.RESET_DATE_CALENDAR: {
      const year = moment().year();
      const month = moment().month();

      return {
        ...state,
        calendar: {
          week: {
            year,
            number: moment().week(),
          },
          month: {
            year,
            number: month,
          },
          range: {
            from: getFirstDayAndLastDayOnMonthlyCalendarView(year, month)
              .firstOfMonthlyCalendarView,
            to: getFirstDayAndLastDayOnMonthlyCalendarView(year, month)
              .lastOfMonthCalendarView,
          },
          selected: 'week',
        },
      };
    }
    case actionTypes.UPDATE_USER_PROFILE: {
      const { userData } = action;

      return {
        ...state,
        ...userData,
      };
    }

    case actionTypes.UPDATE_USER_CALENDAR: {
      const { calendar } = action;

      return {
        ...state,
        calendar,
      };
    }

    case actionTypes.UPDATE_USER_EVENTS: {
      const { events } = action;
      return {
        ...state,
        events,
      };
    }

    case actionTypes.UPDATE_GOOGLE_CALENDAR_EVENT: {
      const { googleEvents } = action;
      return {
        ...state,
        googleEvents,
      };
    }

    case actionTypes.UPDATE_IS_SYNC_WITH_GOOGLE_CALENDAR: {
      const { isSyncWithGoogleCalendar } = action;
      localStorage.setItem(
        LOCAL_STORE_IS_SYNC_WITH_GOOGLE_CALENDAR,
        isSyncWithGoogleCalendar,
      );

      return {
        ...state,
        isSyncWithGoogleCalendar,
      };
    }
    case actionTypes.UPDATE_USER_CONTACT_PERSONS: {
      const { contactPersons } = action;
      localStorage.setItem(
        LOCAL_STORE_CONTACT_PERSONS,
        JSON.stringify(contactPersons),
      );
      return {
        ...state,
        contactPersons,
      };
    }
    case actionTypes.DELETE_SPECIFC_USER_CONTACT_PERSONS: {
      const { contactPersonIdentifier } = action;
      const newContactPersons = state.contactPersons.filter(
        x => x.ContactPersonIdentifier !== contactPersonIdentifier,
      );
      localStorage.setItem(
        LOCAL_STORE_CONTACT_PERSONS,
        JSON.stringify(newContactPersons),
      );
      return {
        ...state,
        contactPersons: [...newContactPersons],
      };
    }
    case actionTypes.UPDATE_SPECIFC_USER_CONTACT_PERSONS: {
      const { contactPerson } = action;
      let newPersonflag = true;
      let newContactPersons = state.contactPersons.map(x => {
        if (
          contactPerson &&
          x.ContactPersonIdentifier === contactPerson.ContactPersonIdentifier
        ) {
          newPersonflag = false;
          return {
            ...x,
            ...contactPerson,
          };
        }
        return x;
      });
      if (newPersonflag) { newContactPersons = [...newContactPersons, contactPerson]; }
      localStorage.setItem(
        LOCAL_STORE_CONTACT_PERSONS,
        JSON.stringify(newContactPersons),
      );
      return {
        ...state,
        contactPersons: newContactPersons,
      };
    }
    case actionTypes.GET_USER_DATA: {
      const { userData } = action;
      return {
        ...state,
        report: { ...userData },
      };
    }
    case actionTypes.SET_BOOK_DIRECT_SERVICE_ID: {
      const { serviceId } = action;
      return {
        ...state,
        bookDirectServiceID: serviceId,
      };
    }
    default:
      return state;
  }
};

export default app;
