import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes, { number } from 'prop-types';
import moment from 'moment';
import { generateUniqueId, shouldDisplayRatingElement } from '../../utils';
import {
  updateAssignmentSummary,
  updateMyAssignmentFilters,
} from '../../../ClientPortal/actions/workAssignment';
import './style.scss';
import history from '../../utils/history';
import Translate from '../Translate';
import IfComponent from '../IfComponent';
import StarComponent from '../StarComponent';
import { RATING_VALUES, status } from '../../constants';
import {
  UPDATE_DURATION,
  UPDATE_MY_ASSIGNMENT_LIST,
} from '../../../ClientPortal/actions/ActionTypes';

const propTypes = {
  assignmentSummaryList: PropTypes.arrayOf(PropTypes.shape({})),
  ratingValues: PropTypes.shape({}),
  assignmentsCanBeRate: PropTypes.number,
  ratedAssignments: PropTypes.number,
  onUpdateAssignmentSummary: PropTypes.func,
};

const defaultProps = {
  assignmentSummaryList: [],
  ratingValues: {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  },
  assignmentsCanBeRate: 0,
  ratedAssignments: 0,
  onUpdateAssignmentSummary: () => {},
};

const INITIAL_STATE = {
  assignmentSummaryList: [],
  ratingValues: {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  },
  assignmentsCanBeRate: 0,
  ratedAssignments: 0,
  averageRating: 0,
};

class RatingSummary extends Component {
  constructor(props) {
    super(props);
    const { onUpdateAssignmentSummary } = this.props;
    this.state = INITIAL_STATE;

    onUpdateAssignmentSummary(
      moment().subtract(3, 'months').format('YYYY-MM-DD HH:mm:ss'),
      moment().format('YYYY-MM-DD HH:mm:ss'),
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const filteredAssignmentList = [...nextProps.assignmentSummaryList];
    const ratingValues = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
    };
    const assignmentsCanBeRate = filteredAssignmentList.filter(
      x => x.CanBeRated,
    ).length;

    filteredAssignmentList
      .filter(x => x.RatingCustomer !== null || x.RatingCustomer > 0)
      .map((currentItem, i) => {
        if (currentItem.RatingCustomer > 0) {
          ratingValues[currentItem.RatingCustomer]++;
        }
      });

    const unratedAssignments = filteredAssignmentList.filter(
      x => x.CanBeRated && x.RatingCustomer === null,
    ).length;

    // Average Rating Calculation
    let totalSum = 0;
    let totalMultiplication = 0;
    for (let a = 1; a <= 5; a++) {
      totalMultiplication += ratingValues[a] * a;
      totalSum += ratingValues[a];
    }
    const averageRating = totalMultiplication / totalSum || 0;

    return {
      unratedAssignments,
      assignmentSummaryList: filteredAssignmentList,
      ratingValues,
      assignmentsCanBeRate,
      averageRating,
    };
  }

  render() {
    const votes = [1, 2, 3, 4, 5];
    const rateColors = {
      0: '',
      1: 'red',
      2: 'red-yellow',
      3: 'yellow',
      4: 'yellow-green',
      5: 'green',
    };
    const {
      unratedAssignments,
      ratingValues,
      assignmentsCanBeRate,
      assignmentSummaryList,
      averageRating,
    } = this.state;

    const RatingStyle = { '--rating': `${(averageRating * 20).toFixed(1)}%` };

    return (
      <div className="rating-summary-cont">
        <div className="rating">
          <div className="stars_area">
            <div className="d-flex flex-row flex-md-column stars_area__container">
              <div className="stars_area__title">
                <Translate content="singleAssignment.averageRating" />
              </div>
              <div className="average-rating-count">
                <span>
                  {averageRating.toLocaleString('sv-SE', {
                    maximumFractionDigits: 1,
                    minimumFractionDigits: 1,
                  })}
                </span>
                <StarComponent
                  style={RatingStyle}
                  value={Math.round(averageRating)}
                />
              </div>
            </div>
            <div className="description">
              Baserat på de senaste 3 månaderna{' '}
            </div>
            <div className="stars_area__pending">
              <div
                className="send-unrated-link tv-buttons__element"
                onClick={() => {
                  this.props.transferAssignmentSummuryList(
                    assignmentSummaryList,
                    moment()
                      .subtract(3, 'months')
                      .format('YYYY-MM-DD HH:mm:ss'),
                    moment().format('YYYY-MM-DD HH:mm:ss'),
                  );
                  history.push({
                    pathname: '/my-assignment',
                    state: {
                      isFromDashboardRating: true,
                      isFromDashboard: false,
                      assignments: [],
                      from: moment()
                        .subtract(3, 'months')
                        .format('YYYY-MM-DD HH:mm:ss'),
                      to: moment().format('YYYY-MM-DD HH:mm:ss'),
                    },
                  });
                }}
              >
                <Translate content="singleAssignment.ratedAssignment" /> (
                {`${parseInt(unratedAssignments)} st`})
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RatingSummary.propTypes = propTypes;
RatingSummary.defaultProps = defaultProps;

const mapStateToProps = state => {
  const { assignmentSummaryList } = state.workAssignment;

  return {
    assignmentSummaryList,
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateAssignmentSummary: (from, to) => {
    dispatch(updateAssignmentSummary(from, to));
  },
  updateMyAssignmentFilters: (e, filterType) => {
    dispatch(updateMyAssignmentFilters(e, filterType));
  },
  transferAssignmentSummuryList: (assignmentList, fromDuration, toDuration) => {
    dispatch({
      type: UPDATE_MY_ASSIGNMENT_LIST,
      assignmentList: [...assignmentList],
    });
    dispatch({
      type: 'UPDATE_MY_ASSIGNMENT_FILTERS',
      filterType: 'RATING_FILTER',
      value: [0],
    });
    dispatch({
      type: 'UPDATE_MY_ASSIGNMENT_FILTERS',
      filterType: 'STATUS_FILTER',
      value: [status.fullfilled.name, status.accepted.name],
    });

    dispatch({
      type: 'UPDATE_MY_ASSIGNMENT_FILTERS',
      filterType: 'TYPE_FILTER',
      value: { name: 'Välj', value: '' },
    });

    const duration = {
      fromDuration,
      toDuration,
    };

    dispatch({
      type: UPDATE_DURATION,
      duration,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RatingSummary);
