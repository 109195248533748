import React, { Component } from 'react';
import PropTypes from 'prop-types';

import "./style.scss";
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css'

const propTypes = {
  icon: PropTypes.string,
  place: PropTypes.string,
  Text:PropTypes.string,
  content:PropTypes.string,
  trigger:PropTypes.string
};

const defaultProps = {
  icon:'fa fa-question-circle-o',
  place:"top",
  content:'',
  trigger:'mouseenter',
};

const HelpContext = ({icon,place,content,trigger,...rest}) => {
  return (
    <div className="tv-tooltip">
      <Tooltip
        arrow
        html={(
          <div className="tv-tooltip-container">
           {content}
          </div>
        )}
        position={place}
        trigger={trigger}
        {...rest}
      >
        <i className={icon}></i>
      </Tooltip>
    </div>
  );
};

HelpContext.propTypes = propTypes;
HelpContext.defaultProps = defaultProps;

export default HelpContext;