import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Dialog from '../Dialog';

import {
  assignmentTimeFormat,
} from '../../utils';
import { DIALOG_GOOGLE_WIDTH, DIALOG_GOOGLE_HEIGHT, APP_PORTAL } from '../../constants';
import { IfComponent } from '..';

const propTypes = {
  type: PropTypes.string,
  from: PropTypes.instanceOf(moment),
  to: PropTypes.instanceOf(moment),
  title: PropTypes.string,
  identifier: PropTypes.string,
  description: PropTypes.string,
  location: PropTypes.string,
  googleEventWithoutTime: PropTypes.bool,
  onClick: PropTypes.func,
  top: PropTypes.number,
  height: PropTypes.number,
  length: PropTypes.number,
  index: PropTypes.number,
  indexGroup: PropTypes.number,
  left: PropTypes.number,
  coverElementRef: PropTypes.shape({}),
};

const defaultProps = {
  type: '',
  from: moment(),
  to: moment(),
  title: '',
  description: '',
  identifier: '',
  location: '',
  googleEventWithoutTime: false,
  onClick: () => { },
  top: 0,
  height: 0,
  length: 0,
  index: 0,
  indexGroup: 0,
  left: 0,
  coverElementRef: {},
};

const INITAL_STATES = {
  showDialog: false,
  zIndex: 0,
  index: 0,
};


class EventItem extends Component {
  // state = INITAL_STATES;

  constructor(props) {
    super(props);
    this.state = INITAL_STATES;
    // Create the ref
    this.eventRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.index !== prevState.index) {
      return {
        index: nextProps.index,
        zIndex: nextProps.index > 0 ? 1 : 0,
      };
    }
    return null;
  }

  onEventClick = () => {
    const {
      type, identifier, onClick, coverElementRef,
    } = this.props;
    const childPos = this.eventRef.current.getBoundingClientRect();
    const parentPos = coverElementRef.current.container.getBoundingClientRect();
    let left;
    let top;
    let borderRightColor;
    let borderLeftColor;
    let borderTopColor;
    let borderBottomColor;
    let tipRight;
    let tipTop;
    const topGap = childPos.y - parentPos.y;
    const bottomGap = parentPos.height - (topGap + childPos.height);
    const leftGap = childPos.x - parentPos.x;
    const rightGap = parentPos.width - (leftGap + childPos.width);
    if (rightGap < DIALOG_GOOGLE_WIDTH) {
      if (leftGap < DIALOG_GOOGLE_WIDTH) {
        left = '0';
      } else {
        left = `-${DIALOG_GOOGLE_WIDTH + 10}px`;
        borderRightColor = 'transparent';
        borderLeftColor = '#fff';
        borderTopColor = 'transparent';
        borderBottomColor = 'transparent';
        tipRight = '-10px';
      }
    }

    if (bottomGap < DIALOG_GOOGLE_HEIGHT) {
      top = `-${DIALOG_GOOGLE_HEIGHT - childPos.height}px`;
      tipTop = `${DIALOG_GOOGLE_HEIGHT - 20}px`;
    }

    if (topGap < DIALOG_GOOGLE_HEIGHT) {
      if (!(rightGap > DIALOG_GOOGLE_WIDTH || leftGap > DIALOG_GOOGLE_WIDTH)) {
        top = `${childPos.height + 10}px`;
        tipTop = '-10px';
        tipRight = `${DIALOG_GOOGLE_WIDTH - 20}px`;
        borderRightColor = 'transparent';
        borderLeftColor = 'transparent';
        borderTopColor = 'transparent';
        borderBottomColor = '#fff';
      }
    }

    if (bottomGap < DIALOG_GOOGLE_HEIGHT) {
      if (!(rightGap > DIALOG_GOOGLE_WIDTH || leftGap > DIALOG_GOOGLE_WIDTH)) {
        top = `${childPos.height + 10}px`;
        tipTop = '-10px';
        tipRight = `${DIALOG_GOOGLE_WIDTH - 20}px`;
        borderRightColor = 'transparent';
        borderLeftColor = 'transparent';
        borderTopColor = '#fff';
        borderBottomColor = 'transparent';
      }
    }

    this.setState({ zIndex: 2 });
    if (type === 'custom') {
      this.setState({
        showDialog: true,
        left,
        top,
        borderRightColor,
        borderLeftColor,
        borderBottomColor,
        borderTopColor,
        tipRight,
        tipTop,
      });
    } else {
      onClick(type, identifier);
    }
  }

  onDialogClose = (e) => {
    this.setState({ showDialog: false });
    e.stopPropagation();
  }

  onBlur = (event) => {
    const { currentTarget } = event;
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        this.setState({ zIndex: this.props.index > 0 ? 1 : 0 });
        this.setState({ showDialog: false });
        event.persist();
      }
    }, 0);
  }

  render() {
    let { height, top, left } = this.props;
    const {
      type, orderStatus, from, to, title, location, index, length, indexGroup, description, googleEventWithoutTime, dateRangeFromWeekEnd, dateRangeFromWeekStart, assignment
    } = this.props;
    let { zIndex } = this.state;
    let width = '100%';
    if (top >= 50) {
      if (index && length) {
        width = `${(100 / length) - 5}%`;
        left = `${100 * index / length - (indexGroup % 2) * 5}%`;
      }
    } else {
      if (length > 1) {
        height = 50 / length;
        top = index * height;
        left = `${left}%`;
      }
      zIndex = zIndex > 0 ? zIndex : 1;

      if (to.isBetween(dateRangeFromWeekStart, dateRangeFromWeekEnd, 'day', '[]')) {
        width = `${(to.clone().startOf('day').diff(from.clone().startOf('day'), 'days') + 1) * 100}%`;
      } else {
        width = `${(dateRangeFromWeekEnd.clone().startOf('day').diff(from.clone().startOf('day'), 'days') + 1) * 100}%`;
      }
    }

    const { showDialog } = this.state;
    const clientName = (assignment && assignment.Customer) ? assignment.Customer.CustomerName : "";
    const displayDate = assignmentTimeFormat(from, to, '{from} - {to}');
    let eventTitle = clientName ? clientName : title;
    if (APP_PORTAL === 'client-portal') {
      eventTitle = assignment && assignment.Skill ? assignment.Skill : title;
    }

    return (
      <IfComponent
        condition={type}
        whenTrue={(
          <div
            style={{
               top, width, zIndex, left,height,marginLeft:'6px',minHeight:'50px',maxWidth:'95%'
            }}
            onBlur={this.onBlur}
            tabIndex="-1"
            onClick={this.onEventClick}
            onKeyPress={this.onEventClick}
            className={`tk-weekly-calendar__event-item tk-weekly-calendar__event-item--${orderStatus} tk-weekly-calendar__event-item--${type}`}
            ref={this.eventRef}
          ><div
          style={{height,borderLeft:'6px solid', position:'absolute',left:'-6px',top:'-1px'}}></div>
            <Dialog
              style={{
                left: this.state.left,
                top: this.state.top,
                borderRightColor: this.state.borderRightColor,
                borderLeftColor: this.state.borderLeftColor,
                borderTopColor: this.state.borderTopColor,
                borderBottomColor: this.state.borderBottomColor,
                tipRight: this.state.tipRight,
                tipTop: this.state.tipTop,
              }}
              onBlur={this.onBlur}
              isVisible={showDialog}
              onClose={this.onDialogClose}
              className="tk-weekly-calendar__dialog"
            >
              <h2 className="tk-weekly-calendar__dialog-title">{eventTitle}</h2>
              {
                googleEventWithoutTime ||
                <div className="tk-weekly-calendar__dialog-time icon-calendar">
                  {assignmentTimeFormat(from, to, 'From {from} - To {to}')}
                </div>
              }
              <div className="tk-weekly-calendar__dialog-location">{location}</div>
            </Dialog>

            <div className="tk-weekly-calendar__event-content tk-monthly-calendar__item-title">
              {eventTitle}
              <br />
              {description}
              <br />
              <span className="tk-weekly-calendar__event-time">{(displayDate)}</span>
            </div>
          </div>
        )}

        whenFalse={(
          <span></span>
        )}
      />

    );
  }
}

EventItem.propTypes = propTypes;
EventItem.defaultProps = defaultProps;

export default EventItem;
