import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { ValidatorForm } from 'react-form-validator-core';
import {
  Translate,
  TransvoiceButton,
  TextField,
} from '../../../Share/components';
import { login } from '../../actions';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerNumber: '',
      password: '',
      submitDisabled: true,
    };
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { customerNumber, password } = this.state;
    this.props.onLogin(customerNumber, password);
  };

  handleValidatorListener = () => {
    this.form.isFormValid().then(isValid => {
      this.setState({ submitDisabled: !isValid });
    });
  };

  render() {
    return (
      <div className="tv-login__wrapper tv-display-flex">
        <div className="tv-login__form tv-login__form-wrapper">
          <div className="tv-login__title">
            <Translate content="login.title" />
          </div>
          <ValidatorForm
            ref={node => (this.form = node)}
            onSubmit={this.handleSubmit}
            onError={this.handleValidatorListener}
          >
            <div className="tv-login__form-email-lablel tv-login__form-label">
              <Translate content="general.customerNumber" />
            </div>
            <TextField
              validatorListener={this.handleValidatorListener}
              onChange={this.handleChange}
              className="tx-input-text tv-login__form-email-input"
              name="customerNumber"
              maxLength={300}
              value={this.state.customerNumber}
              placeholder={Translate({
                content: 'login.enterYourCustomerNumber',
              })}
              validators={['required', 'isNumber']}
              errorMessages={[
                Translate({ content: 'login.customerNumberIsRequire' }),
                Translate({ content: 'login.customerNumberIsNotValid' }),
              ]}
            />

            <div className="tv-login__form-interpreter-id-label tv-login__form-label">
              <Translate content="general.password" />
            </div>
            <TextField
              type="password"
              validatorListener={this.handleValidatorListener}
              onChange={this.handleChange}
              className="tx-input-text tv-login__form-interpreterId-input"
              name="password"
              value={this.state.password}
              placeholder={Translate({ content: 'login.enterYourPassword' })}
              validators={['minStringLength:8']}
              errorMessages={[Translate({ content: 'login.minLengthMessage' })]}
            />

            <TransvoiceButton
              type="primary"
              buttonType="submit"
              text={Translate({ content: 'login.login' })}
              className="tv-login__form-submit"
              onClick={this.handleSubmit}
              disabled={this.state.submitDisabled}
            />
          </ValidatorForm>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLogin: (customerNumber, password) => {
    dispatch(login(customerNumber, password));
  },
});

export default connect(null, mapDispatchToProps)(Login);
