import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import ItemDay from './ItemDay';

import {
  generateUniqueId,
  filterEventInDay,
  filterAvailabilityInDay,
} from '../../utils';
import { LOCAL_STORE_LANGUAGE } from '../../constants/localStorage';
import Scrollbars from 'react-custom-scrollbars';

const propTypes = {
  year: PropTypes.number,
  month: PropTypes.number,
  onEventClick: PropTypes.func,
  onDayClick: PropTypes.func,
  onAvailabilityClick: PropTypes.func,
  onAssignmentSummuryPillClicked: PropTypes.func,
  events: PropTypes.arrayOf(PropTypes.shape({})),
  availability: PropTypes.arrayOf(PropTypes.shape({})),
  displayListItemContactPerson: PropTypes.bool,
  visiblePrefix: PropTypes.bool,
  onCreateOrder: PropTypes.func,
  selectedDate: PropTypes.shape({}),
  quickOrderDateShow: PropTypes.bool,
};

const defaultProps = {
  year: moment().year(),
  month: moment().month(),
  onEventClick: () => {},
  onDayClick: () => {},
  onCreateOrder: () => {},
  onAvailabilityClick: () => {},
  onAssignmentSummuryPillClicked: () => {},
  events: [],
  selectedDate: null,
  quickOrderDateShow: false,
  availability: [],
  displayListItemContactPerson: false,
  visiblePrefix: false,
};

const getFirstAndTotalDayOfMonth = (month, year) => ({
  first: new Date(year, month).getDay(),
  total: 32 - new Date(year, month, 32).getDate(),
  month: moment().year(year).month(month),
});

let lang = localStorage.getItem(LOCAL_STORE_LANGUAGE);
switch (lang) {
  case 'en':
    lang = 'en';
    break;
  case 'se':
  default:
    lang = 'se';
    break;
}

class TableCalendar extends Component {
  constructor(props) {
    super(props);
    // const weekdays = moment.weekdays();
    this.daysOfWeek = {
      en: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      se: [
        'Måndag',
        'Tisdag',
        'Onsdag',
        'Torsdag',
        'Fredag',
        'Lördag',
        'Söndag',
      ],
    };
    this.state = {
      windowWidth: window.innerWidth,
      quickOrderDate: null,
    };
    this.scrollContainer = React.createRef();
  }
  componentDidMount() {
    window.addEventListener('resize', () =>
      this.setState({
        windowWidth: window.innerWidth,
      }),
    );
  }
  render() {
    const {
      year,
      month,
      events,
      onEventClick,
      availability,
      selectedDate,
      quickOrderDateShow,
      onAvailabilityClick,
      onDayClick,
      onCreateOrder,
      displayListItemContactPerson,
      onAssignmentSummuryPillClicked,
      visiblePrefix,
      onCreateDirectBooking,
    } = this.props;
    const { windowWidth, quickOrderDate } = this.state;
    const currentMonth = getFirstAndTotalDayOfMonth(month, year);
    const firstDay = currentMonth.month.clone().startOf('month');
    const lastDay = currentMonth.month.clone().endOf('month');
    let dateCursor = 0;
    let itemCal = moment();
    return (
      <>
        <div className="d-flex flex-column w-100 tk-monthly-calendar__scroll-container">
          <table className="tk-monthly-calendar__table-wrapper">
            <tbody>
              <tr className="tk-monthly-calendar__table-weekdays">
                {this.daysOfWeek[lang].map((day, i) => (
                  <th
                    className="tv-secondary-color"
                    key={`month-calender-${day}`}
                  >
                    {day}
                  </th>
                ))}
              </tr>

              {[...Array(6).keys()].map(rowIndex => (
                <tr key={generateUniqueId()}>
                  {[...Array(7).keys()].map(colIndex => {
                    const isFirstRow = rowIndex === 0;
                    const firstDayOfMonthIsSunday = currentMonth.first === 0;
                    if (isFirstRow && firstDayOfMonthIsSunday && colIndex < 6) {
                      itemCal = firstDay.clone().subtract(6 - colIndex, 'days');
                    } else if (
                      isFirstRow &&
                      colIndex < currentMonth.first - 1
                    ) {
                      itemCal = firstDay
                        .clone()
                        .subtract(currentMonth.first - colIndex - 1, 'days'); // Should subtract by 1, because the first day of week is monday = 1, not sunday = 0
                    } else {
                      dateCursor += 1;
                      if (dateCursor > currentMonth.total) {
                        itemCal = lastDay
                          .clone()
                          .add(dateCursor - currentMonth.total, 'days');
                      } else {
                        itemCal = moment()
                          .year(year)
                          .month(month)
                          .date(dateCursor);
                      }
                    }
                    const eventInDay = filterEventInDay(events, itemCal) || [];
                    eventInDay.sort(
                      (a, b) => a.from.valueOf() - b.from.valueOf(),
                    );
                    const availabilityInDay =
                      filterAvailabilityInDay(availability, itemCal) || [];
                    availabilityInDay.sort(
                      (a, b) =>
                        a.DatetimeFrom.valueOf() - b.DatetimeFrom.valueOf(),
                    );
                    return (
                      <ItemDay
                        key={itemCal.format('MMMMYYYYYDDDD')}
                        onClickDetail={onEventClick}
                        onClickAvailability={onAvailabilityClick}
                        onDayClick={onDayClick}
                        onAssignmentSummuryPillClick={
                          onAssignmentSummuryPillClicked
                        }
                        onCreateOrder={day => {
                          onCreateOrder(day);
                          this.setState({ quickOrderDate: day });
                        }}
                        highlightSummuryPill={
                          selectedDate
                            ? itemCal.isSame(moment(selectedDate), 'day')
                            : false
                        }
                        highlightDay={
                          quickOrderDateShow
                            ? itemCal.isSame(moment(quickOrderDate), 'day')
                            : false
                        }
                        currentDay={itemCal}
                        currentMonth={currentMonth.month}
                        weekNummberDisplay={(colIndex == 0
                          ? itemCal.isoWeek()
                          : ''
                        ).toString()}
                        events={eventInDay}
                        availability={availabilityInDay}
                        displayDay={itemCal.format('D')}
                        displayDayInText={itemCal.format('dddd')}
                        displayListItemContactPerson={
                          displayListItemContactPerson
                        }
                        visiblePrefix={visiblePrefix}
                        onCreateDirectBooking={onCreateDirectBooking}
                      />
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

TableCalendar.propTypes = propTypes;
TableCalendar.defaultProps = defaultProps;

export default TableCalendar;
