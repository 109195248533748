import React, { useRef } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FaRegCalendar } from 'react-icons/fa';
import { Translate, TransvoiceButton } from '../../../Share/components';
import {
  AssignmentIcon,
  LanguageIcon,
  TimeIcon,
} from '../../../Share/assets/icons';
// eslint-disable-next-line react/prop-types
const DataItem = ({ icon, value }) => (
  <div className="tv-booknow-complete__data-item">
    <div className="tv-booknow-complete__data-item-label">{icon}</div>
    <div className="tv-booknow-complete__data-item-value">{value}</div>
  </div>
);

function OrderComplete({
  orderNumber,
  dateRange: { TimeFrom, TimeTo },
  language: { name },
  customerName,
  onClose,
  onSubmit,
  bookedInterpreterName,
  bookedInterpreterNumber,
}) {
  const componentRef = useRef(null);
  const dateTimeFrom = moment(TimeFrom);
  const dateTimeTo = moment(TimeTo);

  return (
    <>
      <div className="tv-booknow-complete__container" ref={componentRef}>
        <div className="tv-booknow-complete__heading">
          {Translate({ content: 'bookNow.yourBookingIsMade' })}
        </div>
        <div className="tv-booknow-complete__interpreter-details">
          <div>{bookedInterpreterName}</div>
          <a href={`tel:${bookedInterpreterNumber}`}>
            {bookedInterpreterNumber}
          </a>
        </div>

        <div className="tv-booknow-complete__data-container">
          <div className="tv-booknow-complete__data-heading">
            Bokningsuppgifter
          </div>
          <div className="tv-booknow-complete__data-table">
            <DataItem
              icon={<FaRegCalendar />}
              value={dateTimeTo.format('DD MMMM')}
            />
            <DataItem icon={<LanguageIcon />} value={name} />
            <DataItem icon={<AssignmentIcon />} value={orderNumber} />
            <DataItem
              icon={<TimeIcon />}
              value={`${dateTimeFrom.format('HH:mm')} - ${dateTimeTo.format(
                'HH:mm',
              )}`}
            />
          </div>
        </div>
        <div className="tv-booknow-complete__data-footer-text">
          Dessa uppgifter finns även i dina bokningar
        </div>
      </div>
      <div className="d-flex flex-column">
        <div className="tv-booknow__form__buttons tv-booknow-complete__buttons  justify-content-between tv-display-flex">
          <TransvoiceButton
            type="primary"
            text={Translate({
              content: 'bookNow.toCalendar',
            })}
            onClick={() => onSubmit()}
          />
        </div>
      </div>
    </>
  );
}

OrderComplete.propTypes = {
  orderNumber: PropTypes.string,
  bookedInterpreterName: PropTypes.string,
  bookedInterpreterNumber: PropTypes.string,
  dateRange: PropTypes.shape({
    TimeFrom: PropTypes.string,
    TimeTo: PropTypes.string,
  }),
  language: PropTypes.shape({
    name: PropTypes.string,
  }),
  customerName: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

OrderComplete.defaultProps = {
  orderNumber: '',
  bookedInterpreterName: '',
  bookedInterpreterNumber: '',
  language: {
    name: '',
  },
  dateRange: {
    TimeFrom: '00:00',
    TimeTo: '00:00',
  },
  customerName: '',
  onClose: () => {},
  onSubmit: () => {},
};

export default OrderComplete;
