import React from 'react';
import PropTypes from 'prop-types';
import FilterItem from '../../../ClientPortal/components/StatusFilter/FilterItem';
import Translate from '../Translate';
import { RATING_VALUES } from '../../constants';
import './style.scss';
import StarComponent from '../StarComponent';

const RateFilter = props => {
  const { onClick, selectedFilter } = props;

  let selectedRateItems = selectedFilter;
  const handleFilterChange = event => {
    const { value } = event.target;

    const parsedValue = parseInt(value, 10);

    if (selectedRateItems.includes(parsedValue)) {
      const index = selectedRateItems.indexOf(parsedValue);
      selectedRateItems.splice(index, 1);
    } else {
      selectedRateItems.push(parsedValue);
    }

    if (parsedValue !== null) {
      if (!selectedRateItems.length) {
        selectedRateItems.push(null);
      } else {
        const index = selectedRateItems.indexOf(null);
        if (index !== -1) {
          selectedRateItems.splice(index, 1);
        }
      }
    } else {
      const index = selectedRateItems.indexOf(parsedValue);
      if (index !== -1) {
        selectedRateItems = [null];
      }
    }
    if (!selectedRateItems.length) {
      onClick([]);
    } else {
      onClick(selectedRateItems);
    }
  };

  const setAllItems = () => {
    if (selectedRateItems.includes(null)) {
      selectedRateItems = [
        RATING_VALUES.NOT_RATED,
        RATING_VALUES.ONE,
        RATING_VALUES.TWO,
        RATING_VALUES.THREE,
        RATING_VALUES.FOUR,
        RATING_VALUES.FIVE,
      ];
    } else {
      selectedRateItems = [null];
    }
    if (!selectedRateItems.length) {
      onClick([]);
    } else {
      onClick(selectedRateItems);
    }
  };

  const checkItem = item => selectedFilter.includes(item);

  return (
    <div className="rate-container">
      <div className="rate-title-wrapper">
        <div className="rate-title">
          {Translate({ content: 'rateFilter.title' })}
        </div>
        <div className="rate-title-sub" onClick={setAllItems}>
          {selectedRateItems.includes(null)
            ? Translate({ content: 'rateFilter.all' })
            : Translate({ content: 'rateFilter.clearAll' })}
        </div>
      </div>
      <div className="filter-border">
        <FilterItem
          onClick={handleFilterChange}
          text={Translate({ content: 'rateFilter.notRated' })}
          value={RATING_VALUES.NOT_RATED}
          labelColor="#F7F9FF"
          textColor="#FF9B4E"
          checked={checkItem(RATING_VALUES.NOT_RATED)}
        />
        <FilterItem
          onClick={handleFilterChange}
          text={
            <StarComponent
              className="stars"
              value={RATING_VALUES.ONE}
              style={{
                '--rating': `${(RATING_VALUES.ONE * 20).toFixed(1)}%`,
              }}
            />
          }
          labelClassName="pt-0 label--available"
          value={RATING_VALUES.ONE}
          labelColor="#F7F9FF"
          textColor="#fff"
          checked={checkItem(RATING_VALUES.ONE)}
        />
        <FilterItem
          onClick={handleFilterChange}
          text={
            <StarComponent
              style={{
                '--rating': `${(RATING_VALUES.TWO * 20).toFixed(1)}%`,
              }}
              value={RATING_VALUES.TWO}
            />
          }
          labelClassName="pt-0 label--available"
          value={RATING_VALUES.TWO}
          labelColor="#F7F9FF"
          textColor="#fff"
          checked={checkItem(RATING_VALUES.TWO)}
        />
        <FilterItem
          onClick={handleFilterChange}
          text={
            <StarComponent
              style={{
                '--rating': `${(RATING_VALUES.THREE * 20).toFixed(1)}%`,
              }}
              value={RATING_VALUES.THREE}
            />
          }
          labelClassName="pt-0 label--available"
          value={RATING_VALUES.THREE}
          labelColor="#F7F9FF"
          textColor="#fff"
          checked={checkItem(RATING_VALUES.THREE)}
        />
        <FilterItem
          onClick={handleFilterChange}
          text={
            <StarComponent
              className="stars"
              value={RATING_VALUES.FOUR}
              style={{
                '--rating': `${(RATING_VALUES.FOUR * 20).toFixed(1)}%`,
              }}
            />
          }
          labelClassName="pt-0 label--available"
          value={RATING_VALUES.FOUR}
          labelColor="#F7F9FF"
          textColor="#fff"
          checked={checkItem(RATING_VALUES.FOUR)}
        />
        <FilterItem
          onClick={handleFilterChange}
          text={
            <StarComponent
              className="stars"
              value={RATING_VALUES.FIVE}
              style={{
                '--rating': `${(RATING_VALUES.FIVE * 20).toFixed(1)}%`,
              }}
            />
          }
          labelClassName="pt-0 label--available"
          value={RATING_VALUES.FIVE}
          labelColor="#F7F9FF"
          textColor="#fff"
          checked={checkItem(RATING_VALUES.FIVE)}
        />
      </div>
    </div>
  );
};

RateFilter.propTypes = {
  onClick: PropTypes.func.isRequired,
  selectedFilter: PropTypes.string.isRequired,
};

export default RateFilter;
