import React from 'react';
import { Translate } from '..';

class MobileApp extends React.Component {
  componentDidMount() {
    const url = new URL(window.location.href);
    const data = this.getUrlParameter('data');
    if (url.pathname === '/mobile-app/login') {
      window.location.href = `transvoice://tolk.transvoice.se/login?data=${data}`;
    }
  }

  getUrlParameter = (name) => {
    const reName = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp(`[\\?&]${reName}=([^&#]*)`);
    const results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(' '));
  };

  render() {
    return (
      <div>
        <a 
          href={`transvoice://tolk.transvoice.se/login?data=${this.getUrlParameter('data')}`} 
          target="_self" 
          alt={Translate({ content: 'login.clickBelowIfYouAreNotRedirected' })}
        >
          <Translate 
            content="login.clickBelowIfYouAreNotRedirected"
          />
        </a>
      </div>
    );
  }
}

export default MobileApp;
