import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.string,
};

const defaultProps = {
  text: '',
  value: '',
  onClick: () => {},
  className: 'tv-filter-list__menu-item',
};

const FilterItem = ({ text, onClick, className, value }) => (
  <li className={className}>
    <button
      className="tv-buttons__type"
      value={value}
      onClick={() => onClick(value)}
    >
      {text}
    </button>
  </li>
);

FilterItem.propTypes = propTypes;
FilterItem.defaultProps = defaultProps;

export default FilterItem;
