import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { MONTHS } from '../../constants';

const propTypes = {
  date: PropTypes.shape({}),
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  children: PropTypes.node,
};

const defaultProps = {
  date: PropTypes.shape({}),
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  children: null,
};

const Header = ({ date, onPrevious, onNext, children }) => {
  const { week, displayWeek, year, displayYear, month } = date;
  moment.updateLocale('sv', { months: MONTHS });
  const currentMonth = moment().year(year).month(month).locale('sv');

  return (
    <div className="tk-weekly-calendar__header tk-calendar__header">
      <div className="tk-calendar__title">
        <span>
          {currentMonth.format('MMMM')} {displayYear}
        </span>
        <button
          onClick={onPrevious}
          className="tk-calendar__button tk-calendar__button--previous"
        />
        <button
          onClick={onNext}
          className="tk-calendar__button tk-calendar__button--next"
        />
      </div>
      {children}
    </div>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
