import React, { useEffect, useRef, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';

function LeftDrawerSlide({
  children,
  isVisible,
  onClose,
  hideClose,
  hideExternalScroll,
  className,
}) {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 768;

  useEffect(() => {
    window.addEventListener('resize', () => setWidth(window.innerWidth));
  }, []);

  return isVisible ? (
    width < breakpoint ? (
      <MobileComponent
        className={className}
        children={children}
        onClose={onClose}
        hideClose={hideClose}
        isVisible={isVisible}
        hideExternalScroll={hideExternalScroll}
      />
    ) : (
      <DesktopComponent
        className={className}
        children={children}
        onClose={onClose}
        hideClose={hideClose}
        isVisible={isVisible}
        hideExternalScroll={hideExternalScroll}
      />
    )
  ) : null;
}

export default LeftDrawerSlide;

const MobileComponent = ({
  children,
  onClose,
  isVisible,
  hideClose,
  className,
  ...rest
}) => {
  const scrollRef = useRef(null);
  useEffect(() => {
    document.body.style.overflow = isVisible ? 'hidden' : 'unset';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isVisible]);
  return (
    <>
      <div
        className={`${className} tk-left-drawer col-12 col-lg-3 col-md-6 col-xl-3`}
      >
        <div
          className="tk-left-drawer__close-icon tk-dialog__btn-close tv-buttons__type icon-close"
          onClick={onClose}
          style={{ display: hideClose ? 'none' : 'block' }}
        />
        {rest.hideExternalScroll ? (
          <>{children}</>
        ) : (
          <Scrollbars ref={scrollRef}>{children}</Scrollbars>
        )}
      </div>
    </>
  );
};

const DesktopComponent = ({
  children,
  onClose,
  hideClose,
  className,
  ...rest
}) => {
  const scrollRef = useRef(null);
  return (
    <>
      <div
        className={`${className} tk-left-drawer col-12 col-lg-3 col-md-6 col-xl-3`}
      >
        <div
          className="tk-left-drawer__close-icon tk-dialog__btn-close tv-buttons__type icon-close"
          onClick={onClose}
          style={{ display: hideClose ? 'none' : 'block' }}
        />
        {rest.hideExternalScroll ? (
          <>{children}</>
        ) : (
          <Scrollbars ref={scrollRef}>{children}</Scrollbars>
        )}
      </div>
    </>
  );
};
