import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

const defaultProps = {
  title: '',
  className: '',
  children: null,
};

const CalenaderTime = ({ className, title, children }) => (
  <div className='tv-calendar-time__item'>
    <span className={className}>{title}</span>
    {children}
  </div>
);

CalenaderTime.propTypes = propTypes;
CalenaderTime.defaultProps = defaultProps;

export default CalenaderTime;