import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import TimePicker from './TimePicker';
import './rc-time-picker/assets/index.scss';

const propTypes = {
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  handleClick: PropTypes.func,
  handleValueChange: PropTypes.func,
  defaultValue: PropTypes.shape({}),
  durationCalculate: PropTypes.shape({}),
  allowNextDay: PropTypes.bool,
  startFromDayBegin: PropTypes.bool,
  rest: PropTypes.shape({}),
};

const defaultProps = {
    className: "",
    isDisabled: false,
    handleClick: () => {},
    handleValueChange: () => {},
    defaultValue: moment(),
    durationCalculate: moment(),
    allowNextDay: false,
    startFromDayBegin: false,
    rest: {},
};

const TransvoiceTimePickerV2 = ({ className, isDisabled, handleClick, defaultValue,durationCalculate,allowNextDay, startFromDayBegin, handleValueChange, ...rest }) => {
  return (
    <TimePicker
      {...rest}
      defaultValue={defaultValue}
      durationCalculate={durationCalculate}
      showSecond={false}
      allowNextDay={allowNextDay}
      startFromDayBegin={startFromDayBegin}
      disabled={isDisabled}
      className={className}
      minuteStep={30}
      onChange={(e) => {
        handleValueChange(e);
      }}
    />
  );
};

TransvoiceTimePickerV2.propTypes = propTypes;
TransvoiceTimePickerV2.defaultProps = defaultProps;

export default TransvoiceTimePickerV2;
