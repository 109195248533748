import moment from 'moment-timezone';
import { LOCAL_WEB_SESSION_ID } from '../constants';
import { customEvent, registerAirshipChannel } from './pushNotificationUtil';

export const createUpdateWebSession = async clientId => {
  const localWebSessionId = localStorage.getItem(LOCAL_WEB_SESSION_ID);
  if (!localWebSessionId) {
    await registerAirshipChannel(clientId, setWebSessionOnLocalStorage);
    return 1;
  }
  if (!validateWebSession(localWebSessionId)) {
    await setWebSessionOnLocalStorage();
    return 1;
  }
  return 0;
};

function validateWebSession(webSessionId) {
  const currentDate = moment().startOf('day');
  const sessionDate = moment(webSessionId, 'YYYYMMDDHHmmss').startOf('day');
  const diff = currentDate.diff(sessionDate, 'days');
  if (diff === 0) {
    return true;
  }
  return false;
}

async function setWebSessionOnLocalStorage() {
  localStorage.setItem(LOCAL_WEB_SESSION_ID, moment().format('YYYYMMDDHHmmss'));
  await customEvent('web_session_open', {});
}
