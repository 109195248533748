import React from 'react';
import PropTypes from 'prop-types';
import AssignmentStatus from '../AssignmentStatus';
import moment from 'moment';
import {
  assignmentTimeFormat,
  generateUniqueId,
  shouldDisplayRatingElement,
  shouldDisplayscrollTop,
  iconForTheServiceType,
} from '../../utils';
import IfComponent from '../IfComponent';
import { convertStringToDate } from '../../utils/dateUtil';
import { Translate } from '..';
import { APP_PORTAL, ENV_CLIENT } from '../../../Share/constants';
import Rating from '../Rating';
import MoreButton from './MoreButton';
import StarComponent from '../StarComponent';

const propTypes = {
  status: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onRateAssignmentClick: PropTypes.func,
  groupNumber: PropTypes.string,
  previousGroupNumber: PropTypes.string,
  screen: PropTypes.string,
  item: PropTypes.shape({}),
};

const defaultProps = {
  status: '',
  className: 'tv-workassignment__list-item',
  onClick: () => {},
  onRateAssignmentClick: () => {},
  groupNumber: '',
  previousGroupNumber: '',
  screen: '',
  item: {},
};

// eslint-disable-next-line react/prefer-stateless-function
class AssignmentItem extends React.Component {
  ItemRef = React.createRef();

  render() {
    const isClientPortal = APP_PORTAL === ENV_CLIENT;
    const {
      status,
      className,
      onClick,
      groupNumber,
      onRateAssignmentClick,
      previousGroupNumber,
      screen,
      isSelected,
      item,
    } = this.props;

    const reClassName =
      previousGroupNumber === groupNumber && groupNumber !== ''
        ? `row tv-workassignment__list-item-child ${className}`
        : `row tv-workassignment__list-item-parent ${className}`;
    return (
      <>
        <div
          className={`tv-reportquality__list-item-parent tv-workassignment__list-item  tv-display-flex ${
            isSelected ? 'selected' : ''
          }`}
          onClick={() => onClick(item)}
          role="button"
          tabIndex="-1"
          onKeyDown={() => onClick(item)}
          ref={this.ItemRef}
        >
          <div className="row px-0 mx-0 w-100 tv-display-mobile-hide  align-items-center position-relative">
            <div className="col-12 col-xs-4  col-sm-4 col-md col-lg col-xl order-0">
              <div className="tv-workassignment__item-text d-flex">
                <i
                  className={` icon tv-workassignment__detail-article-icon--${status} ${iconForTheServiceType(
                    item.ArticleName,
                  )} mr-2`}
                />{' '}
                {item.ArticleName.substring(
                  0,
                  item.ArticleName.length > 4
                    ? item.ArticleName.length - 4
                    : item.ArticleName.length,
                )}
              </div>
            </div>
            <div className="col-12 col-xs-4  col-sm-4 col-md col-lg col-xl order-1">
              <div className="tv-workassignment__item-text tv-workassignment__item-text-date">
                {`${moment(
                  item.DatetimeOrderFrom
                    ? item.DatetimeOrderFrom
                    : item.DatetimeFrom,
                )
                  .locale('sv')
                  .format('DD MMM')}, ${assignmentTimeFormat(
                  item.DatetimeOrderFrom
                    ? item.DatetimeOrderFrom
                    : item.DatetimeFrom,
                  item.DatetimeOrderTo ? item.DatetimeOrderTo : item.DatetimeTo,
                  '{from} - {to}',
                  false,
                  'DD MMM',
                )}`}
              </div>
            </div>
            <div className="col-12 col-xs-4  col-sm-4 col-md col-lg col-xl order-2">
              <div className="tv-workassignment__item-text">
                {item.OrderNumber}
              </div>
            </div>
            <div className="col-12 col-xs-6  col-sm-6 col-md col-lg col-xl order-3">
              <div
                className="tv-workassignment__item-text text-max-lenght"
                title={
                  isClientPortal
                    ? `${item.ContactPerson.ContactPersonFirstName} ${item.ContactPerson.ContactPersonLastName}`
                    : item.Customer.CustomerName
                }
              >
                {isClientPortal
                  ? `${item.ContactPerson.ContactPersonFirstName} ${item.ContactPerson.ContactPersonLastName}`
                  : item.Customer.CustomerName}
              </div>
            </div>
            <div className="col-12 col-xs-6  col-sm-6 col-md col-lg col-xl order-4">
              <div
                className="tv-workassignment__item-text text-max-lenght"
                title={item.skill}
              >
                {item.Skill}
              </div>
            </div>
            <div className="col-12 col-xs-6  col-sm-6 col-md col-lg col-xl order-5 d-flex d-sm-flex d-md-none d-xl-flex">
              <div className="tv-workassignment__item-text">
                {item.RatingCustomer ? (
                  <StarComponent
                    className="stars"
                    style={{
                      '--rating': `${(
                        (item.RatingCustomer ? item.RatingCustomer : 0) * 20
                      ).toFixed(1)}%`,
                    }}
                    value={item.RatingCustomer ? item.RatingCustomer : 0}
                  />
                ) : (
                  item.CanBeRated && (
                    <>
                      <div
                        className="tv-workassignment__item-link-button"
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          onRateAssignmentClick(item);
                        }}
                      >
                        Betygsätt
                      </div>
                    </>
                  )
                )}
              </div>
            </div>
            <div className="col-12 col-xs-6  col-sm-6 col-md col-lg col-xl-1 order-6">
              <div className="tv-workassignment__item-text">
                <AssignmentStatus
                  className="tv-workassignment__detail-status"
                  status={status}
                />
              </div>
            </div>
            {isSelected && shouldDisplayscrollTop(this.ItemRef) && (
              <div
                className="position-absolute tv-workassignment__list-scrolltop bounce-4"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  document
                    .querySelector('.tv-right-pannel-wrapper ')
                    .scrollIntoView({
                      behavior: 'smooth',
                    });
                }}
              >
                <i
                  className="fas fa-arrow-alt-circle-up "
                  title="visa detaljer"
                ></i>
              </div>
            )}
          </div>
          <div className="row px-0 mx-0 w-100 tv-display-mobile-show ">
            <AssignmentStatus
              className="tv-workassignment__detail-status tv-display-flex"
              status={status}
            >
              <div>
                <i
                  className={`tv-workassignment__detail-article-icon mr-2  tv-workassignment__detail-article-icon--${status} ${iconForTheServiceType(
                    item.ArticleName,
                  )}`}
                />{' '}
                {item.ArticleName}
              </div>
            </AssignmentStatus>
            <hr />
            <div className="tv-workassignment__item-text">
              <div className="tv-workassignment__item-text-label">
                <i className="fa fa-globe " />
              </div>
              <div className="d-flex justify-content-between w-100">
                <span>{item.Skill} </span>{' '}
                {item.RatingCustomer ? (
                  <StarComponent
                    className="stars"
                    style={{
                      '--rating': `${(
                        (item.RatingCustomer ? item.RatingCustomer : 0) * 20
                      ).toFixed(1)}%`,
                    }}
                    value={item.RatingCustomer ? item.RatingCustomer : 0}
                  />
                ) : (
                  item.CanBeRated && (
                    <>
                      <div
                        className="tv-workassignment__item-link-button"
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          onRateAssignmentClick(item);
                        }}
                      >
                        Betygsätt
                      </div>
                    </>
                  )
                )}
              </div>
            </div>
            <div className="tv-workassignment__item-text">
              <div className="tv-workassignment__item-text-label">
                <i className="fa fa-clock " />
              </div>
              {`${moment(
                item.DatetimeOrderFrom
                  ? item.DatetimeOrderFrom
                  : item.DatetimeFrom,
              )
                .locale('sv')
                .format('DD MMM')}, ${assignmentTimeFormat(
                item.DatetimeOrderFrom
                  ? item.DatetimeOrderFrom
                  : item.DatetimeFrom,
                item.DatetimeOrderTo ? item.DatetimeOrderTo : item.DatetimeTo,
                '{from} - {to}',
                false,
              )}`}
            </div>
            <div className="tv-workassignment__item-text">
              <div className="tv-workassignment__item-text-label">
                <i className="fa fa-user mr-1" />{' '}
                <Translate
                  content={
                    isClientPortal
                      ? 'singleAssignment.contactPerson'
                      : 'yourProfile.clientName'
                  }
                />
                :{' '}
              </div>
              {isClientPortal
                ? `${item.ContactPerson.ContactPersonFirstName} ${item.ContactPerson.ContactPersonLastName}`
                : item.Customer.CustomerName}
            </div>
            <div className="tv-workassignment__item-text">
              <div className="tv-workassignment__item-text-label">
                <i className="fa fa-book mr-1" />{' '}
                <Translate content="singleAssignment.orderNumber" />:
              </div>
              {item.OrderNumber}
            </div>
          </div>
        </div>
      </>
    );
  }
}

AssignmentItem.propTypes = propTypes;
AssignmentItem.defaultProps = defaultProps;

export default AssignmentItem;

// const temp =()=>(<React.Fragment>
//   <div
//     className={reClassName}
//     onClick={() => onClick(item)}
//     role="button"
//     tabIndex="-1"
//     onKeyDown={() => {}}
//   >
//     <div className="tv-workassignment__item-icon col-1">
//       <i
//         className={` icon ${iconForTheServiceType(item.ArticleName)}`}
//       ></i>
//     </div>
//     <div className="tv-workassignment__item-datetime pl-1 pl-md-4 col-2 my-lg-auto">
//       <div className="tv-workassignment__item-label ">
//         {getDateFormated(
//           item.DatetimeOrderFrom
//             ? item.DatetimeOrderFrom
//             : item.DatetimeFrom,
//         )}
//       </div>
//       <div className="tv-workassignment__item-text">
//         {assignmentTimeFormat(
//           item.DatetimeOrderFrom
//             ? item.DatetimeOrderFrom
//             : item.DatetimeFrom,
//           item.DatetimeOrderTo ? item.DatetimeOrderTo : item.DatetimeTo,
//           '{from} - {to}',
//           false,
//         )}
//       </div>
//     </div>
//     <div className="tv-workassignment__item-order col-2 my-auto">
//       <div className="tv-workassignment__item-label label">
//         <Translate content="singleAssignment.orderNumber" />
//       </div>
//       <div className="tv-workassignment__item-text">
//         {item.OrderNumber}
//       </div>
//     </div>
//     <div className="tv-workassignment__item-name col-2 my-auto">
//       <div className="tv-workassignment__item-label">
//         <Translate
//           content={
//             isClientPortal
//               ? 'singleAssignment.contactPerson'
//               : 'yourProfile.clientName'
//           }
//         />
//       </div>
//       <div className="tv-workassignment__item-text">
//         {isClientPortal
//           ? `${item.ContactPerson.ContactPersonFirstName} ${item.ContactPerson.ContactPersonLastName}`
//           : item.Customer.CustomerName}
//       </div>
//     </div>

//     <div className="tv-workassignment__item-category col-2 my-auto">
//       <div className="tv-workassignment__item-label label">
//         <Translate content="singleAssignment.language" />
//       </div>
//       <div className="tv-workassignment__item-text">{item.Skill}</div>
//     </div>

//     <IfComponent
//       condition={screen === 'availableRequest'}
//       whenTrue={
//         <div className="tv-workassignment__item-datetime">
//           <div className="tv-workassignment__item-label">
//             <Translate content="singleAssignment.availableUtil" />
//           </div>
//           <div className="tv-workassignment__item-text-available-until">
//             {convertStringToDate(
//               item.DatetimeExpiration,
//               'YYYY-MM-DD HH:mm:ss',
//             ).format('MMM Do YYYY')}
//           </div>
//         </div>
//       }
//     />
//     {!shouldDisplayRatingElement(status, item.DatetimeFrom) ? (
//       <AssignmentStatus
//         className="tv-workassignment__item-status tv-display-flex col-2 justify-content-between"
//         status={status || 'accepted'}
//       />
//     ) : (
//       <>
//         <div className="tv-workassignment__item-status tv-display-flex col-2 pl-0 tv-display-mobile-hide">
//           <div className="list-rating-container">
//             <AssignmentStatus
//               className="text-left "
//               status={status || 'accepted'}
//             />
//             <Rating
//               className="list-small-rating tv-display-mobile-hide"
//               display={shouldDisplayRatingElement(
//                 status,
//                 item.DatetimeFrom,
//               )}
//               rate={item.RatingCustomer ? item.RatingCustomer : 0}
//               isDisabled
//               DisplayRateValue={true}
//               key={generateUniqueId()}
//               displaySendButton={false}
//               displayCommentBox={false}
//             />
//           </div>
//         </div>
//         <AssignmentStatus
//           className="tv-workassignment__item-status tv-display-flex col-2 justify-content-between tv-display-mobile-show"
//           status={status || 'accepted'}
//         />
//       </>
//     )}
//   </div>
// </React.Fragment>)
