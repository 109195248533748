import React, { useEffect, useState } from 'react';
import { Translate, Modal, TransvoiceButton } from '../../../Share/components';
import moment from 'moment';

function AcoliAvailableModal({
  isVisible,
  hideAcoliModal,
  displayAcoliSuccessModal,
  assignment,
  setSelectedSlot,
}) {
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const {
    dateRange,
    typeOfAssignment,
    contactPerson,
    orderContactPerson,
    contactPersons,
  } = assignment;

  const orderDateTimeRender = dateRange => {
    const dateTimeFrom = moment(dateRange.ranges.startDate);
    const dateTimeTo = moment(dateRange.ranges.endDate);
    let orderDateTimeStr = `${dateTimeFrom.format(
      'HH:mm',
    )} - ${dateTimeTo.format('HH:mm, DD MMMM YYYY')}`;

    if (!dateTimeFrom.isSame(dateTimeTo, 'day')) {
      orderDateTimeStr = `${dateTimeFrom.format(
        'HH:mm, DD MMMM',
      )} - ${dateTimeTo.format('HH:mm, DD MMMM YYYY')}`;
    }
    return orderDateTimeStr;
  };

  const filterContactPerson = contactPersons.filter(
    obj => obj.value === contactPerson,
  )[0];
  const previewContactPerson = filterContactPerson
    ? filterContactPerson.name
    : Translate({ content: 'general.none' });

  const filterContactOrderPerson = contactPersons.filter(
    obj => obj.value === orderContactPerson,
  )[0];
  const previewContactOrderPerson = filterContactOrderPerson
    ? filterContactOrderPerson.name
    : Translate({ content: 'general.none' });

  return (
    <Modal
      className="tv-alternativeTid__modal"
      isVisible={isVisible}
      headerRender={<></>}
      ieOptionClassName={'alternativeTid-holder'}
      onCloseClicked={() => {
        hideAcoliModal(false);
      }}
    >
      <div className="tv-modal__holder__custom-header">
        <h2>Tyvärr finns ingen ledig tolk för detta språk ditt valda datum</h2>
      </div>
      <div className="tv-alternativeTid__container">
        <div className="tv-alternativeTid__top-container">
          <div className="tv-alternativeTid__top-container__title">
            Bokningsinformation
          </div>
          <div className="tv-alternativeTid__top-container__list">
            <BookingInfoItem label="Språk" value="Acoli" />
            <BookingInfoItem label="Bokning" value={typeOfAssignment.name} />
            <BookingInfoItem
              label="Tid"
              value={orderDateTimeRender(dateRange)}
            />
            <BookingInfoItem label="Tolk" value={previewContactPerson} />
            <BookingInfoItem
              label="Adress"
              value="Sveavägen 159, 113 46 Stockholm"
            />
            <BookingInfoItem
              label="Beställare"
              value={previewContactOrderPerson}
            />
          </div>
        </div>

        <div className="tv-alternativeTid__time-container">
          <div className="tv-alternativeTid__time-container__title">
            Vänligen välj alternativ tid
          </div>
          <div className="tv-alternativeTid__time-container__box">
            <img
              className="ellipse-back"
              src="/images/ellipse.png"
              alt="ellipse-back"
            />
            <img
              className="arrow-back"
              src="/images/vector-back-arrow.png"
              alt="arrow-back"
            />
            <div className="tv-alternativeTid__time-container__box-section">
              <div className="time-container__date">Onsdag 10 januari 2024</div>
              <div
                className={`time-slot ${
                  selectedTimeSlot === 1 ? 'selected' : null
                }`}
                onClick={() => {
                  setSelectedTimeSlot(1);
                  setSelectedSlot(1);
                }}
              >
                <div className="time-limit">10.00-11.00</div>
                <div>
                  <img src="/images/plus.png" alt="plus-icon" />
                  <img src="/images/person.png" alt="person-icon" />
                  <img src="/images/person.png" alt="person-icon" />
                  <img src="/images/person.png" alt="person-icon" />
                </div>
              </div>
              <div
                className={`time-slot ${
                  selectedTimeSlot === 2 ? 'selected' : null
                }`}
                onClick={() => {
                  setSelectedTimeSlot(2);
                  setSelectedSlot(2);
                }}
              >
                <div className="time-limit">13.00-14.00</div>
                <div>
                  <img src="/images/person.png" alt="person-icon" />
                  <img src="/images/person.png" alt="person-icon" />
                  <img src="/images/person.png" alt="person-icon" />
                </div>
              </div>
            </div>
            <div className="tv-alternativeTid__time-container__box-section">
              <div className="time-container__date">Fredag 12 januari 2024</div>
              <div
                className={`time-slot ${
                  selectedTimeSlot === 3 ? 'selected' : null
                }`}
                onClick={() => {
                  setSelectedTimeSlot(3);
                  setSelectedSlot(3);
                }}
              >
                <div className="time-limit">10.00-11.00</div>
                <div>
                  <img src="/images/person.png" alt="person-icon" />
                  <img src="/images/person.png" alt="person-icon" />
                </div>
              </div>
              <div
                className={`time-slot ${
                  selectedTimeSlot === 4 ? 'selected' : null
                }`}
                onClick={() => {
                  setSelectedTimeSlot(4);
                  setSelectedSlot(4);
                }}
              >
                <div className="time-limit">13.00-14.00</div>
                <div>
                  <img src="/images/person.png" alt="person-icon" />
                  <img src="/images/person.png" alt="person-icon" />
                </div>
              </div>
              <div
                className={`time-slot ${
                  selectedTimeSlot === 5 ? 'selected' : null
                }`}
                onClick={() => {
                  setSelectedTimeSlot(5);
                  setSelectedSlot(5);
                }}
              >
                <div className="time-limit">15.00-16.00</div>
                <div>
                  <img src="/images/person.png" alt="person-icon" />
                </div>
              </div>
            </div>
            <img
              className="ellipse-forward"
              src="/images/ellipse.png"
              alt="ellipse-forward"
            />
            <img
              className="arrow-forward"
              src="/images/vector-forward-arrow.png"
              alt="rrow-forward"
            />
          </div>
        </div>
        <div className="tv-alternativeTid__actions">
          <TransvoiceButton
            className="cancel-button"
            text="Tillbaka"
            onClick={() => {
              hideAcoliModal(false);
            }}
          />
          <TransvoiceButton
            text="Bekräfta ny tid"
            className={`submit-button ${
              selectedTimeSlot !== null ? 'continue' : null
            }`}
            onClick={() => {
              if (selectedTimeSlot !== null) {
                displayAcoliSuccessModal(true);
              }
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

const BookingInfoItem = ({ label, value }) => {
  return (
    <div className="tv-alternativeTid__top-container__list-item">
      <div>{label}: &nbsp;</div>
      <div>{value}</div>
    </div>
  );
};

export default AcoliAvailableModal;
