import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import { IfComponent, Translate } from '../../../Share/components';
import SearchBox from '../SearchBox/SearchBox';
import { connect } from 'react-redux';
import { searchOrder, checkUpdateCalendarEvent } from '../../actions';
import { Popup } from '@progress/kendo-react-popup';
import SingleEvent from '../../../Share/components/AssignmentListBox/SingleEvent';
import moment from 'moment';
import { showToastMessage } from '../../../Share/utils';

const RETRIEVE_STATUS = {
  SUCCESS: 0,
  LOADING: 1,
  FAILURE: 2,
  NODATA: 4,
};
// eslint-disable-next-line react/prop-types
const OrderSearchBar = ({ onSearchWorkAssignments, onEventClick, onBlur }) => {
  const [dataRetrieveState, setDataRetrieveState] = useState(
    RETRIEVE_STATUS.SUCCESS,
  );
  const anchor = React.useRef(null);
  const inputRef = React.useRef(null);

  function onComplete(status, order) {
    setDataRetrieveState(status);
    switch (status) {
      case RETRIEVE_STATUS.NODATA:
        inputRef.current.value = '';
        showToastMessage(
          'error',
          'Tyvärr gav din sökning inget resultat. Försök igen med att ändra sökkriterier.',
        );
        break;
      case RETRIEVE_STATUS.SUCCESS:
        inputRef.current.value = '';
        onEventClick('', order[0].OrderIdentifier);
        break;

      case RETRIEVE_STATUS.FAILURE:
        inputRef.current.value = '';
        showToastMessage(
          'error',
          'Something went wrong please try again some time later',
        );
        break;

      default:
        break;
    }
    onBlur();
  }
  const onSearch = searchTarget => {
    if (searchTarget === '' || searchTarget === null) return;
    if (searchTarget.trim() === '') {
      inputRef.current.value = '';
      return;
    }
    onSearchWorkAssignments(searchTarget.trim(), onComplete);
    setDataRetrieveState(RETRIEVE_STATUS.LOADING);
  };

  const onKeyPress = e => {
    const { keyCode } = e;
    if (keyCode === 13) {
      onSearch(e.target.value);
    }
  };

  return (
    <React.Fragment>
      <div className="w-100 position-relative" ref={anchor}>
        <SearchBox
          placeholder={Translate({
            content: 'myfilter.searchByOrderNumber',
          })}
          ref={inputRef}
          className="position-relative"
          onBlur={e => {
            onSearch(e.target.value);
          }}
          onKeyUp={onKeyPress}
        />
        {dataRetrieveState === RETRIEVE_STATUS.LOADING ? (
          <span className="fas fa-spinner fa-pulse tv-search-order-bar__loader" />
        ) : null}
      </div>
    </React.Fragment>
  );
};
const mapDispatchToProps = dispatch => ({
  onSearchWorkAssignments: (orderNumber, onComplete) => {
    dispatch(searchOrder(orderNumber, onComplete));
  },
});

export default connect(null, mapDispatchToProps)(OrderSearchBar);
