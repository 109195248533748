import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Report from './Report';
import { NotFound } from '../../../Share/components';

const ReportContainer = ({ report, match }) => (report ?
  <Report
    reportDetail={report}
    reportId={parseInt(match.params.id, 10)}
    key={parseInt(match.params.id, 10)}
  /> : <NotFound />
);

ReportContainer.propTypes = {
  report: PropTypes.shape({}).isRequired,
};

ReportContainer.defaultProps = {

};

const mapStateToProps = (state, props) => {
  const param = parseInt(props.match.params.id, 10);
  const report = state.reportDetails.reports[param];
  return ({
    report,
  });
};


const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(ReportContainer);
