import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ValidatorForm } from 'react-form-validator-core';
import {
  Translate,
  TransvoiceButton,
  TextField,
} from '../../../Share/components';
import { resetPassword } from '../../actions/authentication';
import './style.scss';
import history from '../../../Share/utils/history';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      retypePassword: '',
    };
  }

  componentDidMount() {
    ValidatorForm.addValidationRule('isReTypePasswordMatch', value => {
      if (value !== this.state.password) {
        return false;
      }
      return true;
    });
    ValidatorForm.addValidationRule('passwordSetPolicy', value => {
      if (value !== this.state.password) {
        return false;
      }
      return true;
    });
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { password } = this.state;
    this.props.onResetPassword(password);
  };

  handleValidatorListener = () => {
    this.form.isFormValid().then(isValid => {
      this.setState({ submitDisabled: !isValid });
    });
  };

  render() {
    const isNotRedirectFromSettings =
      !this.props.location.state ||
      this.props.location.state.from !== '/settings';
    if (isNotRedirectFromSettings) {
      history.push('/'); // Go to dashboard
    }

    return (
      <div className="tv-reset-password__wrapper tv-display-flex">
        <div className="tv-reset-password__title">
          <Translate content="login.resetPassword" />
        </div>
        <div className="tv-reset-password__description">
          <Translate content="login.createYourNewPassword" /> <br />
          <Translate content="login.minimumOfEightCharactersInLength" />
        </div>
        <div className="tv-reset-password__form tv-reset-password__form-wrapper">
          <ValidatorForm
            ref={node => (this.form = node)}
            onSubmit={this.handleSubmit}
            onError={this.handleValidatorListener}
          >
            <div className="tv-reset-password__form-password-label tv-reset-password__form-label">
              <Translate content="login.enterNewPassword" />
            </div>
            <TextField
              type="password"
              validatorListener={this.handleValidatorListener}
              onChange={this.handleChange}
              className="tx-input-text tv-reset-password__form-password-input"
              name="password"
              value={this.state.password}
              placeholder={Translate({ content: 'login.enterNewPassword' })}
              validators={['minStringLength:8', 'passwordSetPolicy']}
              errorMessages={[
                `${Translate({
                  content: 'login.minimumOfEightCharactersInLength',
                })}`,
              ]}
            />

            <div className="tv-reset-password__form-retype-password-label tv-reset-password__form-label">
              <Translate content="login.retypeNewPassword" />
            </div>
            <TextField
              type="password"
              validatorListener={this.handleValidatorListener}
              onChange={this.handleChange}
              className="tx-input-text tv-reset-password__form-retype-password-input"
              name="retypePassword"
              value={this.state.retypePassword}
              placeholder={Translate({ content: 'login.retypeNewPassword' })}
              validators={['minStringLength:8', 'isReTypePasswordMatch']}
              errorMessages={[
                `${Translate({ content: 'login.validator' })}`,
                Translate({
                  content: 'validator.passwordMatch',
                }),
              ]}
            />

            <TransvoiceButton
              type="primary"
              buttonType="submit"
              text={Translate({ content: 'login.changePassword' })}
              className="tv-reset-password__form-submit"
              onClick={this.handleSubmit}
              disabled={this.state.submitDisabled}
            />
          </ValidatorForm>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onResetPassword: plainTextPassword => {
      dispatch(resetPassword(plainTextPassword));
    },
  };
};

export default connect(null, mapDispatchToProps)(ResetPassword);
