import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { searchWorkAssignments, getAttachment, setOrderRating } from '../../actions';
import AssignmentDetail from '../AssignmentDetail';
import { DrawerSlide, AssignmentList, IfComponent, Translate } from '../../../Share/components';
import './style.scss'
import { status } from '../../../Share/constants';
import history from '../../../Share/utils/history';

const propTypes = {
  filters: PropTypes.shape({}),
  assignmentList: PropTypes.arrayOf(PropTypes.shape({})),
  onSearchWorkAssignments: PropTypes.func,
  onGetAttachment: PropTypes.func,
  onOrderRated: PropTypes.func.isRequired,
  // onGetWorkAssignment: PropTypes.func,
  screen: PropTypes.string,
  searchOrder: PropTypes.string,
};

const defaultProps = {
  filters: {},
  assignmentList: [],
  onSearchWorkAssignments: () => { },
  onGetAttachment: () => { },
  // onGetWorkAssignment: () => { },
  screen: 'myAssignment',
  searchOrder: '',
};

const INITAL_STATES = {
  showDetail: false,
  searchOrder: '',
  assignmentDetail: {},
  redirectCancel: false,
};

class SearchResult extends Component {
  state = INITAL_STATES;

  componentDidMount() {
    this.executeSearchAssignment();
  }

  componentDidUpdate(prevProps) {
    if (this.props.searchOrder !== prevProps.searchOrder) {
      this.executeSearchAssignment();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.searchOrder !== prevState.searchOrder) {
      return {
        searchOrder: nextProps.searchOrder,
      };
    }

    return null;
  }

  executeSearchAssignment = () => {
    const { onSearchWorkAssignments, searchOrder } = this.props;

    onSearchWorkAssignments(null, null, null, searchOrder, true);
  }

  handleClickCancel = () => {
    this.setState({
      redirectCancel: true,
    });
  }

  onRowClick = (item) => {
    this.setState({
      showDetail: true,
      assignmentDetail: item,
    });
  }

  getPathName = () => {
    if (this.state.redirectCancel) return '/cancel-assignment';
  }

  renderRedirect = () => {
    if (this.state.redirectCancel) {
      const { assignmentDetail } = this.state;
      return (
        <Redirect
          to={{
            pathname: this.getPathName(),
            state: {
              from: this.props.location,
              assignmentDetail,
            },
          }}
        />
      );
    }

    return null;
  }

  onDrawerClose = () => {
    this.setState({
      showDetail: false,
    });
  }

  redirectToTimeSheet = () => {
    const { assignmentDetail } = this.state;

    history.push({
      pathname: '/time-sheet',
      state: {
        from: this.props.location,
        assignmentDetail,
      },
    });
  };

  handleClickTimeReport = () => {
    this.redirectToTimeSheet();
  };

  render() {
    const { assignmentList, screen, searchOrder, showLoadingOverlay, onOrderRated } = this.props;
    const { showDetail, assignmentDetail } = this.state;
    return (
      <React.Fragment>

        <IfComponent
          condition={!showLoadingOverlay}
          whenTrue={(
            <IfComponent
              condition={assignmentList.length > 0}
              whenTrue={(
                <React.Fragment>
                  <div className="tv-title-result">
                    <span className="tv-title-result__number">{assignmentList.length}</span> <Translate content="main.resultsFoundFor" /> <span className="tv-title-result__order">“{searchOrder}”</span>
                  </div>
                  <div className="tv-workassignment__wrapper">
                    <AssignmentList onRowClick={this.onRowClick} items={assignmentList} screen={screen} />
                  </div>
                  <DrawerSlide
                    onCloseClicked={this.onDrawerClose}
                    onOverlayClick={this.onDrawerClose}
                    isVisible={showDetail}
                  >
                    <AssignmentDetail
                      assignment={assignmentDetail}
                      status={status.getByStatusString(assignmentDetail.OrderStatus)}
                      handleClickCancel={() => this.handleClickCancel(assignmentDetail)}
                      handleOrderRate={onOrderRated}
                      handleGetAttachment={this.props.onGetAttachment}
                      handleClickTimeReport={this.handleClickTimeReport}
                    />
                  </DrawerSlide>
                </React.Fragment>)}
              whenFalse={(
                <div className="tv-not-found">
                  <div className="tv-title-result">
                    <span className="tv-title-result__number">{assignmentList.length}</span> <Translate content="search.resultFoundFor" /> <span className="tv-title-result__order">“{searchOrder}”</span>
                  </div>
                  <div className="tv-not-found__image"></div>
                </div>
              )}
            />
          )}
        />
        {this.renderRedirect()}
      </React.Fragment>
    );
  }
}

SearchResult.propTypes = propTypes;
SearchResult.defaultProps = defaultProps;

const mapStateToProps = state => {
  const { assignmentList, searchOrder } = state.workAssignment;
  const { showLoadingOverlay } = state.app;
  return {
    assignmentList,
    searchOrder,
    showLoadingOverlay
  }
};

const mapDispatchToProps = dispatch => ({
  onSearchWorkAssignments: (s, from, to, o, e) => {
    dispatch(searchWorkAssignments(s, from, to, o, e));
  },
  onOrderRated: (orderIdentifier, rate, comment) => {
    dispatch(setOrderRating(orderIdentifier, rate, comment));
  },
  onGetAttachment: (attachmentIdentifier) => {
    dispatch(getAttachment(attachmentIdentifier));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(SearchResult);