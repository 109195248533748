import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './style.scss';
import { Translate } from '../../../../Share/components';
import { getCustomer } from '../../../actions';

class YourProfile extends Component {
  componentDidMount() {
    const { onGetCustomer } = this.props;
    onGetCustomer();
  }

  render() {
    const {
      profile: {
        customerName,
        customerAddress,
        phoneNumberSwitchboard,
        customerNumber,
      },
    } = this.props;

    const avatarImg = (
      <img
        src="/images/Avatar.png"
        className="tv-myprofile__avatar-img"
        alt="avatar"
      />
    );

    return (
      <div className="tv-myprofile tv-myprofile__wrapper">
        <div className="tv-myprofile__header-wrapper">
          <div className="tv-myprofile__avatar">{avatarImg}</div>
          <div className="tv-myprofile__header-text">
            <div className="tv-myprofile__name">{`${customerName}`}</div>
            <div className="tv-myprofile__client-number">
              <Translate content="yourProfile.clientNumber" />: {customerNumber}
            </div>
          </div>
        </div>
        <div className="tv-myprofile__content-wrapper">
          <div className="row">
            <div className="col-6 col-md-4 col-xl-4 ">
              <div className="tv-myprofile__field">
                <Translate content="yourProfile.visitingAddress" />
                <div className="tv-myprofile_value">
                  <div>
                    {customerAddress.AddressRow1} {customerAddress.AddressRow2}
                  </div>
                  <div>{customerAddress.PostalCode}</div>
                  <div>
                    {customerAddress.City} {customerAddress.Country}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-6 col-xl-4 ">
              <div className="tv-myprofile__field">
                <Translate content="yourProfile.phone" />
                <div className="tv-myprofile_value">
                  <div>{phoneNumberSwitchboard}</div>
                </div>
              </div>
            </div>
          </div>
          <hr className="tv-myprofile_hr" />
        </div>
      </div>
    );
  }
}

YourProfile.propTypes = {
  onGetCustomer: PropTypes.func.isRequired,
  profile: PropTypes.shape({
    customerName: PropTypes.string,
    customerAddress: PropTypes.shape({}),
    phoneNumberSwitchboard: PropTypes.string,
  }),
};

YourProfile.defaultProps = {
  profile: {},
};

const mapStateToProps = state => ({
  profile: state.user.profile,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onGetCustomer: () => {
    dispatch(getCustomer());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(YourProfile);
