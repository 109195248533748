import React from 'react';
import PropTypes from 'prop-types';

import { Translate } from '..';

const propTypes = {
  translateName: PropTypes.string,
  onClick: PropTypes.func,
  filterkey: PropTypes.string,
  filterParamater: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
  translateName: '',
  onClick: () => { },
  filterkey: '',
  filterParamater: [],
};


const QuickFilter = ({ translateName, onClick, filterkey, filterParamater }) => (
  <div
    className="tv_dashboard__filter-panel-badge"
    onClick={onClick}
    onKeyPress={onClick}
    data-filterkey={filterkey}
    style={filterParamater.indexOf(filterkey) < 0 ? {} : { backgroundColor: 'rgba(164, 180, 204, 0.56)' }}
    role="button"
    tabIndex={0}
  ><Translate content={`${translateName}`} />
  </div>
);

QuickFilter.propTypes = propTypes;
QuickFilter.defaultProps = defaultProps;

export default QuickFilter;
