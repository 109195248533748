/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { RATING_VALUES } from '../../constants';
// import MessageServiceItem from '../AssignmentList/AssignmentItem';
import MessageServiceItem from './MessageServiceItem';
import './style.scss';
import SortButton from './SortButton';
import getAssignmentStatus from '../AssignmentStatus/status';

const propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  onRowClick: PropTypes.func,
  onRateAssignmentClick: PropTypes.func,
  screen: PropTypes.string,
  filterParamater: PropTypes.shape([]),
  rateFilter: PropTypes.shape([]),
  selectedItem: PropTypes.shape({}),
  sortDateTime: PropTypes.func,
  dateTimeSortedIcon: PropTypes.string,
};

const defaultProps = {
  items: [],
  onRowClick: () => {},
  onRateAssignmentClick: () => {},
  screen: '',
  selectedItem: null,
  sortDateTime: () => {},
  dateTimeSortedIcon: '',
};

const sortedFiltersEmpty = {
  ArticleName: 0,
  DatetimeFrom: 0,
  OrderNumber: 0,
  ContactPerson: 0,
  Skill: 0,
  RatingCustomer: 0,
  OrderStatus: 0,
};

const MessageServiceList = ({
  items,
  onRowClick,
  onRateAssignmentClick,
  onChangeAssignmentSortType,
  onChangeSortedFilters,
  screen,
  selectedItem,
  rateFilter,
  filterParamater,
  typeValue: value,
  assignmentSortType,
  sortedFilters,
  // sortDateTime,
  // dateTimeSorted,
  // dateTimeSortedIcon,
}) => {
  const [sortedAssignemntList, setSortedAssignemntList] = useState([]);

  function sortAscending(
    assignments,
    sortType,
    targetType,
    childKey,
    sortTypeForDeriveValue,
    deriveValue,
  ) {
    return assignments.sort((a, b) => {
      if (targetType === 'number') {
        return a[sortType] - b[sortType];
      }
      if (targetType === 'string') {
        if (sortType === sortTypeForDeriveValue && deriveValue) {
          return deriveValue(a[sortType]).localeCompare(
            deriveValue(b[sortType]),
            'sv-SE',
          );
        }
        if (childKey) {
          return a[sortType][childKey].localeCompare(
            b[sortType][childKey],
            'sv-SE',
          );
        }
        return a[sortType].localeCompare(b[sortType], 'sv-SE');
      }
      if (targetType === 'date') {
        return moment(a[sortType]).diff(b[sortType]);
      }
      return 0;
    });
  }

  function sortDescending(
    assignments,
    sortType,
    targetType,
    childKey,
    sortTypeForDeriveValue,
    deriveValue,
  ) {
    return assignments.sort((a, b) => {
      if (targetType === 'number') {
        return b[sortType] - a[sortType];
      }
      if (targetType === 'string') {
        if (sortType === sortTypeForDeriveValue && deriveValue) {
          return deriveValue(b[sortType]).localeCompare(
            deriveValue(a[sortType]),
            'sv-SE',
          );
        }
        if (childKey) {
          return b[sortType][childKey].localeCompare(
            a[sortType][childKey],
            'sv-SE',
          );
        }
        return b[sortType].localeCompare(a[sortType], 'sv-SE');
      }
      if (targetType === 'date') {
        return moment(b[sortType]).diff(a[sortType]);
      }
      return 0;
    });
  }

  function sortAssignmentList(
    assignments,
    sortType,
    targetType,
    childKey,
    sortTypeForDeriveValue,
    deriveValue,
    isFiltersApplied = false,
  ) {
    let sortedItems = assignments;

    if (isFiltersApplied) {
      if (sortedFilters[sortType] === 1 || sortedFilters[sortType] === 0) {
        sortedItems = sortAscending(
          sortedItems,
          sortType,
          targetType,
          childKey,
          sortTypeForDeriveValue,
          deriveValue,
        );
      }
      if (sortedFilters[sortType] === 2 || sortedFilters[sortType] === 0) {
        sortedItems = sortDescending(
          sortedItems,
          sortType,
          targetType,
          childKey,
          sortTypeForDeriveValue,
          deriveValue,
        );
      }
    } else {
      if (sortedFilters[sortType] === 1 || sortedFilters[sortType] === 0) {
        sortedItems = sortDescending(
          sortedItems,
          sortType,
          targetType,
          childKey,
          sortTypeForDeriveValue,
          deriveValue,
        );
        onChangeSortedFilters({ ...sortedFiltersEmpty, [sortType]: 2 });
        onChangeAssignmentSortType({ type: sortType, targetType, childKey });
      }
      if (sortedFilters[sortType] === 2 || sortedFilters[sortType] === 0) {
        sortedItems = sortAscending(
          sortedItems,
          sortType,
          targetType,
          childKey,
          sortTypeForDeriveValue,
          deriveValue,
        );
        onChangeSortedFilters({ ...sortedFiltersEmpty, [sortType]: 1 });
        onChangeAssignmentSortType({ type: sortType, targetType, childKey });
      }
    }
    setSortedAssignemntList(sortedItems);
  }

  function onClickSort(sortType, targetType, childKey) {
    sortAssignmentList(
      sortedAssignemntList,
      sortType,
      targetType,
      childKey,
      'OrderStatus',
      getAssignmentStatus,
    );
  }

  useEffect(() => {
    let filteredAssignmentList = [];
    filterParamater.map(filter => {
      if (filter !== 'all-assignments') {
        const filteredAssignmentItems = items.filter(
          item => item.OrderStatus === filter,
        );
        filteredAssignmentItems.forEach(fiterItems => {
          filteredAssignmentList.push(fiterItems);
        });
      } else {
        filteredAssignmentList = [];
      }
    });

    let rateFilteredAssignmentList = [];
    rateFilter.map(rateItem => {
      if (rateItem !== null) {
        const rateFilteredAssignments = filteredAssignmentList.filter(item => {
          if (rateItem === RATING_VALUES.NOT_RATED) {
            return item.RatingCustomer === null || item.RatingCustomer < 1;
          }
          return item.RatingCustomer === rateItem;
        });
        rateFilteredAssignments.forEach(fiterItems => {
          rateFilteredAssignmentList.push(fiterItems);
        });
      } else {
        rateFilteredAssignmentList = [];
      }
      return null;
    });

    let typeFilteredAssignmentList = [];
    if (value.value !== '') {
      const typeFilteredAssignments = rateFilteredAssignmentList.filter(
        item => value.value === item.ArticleName,
      );
      typeFilteredAssignments.forEach(fiterItems => {
        typeFilteredAssignmentList.push(fiterItems);
      });
    } else {
      typeFilteredAssignmentList = rateFilteredAssignmentList;
    }
    sortAssignmentList(
      typeFilteredAssignmentList,
      assignmentSortType.type,
      assignmentSortType.targetType,
      assignmentSortType.childKey,
      'OrderStatus',
      getAssignmentStatus,
      true,
    );
  }, [rateFilter, filterParamater, value, items]);

  return (
    <div className="tv-workassignment__list ">
      <div>
        <div className="row tv-reportquality__list-item-parent tv-reportquality__list-item tv-reportquality__list-item-header d-none d-md-flex mb-0">
          <div className="col-12 col-xs-4  col-sm-4 col-md  col-lg col-xl">
            <div className="tv-reportquality__item-text tv-reportquality__item-text-assignments">
              Typ av meddelande
            </div>
          </div>
          <div className="col-12 col-xs-4  col-sm-4 col-md  col-lg col-xl ">
            <div className="tv-reportquality__item-text tv-reportquality__item-text-assignments">
              Datum
            </div>
          </div>
          <div className="col-12 col-xs-4  col-sm-4 col-md  col-lg col-xl ">
            <div className="tv-reportquality__item-text tv-reportquality__item-text-assignments">
              Nummer
            </div>
          </div>
          <div className="col-12 col-xs-6  col-sm-6 col-md col-lg col-xl ">
            <div className="tv-reportquality__item-text tv-reportquality__item-text-assignments">
              Kopplat bokningnr.
            </div>
          </div>
          <div className="col-12 col-xs-6  col-sm-6 col-md col-lg col-xl ">
            <div className="tv-reportquality__item-text tv-reportquality__item-text-assignments">
              Språk
            </div>
          </div>
          <div className="col-12 col-lg col-md col-sm-6 col-xl col-xs-6 d-flex d-lg-none d-xl-flex">
            <div className="tv-reportquality__item-text tv-reportquality__item-text-assignments">
              Namn
            </div>
          </div>
          <div className="col-12 col-xs-6  col-sm-6 col-md col-lg col-xl-1 ">
            <div className="tv-reportquality__item-text tv-reportquality__item-text-assignments">
              Status
            </div>
          </div>
        </div>
        {items.length > 0 &&
          items.map((item, i, element) => (
            <MessageServiceItem
              key={`${item.OrderIdentifier}-${item.OrderNumber}`}
              onClick={() => onRowClick(item, sortedAssignemntList)}
              className="tv-workassignment__list-item tv-display-flex"
              identifier={item.identifier}
              category={item.skill}
              isSelected={selectedItem?.id === item.id}
              onRateAssignmentClick={onRateAssignmentClick}
              status={item.status ? item.status : item.OrderStatus}
              title={item.title}
              datetimeFrom={item.datetimeFrom}
              datetimeTo={item.datetimeTo}
              orderNumber={item.orderNumber}
              groupNumber={item.groupNumber}
              previousGroupNumber={
                element[i - 1] ? element[i - 1].groupNumber : undefined
              }
              screen={screen}
              availableUntil={item.DatetimeExpiration}
              item={item}
            />
          ))}
        {items.length === 0 && (
          <>
            <div className="tv-workassignment__list-empty d-flex justify-content-center flex-column">
              <div className="tv-workassignment__list-empty-img" />
              <p>
                Tyvärr gav din sökning inget resultat. Försök igen med att ändra
                sökkriterier.
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

MessageServiceList.propTypes = propTypes;
MessageServiceList.defaultProps = defaultProps;

export default MessageServiceList;
