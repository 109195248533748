import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  LOCAL_STORE_CUSTOMER_NUMBER,
  LOCAL_STORE_TOKEN,
} from '../../../Share/constants';
import { sleepTime } from '../../../Share/utils/helpers';
import { unregisterAirShipChannel } from '../../../Share/utils/pushNotificationUtil';
import history from '../../../Share/utils/history';
import { connect, useSelector } from 'react-redux';
import { logout } from '../../actions';
import { validateWorldishLogin } from '../../actions/authentication';

function WorldishLogin({ onLogout, onValidateWorldishLogin }) {
  const { id } = useParams();

  useEffect(() => {
    onClickLogout();
    onValidateWorldishLogin(id);
  }, []);

  const onClickLogout = async () => {
    const clientNumber = localStorage.getItem(LOCAL_STORE_CUSTOMER_NUMBER);
    const list = await navigator.serviceWorker.getRegistrations();

    await sleepTime(1000);

    if (list?.length > 0) {
      await unregisterAirShipChannel(clientNumber);
    }
    // history.push('/login');

    onLogout();
  };

  return <div></div>;
}

const mapDispatchToProps = dispatch => ({
  onLogout: () => {
    dispatch(logout());
  },
  onValidateWorldishLogin: identifier => {
    dispatch(validateWorldishLogin(identifier));
  },
});

export default connect(null, mapDispatchToProps)(WorldishLogin);
