import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  placeHolder: PropTypes.string,
  handleChange: PropTypes.func,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
  shouldFocus: PropTypes.bool,
};

const defaultProps = {
  className: '',
  name: '',
  placeHolder: '',
  handleChange: () => {},
  value: '',
  isDisabled: false,
  shouldFocus: false,
};

class InputText extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot !== null) {
      if (this.props.shouldFocus) {
        this.textInput.current.focus();
      }
    }
  }

  render() {
    const { className, name, placeHolder, handleChange, value, isDisabled } = this.props;

    const controlClassName = classNames({
      'tx-input-text': true,
      'tx-input-text--inactive': isDisabled,
      [className]: !!className,
    });

    return (
      <input
        type="text"
        className={controlClassName}
        name={name}
        placeholder={placeHolder}
        value={value}
        onChange={handleChange}
        disabled={isDisabled ? 'disabled' : ''}
        ref={this.textInput}
      />
    );
  }
}

InputText.propTypes = propTypes;
InputText.defaultProps = defaultProps;
export default InputText;
