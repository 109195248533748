import React from 'react';

function Banner() {
  return (
    <div className="tk-calendar__banner-container">
      <img className="banner" src="/images/banner_with_qr.png" alt="" />
      <div class="banner-text">Har du testat vår nya app?</div>
      <a
        href="https://play.google.com/store/apps/details?id=se.transvoice.tolkdirekt"
        target="_blank"
        className="banner_google-play-image"
      >
        <img
          className="inside-image"
          src="/images/google-play-badge.png"
          alt=""
        />
      </a>
      <a
        href="https://apps.apple.com/us/app/tolkdirekt/id1491013439"
        target="_blank"
        className="banner_apple-store-image"
      >
        <img
          className="inside-image"
          src="/images/apple-store-badge.svg"
          alt=""
        />
      </a>
    </div>
  );
}

export default Banner;
