import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import {
  IntlProvider,
  load,
  LocalizationProvider,
} from '@progress/kendo-react-intl';
import 'react-toastify/dist/ReactToastify.css';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';
import numbers from 'cldr-numbers-full/main/sv/numbers.json';
import currencies from 'cldr-numbers-full/main/sv/currencies.json';
import caGregorian from 'cldr-dates-full/main/sv/ca-gregorian.json';
import dateFields from 'cldr-dates-full/main/sv/dateFields.json';
import timeZoneNames from 'cldr-dates-full/main/sv/timeZoneNames.json';

import store from './ClientPortal/store';
import ClientPortalRoot from './ClientPortal/Root';
import GoogleCalendarInit from './ClientPortal/components/GoogleCalendarInit/index';
import { loadAirShipModules } from './Share/utils/pushNotificationUtil';

load(
  likelySubtags,
  currencyData,
  weekData,
  numbers,
  currencies,
  caGregorian,
  dateFields,
  timeZoneNames,
);

loadAirShipModules(window, document);

ReactDOM.render(
  <Provider store={store}>
    <React.Fragment>
      <LocalizationProvider language="sv-SE">
        <IntlProvider locale="sv">
          <GoogleCalendarInit />
          <ClientPortalRoot />
          <ToastContainer autoClose={5000} />
        </IntlProvider>
      </LocalizationProvider>
    </React.Fragment>
  </Provider>,
  document.getElementById('root'),
);
