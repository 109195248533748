import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Translate } from '../../../Share/components';

const propTypes = {
  total: PropTypes.string,
};

const defaultProps = {
  total: '',
};


const TotalCount = (props) => {
  const { total } = props;
  return (
    <div className="tv-workassignment__control-panel h-pdl--30">
      <span className="tv-filter-list__element h-fs--16">
        {`${Translate({ content: 'singleAssignment.total' })}: `}
        <strong>{total}</strong>
      </span>
    </div>
  );
};

TotalCount.propTypes = propTypes;
TotalCount.defaultProps = defaultProps;

export default TotalCount;
