import React from 'react';
import { Link } from 'react-router-dom';

const Logo = (props) => {
  return (
    <div className="tv-left-pannel__logo">
      <Link to="/">
        <img src="/images/logo.png" alt=""/>
      </Link>
    </div>
  );
};

export default Logo;
