import React from 'react';
import './percentageChartStyles.scss';

const PercentageChart = ({ data = [], title = '' }) => (
  <div className="percentage-chart">
    <div className="percentage-chart-title">{title}</div>
    <div className="percentage-chart-table">
      {data?.map(({ category, value }, i) => (
        <>
          <div className="number">{i + 1} .</div>
          <div className="name">{category}</div>
          <div className="value">{(value * 100).toFixed()} %</div>
        </>
      ))}
    </div>
  </div>
);

export default PercentageChart;
