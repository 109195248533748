import React, { useEffect, useState } from 'react';
import ReactStars from 'react-rating-stars-component';
import PropTypes from 'prop-types';
import './styles.scss';
import { isIE } from '../../../Share/utils';

const StarGroup = props => {
  const [editStarRating, setEditStarRating] = useState(true);
  const {
    handleChange,
    defaultRate,
    activeColor,
    size,
    count,
    classNames,
    editRating,
    value,
  } = props;

  useEffect(() => {
    if (!editRating) {
      setEditStarRating(false);
    }
  }, [editRating]);

  return (
    <>
      {editStarRating === true ? (
        <ReactStars
          count={count}
          classNames={classNames}
          value={value}
          onChange={handleChange}
          color={isIE ? '#ffe7ce' : '#FFF'}
          size={size}
          activeColor={activeColor}
          edit={editStarRating}
          key={editStarRating}
        />
      ) : (
        <ReactStars
          count={count}
          classNames={classNames}
          value={value}
          onChange={handleChange}
          color={isIE ? '#ffe7ce' : '#FFF'}
          size={size}
          activeColor={activeColor}
          edit={editStarRating}
          key={editStarRating}
        />
      )}
    </>
  );
};

StarGroup.propTypes = {
  defaultRate: PropTypes.number,
  handleChange: PropTypes.func,
  activeColor: PropTypes.string,
  size: PropTypes.number,
  count: PropTypes.number,
  editRating: PropTypes.bool,
  value: PropTypes.number,
};

StarGroup.defaultProps = {
  count: 5,
  defaultRate: 0,
  handleChange: () => {},
  activeColor: '#ffd700',
  size: 25,
  editRating: true,
  value: 0,
};

export default StarGroup;
