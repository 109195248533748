import React, { useState, useRef } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import Scrollbars from 'react-custom-scrollbars';
import {
  Row,
  Col,
  Container,
  TransvoiceButton,
  Translate,
} from '../../../Share/components';
import { TRANSVOICE_EMAIL_INFO } from '../../../Share/constants';
import './style.scss';
import faq from './faq';

const ContactTransvoice = props => {
  const [showAccordian, setAccordianState] = useState({
    'rowOne-columnOne': false,
    'rowOne-columnTwo': false,
    'rowTwo-columnOne': false,
    'rowTwo-columnTwo': false,
    'rowThree-columnOne': false,
    'rowThree-columnTwo': false,
  });

  const handleShowAccordian = id => {
    setAccordianState({
      [id]: !showAccordian[id],
    });
  };

  const handleMailClick = e => {
    e.preventDefault();
    window.location.href = `mailto:${TRANSVOICE_EMAIL_INFO}`;
  };

  const displayIcons = id => {
    if (showAccordian[id]) {
      return <IoIosArrowUp />;
    }
    return <IoIosArrowDown />;
  };
  const scrollRef = useRef(null);
  return (
    <>
      <div className="page_content">
        <div className="page-title">
          <div className="title">Kontakta oss</div>
          <div className="contact_now_button_wrapper">
            <TransvoiceButton
              type="line-primary"
              text={Translate({
                content: 'settings.sendTransvoiceAnEmail',
              })}
              className="contact_now_button"
              onClick={handleMailClick}
            />
          </div>
        </div>
        <div className="contact_card_wrapper">
          <div className="d-flex flex-column">
            <div className="d-flex w-100 flex-column flex-md-row ">
              <ContactCard
                imageUrl="/images/stockholm-office.jpg"
                place="Stockholm"
                addressLine="Lindhagensgatan 126"
                addressCityPostCode="112 51 Stockholm"
                customerServiceNumber="08-120 800 00"
              />
              <ContactCard
                imageUrl="/images/Malmö-office.jpg"
                place="Malmö"
                addressLine="Grynbodgatan 14"
                addressCityPostCode="211 33 Malmö"
                customerServiceNumber="040-23 02 05"
              />
            </div>
          </div>
          <div className="page-title">Konsten att tala genom tolk</div>
          <div className="page-section-body">
            Att använda sig av tolk kan tyckas enkelt men är i själva verket
            komplext och ställer stora krav på tolkanvändaren. Transvoice har
            tagit fram den här digitala Tala genom tolk-utbildningen och den
            riktar sig till dig som tolkanvändare och har skapats med syftet att
            tolkanvändaren på ett effektivare sätt ska kunna kommunicera med
            hjälp av tolk. Utbildningen tar ca 20 minuter att göra och du hittar
            den{' '}
            <a
              href="https://www.transvoice.se/talagenomtolk/story_html5.html"
              target="_blank"
            >
              Här
            </a>
          </div>
          <div className="page-title">Frågor och svar</div>
          <div className="faq_wrapper">
            {Object.keys(faq.faqData).map(row => (
              <Row key={`${faq.faqData[row]}-${Math.random()}`}>
                {faq.faqData[row].map(column => (
                  <Col md={6} sm={12} key={column.id}>
                    <div className="drop_accordian">
                      <div className="question_content">
                        <div
                          className="heading"
                          onClick={() => handleShowAccordian(column.id)}
                        >
                          <div>{column.question}</div>
                          <div className="icon_wrapper">
                            {displayIcons(column.id)}
                          </div>
                        </div>
                        <div
                          className="body"
                          style={{
                            display: showAccordian[column.id] ? 'flex' : 'none',
                          }}
                        >
                          {column.answer &&
                            column.answer.split('||').map(x => <p>{x}</p>)}
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            ))}
          </div>
        </div>
        <div className="page-footer">
          <div className="d-flex mt-auto">
            <img src="/images/transvoice-black 1.png" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactTransvoice;

const ContactCard = ({
  addressLine,
  imageUrl,
  place,
  addressCityPostCode,
  customerServiceNumber,
}) => (
  <>
    <div className="w-100 contact_card__item">
      <div className="generic_card">
        <div className="contact_image">
          <img src={imageUrl} alt={place} />
        </div>
        <div className="d-flex flex-column">
          <div className="contact_city">{place}</div>
          <div className="contact_address_wrapper">
            <div>{addressLine}</div>
            <div>{addressCityPostCode}</div>
          </div>
          <div className="contact_method_wrapper">
            <div className="mr-2">Kundtjänst:</div>
            <div className="contact_method_wrapper-number">
              <a href={`tel:${customerServiceNumber}`}>
                {customerServiceNumber}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);
