import React from 'react';
import { Modal, TransvoiceButton } from '../../../Share/components';
import moment from 'moment';

function MessageServicePreview({
  item,
  isVisible,
  onCloseClicked,
  onContinueBooking,
}) {
  const {
    date,
    bookingNumber,
    clientName,
    feedback,
    telephoneNo,
    language,
    message,
    feedbackValue,
  } = item;

  const orderDateTimeRender = date => {
    const startDate = moment(date.ranges.startDate);
    let orderDateTimeStr = startDate.format('DD MMMM YYYY');

    return orderDateTimeStr;
  };

  return (
    <Modal
      className="tv-message-service__modal"
      isVisible={isVisible}
      headerRender={<></>}
      ieOptionClassName={'message-service-preview_holder'}
      onCloseClicked={onCloseClicked}
    >
      <div className="tv-modal__holder__custom-header">
        <h2>Ditt meddelande är inbokat</h2>
      </div>
      <div className="tv-message-service__preview_container">
        <div className="top_container">
          <img
            className="preview__message-ellipse"
            src="/images/message-service-preview-ellipse.png"
          />
          <img
            className="preview__message-logo"
            src="/images/message-service-preview.png"
          />
        </div>
        <div className="detail-conatiner">
          <h2>Bokningsinformation</h2>
          <div className="detail-conatiner__wrapper">
            <div className="detail-conatiner__wrapper-details">
              <div className="detail-conatiner__wrapper-details-item">
                Språk: {language.name}
              </div>
              <div
                className="detail-conatiner__wrapper-details-item"
                style={{ display: 'flex' }}
              >
                Datum:{' '}
                <div style={{ textTransform: 'lowercase' }}>
                  &nbsp;{orderDateTimeRender(date)}
                </div>
              </div>
              <div className="detail-conatiner__wrapper-details-item">
                Klientens namn: {clientName}
              </div>
              <div className="detail-conatiner__wrapper-details-item">
                Återkoppling via:{' '}
                {feedbackValue === 1 ? 'Sms' : 'Telefonsamtal'}
              </div>
              <div className="detail-conatiner__wrapper-details-item">
                Klientens telefonnummer: {telephoneNo}
              </div>
              <div className="detail-conatiner__wrapper-details-item">
                Kopplat bokningsnummer: {bookingNumber}
              </div>
            </div>
            <div>
              <div className="detail-conatiner__wrapper-heading">
                Meddelande:
              </div>
              <div className="detail-conatiner__wrapper-message">
                <i>{message}</i>
              </div>
            </div>
          </div>
        </div>
        <div className="tv-message-service__actions">
          <TransvoiceButton
            className="cancel-button"
            text="Fortsätt boka"
            onClick={onContinueBooking}
          />
          <TransvoiceButton
            buttonType="submit"
            type="secondary"
            text="Klar"
            className="close-button"
            onClick={onCloseClicked}
          />
        </div>
      </div>
    </Modal>
  );
}

export default MessageServicePreview;
