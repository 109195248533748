module.exports = {
  faqData: {
    rowOne: [
      {
        id: 'rowTwo-columnOne',
        question: 'Hur fungerar telefontolkning?',
        answer: `När du bokar telefontolkning så kommer du när uppdraget är tillsatt att få ett telefonnummer till tolken. Det är du som tolkanvändare som ringer upp tolken vid uppdragets start.

        ||Vissa kunder kan också boka telefontolk där tolken ska ansluta till ett virtuellt mötesrum. Då är det tolken som ansluter till mötet.
        
        ||När man använder telefontolk så är det några saker som är extra viktigt att tänka på:

        || - Använd ordentlig högtalarutrustning och kontrollera att utrustningen fungerar som den ska i god tid före tolkningen

        || - Informera tolken om vilka som befinner sig i rummet

        || - Tala extra klart och tydligt och tala en i taget

        || - Minimera störande bakgrundsljud

        || - Stäm av start- och sluttiden med tolken
        
        `,
      },
      {
        id: 'rowOne-columnTwo',
        question: 'Hur långt fram måste jag boka för att få tolk?',
        answer:
          'Det är något som varierar från språk till språk och om man kanske har några speciella krav. Är det tolk på plats, ett mindre språk, krav på exempelvis en specifik tolk eller kön så minskar antalet tillgängliga tolkar och då kan man behöva boka med ännu bättre framförhållning. Boka alltid tolk i så god tid som möjligt.',
      },
    ],
    rowTwo: [
      {
        id: 'rowThree-columnOne',
        question: 'Vad gör jag om tolken inte svarar?',
        answer: `Om tolk inte svarar på utsatt tid för uppdraget ska ni genast ringa oss på 08-120 800 00.

          ||Det ger oss chansen att lösa problemet. Vi har alltid andra kontaktvägar till tolk och kanske har det hänt något med tolkens telefon eller ni har fått fel telefonnummer.`,
      },
      {
        id: 'rowTwo-columnTwo',
        question: 'Vart kan man hitta tolkens telefonnummer?',
        answer: `När tolken är tillsatt så dyker tolkens namn och telefonnummer upp på uppdraget inne i kundportalen.

        ||När tolk är tillsatt på uppdraget så kan man också få en bekräftelse skickad till vald kanal med tolkens telefonnummer.`,
      },
    ],
    rowThree: [
      {
        id: 'rowFour-columnOne',
        question: 'Vad gör jag om jag inte är nöjd med tolken?',
        answer:
          'Om du inte är nöjd med tolken så kan du antingen lämna en lågt betyg på uppdraget(1-2 stjärnor) så kommer du automatiskt vidare för att göra en avvikelse eller så går du in på uppdraget och lämnar en avvikelse direkt. Vi utreder alla avvikelser och återkopplar alltid på ärendet via portalen eller på det sätt som önskas. Det är viktigt att vi får in feedback, både positiv och negativ sådan. Om du inte är nöjd med tolken du har haft är det viktigt att du meddelar oss detta.',
      },
      {
        id: 'rowThree-columnTwo',
        question: 'Hur fungerar videotolkning?',
        answer: `Om du bokar videotolkning så måste du ange videoplattform och bifoga eventuell länk eller annat som är aktuellt för videomötet.

        ||Tolken ansluter sedan till mötet vid uppdragets starttid.`,
      },
    ],
    rowFour: [
      {
        id: 'rowFive-columnOne',
        question:
          'Jag hittar inte Bosniska, Kroatiska eller Serbiska som språk?',
        answer:
          'Eftersom alla dessa är samma språk så är de sammanslagna och har samlingsnamnet "BKS" i språklistan.',
      },

      {
        id: 'rowFour-columnTwo',
        question: 'Jag hittar inte Kurdiska som språk?',
        answer:
          'Kurdiska är inget eget språk utan består utav av olika dialekter. Därav om du vill boka en kurdisk dialekt så finner du dessa under sitt eget namn. Sorani, Kurmanji, Feijli eller Badinani.',
      },
    ],
    rowFive: [
      {
        id: 'rowFive-columnTwo',
        question:
          'Vad innebär det att lämna feedback/ge betyg på uppdrag och varför ska vi göra det?',
        answer: `Ni har möjlighet att skicka feedback på alla utförda uppdrag. Att vi får återkoppling är viktigt för oss och handlar i slutändan om att matcha rätt tolk till rätt uppdrag. Det är lika viktigt för oss att få in positiv feedback som negativ feedback. Ni betygsätter uppdraget på en skala 1-5, där 1 är Inte alls bra och 5 är Utmärkt. Det är det samlade intrycket av tolkningen i dess helhet som ska bedömas, och det är i första hand själva tolkanvändaren som ska lämna feedback.
         Lämna gärna en kommentar i samband med er feedback och betygsätter ni 1-2 så får ni möjlighet att skapa en avvikelse. Tack för att ni ger oss feedback!
          `,
      },
    ],
  },
};
