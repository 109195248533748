import React from 'react';
import PropTypes from 'prop-types';

import CloseButton from '../CloseButton';
import IfComponent from '../IfComponent';

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  isVisible: PropTypes.bool,
  style: PropTypes.shape({}),
};

const defaultProps = {
  className: '',
  children: '',
  onClose: () => { },
  isVisible: false,
  style: {},
};


const Dialog = ({
  className, children, onClose, isVisible, style,bodyClassName
}) => (
  <IfComponent
    condition={isVisible}
    whenTrue={(
      <React.Fragment>
        <div
          className={`tk-dialog__wrapper ${className}`}
          style={{
            left: style.left,
            top: style.top,
          }}
        >
          <span
            className="tk-dialog__wrapper--before"
            style={{
              borderRightColor: style.borderRightColor,
              borderLeftColor: style.borderLeftColor,
              borderBottomColor: style.borderBottomColor,
              borderTopColor: style.borderTopColor,
              transform: style.tipTransform,
              right: style.tipRight,
              top: style.tipTop,
            }}
          />
          <CloseButton
            onClick={onClose}
            className="tk-dialog__btn-close tv-buttons__type icon-close"
          />
          <div className={`tk-dialog__body ${bodyClassName}`}>
            {(children)}
          </div>
        </div>
      </React.Fragment>
    )}
  />
);

Dialog.propTypes = propTypes;
Dialog.defaultProps = defaultProps;

export default Dialog;
