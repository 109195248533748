import React from 'react';
import PropTypes from 'prop-types';
import {
  filterAvailabilityInDay,
} from '../../utils';

import AvailabilityItem from './AvailabilityItem';

const propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  onElementClick: PropTypes.func,
  dateRange: PropTypes.arrayOf(PropTypes.shape({})),
};

const defaultProps = {
  items: [],
  dateRange: [],
  onElementClick: {},
};


const AvailabilityList = ({ items, onElementClick, dateRange }) => (
  <div className="tv-youravailability__list tv-display-flex-column">
    {
      dateRange.map((day) => {
        const processedItem = filterAvailabilityInDay(items, day);
        return (
          <div style={{ marginBottom: '10px' }}>
            <span className="tv-youravailability__item-text-datetime">  {day.format('Do MMMM')}</span>
            <div style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap'}}>
              {processedItem.map((item, i) => (
                <AvailabilityItem
                  key={item.identifier}
                  className="tv-youravailability__list-item tv-display-flex-row"
                  identifier={item.identifier}
                  availableDate={item.availableDate}
                  availableTime={item.availableTime}
                  DateTimeFrom={item.DatetimeFrom}
                  DateTimeTo={item.DatetimeTo}
                  availableFor={item.availableFor}
                  articleIdentifier={item.articleIdentifier}
                  onClick={onElementClick}
                />
              ))}
            </div>
          </div>
        );
      })
    }

  </div>
);


AvailabilityList.propTypes = propTypes;
AvailabilityList.defaultProps = defaultProps;

export default AvailabilityList;
