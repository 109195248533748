/* eslint-disable max-statements */
import CryptoJS from 'crypto-js';
import { Buffer } from 'buffer';

const defaultPassword = process.env.REACT_APP_MULTISOFT_CRYPTO_KEY;

function decrypt(encryptedData, password = defaultPassword) {
  let passwordInBytes = CryptoJS.enc.Utf16LE.parse(password);
  const encryptedString = Buffer.from(encryptedData, 'base64').toString('hex').replace('-', '');
  const version = Buffer.from(encryptedString.substring(0, 8), 'hex').readUIntLE(0, 4);

  let encrypted = null;
  let hashAlgo = null;
  let cryptoAlgo = null;
  const keySize = (version === 1 ? 16 : 32);

  if (version === 1) {
    hashAlgo = CryptoJS.SHA1;
    cryptoAlgo = CryptoJS.TripleDES;
    cryptoAlgo.IV = encryptedString.substring(8, 8 + 16);
    encrypted = encryptedString.substring(24);
  } else if (version === 2) {
    hashAlgo = CryptoJS.SHA256;
    cryptoAlgo = CryptoJS.AES;
    cryptoAlgo.IV = encryptedString.substring(8, 8 + 32);
    encrypted = encryptedString.substring(40);
  } else {
    throw new Error('Unsupported encryption');
  }

  const padding = CryptoJS.pad.Pkcs7;
  const mode = CryptoJS.mode.CBC;

  passwordInBytes = hashAlgo(passwordInBytes).toString(CryptoJS.enc.Hex);
  const key = Buffer.from(passwordInBytes, 'hex').slice(0, keySize).toString('hex');

  const options = { iv: CryptoJS.enc.Hex.parse(cryptoAlgo.IV), mode, padding };
  const ciphertext = CryptoJS.format.Hex.parse(encrypted);

  let decrypted = null;
  if (version === 1) {
    decrypted = Buffer.from(
      cryptoAlgo.decrypt(ciphertext, CryptoJS.enc.Hex.parse(key.repeat(3)), options).toString(CryptoJS.enc.Hex),
      'hex',
    );
  } else if (version === 2) {
    decrypted = Buffer.from(
      cryptoAlgo.decrypt(ciphertext, CryptoJS.enc.Hex.parse(key), options).toString(CryptoJS.enc.Hex),
      'hex',
    );
  }

  const magic = decrypted.readUInt32LE(0);

  if (magic !== 0xbaadf00d) {
    throw new Error('Decrypt failed');
  }

  const decryptedData = decrypted.slice(8);
  const isUtf16 = decryptedData.indexOf(0) !== -1;
  const decryptText = isUtf16 ? decryptedData.toString() : decryptedData.toString('utf8');

  return decryptText;
}

export default decrypt;
