import React from 'react';
import PropTypes from 'prop-types';

import IfComponent from '../IfComponent';
import { Translate } from '..';
import { availabilityStatus } from '../../constants';

const propTypes = {
  availability: PropTypes.number,
  className: PropTypes.string,
  type: PropTypes.number,
  onClick: PropTypes.func,
};

const defaultProps = {
  availability: 0,
  onClick: () => { },
  type: 2,
  className: 'tk-monthly-calendar__item-availability-btn',
};


const ItemDay = ({ availability, onClick, type, className }) => (
  <IfComponent
    condition={availability > 0}
    whenTrue={(
      <div className={className} onClick={onClick}>

        <IfComponent
          condition={ type === availabilityStatus.AVAILABLE}
          whenTrue={
            <Translate content="myfilter.availability" />
          }
          whenFalse={
            <Translate content="myfilter.unavailability" />

          }
        />
      </div>
    )}
  />
);

ItemDay.propTypes = propTypes;
ItemDay.defaultProps = defaultProps;

export default ItemDay;
