import React from "react";
import PropTypes from "prop-types";
import {
    IfComponent,
    AssignmentStatus,
    Translate,
    Rating,
} from "../../../Share/components";
import { assignmentTimeFormat, getDateFormated, shouldDisplayRatingElement } from "../../../Share/utils";

class PrintableAssignmentDetail extends React.PureComponent {
   genderIdtoString = (genderId) => {
    let genderString = Translate({ content: "general.any" });
    switch (genderId) {
        case 1:
            genderString = Translate({ content: "general.male" });
            break;
        case 2:
            genderString = Translate({ content: "general.female" });
            break;

        default:
            genderString = Translate({ content: "general.any" });
            break;
    }
    return genderString;
};

    render() {
        const { assignment,status,isShowInterpreterElement } = this.props;
     
             
        return (
            <div className="d-flex flex-column">
                <AssignmentStatus
                    className="tv-workassignment__modal-status d-flex w-100"
                    status={status.name}
                >
                    <div>
                        {`${Translate({
                            content: "calendar.assignments",
                        })} ${assignment.OrderNumber}`}
                    </div>
                </AssignmentStatus>
                <div className="row w-100 ml-2">
                        <div className="col-6 col-md-4 my-2">
                            <InformationItem
                                label={"Datum"}
                                content={getDateFormated(
                                    assignment.DatetimeFrom,"DD-MM-YYYY"
                                )}
                            />
                        </div>
                        <div className="col-6 col-md-4 my-2">
                            <InformationItem
                                label={"Tid"}
                                content={assignmentTimeFormat(
                                    assignment.DatetimeFrom,
                                    assignment.DatetimeTo
                                )}
                            />
                        </div>
                        <div className="col-6 col-md-4 my-2">
                            <InformationItem
                                label={"Typ av Bokning"}
                                content={assignment.ArticleName}
                            />
                        </div>
                        <div className="col-6 col-md-4 my-2">
                            <InformationItem
                                label={Translate({
                                    content: "taskDetail.fullName",
                                })}
                                content={
                                    assignment.ContactPersonOrderer
                                        .ContactPersonOrdererName
                                }
                            />
                        </div>
                        <div className="col-6 col-md-4 my-2">
                            <InformationItem
                                label={"Bokare"}
                                content={assignment.ArticleName}
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="row w-100 ml-2">
                        <div className="col-6 col-md-4 my-2">
                            <InformationItem
                                label={Translate({
                                    content: "report.skill",
                                })}
                                content={assignment.Skill}
                            />
                        </div>

                        <div className="col-6 col-md-4 my-2">
                            <InformationItem
                                label={Translate({
                                    content: "report.competence",
                                })}
                                content={assignment.CompetenceLevel}
                            />
                        </div>

                        <div className="col-6 col-md-4 my-2">
                            <InformationItem
                                label={"Bokare"}
                                content={assignment.ArticleName}
                            />
                        </div>
                        <div className="col-6 col-md-4 my-2">
                            <InformationItem
                                label={Translate({
                                    content: "taskDetail.gender",
                                })}
                                content={this.genderIdtoString(
                                    assignment.GenderIdPreferred
                                )}
                            />
                        </div>
                        {assignment.assignmentSubskill ? (
                            <div className="col-6 col-md-4 my-2">
                                <InformationItem
                                    label={"Dialekt"}
                                    content={assignment.assignmentSubskill}
                                />
                            </div>
                        ) : null}
                    </div>
                   {isShowInterpreterElement?
                   <>
                                       <hr />
                    <div className="row w-100 ml-2">
                        <div className="tv-workassignment__modal-subheader">
                            {Translate({
                                content: "taskDetail.interpreterFullName",
                            })}
                        </div>
                    </div>
                    <div className="row w-100 ml-2">
                        <div className="col-6 col-md-4 my-2">
                            <InformationItem
                                content={assignment.Resource.ResourceFullName}
                                label={Translate({
                                    content: "taskDetail.interpreterFullName",
                                })}
                            />{" "}
                        </div>
                        <div className="col-6 col-md-4 my-2">
                            <InformationItem
                                content={
                                    assignment.Resource.ResourceNumber
                                }
                                label={Translate({
                                    content: "taskDetail.interpreterId",
                                })}
                            />
                        </div>
                        <div className="col-6 col-md-4 my-2">
                            <InformationItem
                                label={Translate({
                                    content: "taskDetail.phoneNumber",
                                })}
                                content={assignment.PhoneNumberOrder?assignment.PhoneNumberOrder:"-"}
                            />
                        </div>
                    </div>
                   </>:null
                   }
                     <hr />
                   <div className="row w-100 ml-2">
                        <div className="col-12 col-md-6 my-2 text-box-content">
                            <InformationItem
                                content={assignment.SpecificTravelInstructions?assignment.SpecificTravelInstructions:"-"}
                                label={Translate({
                                    content: "taskDetail.infoForTransvoice",
                                })}
                            />{" "}
                        </div>
                        <div className="col-12 col-md-6 my-2 text-box-content">
                            <InformationItem
                                content={assignment.assignmentDescription?assignment.assignmentDescription:"-"}
                                label={Translate({
                                    content: "taskDetail.infoForInterpreter",
                                })}
                            />{" "}
                        </div>
                        <div className="col-12 col-md-6 my-2">
                            <InformationItem
                                content={assignment.requestedInterpreter?assignment.requestedInterpreter:"-"}
                                label={Translate({
                                    content: "taskDetail.requestedInterpreter",
                                })}
                            />{" "}
                        </div>
                    </div>
                  
            </div>
        );
    }
}

PrintableAssignmentDetail.propTypes = {
    assignment: PropTypes.shape({}),
    status: PropTypes.shape({}),
    attachmentElement: PropTypes.shape({}),
};
PrintableAssignmentDetail.defaultProps = {
    assignment: {},
    status: {},
    attachmentElement: {},
};

export default PrintableAssignmentDetail;

function InformationItem({ label, content }) {
  return (
      <div className="d-flex flex-column">
          <div className="tv-workassignment__modal-info-label">{label}</div>
          <div className="tv-workassignment__modal-info-content">
              {content}
          </div>
      </div>
  );
}
