import React from 'react';
import PropTypes from 'prop-types';

const Emoji = props => {
  const { label, symbol, className, iconClassName } = props;

  return (
    <span
      className={className}
      role="img"
      aria-label={label || ''}
      aria-hidden={label ? 'false' : 'true'}
    >
      <div className={iconClassName}>{symbol}</div>
    </span>
  );
};

Emoji.propTypes = {
  label: PropTypes.string,
  symbol: PropTypes.string,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
};

Emoji.defaultProps = {
  label: '',
  symbol: '',
  className: '',
  iconClassName: '',
};

export default Emoji;
