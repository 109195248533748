import React, { Component } from 'react';
import { Modal } from '../../../Share/components';
import { getMobilePlatform } from '../../../Share/utils';

export class MobileAppInfoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMobileAppInfo: Boolean(getMobilePlatform()),
    };
  }

  getMobileAppImagePath = () => {
    const platform = getMobilePlatform();

    if (platform === 'android') {
      return '/images/google-play-badge.png';
    } else if (platform === 'ios') {
      return '/images/apple-store-badge.svg';
    }
    return null;
  };

  generateMobileAppLink = () => {
    const platform = getMobilePlatform();
    switch (platform) {
      case 'android':
        return 'https://play.google.com/store/apps/details?id=se.transvoice.tolkdirekt';
      case 'ios':
        return 'https://apps.apple.com/us/app/tolkdirekt/id1491013439';
      default:
        return '';
    }
  };

  render() {
    const { showMobileAppInfo } = this.state;
    return (
      <Modal
        isVisible={showMobileAppInfo}
        headerText="Ladda ner vår app för kunder!"
        className="mobile-app-info"
        headerClassName="header"
        onCloseClicked={() => this.setState({ showMobileAppInfo: false })}
      >
        <div className="content-container">
          <div>
            Nu finns Transvoice nya app Tolk! att ladda ner. Använd den för en
            bättre upplevelse i mobilen.
          </div>
          <div className="button-container">
            <a
              className="download-button"
              href={this.generateMobileAppLink()}
              target="_blank"
              rel="noopener noreferrer"
            >
              {this.getMobileAppImagePath() ? (
                <img
                  src={this.getMobileAppImagePath()}
                  alt="app store badge"
                  className="store-badge"
                />
              ) : null}
            </a>
          </div>
        </div>
      </Modal>
    );
  }
}

export default MobileAppInfoModal;
