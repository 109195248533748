import React from 'react';
import PropTypes from 'prop-types';
import IfComponent from '../IfComponent';
import CloseButton from '../CloseButton';

import { preventScrollWhenVisible, removeContextualClass } from '../../utils';

const propTypes = {
  isVisible: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  headerText: PropTypes.string,
  onCloseClicked: PropTypes.func,
  onOverlayClick: PropTypes.func,
  headerRender: PropTypes.node,
  ieOptionClassName: PropTypes.string,
  hideCloseButton: PropTypes.bool,
};

const defaultProps = {
  isVisible: false,
  children: null,
  className: '',
  headerClassName: '',
  headerRender: null,
  headerText: '',
  ieOptionClassName: '',
  hideCloseButton: false,
  onCloseClicked: () => {},
  onOverlayClick: () => {},
};

class Modal extends React.Component {
  state = {};
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isVisible) {
      const { isVisible } = nextProps;
      preventScrollWhenVisible(isVisible);
    } else {
      removeContextualClass();
    }

    return null;
  }

  componentDidMount(nextProps) {
    const { isVisible } = this.props;
    preventScrollWhenVisible(isVisible);
  }

  onHandlerClose = (e, fn) => {
    removeContextualClass();
    fn(e);
  };

  render() {
    const {
      className,
      headerClassName,
      headerText,
      children,
      isVisible,
      onCloseClicked,
      onOverlayClick,
      ieOptionClassName,
      headerRender,
      hideCloseButton,
    } = this.props;
    return (
      <IfComponent
        condition={isVisible}
        whenTrue={
          <div className={`tv-modal__wrapper tv-display-flex ${className}`}>
            <div
              className="tv-modal__overlay"
              onClick={e => {
                this.onHandlerClose(e, onOverlayClick);
              }}
              role="button"
              tabIndex="-1"
            />
            <div className={`tv-modal__holder ${ieOptionClassName}`}>
              <IfComponent
                condition={hideCloseButton}
                whenFalse={
                  <CloseButton
                    onClick={e => {
                      this.onHandlerClose(e, onCloseClicked);
                    }}
                    className="tv-modal__btn-close tv-buttons__type icon-close"
                  />
                }
              />
              <IfComponent
                condition={isVisible}
                whenTrue={
                  headerRender ? (
                    <>{headerRender}</>
                  ) : (
                    <div className={headerClassName}>{headerText}</div>
                  )
                }
              />
              <>{children}</>
            </div>
          </div>
        }
        whenFalse={null}
      />
    );
  }
}

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;
