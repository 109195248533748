import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';
import messages from '../../../Share/components/ReleaseMessage/releaseMessages.json';
import { Row, Col, Container } from '../../../Share/components';
import './style.scss';

const formatLine = (line = '') => {
  if (!line) return <></>;
  if (line.includes('<<')) {
    const seperatedText = line.split('<<');

    return (
      <p>
        {seperatedText.map((x, i) =>
          i % 2 == 1 ? (
            <a href={x} target="__blank">
              läs mer här
            </a>
          ) : (
            x
          ),
        )}
      </p>
    );
  }
  return <p>{line}</p>;
};

const parseDescriptionText = description => {
  if (!description) return <></>;
  const arrayOfNewLine = description.split('|');
  return arrayOfNewLine.map(line => formatLine(line));
};
const ReleasesPage = props => {
  const releaseNote = (title, date, description) => (
    <div className="wrapper">
      <Container className="container-styled" fluid>
        <Row>
          <Col md={12}>
            <div className="date">{date}</div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="title">{title}</div>
          </Col>
        </Row>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </Container>
    </div>
  );

  const pageDescription = () => (
    <div className="wrapper">
      <Row>
        <Col md={12} className="d-flex ">
          <div className="icon">👉</div>
          <p>
            Vi på Transvoice arbetar för att förenkla våra kunders dagliga
            arbete och att göra det mer effektivt. Vi uppskattar er feedback så
            att vi tillsammans kan ge en bättre användarupplevelse. Har du
            synpunkter på något du önskar kunde förbättras i bokningssystemet,
            var god kontakta oss på{' '}
            <a href="mailto:info@transvoice.se">info@transvoice.se</a>
          </p>
        </Col>
      </Row>
    </div>
  );
  const scrollRef = useRef(null);
  return (
    <div className="release-news__container">
      <div className="page-title">Nyheter</div>
      {pageDescription()}
      {/* {[...messages].reverse().map(({ title, date, message, id }) => (
        <div key={id}>{releaseNote(title, date, message)}</div>
      ))} */}
      <StaticContent />
    </div>
  );
};

ReleasesPage.propTypes = {
  props: PropTypes.shape({}),
};

export default ReleasesPage;

const StaticContent = () => (
  <>
    <div className="wrapper">
      <Container className="container-styled" fluid>
        <Row>
          <Col md={12}>
            <div className="date">2021-12-09</div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div className="title">Nu lanserar vi verktyget TolkDirekt!</div>
          </Col>
        </Row>
        <p>
          TolkDirekt handlar om att enkelt och snabbt boka telefontolk till
          samma dag. Med start direkt eller inom ett par timmar. Bokningen
          matchas automatiskt mot en ledig tolk. Antingen så matchas uppdraget
          mot en ledig tolk i Transvoice TolkCenter eller så matchas det mot en
          frilanstolk. Alla frilanstolkar kan lägga sig tillgängliga för
          TolkDirekt via Tranvoice tolkportal eller app för tolkar. Detta gör
          att kapaciteten för tjänsten med tiden kommer att bli stor och att
          många tolkar automatiskt finns att tillgå. Initialt så kommer
          TolkDirekt gå att använda vardagar 07:30-19:00. Detta kommer på sikt
          att utökas.
        </p>
        <div className="bold mb-2 tv-title-text">
          Så här bokar du TolkDirekt
        </div>
        <ol>
          <li> Tryck på TolkDirekt-symbolen längst upp på sidan</li>
          <li>
            Ange önskad starttid, längd och språk samt beställare och
            tolkanvändare.
          </li>
          <li> Trycka på Boka nu</li>
          <li>
            I nästa steg händer något av följande;
            <ul>
              <li>
                Bokningen går igenom och du blir tilldelad en tolk. Du ser då
                tolkens telefonnummer direkt och ringer upp vid den avtalade
                starttiden. Bokningen blir även sparad bland övriga bokningar.
              </li>
              <li>
                Just den tiden du önskade så finns det ingen ledig tolk. Du får
                istället förslag på angränsande tider där det finns lediga
                tolkar.
              </li>
              <li>
                Det finns tyvärr ingen tolk alls ledig vare sig den önskade
                tiden eller nära angränsande tider. Du kan då istället pröva att
                ringa till oss så kan vi leta efter en tolk utanför
                automatflödet.
              </li>
            </ul>
          </li>
        </ol>
        <p>
          Lycka till med verktyget och hör av dig till oss om du har några
          frågor!
        </p>
      </Container>
      <hr />
      <Container className="container-styled" fluid>
        <Row>
          <Col md={12}>
            <div className="date">2021-10-06</div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div className="title">Ändra bokning</div>
          </Col>
        </Row>
        <p>
          Nu lanserar vi möjligheten att ändra en bokning ända fram tills
          uppdragsstart. Knappen för att ändra bokning hittar ni på
          uppdragskortet. Ni kan ändra allt från enkla fält som tolkanvändare
          och önskemål till kritiska fält som datum, tid, språk och adress. Om
          ett kritiskt fält ändras nära inpå uppdragsstart, vilket innebär
          debiterat tid, så kommer en ny bokning med bokningsnummer att
          genereras. Uppdraget hoppar även tillbaka till status Öppen och vi ser
          om vi kan tillsätta det nya uppdraget. En ändring på ett kritiskt fält
          samma dag, alltså att till exempel ändra språk på uppdraget som ska
          börja om 2 timmar, är inte möjligt. Då måste ni istället avboka
          uppdraget och ringa till oss för att göra en ny bokning. Om ett
          kritiskt fält ändras på ett uppdrag längre fram med status Bokad så
          kommer uppdraget att hoppa tillbaka till status Öppen och den
          tillsatta tolken kommer avbokas. Vi ser om vi kan tillsätta uppdraget
          efter den kritiska ändringen och tolken som var tillsatt på uppdraget
          från början kommer få förfrågan i första hand, om hon passar för
          uppdraget.
        </p>
      </Container>
      <hr />
      <Container className="container-styled" fluid>
        <Row>
          <Col md={12}>
            <div className="date">2021-10-06</div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div className="title">Notiser</div>
          </Col>
        </Row>
        <p>
          Nu lanserar vi webbnotifikationer kopplat till bokningar. Vi skickar i
          dagsläget ut notiser när ett uppdrag har blivit tillsatt med en tolk
          eller när ett telefonnummer till tolk har blivit ändrat.
        </p>
        <p>
          Ni väljer själva om ni vill acceptera att webbplatsen skickar notiser.
          Stänga av notiser kan man också självklart göra i efterhand. För att
          Kundportalen ska fungera fullt ut och för att ni inte ska missa något
          viktigt så rekommenderar vi användare att tillåta notiser.
        </p>
      </Container>
      <hr />

      <Container className="container-styled" fluid>
        <Row>
          <Col md={12}>
            <div className="date">2021-04-21</div>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <div className="title">Välkommen till vår nya Kundportal!</div>
          </Col>
        </Row>
        <div>
          <p>
            Kundportalen har fått en helt ny design och flera nya funktioner. Vi
            hoppas att ni verkligen ska gilla det ni ser och att nya funktioner
            underlättar ert arbete. En nyhet är att kundportalen nu är responsiv
            och fungerar utmärkt på exempelvis en mobiltelefon. Ni kommer att
            känna igen er i mycket men här kommer en förklaring av det som är
            nytt.
          </p>
          <p>
            Startsidan är en kalender där ni kan se alla er bokningar i en
            veckovy eller månadsvy. Ni kan även sortera på status på bokningen.
            Här hittar ni också knappen för Ny bokning. Bokningsformuläret är
            nytt men följer egentligen samma mönster som innan. Under Våra
            uppdrag kan ni se och filtrera alla er bokningar och här kan ni
            också använda sökrutan för att söka på uppdragsnummer, Tolkanvändare
            eller språk. Ni kan filtrera på datum, typ av tolkning och status på
            uppdrag.
          </p>
          <div className="bold mb-2 tv-title-text">Nya statusar på uppdrag</div>

          <p>
            En utav de största förändringarna i nya kundportalen är status på
            uppdrag. Transvoice har som ambition att alltid berätta för er vad
            som händer med ert uppdrag. Därför inför vi nu nya statusar på era
            uppdrag. Under uppdraget får ni alltid transparent och aktuell
            information var uppdraget just nu befinner sig. Alla statusar kan ha
            en eller flera understatusar. Här kommer en snabb genomgång av vad
            alla statusar innebär.
          </p>
          <p>
            <span className="tv-workassignment__status tv-workassignment__status--available">
              Öppen
            </span>{' '}
            – Vi har tagit emot er bokningsförfrågan och kommer att svara inom
            tiden för vad avtalet stipulerar för svarstider. Senast tidpunkt för
            svar ser ni på bokningen.
          </p>
          <p>
            <span className="tv-workassignment__status tv-workassignment__status--accepted">
              Bokad
            </span>{' '}
            – Vi har garanterat att tillsätta uppdraget med en tolk. Den går då
            från att vara en bokningsförfrågan till ett bokat och garanterat
            uppdrag. Du kan se om uppdraget är garanterat, på förfrågan hos en
            tolk, eller tillsatt. När uppdraget är tillsatt ser ni tolkens
            fullständiga uppgifter här. Har ni en vald kanal för bekräftelse på
            tillsatt uppdrag så kommer det en bekräftelse dit när en tolk blir
            tillsatt på uppdraget.
          </p>
          <p>
            <span className="tv-workassignment__status tv-workassignment__status--fullfilled">
              Utförd
            </span>{' '}
            – När uppdraget är utfört och sluttiden har passerats. När tolken
            har tidrappporterat uppdraget så ser ni även vilken tid tolken har
            tidsrapporterat.
          </p>
          <p>
            <span className="tv-workassignment__status tv-workassignment__status--cancelled">
              Avbokad
            </span>{' '}
            – Här kan ni se vilka uppdrag som ni avbokat, ni kan också se om ni
            kommer bli debiterade för avbokningen.
          </p>
          <p>
            <span className="tv-workassignment__status tv-workassignment__status--rejected">
              Avböjd
            </span>
            – Här ser ni en bokning som gått från Öppen till Avböjd av
            Transvoice. När ni lägger en bokningsförfrågan(Öppen) som vi inte
            kan garantera blir den Avböjd. Ni kan då kontakta oss för en
            alternativ tid. Vid sällsynta undantagsfall, om till exempel något
            skulle hända med tolk, så kan en bokning från Bokad till att bli
            Avböjd av Transvoice. Ni kan då se detta på uppdraget och ni kommer
            att bli kontaktade av Transvoice.
          </p>
          <div className="bold mb-2 tv-title-text">Avvikelser</div>
          <p>
            Vi lanserar nu en helt ny sida för avvikelser. Här kan ni skapa
            avvikelser och sedan ta del av vårt svar i kundportalen. Vi utreder
            varje avvikelse och svarar på varje avvikelse inom 5 arbetsdagar.
            Att vi får in avvikelser när något inte har fungerat är mycket
            viktigt.
          </p>
          <div className="bold mb-2 tv-title-text">Feedback</div>

          <p>
            Ni har möjligheten att skicka feedback på alla utförda uppdrag. Att
            vi får återkoppling är viktigt för oss och handlar i slutändan om
            att matcha rätt tolk till rätt uppdrag. Det är lika viktigt för oss
            att få in positiv feedback som negativ feedback. Ni betygsätter
            uppdraget på en skala 1-5, där 1 är Inte alls bra och 5 är Utmärkt.
            Det är det samlade intrycket av tolkningen i dess helhet som ska
            bedömas.
          </p>
          <p>
            Lämna gärna en kommentar i samband med er feedback och betygsätter
            ni 1-2 så får ni möjlighet att skapa en avvikelse. Ni kan ge
            feedback på ett uppdrag så fort ni har avslutat samtalet och ni kan
            sedan se alla era betygsatta uppdrag under fliken Våra Uppdrag.
          </p>
          <p>Tack för att ni ger oss feedback!</p>
          <p>
            <b>
              Lycka till med arbetet i nya kundportalen! Om ni har några frågor
              eller synpunkter, tveka inte att höra av er till oss på{' '}
              <a href="mailto:info@transvoice.se">info@transvoice.se</a> eller
              telefon <a href="tele:0812080000">08-120 800 00</a>
            </b>
          </p>
        </div>
      </Container>
    </div>
  </>
);
