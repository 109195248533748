import React, { useState, useEffect } from 'react';
import packageJson from '../../../../package.json';
import data from './../../static/gitInfo.txt';

const Version = () => {
  const [hash, setHash] = useState(0);
  const [brandName, setBranchName] = useState(1);
  useEffect(() => {
    // Update the document title using the browser API
    fetch(data)
      .then(response => response.text())
      .then((text) => {
        const hashStr = text.split('\n')[0];
        const branchNameStr = text.split('\n')[1];

        setHash(hashStr);
        setBranchName(branchNameStr);
      });
  });

  return (
    <div>
      Version: {packageJson.version} <br />
      Branch Name: {brandName} <br />
      Commit Hash: {hash} <br />
    </div>
  );
};

export default Version;
