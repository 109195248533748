import Translate from '../Translate';
import { APP_PORTAL } from '../../constants';

const getAssignmentStatus = status => {
  let item = Translate({ content: 'status.status' });

  switch (status) {
    case 'all':
      item = Translate({ content: 'status.status' });
      break;
    case 'accepted':
      item = Translate({ content: 'status.accepted' });
      break;
    case 'working':
      item = Translate({ content: 'status.working' });
      break;
    case 'fullfilled':
      item = Translate({ content: 'status.fullfilled' });
      break;
    case 'cancelled':
      item = Translate({ content: 'status.cancelled' });
      break;
    case 'available':
      item = Translate({ content: 'status.available' });
      break;
    case 'submitted':
      item = Translate({ content: 'status.submitted' });
      break;
    case 'done':
      item = Translate({ content: 'status.done' });
      break;
    case 'rejected':
      item = Translate({ content: 'status.rejected' });
      break;
    case 'losted':
      item = Translate({ content: 'status.losted' });
      break;
    case 'ordered':
      item = Translate({ content: 'status.ordered' });
      break;
    case 'notPerformed':
      item = Translate({ content: 'status.notPerformed' });
      break;
    case 'interrupted':
      item = Translate({ content: 'status.interrupted' });
      break;
    default:
      break;
  }
  // Override text for available status for client-portal
  if (APP_PORTAL === 'client-portal' && status === 'available') {
    item = Translate({ content: 'status.waitingAccept' });
  }
  return item;
};

export default getAssignmentStatus;
