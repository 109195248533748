import { combineReducers } from 'redux';

import app from './app';
import authentication from './authentication';
import workAssignment from './workAssignment';
import user from './user';
import reportDetails from './reportDetails';
import dataSources from './dataSources';
import reportDataSources from './reportDataSource';
import locationType from './locationType';
import dashboard from './dashboard';
import jobTimer from './jobTimer';
import skill from './skill';
import notification from './notification';
import report from './report';
// import service from './service';
import reason from './reason';

export default combineReducers({
  app,
  authentication,
  workAssignment,
  user,
  locationType,
  jobTimer,
  skill,
  reportDataSources,
  reportDetails,
  dashboard,
  dataSources,
  // service,
  reason,
  notification,
  report,
});
