import React from 'react';
import { Translate, } from '../../../Share/components';

const propTypes = {
};

const defaultProps = {
};

const ContentPanel = (props) => (
  <div className="tab-panel-content single">{props.children}</div>
);

const ContentPageWrap = (props) => (
  <div className="tab-panel-content__page-wrap">{props.children}</div>
);

const ContentPage = (props) => (
  <div className="tab-panel-content__page">{props.children}</div>
);

const ContentTitle = (props) => (
  <h4 className="tab-panel-content__heading1">{props.children}</h4>
);

const ContentHeading = (props) => (
  <p className="tab-panel-content__heading2"><b>{props.children}</b></p>
);

const ContentParagraph = (props) => (
  <p className="tab-panel-content__paragraph">{props.children}</p>
);

const ContentSection = (props) => (
  <div className="tab-panel-section">{props.children}</div>
);

const ContentSubSection = (props) => (
  <div className="tab-panel-section__sub">{props.children}</div>
);


const GeneralTermsAndConditions = () => (
  <ContentPanel>
    <ContentTitle>
      <Translate content={'settings.generalTermAndCondions'} />
    </ContentTitle>
    <ContentPageWrap>
      <ContentPage>
        <ContentHeading>
          Allmänna villkor för tolkuppdrag
      </ContentHeading>
        <ContentSection>
          <ContentHeading>
            1. Inledning
        </ContentHeading>
          <ContentParagraph>
            Dessa allmänna villkor gäller för tolkar som utför tolkuppdrag för Transvoice Sweden AB, org. nr: 556653- 6370 samt Transvoice AB, org.nr. 556482-8654, Sveavägen 159, 113 46 Stockholm. Transvoice-bolagen benämns härefter gemensamt ”Transvoice”. Dessa allmänna villkor reglerar vad som ska gälla mellan parterna vid utförande av tolkuppdrag för Transvoice.
        </ContentParagraph>
        </ContentSection>

        <ContentSection>
          <ContentHeading>
            2. Tolken som uppdragstagare
        </ContentHeading>
          <ContentParagraph>
            Tolkuppdraget faller inte inom ramen för anställning, dock betalar Transvoice de sociala avgifter som gäller för uppdragstagaren med A-skattsedel. Sjuk-och friskanmälan till försäkringskassan ansvarar tolken själv för. Antal tolkuppdrag som kommer in till Transvoice per dag beror på den efterfrågan som för tillfället finns på marknaden
        </ContentParagraph>
          <ContentParagraph>
            Transvoice kan därför inte förbinda sig att ge några tolkuppdrag till tolken. Tolken arbetar därmed på frilansbasis med ersättning per timme. Utbetalning av arvode sker antingen efter att tolken rapporterat sina utförda uppdrag till Transvoice, eller, i det fall  tolken har F- skattsedel, efter att tolken fakturerat Transvoice för utförda tolktjänster. Tolken ska alltid lämna in sin preliminära skattsedel för innevarande år till Transvoice.
        </ContentParagraph>
        </ContentSection>

        <ContentSection>
          <ContentHeading>
            3. Tolkens rättigheter och skyldigheter
        </ContentHeading>
          <ContentParagraph>
            Tolken har som individuell uppdragstagare stor frihet och valmöjlighet främst då det gäller planering och åtagande av arbete. Tolken är inte bunden till fasta tider eller ärenden, utan kan självständigt välja att åta sig eller avböja uppdrag.
        </ContentParagraph>
          <ContentParagraph>
            Tolken är skyldig att iaktta Transvoice allmänna villkor och riktlinjer inklusive arbetsinstruktion och utföra varje tolkning i enlighet med dessa.
        </ContentParagraph>
          <ContentParagraph>
            Tolken ska skriva en tjänstgöringsrapport i enlighet med bilaga 1. Tolken ska inneha en ansvarsförsäkring som är avsedd för utförande av tolkuppdrag.
        </ContentParagraph>
          <ContentParagraph>
            Tolken ska utföra sitt uppdrag i enlighet med Kammarkollegiets föreskrift God tolksed. Med god tolksed menas det regelverk som finns på området, den yrkeskodex som utvecklats av tolkar och den praxis som skapats av Kammarkollegiet på tolkområdet.
        </ContentParagraph>
        </ContentSection>

        <ContentSection>
          <ContentHeading>
            4. Tilldelning av tolkuppdrag
        </ContentHeading>
          <ContentParagraph>
            Transvoice arbetar efter principen att alltid tillsätta uppdraget med högsta tillgängliga kompetens. Med tillgänglighet avses anmäld tillgänglighet på tolkwebben. Tillsammans med anmäld tillgänglighet så prioriteras tolkarna enligt kompetensnivå. Det innebär att auktoriserade tolkar med speciell kompetens (nivå 5) prioriteras först, och därefter auktoriserade tolkar (nivå 4). Därefter förmedlas jobben till grundutbildade tolkar MYH (nivå 3) samt grundutbildade tolkar (nivå 2) och till sist registrerade tolkar (nivå 1), det vill säga tolkar som har genomgått minst en introduktionsutbildning eller grundkurs och sedan är testade och godkända i Transvoice rekryteringstest.
        </ContentParagraph>
          <ContentParagraph>
            Utöver detta prioriteras tolkar även enligt närhetsprincipen vid kontakttolkningsuppdrag samt efter erfarenhet och lojalitet. Tolkuppdragen förmedlas av språkansvarig agent/förmedlare via tolkens tolkwebb, telefon, sms eller e-mail. När en tolk accepterat ett uppdrag är det tolkens skyldighet att genomföra åtagandet.
        </ContentParagraph>
        </ContentSection>

        <ContentSection>
          <ContentHeading>
            5. Princip vid åtagande av uppdrag
        </ContentHeading>
          <ContentParagraph>
            När uppdragstagaren tagit sig an ett ärende är han/hon skyldig att utföra tolkningen. Innan tolken åtar sig och fullföljer ett uppdrag, bör denne noga överväga om han/hon är lämplig för ärendet och därmed kan utföra tolkningen på ett tillfredställande sätt. Om tolken anser sig ej vara kompetent för uppdragets innehåll eller av annan anledning olämplig, ska han/hon inte åta sig uppdraget. Tolken ska endast åta sig uppdrag som han/hon finner sig lämpad för.
        </ContentParagraph>
        </ContentSection>

        <ContentSection>
          <ContentHeading>
            6. När betraktas uppdragsåtagandet vara accepterat?
        </ContentHeading>
          <ContentParagraph>
            När en tolk anmält sig tillgänglig eller accepterat en uppdragsförfrågan via Tolka! webbportalen och/eller Tolka! Appenär tolken skyldig att genomföra åtagandet. Uppdragsförfrågningar som inkommer via telefonsamtal från förmedlingen anses som accepterade när tolken har tackat ja, samt tilldelats uppdragsuppgifter och uppdragsnummer. Om/när uppdragsuppgifter inte distribueras via Tolka! Portalen eller Tolka! Appen, utan via e-post är detta en bekräftelse på tidigare muntlig överenskommelse där tolk accepterat uppdrag på förhand. Tolk och agent/förmedlare har då kommit överens om via telefon alternativt mail/SMS vilka dagar och tider tolken är tillgänglig för  uppdrag.
        </ContentParagraph>
        </ContentSection>

        <ContentSection>
          <ContentHeading>
            7. Om kunden avbokar
        </ContentHeading>
          <ContentParagraph>
            Om Transvoice kund avbokar redan inplanerade uppdrag kvarstår tolkens rätt till arvode för uppdraget om avbokningen meddelas senare än 18 timmar före uppdragets start (18-timmarsregeln vid kontakttolkningsuppdrag). Vid telefontolkning gäller en snävare tidsram. Om Transvoice kund avbokar redan inplanerade uppdrag att tolka per telefon kvarstår tolkens rätt till arvode för uppdraget om avbokningen meddelas senare än 2 timmar före uppdragets start. Om en avbokning från kund möjliggör att tolken kan åta sig ett annat uppdrag av likvärdig art inom samma geografiska område, utgår endast arvode för det nya uppdraget.
        </ContentParagraph>
        </ContentSection>

        <ContentSection>
          <ContentHeading>
            8. Jäv och tystnadsplikt
        </ContentHeading>
          <ContentParagraph>
            Tolken ska vara opartisk och inte ge uttryck för sina åsikter och värderingar. Tolken är en oberoende part och får inte åta sig uppdrag där tolkens opartiskhet kan ifrågasättas.
        </ContentParagraph>
          <ContentParagraph>
            Tolkens uppdrag är förenat med tystnadsplikt i enlighet med bland annat offentlighets- och sekretesslagen (2009:400) då tolken vid utförandet av sina uppdrag kan komma att ta del av sekretessbelagda uppgifter. Att röja information kring ett uppdrag eller samtalets innehåll är ett allvarligt brott enligt svensk lagstiftning.
        </ContentParagraph>
        </ContentSection>

        <ContentSection>
          <ContentHeading>
            9. Vite
        </ContentHeading>
          <ContentParagraph>
            Om tolken uteblir eller är försenad utgör detta en avvikelse i form av en störning i tjänsten och om kunden ej nyttjar tolkens service, betalas varken ersättning för uppdraget eller resekostnader. Vid eventuell försening måste Transvoice omedelbart meddelas om förseningen. Vid kontakt med förmedlingen kan Transvoice i samråd med kund besluta vad som ska göras.
        </ContentParagraph>
          <ContentParagraph>
            Med avvikelse menas bland annat att kunden har upplevt störningar i verksamheten i form av kvalitetsbrist i tolkens utförande av uppdraget, bristande service vid utfört tolkuppdrag eller att tolken har uteblivit eller blivit försenad till ett uppdrag. En avvikelse kan medföra att tolken får betala ett vite till Transvoice.
        </ContentParagraph>
        </ContentSection>

        <ContentSection>
          <ContentHeading>
            10. Överlåtelse av avtalet
        </ContentHeading>
          <ContentParagraph>
            Tolken får inte överlåta sitt tolkuppdrag till någon annan tolk utan att inhämta Tranvoice skriftliga godkännande i förväg. För det fall tolken överlåter sitt tolkuppdrag utan att inhämta Tranvoice godkännande i förväg äger Transvoice rätt att utkräva vite i enlighet med punkten 8 ovan med ett belopp som motsvarar tolkersättningen för uppdraget.
        </ContentParagraph>
        </ContentSection>

        <ContentSection>
          <ContentHeading>
            11. Avtalsdokument
        </ContentHeading>
          <ContentParagraph>
            Detta avtal består av följande handlingar och de ska tolkas i den ordning de räknas upp nedan:
        </ContentParagraph>
          <ContentParagraph>
            <ul>
              <li>
                Allmänna villkor och riktlinjer
          </li>
              <li>
                Arbetsinstruktion – Bilaga 1
          </li>
              <li>
                Användarvillkor för Tolka! Portal och Tolka! App – Bilaga 2
          </li>
            </ul>
          </ContentParagraph>
        </ContentSection>

        <ContentSection>
          <ContentHeading>
            12. Tvist
        </ContentHeading>
          <ContentParagraph>
            Tvist mellan parterna med anledning av detta avtal ska lösas av allmän domstol med tillämpning av svensk lag.
        </ContentParagraph>
        </ContentSection>
      </ContentPage>
      <ContentPage>
        <ContentHeading>
          Bilaga 1
        <br />
          AArbetsinstruktion
      </ContentHeading>

        <ContentSection>
          <ContentHeading>
            1. Allmänt om branschen
        </ContentHeading>
          <ContentParagraph>
            Tolkbranschen har en mycket viktig funktion i det svenska samhället. Sverige är ett invandrartätt land, och har därtill ett stort antal internationella besökare i turistande och arbetsrelaterade ärenden. För att viktiga samhällsfunktioner, myndigheter och företag ska fungera väl i sin verksamhet krävs många gånger tolkservice och hjälp med språkförståelsen. Varje yrkesverksam tolk bidrar bland annat till det svenska samhällets funktionalitet, kommunikationsförmåga och framväxt.
        </ContentParagraph>
          <ContentParagraph>
            Tolkar och medarbetare på Transvoice har ett stort ansvar och ska tillsammans svara på den efterfrågan som finns, och samtidigt arbeta för expansion och utveckling. Vi ska möjliggöra, upprätthålla och stärka kommunikationen mellan beställare och klient. Våra tjänster ska frambringatillit/trygghet både för den enskilde individen och för samhället i stort. En stor mängd företag, organisationer, och privatpersoner som bor eller är på besök i Sverige, har ett behov av tolkning. Genom att ta vårt ansvar driver vi branschen framåt och verkar för en säker och positiv framtid.
        </ContentParagraph>
        </ContentSection>

        <ContentSection>
          <ContentHeading>
            2. Förpliktelser vid uppdragstagande
        </ContentHeading>

          <ContentSubSection>
            <ContentHeading>
              2.1 Vardagliga rutiner
          </ContentHeading>
            <ContentParagraph>
              <ul>
                <li>
                  Det åligger dig att se till att du har alla uppgifter om tolkuppdraget.
              </li>
                <li>
                  Adressen ska kollas upp i förväg så att du hittar till kunden utan problem eller försening.
              </li>
                <li>
                  Du kommer i god tid till varje uppdrag, gärna 10 minuter före.
              </li>
                <li>
                  Vid uppdragets start ger du följande upplysningar till involverade parter:
                <br />
                  <p>
                    <b>
                      <em>
                        "Jag följer reglerna om tystnadsplikt. Jag är neutral och tar inte parti för någon i tolkningssituationen. Jag kommer att återge vad som sagts i första person (jag-form)".
                      </em>
                    </b>
                  </p>
                </li>
                <li>
                  Hela tolkningen ska präglas av professionalitet, från bemötandet till utförandet.
                </li>
                <li>
                  Färdigifylld tjänstgöringsrapport skrivs under av kunden efter avslutat uppdrag.
                </li>
                <li>
                  Signerad rapport lämnas till ditt lokala förmedlingskontor.
                </li>
                <li>
                  Vid eventuella problem med uppdrag måste Transvoicekontaktas så att kunden kan underrättas.
                </li>
                <li>
                  Telefontolkning ska utföras från fast telefon, eller mobil, i ett ostört rum där inga störningsmoment föreligger som kan påverka tolkningen negativt. Du ska vara ensam i rummet när du utför tolkningen.
                </li>
                <li>
                  Se till att vara tillgänglig när du inte tolkar, då akuta uppdrag kan komma.
                </li>
                <li>
                  Du ska ha mobilen avstängd under hela uppdraget.
                </li>
                <li>
                  Du måste stanna under hela den bokade tiden om kunden så önskar.
                </li>
                <li>
                  Du ska göra ditt yttersta för att tillfredsställa varje kund och ärende.
                </li>
              </ul>
            </ContentParagraph>
          </ContentSubSection>

          <ContentSubSection>
            <ContentHeading>
              2.2 YYrkesbevis
          </ContentHeading>
            <ContentParagraph>
              Transvoice tolklegitimation ska hållas väl synlig under hela vistelsen hos kunden. Denna identifikation är ett yrkesbevis, visar leverans för aktuell leverantör, samt är kundens säkerhet att en utbildad och godkänd tolk utför deras tjänster.
          </ContentParagraph>
            <ContentParagraph>
              För uppdaterat yrkesbevis, såsom vid förändring av kompetensnivå (exempel; nivå 2 blir nivå 3), ska Transvoice kontaktas för ny legitimation.
          </ContentParagraph>
          </ContentSubSection>

          <ContentSubSection>
            <ContentHeading>
              2.3 Planering
          </ContentHeading>
            <ContentParagraph>
              När du tar täta tolkuppdrag måste du vara väl förberedd. Det är alltid ditt ansvar att du hinner till nästa uppdrag. Om du inte kan planera optimalt så att du kan närvara på utsatt tid, ska du inte åta dig ärendet. Du får inte pressa kunden på att få gå tidigare på grund av efterföljande uppdrag. Det är endast på kundens eget initiativ som ett uppdrag kan avslutas före utsatt tid. Vi ska alltid acceptera den bokade tiden.
          </ContentParagraph>
          </ContentSubSection>

          <ContentSubSection>
            <ContentHeading>
              2.4Tolkningen sker i enlighet med god tolksed
          </ContentHeading>
            <ContentParagraph>
              <ul>
                <li>
                  Du har tystnadsplikt (skyldighet att iaktta sekretess).
              </li>
                <li>
                  Du ska vara neutral och inte ge uttryck för egna åsikter och värderingar.
              </li>
                <li>
                  Du ska vara opartisk och inte ta ställning för någon av parterna.
              </li>
                <li>
                  Du ska översätta allt som sägs i rummet, utan att lägga till, dra ifrån eller förändra.
              </li>
                <li>
                  Du återger vad som sägs i första person/jag-form.
              </li>
                <li>
                  Du ska undvika jäv-situation.
              </li>
              </ul>
            </ContentParagraph>
          </ContentSubSection>

          <ContentSubSection>
            <ContentHeading>
              2.5 Professionell framtoningg
          </ContentHeading>
            <ContentParagraph>
              Varje uppdragstagare åtar sig att vara representativt klädd. Med detta menas att du har diskret klädsel, och därmed inte bär utmanande eller färgstarka plagg. Det diskreta intrycket inkluderar även att inte lukta rök/snus, alkohol eller bära tunga parfymer.
          </ContentParagraph>
          </ContentSubSection>

        </ContentSection>

        <ContentSection>
          <ContentHeading>
            3.Samarbete tolk och agent/förmedlare
        </ContentHeading>
          <ContentParagraph>
            Varje agent/förmedlare på Transvoice har ansvar för olika språk och språkgrupper. Med detta som grund blir agenten/förmedlaren expert på sitt språkområde och vet vilka tolkar som är aktiva, vilken kompetens de har samt hur de önskar att arbeta. Därmed finns goda förutsättningar för ett gott och nära samarbete mellan uppdragstagare och agent/förmedlare. Uppdragstagarens lojalitet, flexibilitet och tillgänglighet utgör faktorer för att ett långsiktigt och hållbart samarbete ska ta form. Agenten/förmedlaren finns till hands via direktnummer och e-post för att uppdragstagaren lätt ska kunna få tag på sin Tolkanvändare vid såväl planering och inbokning av uppdrag som vid akuta situationer.
        </ContentParagraph>
        </ContentSection>

        <ContentSection>
          <ContentHeading>
            4. Tjänstgöringsrapporter – Utbetalning ochfakturering av arvode
        </ContentHeading>

          <ContentSubSection>
            <ContentHeading>
              4.1 Vad är en tjänstgöringsrapport och varför ska jag fylla i en sådan?
          </ContentHeading>
            <ContentParagraph>
              En tjänstgöringsrapport är en redogörelse för utfört arbete. Rapporten utgör underlag för utbetalning av arvode för dina tjänster. Varje tjänstgöringsrapport är unik för varje enskilt ärende du utför, och din rapport hänvisar alltid till ett aktuellt bokningsnummer. Genom att fylla i en tjänstgöringsrapport har både du själv, aktuell kund, och Transvoice, en god kontroll på genomfört uppdrag. Samtliga parter i denna relation behöver en kvittens på utfört uppdrag för att faktureringen och arvodet ska bli korrekt. Fyll i tjänstgöringsrapporten innan du går ut på uppdrag. Det är mycket viktigt att den är korrekt utförd. En felaktig rapport kan leda till att du inte får ut någon ersättning för uppdraget.
          </ContentParagraph>
          </ContentSubSection>

          <ContentSubSection>
            <ContentHeading>
              4.2 Hur fyller jag i rapporten?
          </ContentHeading>
            <ContentParagraph>
              De uppgifter som ska fyllas i är: ditt för- och efternamn, personnummer, språk, arvodesnivå, uppdragsnummer, datum och tid för uppdraget. Därtill fyller du även i vilken kunden var, avdelning och så vidare.
          </ContentParagraph>
            <ContentParagraph>
              Vid ärenden där du får ersättning för resor anmäls restid och antal kilometer (vid bilresor). För eventuella utlägg måste kvitto i original bifogas. Vid tolkningar som utförs på så kallad obekväm arbetstid anges även den arvodesgrundade tiden. Eventuell försening och övriga kommentarer fylls i av kunden. Därefter sätter kunden sin signatur och bestyrker därmed din service.
          </ContentParagraph>
            <ContentParagraph>
              Vid inlämning av tjänstgöringsrapport:
          </ContentParagraph>
            <ContentParagraph>
              Den vita delen på tjänstgöringsrapporten lämnas in till Transvoice, och den rosa kopian lämnas kvar hos kunden. Den gula kopian behåller du för egen kontroll.
          </ContentParagraph>
            <ContentParagraph>
              Vid rapportering online:
          </ContentParagraph>
            <ContentParagraph>
              Den vita delen är original och måste sparas och arkiveras avdig som uppdragstagare i minst 2 år.
          </ContentParagraph>
            <ContentParagraph>
              Den rosa kopian lämnas kvar hos kund.
          </ContentParagraph>
            <ContentParagraph>
              Observera att originalrapporten måste sparas och arkiveras av uppdragstagaren i minst 2 år. Kontroll av originalrapport kan komma att göras av Transvoice.
          </ContentParagraph>
            <ContentParagraph>
              Vid onlinerapportering via Tolka! Portal eller Tolka! Appen utan digital underskrift av kunden är det således ingen kopia som skall skickas in till Transvoice annat än i de fall då Transvoice och/eller kund kan kräva att få ta del av originalrapporten.
          </ContentParagraph>
          </ContentSubSection>

          <ContentSubSection>
            <ContentHeading>
              4.3 När och var ska rapporten lämnas in? När får jag min utbetalning av arvode?
          </ContentHeading>
            <ContentParagraph>
              Vid inlämning av tjänstgöringsrapport:
          </ContentParagraph>
            <ContentParagraph>
              Tjänstgöringsrapporterna för varje utfört uppdrag ska vara oss tillhanda senast den 15:e varje månad
          </ContentParagraph>
            <ContentParagraph>
              Arvodesutbetalningen sker den 25:e efterföljande månad. Om den 25:e infaller under helg sker utbetalning närmast föregående vardag.
          </ContentParagraph>
            <ContentParagraph>
              Rapporterna lämnas till ditt lokala kontor (Stockholm, Norrköping eller Malmö) som förmedlat dina utförda ärenden. När du lämnar in dina rapporter ska de vara sorterade i ordning efter datum och tid.
          </ContentParagraph>
          </ContentSubSection>

          <ContentSubSection>
            <ContentHeading>
              4.4 Onlinerapportering:
          </ContentHeading>
            <ContentParagraph>
              Uppdrag rapporterade som utförda online via Tolka! Portalen eller Tolka! Appen innan den sista vardagen varje månad betalas ut den 25:e nästkommande månad. Vid helg sker utbetalning föregående vardag. Uppdraget kvitteras i Tolka! Appen, genom att kunden signerar på skärmen.Rapporteras uppdraget utan kundens påskrift, kan det medföra att arvodet inte betalas ut. Ett bevis på utfört uppdrag måste då presenteras för Transvoice.
          </ContentParagraph>
            <ContentParagraph>
              Tjänstgöringsrapporter ska lämnas in/rapporteras online inom 1 månad efter utförd tjänst. Lämnas rapporten in/rapporteras online senare än två (2) månader efter utfört uppdragsdatum utbetalas ingen ersättning till tolken. Transvoice förbehåller sig i sådant fall ändå rätten att debitera utfört uppdrag till kund.
          </ContentParagraph>
          </ContentSubSection>

          <ContentSubSection>
            <ContentHeading>
              4.4 Jag har inte fått mitt arvode, varför?
          </ContentHeading>
            <ContentParagraph>
              För dig som skickar in tjänstgöringsrapporterna via post är det viktigt att du betalat rätt porto. Det kan annars hända att försändelsen fördröjs på Postterminalen och dina tjänstgöringsrapporter är oss tillhanda flera dagar för sent. Ditt arvode utbetalas likt ovan vid nästkommande månad. Är din tjänstgöringsrapport inlämnad/rapporterad online senare än två (2) månader efter uppdragets utförandedatum, utbetalas ingen ersättning.
          </ContentParagraph>
          </ContentSubSection>

          <ContentSubSection>
            <ContentHeading>
              4.5 Arvodesspecifikation
          </ContentHeading>
            <ContentParagraph>
              Fr o m 1 februari 2017 distribueras alla  arvodesspecifikationer digitalt via tjänsten Kivra. Det åligger tolken att via BankID skapa ett Kivra-konto för nedladdning av arvodesspecifikationen. För det fall tolken vill fortsätta få arvodesspecifikationen via vanlig post, tas en avgift ut om 25 kr/försändelse, för utskrift och utskick.
          </ContentParagraph>
          </ContentSubSection>

        </ContentSection>

        <ContentSection>
          <ContentHeading>
            5. Avvikelserapport
        </ContentHeading>
          <ContentParagraph>
            Varje avvikelserapport registreras och granskas av Transvoice. Det eventuella felet identifieras och undersökning görs gällande varför avvikelsen inträffat. Om rapporteringen är direkt knuten till tjänsteleveransen, kontaktas aktuell uppdragstagare av Transvoice för klargörande och förbättringsåtgärder. I annat fall löses detta internt hos Transvoice. Åtgärder vidtas för konstruktiva syften, så att dylik avvikelse inte upprepas. Detta kan medföra förnyelse av rutiner, vilket leder till kvalitetsförbättring. När granskningen är gjord återkopplar Transvoice avseende rapporteringen till kunden. Transvoice dokumenterar ärendet och analyserar resultatet internt för att få en god överblick över sin verksamhet.
        </ContentParagraph>
        </ContentSection>

        <ContentSection>
          <ContentHeading>
            6.Villkor för fakturor (tolkar med F-skattsedel)
        </ContentHeading>
          <ContentParagraph>
            För att fakturera Transvoice för dina uppdragstaganden krävs att du har eget företag. Du lämnar då in F-skattebevis som du får från Skatteverke
        </ContentParagraph>
          <ContentParagraph>
            Du fyller i tjänstgöringsrapporter efter varje utförd tolkning, och dessa lämnas in senast den 15:e varje månad till ditt lokala förmedlingskontor. I början av nästkommande månad får du ett fakturaunderlag. Det är utifrån detta underlag du fakturerar. Du kompletterar med moms och sociala avgifter. Utbetalning sker senast 30 dagar från ställt fakturadatum.
        </ContentParagraph>
          <ContentParagraph>
            Lämna in din preliminära F-skattsedel tillsammans med första fakturan varje år.
        </ContentParagraph>
        </ContentSection>
      </ContentPage>
    </ContentPageWrap>
  </ContentPanel>
);

GeneralTermsAndConditions.propTypes = propTypes;
GeneralTermsAndConditions.defaultProps = defaultProps;

export default GeneralTermsAndConditions;
