import moment from 'moment';

const sortByDate = (a, b) => {
  const timediff =
    moment(a.DatetimeFrom, 'YYYY-MM-DD HH:mm:ss') -
    moment(b.DatetimeFrom, 'YYYY-MM-DD HH:mm:ss');
  // eslint-disable-next-line no-nested-ternary
  return timediff === 0 ? 0 : timediff > 0 ? 1 : -1;
};

export const sleepTime = ms => new Promise(resolve => setTimeout(resolve, ms));

export { sortByDate as default };
