import React, { useEffect, useState } from 'react';
import './style.scss';
import { useSelector } from 'react-redux';

const Frasbank = () => {
  const [displayLink, setDisplayLink] = useState(false);
  const isWorldishEnabled = useSelector(
    state => state.user.profile.isWorldishEnabled,
  );
  const worldishExternalURL = useSelector(
    state => state.user.profile.worldishExternalURL,
  );

  useEffect(() => {
    if (isWorldishEnabled === true && worldishExternalURL !== null) {
      setDisplayLink(true);
    }
  });

  return (
    <div className="page_content frasbank_page">
      <div className="frasbank_wrapper">
        <div className="frasbank_content">
          <div className="title">Frasbanken</div>
          <div className="frasbank_subtitlte">
            Tillsammans med Worldish erbjuder vi en frasbank som innehåller
            förslag på vanligt förekommande fraser inom medicinsk verksamhet.
          </div>
          <div className="frasbank_body">
            Med hjälp av Helen® Caregiver, vår kulturellt anpassade digitala
            medicinska kommunikations-assistent, kan vårdgivare och patienter
            enkelt kommunicera under en konsultation. Alla fraser är
            kvalitetssäkrade och verifierade för hälsosjukvårds kommunikation.
            Helen är medicinskt CE-märkt.
          </div>
          {displayLink && (
            <a
              className="frasbank_link"
              href={worldishExternalURL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>Utforska frasbanken hos Worldish</span>
              <span>
                <img src="/images/frasbank-link.png" alt="frasbank-link" />
              </span>
            </a>
          )}
        </div>

        <div className="frasbank_cover">
          <img
            src="/images/phrasbank-ellipse.png"
            alt="phrasbank-ellipse"
            className="frasbank_cover-ellipse"
          />
          <img
            src="/images/phrasbank-logo.png"
            alt="phrasbank-logo"
            className="frasbank_cover-logo"
          />
        </div>
      </div>
    </div>
  );
};

export default Frasbank;
