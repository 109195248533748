import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const propTypes = {
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  component: PropTypes.string,
  onKeyUp: PropTypes.func,
};

const defaultProps = {
  placeholder: '',
  onBlur: () => {},
  onKeyUp: () => {},
  component: 'search',
};

const SearchBox = React.forwardRef(
  ({ placeholder, onBlur, component, className, onKeyUp }, ref) => (
    <div className={`tv-search-box ${className}`}>
      <input
        onBlur={onBlur}
        placeholder={placeholder}
        type="text"
        onKeyUp={onKeyUp}
        ref={ref}
      />
      {component === 'search' && <i className="fa fa-search" />}
    </div>
  ),
);

SearchBox.propTypes = propTypes;
SearchBox.defaultProps = defaultProps;

export default SearchBox;
