import React from 'react';
import PropTypes from 'prop-types';
import Translate from '../Translate';
import MoreAction from './MoreAction';

const propTypes = {
  availableFor: PropTypes.string,
  availableDate: PropTypes.string,
  availableTime: PropTypes.string,
  className: PropTypes.string,
  DateTimeFrom: PropTypes.string,
  DateTimeTo: PropTypes.string,
  onClick: PropTypes.func,
  articleIdentifier: PropTypes.string,
};

const defaultProps = {
  availableFor: '',
  availableDate: '',
  availableTime: '',
  DateTimeFrom: '',
  DateTimeTo: '',
  className: 'tv-youravailability__list-item',
  onClick: () => { },
  articleIdentifier: '',
};

// eslint-disable-next-line react/prefer-stateless-function
class AvailabilityItem extends React.Component {
  render() {
    const {
      availableFor, className, onClick, availableDate, availableTime, DateTimeFrom, DateTimeTo, articleIdentifier,
    } = this.props;
    let availableElm = null;
    if (availableFor === 'onSite') {
      availableElm = (
        <span className="tv-youravailability__item-space">
          <Translate content="yourAvailability.onSite" />
        </span>
      );
    } else {
      availableElm = (
        <span className="tv-youravailability__item-space">
          <Translate content="yourAvailability.phone" />
        </span>
      );
    }

    return (
      <div className={className} role="button" tabIndex="-1">
        <div className="tv-youravailability__container">
          <div className="tv-youravailability__item-name">
           
            <div className="tv-youravailability__item-text tv-youravailability__item-text-datetime " style={{minHeight: 'auto' }}> 
            {availableTime}
            </div>
          </div>
          <hr className="tv-youravailability__item-hr" />
          <div className="tv-youravailability__item-category tv-display-flex">
            <div className="tv-youravailability__item-label">
              <Translate content="yourAvailability.availableFor" />:
            </div>
            <div className="tv-youravailability__item-text">
              { availableElm }
            </div>
          </div>
        </div>
        <div className="tv-youravailability__item-menu tv-display-flex" >
          <MoreAction onClick={() => {
            onClick(DateTimeFrom, DateTimeTo, articleIdentifier);
          }}
          />
        </div>
      </div>
    );
  }
}

AvailabilityItem.propTypes = propTypes;
AvailabilityItem.defaultProps = defaultProps;

export default AvailabilityItem;
