import React, { useEffect, useState } from 'react';
import { bool } from 'prop-types';
import moment from 'moment';
import Container from '../Container';
import Row from '../Row';
import Col from '../Col';
import Translate from '../Translate';
import { isEmpty } from 'lodash';
import messages from './releaseMessages';
import { Link } from 'react-router-dom';

import './style.scss';

const propTypes = {
  showMessage: bool,
  expand: bool,
};

const defaultProps = {
  showMessage: false,
  expand: false,
};
const isMessageDisabled = () => {
  let returnValue = false;
  if (!isEmpty(localStorage.getItem('releaseMessageDisabled'))) {
    const disabled = moment(localStorage.getItem('releaseMessageDisabled'));
    returnValue = !!(disabled == 'true' || disabled);
  }
  return returnValue;
};
const checkIfMessageshoulddisplay = expiryDate => {
  if (isEmpty(localStorage.getItem('showMessageValue'))) { return moment(expiryDate, 'YYYY-MM-DD').diff(moment(), 'days') >= 0; }
  return JSON.parse(localStorage.getItem('showMessageValue'));
};

const setMessageDisabled = () => {
  localStorage.setItem('releaseMessageDisabled', true);
};

// No of days to enable dismiss
const enableDismissDays = -14;

const ReleaseMessage = ({ showMessage, expand }) => {
  const { expiryDate, summary: releaseText } =
    !isEmpty(messages) && messages[messages.length - 1];

  const [showMessageValue, setShowMessageValue] = useState(
    checkIfMessageshoulddisplay(expiryDate),
  );

  const [disableMessageForToday, setDisableMessage] = useState(
    isMessageDisabled(),
  );

  useEffect(() => {
    setDisableMessage(isMessageDisabled());
  }, []);

  if (isEmpty(messages)) {
    localStorage.setItem('showMessageValue', false);
  } else {
    localStorage.setItem('showMessageValue', showMessageValue);
  }

  const releaseMessageViewDate = isEmpty(
    localStorage.getItem('releaseMessageViewDate'),
  )
    ? moment().format('YYYY-MM-DD')
    : localStorage.getItem('releaseMessageViewDate');

  const messageList = releaseText ? releaseText.split('|') : [];

  if (moment(expiryDate, 'YYYY-MM-DD').diff(moment(), 'days') >= 0) {
    localStorage.setItem(
      'releaseMessageViewDate',
      moment().format('YYYY-MM-DD'),
    );
  }

  const tempClose = () => {
    setDisableMessage(true);
    setMessageDisabled();
  };
  return (
    <div
      className="release-message-cont"
      style={{
        display: showMessageValue && !disableMessageForToday ? 'flex' : 'none',
      }}
    >
      <div className="body d-flex">
        <i className="release-message-cont-alert" />
        <div className="d-flex w-100 ">
          <>
            {messageList.map((item, i) => (
              <div key={i} className="release-summary">
                {item}{' '}
                <Link
                  className="link-style tv-display-mobile-show"
                  to="/releases-page"
                >
                  <Translate content="ReleaseMessage.learnMore" />
                </Link>
              </div>
            ))}
          </>

          <div className="release-actions tv-display-mobile-hide">
            <Link className="link-style " to="/releases-page">
              <Translate content="ReleaseMessage.learnMore" />
            </Link>
          </div>
        </div>
        <i
          className="release-message-cont-close fa fa-times"
          onClick={() => {
            if (
              moment(releaseMessageViewDate, 'YYYY-MM-DD').diff(
                moment(),
                'days',
              ) <= enableDismissDays
            ) {
              setShowMessageValue(false);
            } else tempClose();
          }}
        />
      </div>
    </div>
  );
};

ReleaseMessage.propTypes = propTypes;
ReleaseMessage.defaultProps = defaultProps;

export default ReleaseMessage;
