import axios from 'axios';

const axiosMailInstance = axios.create({
  baseURL: process.env.REACT_APP_MAIL_API,
});

export function sendMail(subject, content) {
  return axiosMailInstance.post('/api/v1/send-mail', {
    subject,
    content,
  });
}
