import React,  { Component } from 'react';
import './style.scss';
import { ThankYouComponent, IfComponent, Translate } from '../../../Share/components';
import InformationItem from '../AssignmentDetail/InformationItem';


const propTypes = {
};

const defaultProps = {
};

class ThankYou extends Component {
  constructor(props) {
    super(props);
    // eslint-disable-next-line react/prop-types
    const { detail, assignmentDetails } = props.location.state;
    this.state = {
      thanksContent: detail,
      assignmentDetails,
    };
  }

  render() {
    const {
      assignmentDetails,
      thanksContent,
    } = this.state;

    return (
      <ThankYouComponent callbackUrl="/my-assignment" buttonText={Translate({ content: 'sideBar.myAssignment' })}>
        <h2>{Translate({ content: 'thankForSubmitting.title' })}</h2>
        <p>
          <IfComponent
            condition={thanksContent === ''}
            whenTrue={Translate({ content: 'thankForSubmitting.thankForSubmittedTimesheet' })}
            whenFalse={thanksContent}
          />
        </p>
        <div className="tv-youravailability__list tv-display-flex">
          {(assignmentDetails || []).map(assigment => (
            <div className="tv-youravailability__list-item tv-display-flex" tabIndex="-1">
              <div className="tv-youravailability__container">
                <div className="tv-youravailability__item-name">
                  <div className="tv-youravailability__item-text tv-youravailability__item-text-datetime">
                    {assigment.orderNumber}
                  </div>
                </div>
                <hr className="tv-youravailability__item-hr" />
                <div className="tv-youravailability__item-category tv-display-flex">
                  <ul className="tv-workassignment__info-wrapper">
                    <InformationItem
                      data={assigment.language.SkillName}
                      label=""
                      icon="task"
                    />
                    <InformationItem
                      data={assigment.typeOfAssignment.ServiceName}
                      label=""
                      icon="task"
                    />
                    <InformationItem
                      data={`${assigment.dateTimeOrderFrom}  -  ${assigment.dateTimeOrderTo}`}
                      label=""
                      icon="calendar"
                    />
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </ThankYouComponent>
    );
  }
}

ThankYou.propTypes = propTypes;
ThankYou.defaultProps = defaultProps;

export default ThankYou;
