import React from 'react';
import PropTypes from 'prop-types';

import { IfComponent } from '../../../Share/components';

const propTypes = {
  icon: PropTypes.string,
  data: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
};

const defaultProps = {
  icon: '',
  data: '',
  label: '',
  className: '',
};

const displayData = (icon, data, label) => {
  const labelBuilder = label ? `${label}: ` : '';

  switch (icon) {
    case 'handset':
    case 'handset-2':
    case 'mobile-phone':
      return (
        <>
          <i className={`fa fa-mobile-alt`} />{' '}
          {labelBuilder != '' && <span>{labelBuilder}</span>}
          <a href={`tel:${data}`} target="_blank">
            {data}
          </a>
        </>
      );
    case 'video':
      return (
        <>
          <i className={`fa fa-video`} /> {labelBuilder}
          <a href={`${data}`} target="_blank">
            {data}
          </a>
        </>
      );

    case 'envelope':
    case 'share':
      return (
        <>
          <i className={`fa fa-${icon}`} /> {labelBuilder}
          <a href={`mailto:${data}`} target="_blank">
            {data}
          </a>
        </>
      );
    case 'customer-order-number':
      return (
        <>
          <i className={`fa fa-${icon}`} /> {labelBuilder}
          <span className="tv-secondary-color">{data}</span>
        </>
      );

    default:
      return (
        <>
          <i className={`fa fa-${icon}`} /> {labelBuilder}
          {data}
        </>
      );
  }
};

const InformationItem = ({ icon, data, label, className }) => (
  <IfComponent
    condition={!!data}
    whenTrue={
      <li className={`tv-workassignment__info-item ${className}`}>
        {displayData(icon, data, label)}
      </li>
    }
  />
);

InformationItem.propTypes = propTypes;
InformationItem.defaultProps = defaultProps;

export default InformationItem;
