import * as actionTypes from '../actions/ActionTypes';
import { currentDate } from '../../Share/utils/dateUtil';

const INITIAL_STATE = {
  timeMyAssignment: currentDate().subtract(1, 'hours'),
  timeAssignmentRequest: currentDate().subtract(1, 'hours'),
};

const jobTimer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.RESET_APP:
      return INITIAL_STATE;

    case actionTypes.UPDATE_JOBTIMER_MY_ASSIGNMENT:
    {
      const { timeMyAssignment } = action;
      return {
        ...state,
        timeMyAssignment,
      };
    }

    default:
      return state;
  }
};

export default jobTimer;
