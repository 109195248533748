import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { Translate, ThankYouComponent } from '../../../Share/components';
import './style.scss';

const propTypes = {
  assignment: PropTypes.shape({}),
};

const defaultProps = {
  assignment: {},
};

const SubmittedCancel = (props, context) => {
  const assignmentDetail = props.location.state ? props.location.state.assignmentDetail : null;
  if (!assignmentDetail) {
    return (
      <Redirect to={{
        pathname: '/my-assignment',
      }}
      />
    );
  }
  return (
    <React.Fragment>
      <ThankYouComponent
        callbackUrl="/"
        buttonText={Translate({ content: 'submittedCancel.backToDashboard' })}
      >
        <div className="tv-submittedcancel__title">
          <Translate content="submittedCancel.youCancelledFor" />
        </div>
        <div className="tv-submittedcancel__data">
          <div className="tv-submittedcancel__number">{assignmentDetail.OrderNumber}</div>
          {/* <div className="tv-submittedcancel__content">{assignmentDetail.Category}</div> */}
        </div>
      </ThankYouComponent>
    </React.Fragment>
  );
};

SubmittedCancel.propTypes = propTypes;
SubmittedCancel.defaultProps = defaultProps;

export default SubmittedCancel;

