import React, { useRef } from "react";
import Scrollbars from "react-custom-scrollbars";
import { Dialog, Translate} from "..";
import SingleEvent from "./SingleEvent";

function AssignmentListBox({ onClose,assignmentList,dateItem , onEventClick,newBooking, childButton}) {
  const scrollRef = useRef(null);
    return (
        <>
            <Dialog
                className="tk-assignment-list-box__container"
                isVisible={true}
                onClose={onClose}
            >
                <div className="tk-assignment-list-box_header tv-display-mobile-hide"><Translate content="calendar.assignments"/> </div>
                <div className="tk-assignment-list-box_header tv-display-mobile-show text-center">{dateItem.locale('sv').format('dddd, DD MMMM')} </div>
                <div className="tk-assignment-list-box_body">
                    <div className="w-100 d-none d-md-flex flex-wrap tk-assignment-list-box_body-text tv-display-mobile-hide"><Translate content="calendar.allAssigmentShow"/> {dateItem.locale('sv').format('DD MMMM YYYY')}</div>
                    <div className="w-100 d-flex d-md-none flex-wrap tk-assignment-list-box_body-text tv-display-mobile-show"><Translate content="dashboard.history"/></div>
                    {/* <hr
                    className="my-0 tv-display-mobile-hide"
                    style={{
                        width: "calc(100%)",
                        height: "0px",
                        marginBottom: "-0.5px !important",
                       
                    }}
                /> */}
                  <Scrollbars ref={scrollRef}
                  className="tk-assignment-list-box_body-scroll"
                  >
                  {assignmentList.map(item=>(
                      <SingleEvent
                      key={item.assignment.OrderIdentifier}
                      onEventClick={onEventClick}
                      date={dateItem}
                      event={item}
                  />
                  ))}
                  </Scrollbars>
                  {childButton}
                </div>
                <div>
                
                </div>
            </Dialog>
        </>
    );
}

export default AssignmentListBox;
