import React from 'react';
import PropTypes from 'prop-types';
import getAssignmentStatus from './status';

const propTypes = {
  status: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

const defaultProps = {
  status: '',
  className: 'tv-workassignment__item-status',
  children: null,
};

const AssignmentStatus = ({ status, className, children }) => {
  const item = getAssignmentStatus(status);
  return (
    <div className={className}>
      {children}
      <span
        className={`tv-workassignment__status tv-workassignment__status--${status}`}
      >
        {item}
      </span>
    </div>
  );
};

AssignmentStatus.propTypes = propTypes;
AssignmentStatus.defaultProps = defaultProps;

export default AssignmentStatus;
