import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { acceptedFileType } from '../../../Share/constants';
import { IfComponent, Translate } from '../../../Share/components';
import { truncateFileName, getFileBase64 } from '../../../Share/utils/fileUtil';

const propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({})),
  onDrop: PropTypes.func,
  onRemove: PropTypes.func,
  isPreviewMode: PropTypes.bool,
};

const defaultProps = {
  files: [],
  onDrop: () => { },
  onRemove: () => { },
  isPreviewMode: false,
};

class FileUpload extends Component {
  handleDrop = (acceptedFile) => {
    const file = acceptedFile[0];
    if (file) {
      getFileBase64(file).then((data) => {
        this.props.onDrop({
          data: data.replace(new RegExp('^data:.*;base64,', 'gm'), ''),
          name: file.name,
          size: file.size,
          type: file.type,
        });
      });
    }
  }

  render() {
    const { files, onRemove, isPreviewMode } = this.props;
    const renderFiles = () => (
      <React.Fragment>
        {
          files.map((file, i) => {
            const extension = acceptedFileType[file.type];
            return (
              <li className="tv-single-assign-form__file-element">
                <div className={`tv-single-assign-form__file-item
                                      tv-single-assign-form__file-item--${extension || 'other'}`}
                >
                  <div className="tv-single-assign-form__file-name">
                    {truncateFileName(file.name, 13, '...')}
                  </div>
                </div>
                <IfComponent
                  condition={!isPreviewMode}
                  whenTrue={
                    <button
                      type="button"
                      onClick={() => onRemove(i)}
                      className="tv-single-assign-form__button-removeFile"
                    >
                      <Translate content="singleAssignment.fileRemove" />
                    </button>}
                />
              </li>);
          })
        }
      </React.Fragment>
    );
    const renderButton = () => (
      <IfComponent
        condition={!isPreviewMode}
        whenTrue={
          <li className="tv-single-assign-form__file-element tv-display-flex">
            <Dropzone
              multiple={false}
              accept={Object.keys(acceptedFileType).join(',')}
              onDrop={this.handleDrop}
            >
              {({ getRootProps, getInputProps, isDragReject }) => (
                <div
                  {...getRootProps()}
                  className="tv-single-assign-form__file-item tv-single-assign-form__file-item--dragdrop"
                >
                  <input {...getInputProps()} />
                  <IfComponent
                    condition={isDragReject}
                    whenTrue={(<Translate content="singleAssignment.pleaseSubmitAValidFile" />)}
                    whenFalse={(<Translate content="singleAssignment.dragAttachFilesHere" />)}
                  />
                </div>
              )}
            </Dropzone>
          </li>
        }
      />
    );
    if (!!isPreviewMode) {
      return (
        <ul className="tv-single-assign-form__file-container tv-display-flex">
          {renderFiles()}
          {renderButton()}
        </ul>
      );
    } else {
      return (
        <React.Fragment>
          <div className="tv-col-3">
            <ul className="tv-single-assign-form__file-container tv-display-flex">
              {renderButton()}
            </ul>
          </div>
          <div className="tv-col-12">
            <ul className="tv-single-assign-form__file-container tv-display-flex">
              {renderFiles()}
            </ul>
          </div>
        </React.Fragment>
      );
    }
  }
}

FileUpload.propTypes = propTypes;
FileUpload.defaultProps = defaultProps;


export default FileUpload;
