import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LOCAL_STORE_TOKEN } from '../../Share/constants';
import { NotFound } from '../../Share/components';
import { connect } from 'react-redux';

const propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  layout: PropTypes.elementType.isRequired,
};

const defaultProps = {};

const PrivateRoute = ({
  component: Component,
  displayKPIReports,
  layout: Layout,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem(LOCAL_STORE_TOKEN) ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = propTypes;
PrivateRoute.defaultProps = defaultProps;

// export default PrivateRoute;
const mapStateToProps = (state, props) => ({
  displayKPIReports: state.authentication.displayKPIReports,
});
export default connect(mapStateToProps, null)(PrivateRoute);
