import PropTypes from 'prop-types';
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
  ChartTooltip,
} from '@progress/kendo-react-charts';
import { Circle, geometry } from '@progress/kendo-drawing';
import React from 'react';

const { Circle: CircleGeometry } = geometry;

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
};

const defaultProps = {
  data: [],
  title: '',
};

const renderChartTooltip = context => {
  const { category, percentage } = context.point || context;
  return (
    <div>
      {category}:{' '}
      {percentage.toLocaleString(undefined, {
        style: 'percent',
        minimumFractionDigits: 2,
      })}
    </div>
  );
};

const ClientOrdersChart = ({ data, title }) => (
  <Chart>
    <ChartTitle text={title} align="center" font="500 18px Circular Std" />
    <ChartLegend
      position="bottom"
      item={{
        visual: e => {
          const defaultVisual = e.createVisual();
          const group = defaultVisual.children;
          const fillColor = group[0].options.fill.color;

          const circleGeom = new CircleGeometry([10, 7], 4);
          const circle = new Circle(circleGeom, {
            fill: {
              color: fillColor,
            },
            stroke: {
              color: fillColor,
            },
          });

          group[0] = circle;

          return defaultVisual;
        },
      }}
    />
    <ChartTooltip render={renderChartTooltip} />
    <ChartSeries>
      <ChartSeriesItem
        type="pie"
        data={data}
        field="value"
        categoryField="category"
        tooltip={{ visible: true, format: '{0:p}' }}
      />
    </ChartSeries>
  </Chart>
);

ClientOrdersChart.propTypes = propTypes;
ClientOrdersChart.defaultProps = defaultProps;

export default ClientOrdersChart;
