import React from 'react';

const Searching = () => (
  <div className="tv-booknow__loading-container">
    <h2 className="tv-booknow__loading-container-title">
      Vi letar efter din tolk...
    </h2>
    <div className="tv-booknow__loading-container-animation-container">
      <div className="tv-booknow__loading-container-animation-container-images">
        <img
          src="/images/person-1.svg"
          alt="magnifying-glass"
          className="person-1"
        />
        <img
          src="/images/person-2.svg"
          alt="magnifying-glass"
          className="person-2"
        />
        <img
          src="/images/person-3.svg"
          alt="magnifying-glass"
          className="person-3"
        />
        <img
          src="/images/person-4.svg"
          alt="magnifying-glass"
          className="person-4"
        />
        <img
          src="/images/person-5.svg"
          alt="magnifying-glass"
          className="person-5"
        />
        <img
          src="/images/person-6.svg"
          alt="magnifying-glass"
          className="person-6"
        />
      </div>
      <img
        src="/images/magnifier.svg"
        alt="magnifying-glass"
        className="tv-booknow__loading-container-animation-container-magnifier"
      />
    </div>
  </div>
);

export default Searching;
