import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Translate, TransvoiceButton } from '../../../Share/components';

function AvailableInterpreters({
  onGoBack,
  onSubmit,
  data,
  visibleRetryMessage,
}) {
  const [selectedSlot, setSelectedSlot] = useState({});
  const [enableBookNowButton, setEnableBookNowButton] = useState(true);
  const selectSlot = slot => {
    setSelectedSlot(slot);
    setEnableBookNowButton(false);
  };
  const handleSubmit = () => {
    onSubmit(selectedSlot);
  };

  const filteredData =
    !isEmpty(data) &&
    data.filter(item => item.NumberOfAvailableResources !== 0);

  return (
    <div
      className={`tv-available-interpreters__container ${
        isEmpty(filteredData) || visibleRetryMessage ? 'message-container' : ''
      }`}
    >
      {!isEmpty(filteredData) && !visibleRetryMessage && (
        <div className="tv-available-interpreters-description">
          <div className="tv-available-interpreters-description__sub-heading">
            Hittade ingen ledig tolk
          </div>
          <div className="tv-available-interpreters-description__text">
            Vi har tyvärr ingen ledig tolk just nu. Men du kan boka tiderna
            nedan!
          </div>
        </div>
      )}
      {(isEmpty(filteredData) || visibleRetryMessage) && (
        <>
          <h1 className="tv-available-interpreters__title">
            Hittade ingen ledig tolk
          </h1>
          <div className="tv-available-interpreters__message">
            Vi har tyvärr ingen ledig tolk den önskade tiden. Ring till oss så
            kan vi söka tolk utanför TolkDirekt.
          </div>
        </>
      )}
      <div className="tv-available-interpreters-slot-container">
        {!isEmpty(filteredData) &&
          filteredData.map(slot => (
            <div
              onClick={() => selectSlot(slot)}
              onKeyPress={() => selectSlot(slot)}
              tabIndex={0}
              role="button"
              className={`tv-available-interpreters__row ${
                slot === selectedSlot
                  ? 'tv-available-interpreters__row-active'
                  : ''
              }`}
            >
              <div>
                {slot.TimeFrom} - {slot.TimeTo}
              </div>
              <div className="user-icons">
                <div className="plus">
                  {Number(slot.NumberOfAvailableResources) > 3 ? '+' : ''}
                </div>
                {Array(Number(slot.NumberOfAvailableResources))
                  .fill(0)
                  .slice(0, 3)
                  .map(() => (
                    <i className="fa fa-user" />
                  ))}
              </div>
            </div>
          ))}
      </div>
      <div className="d-flex flex-column">
        <div className="tv-booknow__form__buttons justify-content-between tv-display-flex">
          {!isEmpty(filteredData) && !visibleRetryMessage && (
            <TransvoiceButton
              className="back-button"
              text={Translate({
                content: 'bookNow.goBack',
              })}
              onClick={onGoBack}
            />
          )}
          {(isEmpty(filteredData) || visibleRetryMessage) && (
            <a href="tel:0812080000" className="contact-link">
              Ring 08-120 800 00
            </a>
          )}
          {!isEmpty(filteredData) && (
            <TransvoiceButton
              type="secondary"
              disabled={enableBookNowButton}
              text={Translate({
                content: 'bookNow.submitButton',
              })}
              onClick={() => {
                handleSubmit();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

AvailableInterpreters.propTypes = {
  onSubmit: PropTypes.func,
  onGoBack: PropTypes.func,
  data: PropTypes.shape([]),
  visibleRetryMessage: PropTypes.bool,
};
AvailableInterpreters.defaultProps = {
  onSubmit: () => {},
  onGoBack: () => {},
  data: [],
  visibleRetryMessage: false,
};

export default AvailableInterpreters;
