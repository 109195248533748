import React from 'react';
import PropTypes from 'prop-types';
import { Rating, Translate } from '../../../Share/components';

const propTypes = {
  title: PropTypes.string,
  timesheetId: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  handleOrderRate: PropTypes.func.isRequired,
  ratingCustomer: PropTypes.number.isRequired,
  orderIdentifier: PropTypes.string.isRequired,
  canBeRated: PropTypes.bool,
  comment: PropTypes.string,
  onCreateDeviation: PropTypes.func,
};

const defaultProps = {
  title: '',
  timesheetId: '',
  description: '',
  date: '',
  comment: '',
  onCreateDeviation: () => {},
};

const TimesheetHeader = ({
  title,
  timesheetId,
  description,
  date,
  ratingCustomer,
  orderIdentifier,
  handleOrderRate,
  canBeRated,
  ratingReasons,
  comment,
  onCreateDeviation,
  assignment,
}) => (
  <div className="timesheet__header w-100">
    <div className="timesheet__header-content">
      <Rating
        className="detailed-rating timesheet__header-rating"
        ratingClassName="d-flex flex-lg-nowrap flex-wrap justify-content-between"
        commentBoxClass="col-12 px-0"
        isDisabled={!canBeRated || ratingCustomer != null}
        rate={ratingCustomer || 0}
        handleSelectRate={handleOrderRate}
        displayCommentBox
        showRedirectCheckBox
        ratingReasons={ratingReasons}
        orderIdentifier={orderIdentifier}
        comment={comment}
        DisplayRateValue={false}
        onCreateDeviation={onCreateDeviation}
        assignment={assignment}
      >
        <div className="col-12 col-lg-5 d-flex flex-column mb-3 pl-0">
          <h6 className=" timesheet__header-content-id">
            <Translate content="timesheet.assignment" /> {timesheetId}
          </h6>
          <h6 className="timesheet__header-content-type mt-1">{description}</h6>
          <h6 className="timesheet__header-content-type">{date}</h6>
        </div>
      </Rating>
    </div>
  </div>
);

TimesheetHeader.propTypes = propTypes;
TimesheetHeader.defaultProps = defaultProps;

export default TimesheetHeader;
