import { TOGGLE_LOADING_OVERLAY } from './ActionTypes';

function toggleLoadingOverlay(show) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_LOADING_OVERLAY,
      showLoadingOverlay: show,
    });
  };
}

export function showLoadingOverlay() {
  return (dispatch) => {
    dispatch(toggleLoadingOverlay(true));
  };
}

export function hiddenLoadingOverlay() {
  return (dispatch) => {
    dispatch(toggleLoadingOverlay(false));
  };
}
