/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { isEqual } from 'lodash/core';
import ValidatorForm from 'react-form-validator-core/lib/ValidatorForm';
import {
  searchWorkAssignments,
  updateMyAssignmentFilters,
  getAttachment,
  setOrderRating,
  GetRatingReasons,
  onUpdateSearchByOrder,
  onUpdateNewAssignmentsTemp,
  getDeviations,
  getNotificationList,
} from '../../actions';
import MessageServiceDetail from '../MessageServiceDetail';
import NotificationDetail from '../NotificationDetail';
import {
  LeftDrawerSlide,
  Dialog,
  MessageServiceList,
  Translate,
  ArrowButton,
  IfComponent,
  DropdownList,
  RateFilter,
  Row,
  Col,
} from '../../../Share/components';
import {
  status,
  RATING_VALUES,
  FILTER_TYPES,
  PAGE_TYPES,
} from '../../../Share/constants';
import history from '../../../Share/utils/history';
import {
  convertAssignmentToOrder,
  generateEditOrderDateSession,
  generateUniqueId,
  shouldDisplayRatingElement,
} from '../../../Share/utils';
import SearchBox from '../SearchBox/SearchBox';
import StatusFilter from '../StatusFilter';
import './style.scss';
import { QuickOrder } from '../QuickOrder';
import { subscribe, unSubscribe } from '../../actions/eventBus';
import FeedBack from '../FeedBack';

const propTypes = {
  assignmentList: PropTypes.arrayOf(PropTypes.shape({})),
  onSearchWorkAssignments: PropTypes.func,
  updateMyAssignmentFilters: PropTypes.func,
  onGetAttachment: PropTypes.func,
  screen: PropTypes.string,
  onOrderRated: PropTypes.func,
  defaultDateRange: PropTypes.shape({}),
  notificationList: PropTypes.arrayOf(PropTypes.shape({})),
  onGetNotifications: PropTypes.func,
  assignmentNotifications: PropTypes.arrayOf(PropTypes.shape({})),
};

const defaultProps = {
  assignmentList: [],
  onOrderRated: () => {},
  onSearchWorkAssignments: () => {},
  updateMyAssignmentFilters: () => {},
  onGetAttachment: () => {},
  screen: 'myAssignment',
  defaultDateRange: {
    from: moment(),
    to: moment(),
  },
  notificationList: [],
  onGetNotifications: () => {},
  assignmentNotifications: [],
};

const INITAL_STATES = {
  isFromDashboard: false,
  showDetail: false,
  showNotification: false,
  activeFilters: [],
  ratedFlag: false,
  dateRangePicker: {
    selection: {
      startDate: moment(),
      endDate: moment(),
      key: 'selection',
    },
  },
  filters: '',
  isLoad: true,
  statusName: 'all',
  searchValue: '',
  assignmentList: [],
  orderIdentifier: '',
  assignmentDetail: {},
  redirectCancel: false,
  disableNextButton: false,
  disablePreviousButton: false,
  windowWidth: window.innerWidth,
  showStatusFilter: false,
  filterParamater: ['all-assignments'],
  rateFilter: [0],
  lacalTempFilterstate: [],
  filteredListItems: [],
  dateTimeSorted: false,
  selectedNotificationId: null,
  selectedNotification: null,
};
const type = {
  STATUS: 'status',
  OTHER: 'other',
};

function notificationHandler(localhistory) {
  const searchParameter = localhistory?.location?.search;
  let notificationId;
  if (searchParameter && searchParameter.includes('notification_id')) {
    const notificationSearchParameter = /notification_id=(.*)/gm.exec(
      searchParameter,
    ) || [null];
    notificationId = notificationSearchParameter[1];
    history.replace();
    return notificationId;
  }
  return null;
}

class MessageService extends Component {
  constructor(props) {
    super(props);

    const { assignments, isFromDashboard, isFromDashboardRating, from, to } =
      props.history.location.state
        ? props.history.location.state
        : {
            assignments: [],
            isFromDashboard: false,
            isFromDashboardRating: false,
            from: null,
            to: null,
          };

    const selectedNotificationId = notificationHandler(history);
    const { defaultDateRange } = this.props;
    this.state = {
      ...INITAL_STATES,
      isFromDashboard,
      isFromDashboardRating,
      assignmentList: assignments,
      showQuickOrder: false,
      showFeedBackModal: false,
      quickOrderDate: null,
      dateRangePicker: {
        selection: {
          startDate: from || defaultDateRange.from.clone().subtract(2, 'days'),
          endDate: to || defaultDateRange.to.clone().add(1, 'days'),
          key: 'selection',
        },
      },
      selectedNotificationId,
      assignmentSortType: {
        type: 'ArticleName',
        targetType: 'string',
        childKey: null,
      },
      sortedFilters: {
        ArticleName: 1,
        DatetimeFrom: 0,
        OrderNumber: 0,
        ContactPerson: 0,
        Skill: 0,
        RatingCustomer: 0,
        OrderStatus: 0,
      },
    };
  }

  componentDidMount() {
    const { GetRatingReasons, history } = this.props;
    const {
      isFromDashboard,
      dateRangePicker,
      isFromDashboardRating,
      selectedNotificationId,
    } = this.state;

    if (history.location.state) {
      const state = {
        ...history.location.state,
        isFromDashboard: false,
        isFromDashboardRating: false,
      };

      history.replace({ ...history.location, state });
    }
    if (
      isFromDashboard === false &&
      isFromDashboardRating === false
      //  &&
      // !selectedNotificationId
    ) {
      this.executeSearchAssignment(isFromDashboard, dateRangePicker);
    }

    GetRatingReasons();
    window.addEventListener('resize', () => {
      this.setState({
        windowWidth: window.innerWidth,
      });
    });
    this.orderSuccess = subscribe('SuccessFullOrderCreation', () => {
      this.handleFilter();
    });

    this.cancelevent = subscribe('SuccessFullCancellOrder', response => {
      if (
        response &&
        response.OrderIdentifier &&
        response.OrderIdentifier ===
          this.state.assignmentDetail?.OrderIdentifier
      ) {
        this.setState({ showDetail: false });
      }
    });
  }

  componentDidUpdate(prevProps) {
    const nextNotificationId = notificationHandler(history);
    const { selectedNotificationId } = this.state;

    if (nextNotificationId && selectedNotificationId !== nextNotificationId) {
      if (nextNotificationId && prevProps.notificationList.length > 0) {
        const selectedNotification = prevProps.notificationList.find(
          x => x.NotificationIdentifier === nextNotificationId,
        );
        if (selectedNotification && prevProps.assingmentPageList.length > 0) {
          const selectedAssignemnt = prevProps.assingmentPageList.find(
            x =>
              x.OrderIdentifier ===
              selectedNotification.OrderIdentifiers[0].OrderIdentifier,
          );
          if (selectedAssignemnt) {
            this.onShowAssignmentDetail(selectedAssignemnt, nextNotificationId);
          } else {
            prevProps.onSearchWorkAssignments(
              null,
              null,
              null,
              null,
              true,
              false,
              selectedNotification.OrderIdentifiers[0].OrderIdentifier,
            );
          }
        }
      }
    }
  }

  onShowAssignmentDetail = (assignmentDetail, selectedNotificationId) => {
    this.setState({
      assignmentDetail,
      showDetail: true,
      selectedNotificationId,
    });
  };

  handleChangeAssinmentSortType = type => {
    this.setState({
      assignmentSortType: type,
    });
  };

  handleChageSortedFilters = filters => {
    this.setState({
      sortedFilters: filters,
    });
  };

  componentWillUnmount() {
    unSubscribe('SuccessFullCancellOrder', this.cancelevent);
    unSubscribe('SuccessFullOrderCreation', this.orderSuccess);
  }

  static extractUpdatedActiveFilters(nextProps, prevState) {
    const updateFilter = nextProps.filters;
    const updateOtherFilter = nextProps.otherFilter;
    const { activeFilters } = prevState;
    let updatedActivefilter = [...activeFilters];
    updatedActivefilter =
      updatedActivefilter.filter(x => x.type === type.STATUS).length === 0 &&
      !(updateFilter === '' || updateFilter === 'all')
        ? [
            ...activeFilters,
            {
              key: updatedActivefilter.length,
              value: updateFilter,
              type: type.STATUS,
            },
          ]
        : [...updatedActivefilter];

    updatedActivefilter =
      updatedActivefilter.filter(x => x.type == type.OTHER).length === 0 &&
      !(updateOtherFilter === '' || updateOtherFilter === 'all')
        ? [
            ...updatedActivefilter,
            {
              key: updatedActivefilter.length,
              value: updateOtherFilter,
              type: type.OTHER,
            },
          ]
        : [...updatedActivefilter];
    return { updateFilter, updateOtherFilter, updatedActivefilter };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.filters !== prevState.filters ||
      nextProps.otherFilter !== prevState.otherFilter ||
      prevState.ratedFlag
    ) {
      const { updateFilter, updateOtherFilter, updatedActivefilter } =
        MessageService.extractUpdatedActiveFilters(nextProps, prevState);
      let filteredAssignmentList = [...nextProps.assignmentList];

      if (nextProps.filters !== 'all' && nextProps.filters !== '') {
        filteredAssignmentList = filteredAssignmentList.filter(
          x => x.OrderStatus === nextProps.filters,
        );
      }

      if (nextProps.otherFilter !== 'all' && nextProps.otherFilter !== '') {
        filteredAssignmentList =
          nextProps.otherFilter === 'rated-order'
            ? filteredAssignmentList.filter(
                x => x.CanBeRated && x.RatingCustomer !== null,
              )
            : filteredAssignmentList.filter(
                x => x.CanBeRated && x.RatingCustomer === null,
              );
      }

      return {
        filters: updateFilter,
        otherFilter: updateOtherFilter,
        activeFilters: updatedActivefilter,
        assignmentList: filteredAssignmentList,
      };
    }

    if (
      !isEqual(
        nextProps.assignmentList.sort(),
        prevState.assignmentList.sort(),
      ) &&
      prevState.isLoad
    ) {
      const { updatedActivefilter } =
        MessageService.extractUpdatedActiveFilters(nextProps, prevState);

      let filteredAssignmentSecondoryList = [...nextProps.assignmentList];

      if (nextProps.filters !== 'all' && nextProps.filters !== '') {
        filteredAssignmentSecondoryList =
          filteredAssignmentSecondoryList.filter(
            x => x.OrderStatus === nextProps.filters,
          );
      }
      if (nextProps.otherFilter !== 'all' && nextProps.otherFilter !== '') {
        filteredAssignmentSecondoryList =
          nextProps.otherFilter === 'rated-order'
            ? filteredAssignmentSecondoryList.filter(
                x => x.CanBeRated && x.RatingCustomer !== null,
              )
            : filteredAssignmentSecondoryList.filter(
                x => x.CanBeRated && x.RatingCustomer === null,
              );
      }

      return {
        assignmentList: filteredAssignmentSecondoryList,
        activeFilters: updatedActivefilter,
        isLoad: !!isEqual(
          filteredAssignmentSecondoryList.sort(),
          prevState.assignmentList.sort(),
        ),
      };
    }

    if (
      prevState.selectedNotificationId &&
      nextProps.notificationList.length > 0
    ) {
      const selectedNotification = nextProps.notificationList.find(
        x => x.NotificationIdentifier === prevState.selectedNotificationId,
      );
      if (selectedNotification && nextProps.assingmentPageList.length > 0) {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const selectedAssignemnt = nextProps.assingmentPageList.find(
          x =>
            x.OrderIdentifier ===
            selectedNotification.OrderIdentifiers[0].OrderIdentifier,
        );
        if (selectedAssignemnt) {
          return {
            assignmentDetail: selectedAssignemnt,
            showDetail: true,
            showNotification: false,
          };
        } else {
          nextProps.onSearchWorkAssignments(
            null,
            null,
            null,
            null,
            true,
            false,
            selectedNotification.OrderIdentifiers[0].OrderIdentifier,
          );
        }
      }
    }

    return null;
  }

  removeQuery = (...queryNames) => {
    const { location } = history;
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has('notification_id')) {
      queryParams.delete('notification_id');
      history.replace({
        search: queryParams.toString(),
      });
    }
  };

  onRowClick = (item, filteredItems) => {
    this.removeQuery('notification_id');
    const { assingmentPageList } = this.props;
    const Index = assingmentPageList.findIndex(
      x => x.OrderIdentifier === item.OrderIdentifier,
    );
    this.setState({
      ...this.state,
      showDetail: true,
      showNotification: false,
      showQuickOrder: false,
      assignmentDetail: item,
      disableNextButton: Index === assingmentPageList.length - 1,
      disablePreviousButton: Index === 0,
      filteredListItems: filteredItems || assingmentPageList,
      selectedNotificationId: null,
    });
  };

  onDrawerClose = () => {
    this.setState({
      showDetail: false,
    });
  };

  handleFilter = (value, filterType) => {
    const { dateRangePicker, searchValue } = this.state;
    const { onSearchWorkAssignments, location } = this.props;
    const { isFromDashboard } =
      location.state && location.state.isFromDashboard
        ? location.state
        : {
            isFromDashboard: false,
          };

    onSearchWorkAssignments(
      null,
      dateRangePicker.selection.startDate,
      dateRangePicker.selection.endDate,
      searchValue,
      true,
      isFromDashboard,
    );
    this.setState({
      ...this.state,
      isLoad: true,
    });
  };

  handleRangeChange = payload => {
    payload.selection.endDate = moment(payload.selection.endDate).endOf('day');
    this.setState({
      dateRangePicker: {
        ...payload,
      },
    });
  };

  executeSearchAssignment = (isFromDashboard, dateRangePicker) => {
    const { onSearchWorkAssignments } = this.props;
    const { from, to } = this.props.defaultDateRange;
    const dateFrom = isFromDashboard
      ? dateRangePicker.selection.startDate
      : from.clone().subtract(1, 'days');
    const dateTo = isFromDashboard
      ? dateRangePicker.selection.endDate
      : to.clone().add(1, 'days');

    onSearchWorkAssignments(
      null,
      dateFrom,
      dateTo,
      '',
      isFromDashboard,
      isFromDashboard,
    );
  };

  handleClickCancel = () => {
    const { assignmentDetail } = this.state;

    const redirectToCancelAssignment = () => {
      history.push({
        pathname: '/cancel-assignment',
        state: {
          from: this.props.location,
          assignmentDetail,
        },
      });
    };

    redirectToCancelAssignment();
  };

  redirectToTimeSheet = () => {
    const { assignmentDetail } = this.state;

    history.push({
      pathname: '/time-sheet',
      state: {
        from: this.props.location,
        assignmentDetail,
      },
    });
  };

  handleClickTimeReport = () => {
    this.redirectToTimeSheet();
  };

  handleOrderRated = (orderIdentifier, rate, comment, reason) => {
    const { onOrderRated } = this.props;
    this.setState({
      ...this.state,
      ratedFlag: true,
    });
    onOrderRated(orderIdentifier, rate, comment, reason);
  };

  onPreviousNextClicked = value => {
    const { assignmentDetail, filteredListItems } = this.state;
    const activeAssignmentId = assignmentDetail.OrderIdentifier;

    this.setState({
      ...this.state,
      disablePreviousButton: false,
    });

    if (filteredListItems.length && activeAssignmentId) {
      const Index = filteredListItems.findIndex(
        x => x.OrderIdentifier === activeAssignmentId,
      );

      if (Index + value <= filteredListItems.length - 1 && Index + value >= 0) {
        const assignmentDetailItem = filteredListItems[Index + value];
        this.setState({
          ...this.state,
          assignmentDetail: assignmentDetailItem,
          disableNextButton: Index + value === filteredListItems.length - 1,
          disablePreviousButton: Index + value === 0,
        });
      }
    }
  };

  handleStatusFilter = value => {
    const { updateMyAssignmentFilter } = this.props;

    updateMyAssignmentFilter(FILTER_TYPES.STATUS_FILTER, value);
  };

  onKeyPress = event => {
    const { keyCode } = event;
    if (keyCode === 13) {
      this.searchHandler(event);
    }
  };

  searchHandler = event => {
    const searchValue = event.target.value;
    const { dateRangePicker, searchValue: previousSearchValue } = this.state;
    const { onSearchWorkAssignments, location } = this.props;
    const { isFromDashboard } =
      location.state && location.state.isFromDashboard
        ? location.state
        : {
            isFromDashboard: false,
          };
    if (!isEqual(searchValue, previousSearchValue)) {
      onSearchWorkAssignments(
        null,
        dateRangePicker.selection.startDate,
        dateRangePicker.selection.endDate,
        searchValue,
        true,
        isFromDashboard,
      );
      this.setState({
        ...this.state,
        isLoad: true,
        searchValue,
      });
    }
  };

  handleStatusFilterDrawerClose = () => {
    this.setState({
      showStatusFilter: false,
    });
  };

  handleRateFilter = value => {
    const { updateMyAssignmentFilter } = this.props;

    updateMyAssignmentFilter(FILTER_TYPES.RATING_FILTER, value);
  };

  handleTypeFilter = e => {
    const {
      target: { value },
    } = e;
    const { updateMyAssignmentFilter } = this.props;

    updateMyAssignmentFilter(FILTER_TYPES.TYPE_FILTER, value);
  };

  resetQuickOrderDate = () => {
    this.setState({
      quickOrderDate: null,
    });
  };

  handleQuickOrderDrawerClose = () => {
    this.setState({
      showQuickOrder: false,
    });
  };

  handleCopyOrder = assignment => {
    const convertedOrder = convertAssignmentToOrder(
      assignment,
      this.props.profile,
      this.props.skills,
    );
    this.props.onSwitchToDetailOrder(convertedOrder);
    this.setState({
      showQuickOrder: true,
      showDetail: false,
    });
  };

  handleEditOrder = assignment => {
    const { skills, profile } = this.props;

    const convertedOrder = convertAssignmentToOrder(
      assignment,
      profile,
      skills,
    );

    this.props.onSwitchToDetailOrder({
      ...convertedOrder,
      dateRange: generateEditOrderDateSession(
        assignment.DatetimeFrom,
        assignment.DatetimeTo,
      ),
      isEdit: true,
      OrderIdentifier: assignment.OrderIdentifier,
      OrderNumber: assignment.OrderNumber,
    });

    this.setState({
      showQuickOrder: true,
      showDetail: false,
      showAssigmentList: false,
    });
  };

  handleFilterClear = () => {
    const { updateMyAssignmentFilter } = this.props;

    updateMyAssignmentFilter(FILTER_TYPES.RATING_FILTER, [null]);
    updateMyAssignmentFilter(FILTER_TYPES.STATUS_FILTER, ['all-assignments']);
    updateMyAssignmentFilter(FILTER_TYPES.TYPE_FILTER, {
      name: 'Välj',
      value: '',
    });
    this.setState({
      showStatusFilter: false,
    });
  };

  handleAllFilter = () => {
    this.setState({
      showStatusFilter: false,
    });
  };

  sortDateTime = () => {
    const { assingmentPageList } = this.props;

    const sortedArray = assingmentPageList.sort((a, b) => {
      if (this.state.dateTimeSorted) {
        return moment(b.DatetimeFrom).diff(a.DatetimeFrom);
      }
      return moment(a.DatetimeFrom).diff(b.DatetimeFrom);
    });
    this.setState({
      dateTimeSorted: !this.state.dateTimeSorted,
      assingmentPageList: sortedArray,
    });
  };
  handleFeedBackModal = () => {
    this.setState({
      showFeedBackModal: !this.state.showFeedBackModal,
      assignmentDetail: {},
      showDetail: false,
    });
  };

  handlerNotification = notifications => {
    this.removeQuery('notification_id');
    const { onGetNotifications } = this.props;
    const { assignmentDetail } = this.state;
    onGetNotifications(assignmentDetail.OrderIdentifier, () => {
      this.setState({
        showNotification: true,
        selectedNotificationId: null,
      });
    });
  };

  render() {
    const {
      screen,
      onOrderRated,
      ratingReasons,
      assingmentPageList,
      statusFilter,
      rateFilter,
      types,
      selectedType,
      mapOnFilterDeviation,
      assignmentNotifications,
    } = this.props;
    const {
      showStatusFilter,
      showDetail,
      showNotification,
      showFeedBackModal,
      dateRangePicker,
      assignmentList,
      assignmentDetail,
      disableNextButton,
      showQuickOrder,
      quickOrderDate,
      disablePreviousButton,
      windowWidth,
      dateTimeSorted,
    } = this.state;

    let messageStatus = [
      'ordered',
      'fullfilled',
      'notPerformed',
      'interrupted',
    ];
    return (
      <>
        <div className="tv-workassignment__wrapper">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 px-0 pr-xl-3">
              <SearchBox
                placeholder={Translate({
                  content: 'myfilter.search',
                })}
                className="position-relative"
                onBlur={this.searchHandler}
                onKeyUp={this.onKeyPress}
              />
              <div className="tv-display-mobile-hide tabs">
                {windowWidth > 1024 && (
                  <StatusFilter
                    // onClick={this.handleStatusFilter}
                    onClick={() => {}}
                    selectedFilter={messageStatus}
                    isMessageService
                  />
                )}
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-9 px-0">
              <h3
                className="message-service-title"
                style={{ zIndex: 7, position: 'relative' }}
              >
                Översikt av beställda meddelanden
              </h3>

              <MessageServiceList
                onRowClick={this.onRowClick}
                onChangeAssignmentSortType={this.handleChangeAssinmentSortType}
                onChangeSortedFilters={this.handleChageSortedFilters}
                assignmentSortType={this.state.assignmentSortType}
                sortedFilters={this.state.sortedFilters}
                // items={assingmentPageList}
                items={messageList}
                screen={screen}
                selectedItem={assignmentDetail}
                onRateAssignmentClick={assignment => {
                  document
                    .querySelector('.tv-right-pannel-wrapper ')
                    .scrollIntoView({
                      behavior: 'smooth',
                    });
                  this.setState({
                    assignmentDetail: assignment,
                    showFeedBackModal: true,
                  });
                }}
                rateFilter={rateFilter}
                filterParamater={statusFilter}
                typeValue={selectedType}
                sortDateTime={this.sortDateTime}
                dateTimeSorted={dateTimeSorted}
                dateTimeSortedIcon={
                  dateTimeSorted ? 'icon-arrow-top' : 'icon-arrow-down'
                }
              />
              <div className="tv-workassignment__list__mobile-fixed-bottom">
                <button
                  onClick={() => {
                    this.setState({
                      showStatusFilter: true,
                    });
                  }}
                  className="tk-calendar__btn-filter mx-auto"
                >
                  {Translate({ content: 'calendar.filter' })}
                </button>
              </div>
            </div>
          </div>

          {showQuickOrder && (
            <QuickOrder
              onResetDate={this.resetQuickOrderDate}
              date={quickOrderDate}
              onClose={() => {
                this.handleQuickOrderDrawerClose();
                this.props.onSwitchToDetailOrder({});
                this.setState({
                  assignmentDetail: {},
                });
              }}
              showQuickOrder={showQuickOrder}
            />
          )}
          {showFeedBackModal && (
            <FeedBack
              assignmentDetail={assignmentDetail}
              from="ASSIGNMENT"
              history={history}
              onHandleModal={this.handleFeedBackModal}
              showModal={showFeedBackModal}
            />
          )}

          {showNotification && (
            <div>
              Notification
              <NotificationDetail
                isVisible={showNotification}
                onClose={() => {
                  this.setState({
                    showNotification: false,
                    // assignmentDetail: {},
                  });
                }}
                notifications={assignmentNotifications}
              />
            </div>
          )}

          {showDetail && (
            <MessageServiceDetail
              isVisible={showDetail}
              onClose={() => {
                history.replace();
                this.setState({
                  showDetail: false,
                  assignmentDetail: {},
                  selectedNotificationId: null,
                });
              }}
              from={PAGE_TYPES.ASSIGNMENT}
              assignment={assignmentDetail}
              ratingReasons={ratingReasons}
              status={status.getByStatusString(assignmentDetail.OrderStatus)}
              handleClickCancel={() => this.handleClickCancel(assignmentDetail)}
              handleGetAttachment={this.props.onGetAttachment}
              handleOrderRate={this.handleOrderRated}
              onCopyOrder={this.handleCopyOrder}
              onEditOrder={this.handleEditOrder}
              handleClickTimeReport={this.handleClickTimeReport}
              visibleNextPreviousButtons
              disablePreviousButton={disablePreviousButton}
              disableNextButton={disableNextButton}
              onPreviousNextClicked={val => {
                this.onPreviousNextClicked(val);
              }}
              onFilterDeviation={(val, callback) => {
                mapOnFilterDeviation(val, callback, false);
              }}
              onClickMoreNotification={this.handlerNotification}
            />
          )}
        </div>
      </>
    );
  }
}

MessageService.propTypes = propTypes;
MessageService.defaultProps = defaultProps;

const mapStateToProps = state => {
  const {
    filters,
    assingmentPageList,
    otherFilter,
    ratingReasons,
    statusFilter,
    rateFilter,
    types,
    selectedType,
  } = state.workAssignment;
  const { notificationList } = state.notification;
  const defaultDateRange = state.user.calendar.range;
  const skills = state.skill.skills || [];
  return {
    filters,
    skills,
    otherFilter,
    assingmentPageList,
    defaultDateRange,
    ratingReasons,
    statusFilter,
    rateFilter,
    notificationList,
    types,
    selectedType,
    assignmentNotifications: state.notification.assignmenNotificationList,
  };
};

const mapDispatchToProps = dispatch => ({
  onSearchWorkAssignments: (s, from, to, e, f, g, h) => {
    dispatch(searchWorkAssignments(s, from, to, e, f, g, h));
  },
  updateMyAssignmentFilter: (e, filterType) => {
    dispatch(updateMyAssignmentFilters(e, filterType));
  },
  onGetAttachment: attachmentIdentifier => {
    dispatch(getAttachment(attachmentIdentifier));
  },
  onOrderRated: (orderIdentifier, rate, comment, reason) => {
    dispatch(setOrderRating(orderIdentifier, rate, comment, reason));
  },
  onSwitchToDetailOrder: assignment => {
    dispatch(onUpdateNewAssignmentsTemp(assignment));
  },
  GetRatingReasons: () => {
    dispatch(GetRatingReasons());
  },
  mapOnUpdateSearchByOrder: searchOrder => {
    dispatch(onUpdateSearchByOrder(searchOrder));
  },
  mapOnFilterDeviation: (data, callback, setData) => {
    dispatch(getDeviations(data, callback, setData));
  },
  onGetNotifications: (orderIdentifier, successCallback) => {
    dispatch(
      getNotificationList(orderIdentifier, false, null, successCallback),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageService);

const messageList = [
  {
    id: '1',
    typeOfMessage: 'Telefonsamtal',
    date: '2024-01-05 20:15:00',
    number: '070-1740605',
    bookingNumber: '12345678',
    language: 'Finska',
    name: 'Jane Doe',
    status: 'ordered',
  },
  {
    id: '2',
    typeOfMessage: 'Sms',
    date: '2024-01-10 20:15:00',
    number: '070-1740606',
    bookingNumber: '',
    language: 'Ukrainska',
    name: 'John Doe',
    status: 'fullfilled',
  },
  {
    id: '3',
    typeOfMessage: 'Telefonsamtal',
    date: '2024-01-17 20:15:00',
    number: '070-1740607',
    bookingNumber: '',
    language: 'Syriska',
    name: 'Jane Doe',
    status: 'fullfilled',
  },
  {
    id: '4',
    typeOfMessage: 'Sms',
    date: '2024-01-19 20:15:00',
    number: '070-1740608',
    bookingNumber: '12345678',
    language: 'Italienska',
    name: 'John Doe',
    status: 'ordered',
  },
  {
    id: '5',
    typeOfMessage: 'Telefonsamtal',
    date: '2024-01-23 20:15:00',
    number: '070-1740609',
    bookingNumber: '',
    language: 'Syriska',
    name: 'Jane Doe',
    status: 'notPerformed',
  },
];
