/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Translate, Checkbox } from '../../../../Share/components';
import { convertStringToDate } from '../../../../Share/utils/dateUtil';
import { getDateFormated } from '../../../../Share/utils';
import './style.scss';

const GeneralNotification = ({
  index,
  title,
  message,
  date,
  isNew,
  isPage,
  handleCardClick,
  item,
  visibleMarkAsRead,
  visibleButtons,
  handleCheck,
}) => {
  // const [isChecked, setIsChecked] = useState(false);

  // const onChangeCheckbox = (e) => {
  //   e.stopPropagation();
  //   handleCheck(!isChecked);
  //   setIsChecked(!isChecked);
  // };

  const onClickCard = event => {
    handleCheck(true);
    handleCardClick(
      title,
      item.WorkAssignmentIdentifiers,
      item.NotificationIdentifier,
    );
  };

  const dateTime = getDateFormated(convertStringToDate(date), 'DD MMMM, HH:mm');

  return (
    <div>
      <div
        className="tv-general-notification__container"
        role="button"
        onClick={e => onClickCard(e)}
      >
        <div className="tv-general-notification__wrapper">
          <div className="tv-general-notification__title">
            {isPage && isNew && (
              <div className="tv-general-notification__new-label tv-general-notification__new-label-forpage">
                {Translate({ content: 'notification.new' })}
              </div>
            )}
            {title}
          </div>
          {!isPage && isNew && (
            <div className="tv-general-notification__new-label">
              {Translate({ content: 'notification.new' })}
            </div>
          )}
          {isPage && (
            <div className="tv-general-notification__date">
              {dateTime}
            </div>
          )}
        </div>
        <div
          className={`tv-general-notification__message ${
            isPage && 'tv-general-notification__message-forpage'
          }`}
        >
          {message}
        </div>
        <div className="d-flex">
          {!isPage && (
            <div className="tv-general-notification__date">
              {dateTime}
            </div>
          )}
          {visibleButtons && (
            <div className="tv-general-notification__buttons">
              <button className="tv-general-notification__buttons-decline">
                Avböj
              </button>
              <button className="tv-general-notification__buttons-accept">
                Acceptera
              </button>
            </div>
          )}
        </div>
      </div>
      {/* <div className="tv-general-notification__checkbox">
        {visibleMarkAsRead && (
          <div className="tv-general-notification__mark-as-read ">
            <label className="tv-general-notification__mark-as-read-label">
              Markera som läst
            </label>
            <Checkbox
              value={`noti-${index}`}
              defaultChecked={isChecked}
              handleChange={onChangeCheckbox}
            />
          </div>
        )}
      </div> */}
    </div>
  );
};

GeneralNotification.propTypes = {
  index: PropTypes.number,
  title: PropTypes.string,
  message: PropTypes.string,
  date: PropTypes.string,
  isNew: PropTypes.bool,
  isPage: PropTypes.bool,
  item: PropTypes.shape({
    WorkAssignmentIdentifiers: PropTypes.arrayOf,
    NotificationIdentifier: PropTypes.string,
  }),
  handleCardClick: PropTypes.func,
  visibleMarkAsRead: PropTypes.bool,
  visibleButtons: PropTypes.bool,
  handleCheck: PropTypes.func,
};
GeneralNotification.defaultProps = {
  index: 0,
  title: '',
  message: '',
  date: '',
  isNew: false,
  isPage: false,
  item: {},
  handleCardClick: () => {},
  visibleMarkAsRead: false,
  visibleButtons: false,
  handleCheck: () => { },
};

export default GeneralNotification;
