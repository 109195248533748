import React, { Component } from "react";
import PropTypes from "prop-types";

import { preventScrollWhenVisible, removeContextualClass } from "../../utils";

import IfComponent from "../IfComponent";
import CloseButton from "../CloseButton";
import ArrowButton from "../ArrowButton";
import Translate from "../Translate";

const propTypes = {
    isVisible: PropTypes.bool,
    visiblePreviousButton: PropTypes.bool,
    visibleNextButton: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    onCloseClicked: PropTypes.func,
    onOverlayClick: PropTypes.func,
    onPreviousNextClicked: PropTypes.func,
    disableNextButton: PropTypes.bool,
    disablePreviousButton: PropTypes.bool,
};

const defaultProps = {
    isVisible: false,
    visiblePreviousButton: false,
    visibleNextButton: false,
    disableNextButton: false,
    disablePreviousButton: false,
    children: null,
    className: "",
    onPreviousNextClicked: () => {},
};

const INITAL_STATES = {};

class DrawerSlide extends Component {
    state = INITAL_STATES;

    static getDerivedStateFromProps(nextProps, prevState) {
        const { isVisible } = nextProps;
        if (isVisible) {
            preventScrollWhenVisible(isVisible);
        } else {
            removeContextualClass();
        }
        return null;
    }

    componentDidMount(nextProps) {
        const { isVisible } = this.props;
        preventScrollWhenVisible(isVisible);
    }

    onHandlerClose = (e, fn) => {
        removeContextualClass();
        if (fn) {
            setTimeout(() => {
                fn(e);
            }, 550);
        }
    };

    render() {
        const {
            className,
            children,
            isVisible,
            onCloseClicked,
            onPreviousNextClicked,
            onOverlayClick,
            visiblePreviousButton,
            visibleNextButton,
            disablePreviousButton,
            disableNextButton,
        } = this.props;
        return (
            <IfComponent
                condition={isVisible}
                whenTrue={
                    <div
                        className={`tv-workassignment__drawer-wrapper ${className}`}
                    >
                        <div
                            className="tv-workassignment__drawer-overlay"
                            onClick={(e) => {
                                this.onHandlerClose(e, onOverlayClick);
                            }}
                            role="button"
                            tabIndex="-1"
                        />
                        
                        <div className="tv-workassignment__drawer-holder">
                            <div className="tv-workassignment__drawer-button-wrapper">
                                <IfComponent
                                    condition={visibleNextButton}
                                    whenTrue={
                                        <ArrowButton
                                            Text={Translate({
                                                content:
                                                    "taskDetail.previousButton",
                                            })}
                                            title="Previous"
                                            onClick={(e) => {
                                                onPreviousNextClicked(-1);
                                            }}
                                            disabled={disablePreviousButton}
                                            className={ disablePreviousButton?'tv-workassignment__drawer-previous tv-buttons__type icon-previous disabled':'tv-workassignment__drawer-previous tv-buttons__type icon-previous'}
                                        />
                                    }
                                />

                                <IfComponent
                                    condition={visiblePreviousButton}
                                    whenTrue={
                                        <ArrowButton
                                            Text={Translate({
                                                content: "taskDetail.nextButton",
                                            })}
                                            title="Next"
                                            onClick={() => {
                                                onPreviousNextClicked(+1);
                                            }}
                                            disabled={disableNextButton}
                                            className={ disableNextButton?'tv-workassignment__drawer-next tv-buttons__type icon-next disabled':'tv-workassignment__drawer-next tv-buttons__type icon-next '}
                                        />
                                    }
                                />
                            </div>

                            <CloseButton
                                onClick={(e) => {
                                    this.onHandlerClose(e, onCloseClicked);
                                }}
                                className="tv-workassignment__drawer-close tv-buttons__type icon-close"
                            />
                            <div>{children}</div>
                        </div>
                    </div>
                }
                whenFalse={null}
            />
        );
    }
}

DrawerSlide.propTypes = propTypes;
DrawerSlide.defaultProps = defaultProps;

export default DrawerSlide;
