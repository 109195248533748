import React from 'react';
import PropTypes from 'prop-types';

import IfComponent from '../IfComponent';
import { Translate } from '..';

const propTypes = {
    total: PropTypes.number,
    onClick: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    total: 0,
    onClick: () => { },
    className: 'tk-monthly-calendar__item-more-btn',
};


const ItemDay = ({ total, onClick, className }) => (
    <IfComponent condition={total > 1}
        whenTrue={(
                <button className={className} onClick={onClick}>
                    {(total-1)} <Translate content="calendar.more" />
                </button>
        )} />
);

ItemDay.propTypes = propTypes;
ItemDay.defaultProps = defaultProps;

export default ItemDay;