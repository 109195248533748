import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Translate } from '../../../Share/components';

import './style.scss';

function NotificationItem({ HeadLine, Datetime, isNew }) {
  return (
    <div className="tk-notification-item__container">
      {/* <div className="">
        <Checkbox
          onClick={()=>{}}
          className=""
          value=""
          defaultChecked={isNew}
        />
      </div> */}
      <div className="">
        <h6 className="tk-notification-item__date">{Datetime}</h6>
        <div className="tk-notification-item__title">
          {HeadLine}
        </div>
      </div>
      {isNew && (
        <div className="tk-notification-item__label-new">
          {Translate({ content: 'notification.new' })}
        </div>
      )}
    </div>
  );
}

NotificationItem.propTypes = {
  HeadLine: PropTypes.string,
  Datetime: PropTypes.string,
  isNew: PropTypes.bool,
};
NotificationItem.defaultProps = {
  HeadLine: '',
  Datetime: '',
  isNew: false,
};

export default NotificationItem;
