/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { isIE } from '../../../Share/utils';
import { Modal, Translate, TransvoiceButton } from '../../../Share/components';

function SuccessMessage({
  visible,
  onClose,
  assignmentDetails,
  onContinueBooking,
  deviationNumber,
  mode,
}) {
  const deviationSuccessMessage = () => (
    <div className="tv-deviation-message">
      <div className="tv-deviation-message-title">
        <Translate content="reportQuality.deviationSuccessTitle" />
      </div>
      <div className="tv-deviation-message-paragraph">
        <Translate content="reportQuality.deviationSuccessMessagePart1" />
      </div>
      <div className="tv-deviation-message-paragraph">
        <Translate content="reportQuality.deviationSuccessMessagePart2" />
      </div>
    </div>
  );
  return (
    <>
      <div className="d-flex flex-column tv-workassignment__modal-body">
        <div className="d-flex flex-column m-1 m-md-5 h-100 justify-content-around">
          <div>
            <h4 className="tv-headline-1 my-1 text-center">
              {mode === 'deviation'
                ? ''
                : mode === 'edit'
                ? 'Din bokning är ändrad'
                : 'Din bokning är skickad'}
            </h4>
            <div
              className="tv-title-text  mb-2 text-center"
              style={{ color: '#74737B' }}
            >
              {mode === 'deviation'
                ? deviationSuccessMessage()
                : assignmentDetails[0].isNewOrder
                ? ` Din första bokning är avbokad och en ny är skapad.
Ditt nya bokningsnummer är ${assignmentDetails[0].orderNumber}`
                : `Ditt ${mode === 'edit' ? '' : 'nya'} bokningsnummer är ${
                    assignmentDetails[0].orderNumber
                  } `}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column">
        <div className="tv-workassignment__detail-links ml-2" />
        <div
          className="tv-workassignment__detail-buttons tv-display-flex"
          style={{
            justifyContent:
              mode === 'assignment' || mode === 'edit'
                ? 'space-between'
                : 'center',
          }}
        >
          <TransvoiceButton
            type="secondary"
            className="tv-single-assign-form__button-preview"
            buttonType="button"
            text="Klar"
            onClick={onClose}
          />
          {(mode === 'assignment' || mode === 'edit') && (
            <TransvoiceButton
              type="line-primary"
              buttonType="button"
              className="w-50 ml-1"
              text="Fortsätt boka"
              onClick={e => {
                onContinueBooking();
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

SuccessMessage.propTypes = {
  mode: PropTypes.string,
  deviationNumber: PropTypes.number,
  onContinueBooking: PropTypes.func,
  onClose: PropTypes.func,
};
SuccessMessage.defaultProps = {
  mode: 'assignment',
  onContinueBooking: () => {},
  onClose: () => {},
  deviationNumber: null,
};

export default SuccessMessage;
