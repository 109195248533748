import * as actionTypes from '../actions/ActionTypes';
import { LOCAL_STORE_REASONS, LOCAL_STORE_COMPLAINT_REASONS } from '../../Share/constants';

const INITIAL_STATE = {
  reasonList: JSON.parse(localStorage.getItem(LOCAL_STORE_REASONS)) || [],
  complaintReasons: JSON.parse(localStorage.getItem(LOCAL_STORE_COMPLAINT_REASONS)) || [],
};

function localRemove() {
  localStorage.removeItem(LOCAL_STORE_REASONS);
}

function removeComplaintReasonsFromLocal() {
  localStorage.removeItem(LOCAL_STORE_COMPLAINT_REASONS);
}

const reason = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.RESET_APP:
      localRemove();
      removeComplaintReasonsFromLocal();
      return INITIAL_STATE;

    case actionTypes.UPDATE_REASON_LIST:
    {
      const { reasonList } = action;
      localStorage.setItem(LOCAL_STORE_REASONS, JSON.stringify(reasonList));
      return {
        ...state,
        reasonList,
      };
    }
    case actionTypes.UPDATE_COMPLAINT_REASON_LIST:
    {
      const { complaintReasons } = action;
      localStorage.setItem(LOCAL_STORE_COMPLAINT_REASONS, JSON.stringify(complaintReasons));
      return {
        ...state,
        complaintReasons,
      };
    }

    default:
      return state;
  }
};

export default reason;