import moment from 'moment';
import React from 'react';
import { useRef } from 'react';
import { Translate } from '../../../Share/components';
import { shouldDisplayscrollTop } from '../../../Share/utils';

const DeviationItem = ({ item, onClick, isSelected = false }) => {
  const itemRef = useRef(null);
  return (
    <div
      className={`tv-reportquality__list-item-parent tv-workassignment__list-item  tv-display-flex ${
        isSelected ? 'selected' : ''
      }`}
      onClick={() => {
        onClick(item);
      }}
      ref={itemRef}
    >
      <div className="row px-0 mx-0 w-100 tv-display-mobile-hide  align-items-center">
        <div className="col-12 col-md col-lg col-xl order-0">
          <div className="tv-workassignment__item-text">
            {item.ComplaintReasonDelivery}
          </div>
        </div>
        <div className="col-12  col-md col-lg col-xl order-1">
          <div className="tv-workassignment__item-text tv-workassignment__item-text-date ">
            {`${moment(item.DateDeviationArrived)
              .locale('sv')
              .format('DD MMMM')}`}
          </div>
        </div>
        <div className="col-12  col-md col-lg col-xl order-2">
          <div className="tv-workassignment__item-text">{item.OrderNumber}</div>
        </div>
        <div className="col-12  col-md col-lg col-xl order-4">
          <div className="tv-workassignment__item-text">
            {item.Responsible && item.Responsible.includes('Webbtjänst')
              ? ''
              : item.Responsible}
          </div>
        </div>
        <div className="col-12  col-md col-lg col-xl order-4">
          <div className="deviation-status-wrapper">
            <div
              className={`tv-reportquality__item-text tv-reportquality__item-status tv-reportquality__item-status--${item.DeviationStatusId}`}
            >
              {item.DeviationStatus}
            </div>
          </div>
        </div>
        {isSelected && shouldDisplayscrollTop(itemRef) && (
          <div
            className="position-absolute tv-workassignment__list-scrolltop bounce-4"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              document
                .querySelector('.tv-right-pannel-wrapper ')
                .scrollIntoView({
                  behavior: 'smooth',
                });
            }}
          >
            <i
              className="fas fa-arrow-alt-circle-up "
             title="visa detaljer"
            ></i>
          </div>
        )}
      </div>
      <div className="row px-0 mx-0 w-100 tv-display-mobile-show ">
        <div className="d-flex justify-content-between">
          <div className="tv-workassignment__detail-status text-capitalize mt-0">
            {item.OrderNumber}
          </div>
          <div className="tv-reportquality__item-text tv-reportquality__item-status">
            {item.DeviationStatus}
          </div>
        </div>
        <hr />
        <div className="tv-workassignment__item-text">
          <div className="tv-workassignment__item-text-label">
            <i className="fa fa-exclamation-triangle mr-1" />
            Orsak:
          </div>
          {item.ComplaintReasonDelivery}
        </div>
        <div className="tv-workassignment__item-text">
          <div className="tv-workassignment__item-text-label">
            <i className="fa fa-clock mr-1" />
            Skickad:
          </div>
          {`${moment(item.DateDeviationArrived)
            .locale('sv')
            .format('DD MMMM')}`}
        </div>
        <div className="tv-workassignment__item-text">
          <div className="tv-workassignment__item-text-label">
            <i className="fa fa-user mr-1" />
            Ansvarig hos Transvoice:{' '}
          </div>
          {item.Responsible && item.Responsible.includes('Webbtjänst')
            ? ''
            : item.Responsible}
        </div>
      </div>
    </div>
  );
};

export default DeviationItem;
