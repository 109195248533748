import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { LOCAL_STORE_LANGUAGE } from '../../constants/localStorage';
import { APP_PORTAL, MONTHS } from '../../constants';

let lang = localStorage.getItem(LOCAL_STORE_LANGUAGE);
switch (lang) {
  case 'en':
    lang = 'en';
    break;
  case 'se':
    lang = 'sv-SE';
    break;
  default:
    lang = 'sv-SE';
    break;
}

const propTypes = {
  date: PropTypes.shape({}),
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
  onNewOrderClick: PropTypes.func,
  children: PropTypes.node,
};

const defaultProps = {
  date: PropTypes.shape({}),
  onPrevious: PropTypes.func,
  onNewOrderClick: PropTypes.func,
  onNext: PropTypes.func,
  children: null,
};

const Header = ({ date, onPrevious, onNext, children, onNewOrderClick }) => {
  const { month, year } = date;
  moment.updateLocale('sv', { months: MONTHS });
  const currentMonth = moment().year(year).month(month).locale('sv');
  return (
    <div className="tk-monthly-calendar__header  tk-calendar__header">
      {APP_PORTAL === 'interpreter-portal' ? (
        <div
          className="tv-display-flex"
          style={{
            flexDirection: 'column',
            position: 'absolute',
            left: '5px',
            top: '5px',
            lineHeight: '1.2rem',
            fontSize: '0.675rem',
          }}
        >
          <div className="tv-display-flex">
            <span className="tk-monthly-calendar__item-availability-icon" />
            Tillgänglig
          </div>
          <div className="tv-display-flex">
            <span className="tk-monthly-calendar__item-unavailability-icon" />
            Ej tillgänglig
          </div>
        </div>
      ) : null}
      <div className="tk-calendar__title">
        <span>
          {currentMonth.format('MMMM')} {year}
        </span>
        <button
          onClick={onPrevious}
          className="tk-calendar__button tk-calendar__button--previous"
        />
        <button
          onClick={onNext}
          className="tk-calendar__button tk-calendar__button--next"
        />
      </div>
      {children}
    </div>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
