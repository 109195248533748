import React from 'react';
import PropTypes from 'prop-types';
import {
  TransvoiceTimePicker,
  TransvoiceDateRangePicker,
  Translate,
} from '../../../Share/components';
import { getNearest5MinutesInterval } from '../../../Share/utils/dateUtil';
import { breakTimes } from '../../../Share/constants';

const propTypes = {
  startHour: PropTypes.shape({}),
  endHour: PropTypes.shape({}),
  handleChangeDate: PropTypes.func,
  handleChangeHour: PropTypes.func,
  handleChange: PropTypes.func,
  breakTime: PropTypes.string,
  isDisabled: PropTypes.bool,
  key: PropTypes.number,
};

const defaultProps = {
  startHour: getNearest5MinutesInterval(),
  endHour: getNearest5MinutesInterval(),
  handleChangeDate: () => {},
  handleChangeHour: () => {},
  handleChange: () => {},
  breakTime: '0',
  isDisabled: false,
  key: 1,
};

const InterpretationTime = ({
  startHour,
  endHour,
  handleChangeHour,
  handleChangeDate,
  handleChange,
  breakTime,
  isDisabled,
  key,
}) => (
  <div className="timesheet__travel_time">
    <span className="timesheet__travel_time-title">
      <Translate content="timeSheet.interpretationTime" />
    </span>

    {isDisabled ? (
      <div className="row read-only-data">
        <div className="d-flex justify-content-between  ">
          <label> <Translate content="timesheet.startTime" /></label>
          <span>{ startHour.locale('sv').format("HH:mm, DD MMMM")}</span>
        </div>
        <div className="d-flex justify-content-between  ">
        <label> <Translate content="timesheet.endTime" /></label>
          <span>{ endHour.locale('sv').format("HH:mm, DD MMMM")}</span>
        </div>
        <div className="d-flex justify-content-between  ">
        <label>   <Translate content="timeSheet.breakTime" /></label>
          <span>{`${breakTime} minuter`}</span>
        </div>
      </div>
    ) : (
      <div className="row">
        <div className="col-12 ">
          <div className="row timesheet__travel_time-content">
            <div className="col-4 order-0 tv-single-assign-form__label mt-sm-2">
              <Translate content="timesheet.startTime" />
            </div>
            <div className="col-5 order-2 pr-0 mt-sm-2">
              <div className="tv-single-assign-form__input read-only">
                <TransvoiceDateRangePicker
                  ranges={{
                    startDate: startHour.locale('sv'),
                    endDate: startHour.locale('sv'),
                    key: 'selection',
                  }}
                  onChange={e =>
                    handleChangeDate(e, 'startDate', 'interpretationTime')
                  }
                  isDisabled={isDisabled}
                  enabledEarlierDates={true}
                  disabledWeeky={true}
                />
              </div>
            </div>
            <div className="col-3 mt-sm-2 order-1 ">
              <div className="tv-interperet-now__start-time-wrapper pdt0 read-only">
                <TransvoiceTimePicker
                  className="timesheet_time"
                  isDisabled={isDisabled}
                  defaultValue={startHour}
                  handleValueChange={e =>
                    handleChangeHour(e, 'start', 'interpretationTime')
                  }
                  key={key}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 ">
          <div className="row">
            <div className="col-4 order-0 pr-0 mt-sm-2 mt-lg-3">
              <div className="pl-0 col-9 tv-single-assign-form__label">
                <Translate content="timesheet.endTime" />
              </div>
            </div>
            <div className="col-5 order-2 pr-0 mt-sm-2">
              <div className="tv-single-assign-form__input read-only">
                <TransvoiceDateRangePicker
                  ranges={{
                    startDate: endHour.locale('sv'),
                    endDate: endHour.locale('sv'),
                    key: 'selection',
                  }}
                  onChange={e =>
                    handleChangeDate(e, 'endDate', 'interpretationTime')
                  }
                  isDisabled={isDisabled}
                  enabledEarlierDates={true}
                  disabledWeeky={true}
                />
              </div>
            </div>
            <div className="col-3 mt-sm-2 order-1 ">
              <div className="tv-interperet-now__start-time-wrapper pdt0 read-only">
                <TransvoiceTimePicker
                  isDisabled={isDisabled}
                  className="timesheet_time"
                  defaultValue={endHour}
                  handleValueChange={e =>
                    handleChangeHour(e, 'end', 'interpretationTime')
                  }
                  key={key}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 ">
          <div className="row">
            <div className="col-4 order-0 tv-single-assign-form__label mt-sm-2">
              <Translate content="timeSheet.breakTime" />
            </div>
            <div className="pl-0 col-3 mt-sm-2 order-1 read-only">
              <select
                className="tv-form__control tv-single-assign-form__dropdownlist tv-single-assign-form__control"
                disabled={isDisabled}
                onChange={e => handleChange(e, 'interpretationTime')}
                name="breakTime"
                value={breakTime}
              >
                {breakTimes.map(item => (
                  <option key={item.value} value={item.value}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    )}
  </div>
);

InterpretationTime.propTypes = propTypes;
InterpretationTime.defaultProps = defaultProps;

export default InterpretationTime;
