import React, { Component } from 'react';
import { connect } from 'react-redux';
import './style.scss';
import { InputText, Translate } from '../../../Share/components';
import { getCustomer } from '../../actions';

class YourProfile extends Component {
  componentDidMount() {
    this.props.onGetCustomer();
  }

  render() {
    const {
      customerName,
      customerNumber,
      phoneNumberDirect,
      customerAddress,
    } = this.props.profile;

    const avatarImg = (
      <img
        src="/images/default-avatar.jpg"
        className="tv-profile__avatar-img"
        alt="avatar"
      />
    );

    return (
      <div className="tv-profile tv-profile__wrapper">
        <div className="tv-profile__header-wrapper">
          <div className="tv-profile__avatar">{avatarImg}</div>
          <div className="tv-profile__name">{`${customerName}`}</div>
          <div className="tv-profile__client-number">
            <Translate content="yourProfile.clientNumber" />: {customerNumber}
          </div>
        </div>

        <div className="tv-profile__content-wrapper">
          <div className="tv-row">
            <div className="tv-col-12">
              <div className="tv-profile__input-label">
                <Translate content="yourProfile.clientName" />
              </div>
              <InputText
                className="tx-input-text--border"
                name="clientName"
                value={customerName}
                isDisabled
              />
            </div>
          </div>

          <div className="tv-row">
            <div className="tv-col-6">
              <div className="tv-profile__input-label">
                <Translate content="yourProfile.phoneNumber" />
              </div>
              <InputText
                className="tx-input-text--border"
                name="mobileNumber"
                value={phoneNumberDirect}
                isDisabled
              />
            </div>
            <div className="tv-col-6">
              <div className="tv-profile__input-label">
                <Translate content="yourProfile.city" />
              </div>
              <InputText
                className="tx-input-text--border"
                name="mobileNumber"
                value={customerAddress.City}
                isDisabled
              />
            </div>
          </div>

          <div className="tv-row">
            <div className="tv-col-8">
              <div className="tv-profile__input-label">
                <Translate content="yourProfile.address" />
              </div>
              <InputText
                className="tx-input-text--border"
                name="address"
                value={customerAddress.AddressRow1}
                isDisabled
              />
            </div>
            <div className="tv-col-4">
              <div className="tv-profile__input-label">
                <Translate content="yourProfile.postalCode" />
              </div>
              <InputText
                className="tx-input-text--border"
                name="postalCode"
                value={customerAddress.PostalCode}
                isDisabled
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profile: state.user.profile,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onGetCustomer: () => {
    dispatch(getCustomer());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(YourProfile);
