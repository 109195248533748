import React from "react";
import moment from "moment";
import PropTypes from "prop-types";

import EventItem from "./EventItem";
import IfComponent from "../IfComponent";
import Button from "../Button";
import Translate from "../Translate";
import { generateUniqueId } from "../../utils";
import history from "../../utils/history";

const propTypes = {
    currentDay: PropTypes.instanceOf(moment),
    events: PropTypes.arrayOf(PropTypes.shape({})),
    onClick: PropTypes.func,
    displayListItemContactPerson: PropTypes.bool,
    visiblePrefix: PropTypes.bool,
};

const defaultProps = {
    currentDay: moment(),
    events: [],
    onClick: () => {},
    displayListItemContactPerson: false,
    visiblePrefix: false,
};

const EventList = ({
    currentDay,
    events,
    onClick,
    displayListItemContactPerson,
    visiblePrefix
}) => {
    if (events.length === 0) {
        return null;
    }

    const ViewAllButtonClickHandler = () => {
        
        history.push({
            pathname: "/my-assignment",
            state: {
                isFromDashboard:true,
                from: moment(currentDay).startOf('day'),
                to:  moment(currentDay).endOf('day')
            },
        });
    }

    const dropDownScrollable = () => {
        return events.length>2?"tk-monthly-calendar__item_scrollable":"tk-monthly-calendar__item_list"
    }

    return (
        <div
            role="button"
            tabIndex="-1"
            className="tk-monthly-calendar__list-wrapper"
        >
            <div className="tk-monthly-calendar__list-date-month">
                <span className="tk-monthly-calendar__list-month">
                    {currentDay.format("MMMM")} {currentDay.format("DD")}
                </span>
            </div>
            <div className="tk-monthly-calendar__item_list">
                <div className={dropDownScrollable()}>
                {events.map((event, i) => (
                    <>
                        <IfComponent
                            condition={true}
                            whenTrue={
                                <EventItem
                                    key={generateUniqueId()}
                                    onClick={onClick}
                                    event={event}
                                    displayListItemContactPerson={
                                        displayListItemContactPerson
                                    }
                                    visiblePrefix={visiblePrefix}
                                />
                            }
                        />
                        
                    </>
                ))}
                </div>
            </div>
            <div className="tk-monthly-calendar__button">
                <IfComponent
                    condition={events.length>2}
                    whenTrue={
                        <Button onClick={ViewAllButtonClickHandler} className="tk-monthly-calendar__item-view-all-btn" Text={Translate({ content: "calendar.viewAll" })} />
                    }
                />
            </div>
        </div>
    );
};

EventList.propTypes = propTypes;
EventList.defaultProps = defaultProps;

export default EventList;
