import React from 'react';
import NotificationContainer from '../NotificationContainer';

// import './style.scss';

function NotificationPage() {
  return (
    <>
      <div className="d-flex w-100">
        <NotificationContainer isPage />
      </div>
    </>
  );
}

NotificationPage.propTypes = {};

export default NotificationPage;
