import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import MoreButton from './MoreButton';
import AvailabilityIdicator from './AvailabilityIndicator';
import EventItem from './EventItem';
import EventList from './EventList';
import Dialog from '../Dialog';
import IfComponent from '../IfComponent';
import Translate from '../Translate';
import { availabilityStatus, APP_PORTAL, ENV_CLIENT } from '../../constants';
import { checkIfWeekend } from '../../utils/dateUtil';

const propTypes = {
  currentDay: PropTypes.instanceOf(moment),
  currentMonth: PropTypes.instanceOf(moment),
  displayDay: PropTypes.string,
  weekNummberDisplay: PropTypes.string,
  displayDayInText: PropTypes.string,
  displayListItemContactPerson: PropTypes.bool,
  events: PropTypes.arrayOf(PropTypes.shape({})),
  availability: PropTypes.arrayOf(PropTypes.shape({})),
  onAssignmentSummuryPillClick: PropTypes.func,
  onDayClick: PropTypes.func,
  onClickAvailability: PropTypes.func,
  visiblePrefix: PropTypes.bool,
  highlightSummuryPill: PropTypes.bool,
  highlightDay: PropTypes.bool,
  onCreateOrder: PropTypes.func,
};

const defaultProps = {
  currentDay: moment(),
  currentMonth: moment(),
  displayDay: '',
  displayDayInText: '',
  highlightDay: false,
  highlightSummuryPill: false,
  weekNummberDisplay: '',
  displayListItemContactPerson: false,
  events: [],
  availability: [],
  onAssignmentSummuryPillClick: () => {},
  onDayClick: () => {},
  onClickAvailability: () => {},
  visiblePrefix: false,
  onCreateOrder: () => {},
};

const INITAL_STATES = {
  showDialog: false,
};

class ItemDay extends React.Component {
  state = INITAL_STATES;

  onDialogShow = event => {
    this.setState({ showDialog: true });
    event.stopPropagation();
  };

  onDialogClose = e => {
    this.setState({ showDialog: false });
    e.stopPropagation();
  };

  onBlur = event => {
    const { currentTarget } = event;
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        this.setState({ showDialog: false });
        event.persist();
      }
    }, 0);
  };

  render() {
    const {
      currentDay,
      displayDay,
      displayDayInText,
      currentMonth,
      highlightDay,
      highlightSummuryPill,
      events,
      onAssignmentSummuryPillClick,
      availability,
      onClickAvailability,
      weekNummberDisplay,
      onDayClick,
      onCreateOrder,
      displayListItemContactPerson,
      visiblePrefix,
      onCreateDirectBooking,
    } = this.props;
    const isClientPortal = APP_PORTAL === ENV_CLIENT;
    const { showDialog, selectedDay } = this.state;
    const isToday = currentDay.isSame(moment(), 'day');
    const todayClass = isToday ? 'today' : '';
    const thisMonthClass = currentDay.isSame(currentMonth, 'month')
      ? 'this-month'
      : 'this-month';
    const futureDay =
      currentDay.startOf('day').diff(moment().startOf('day'), 'day') >= 1;
    const unavailabilities = availability.filter(
      item => item.articleType === availabilityStatus.UNAVAILABLE,
    );
    const filteredAvailabilities = availability.filter(
      item => item.articleType === availabilityStatus.AVAILABLE,
    );
    const noEventClass = events.length
      ? ''
      : 'tk-monthly-calendar__item-no_event';
    const highlightDayClass = highlightDay ? 'highlight-day' : '';
    const highlightSummuryPillClass = highlightSummuryPill
      ? 'highlight-pill'
      : '';
    return (
      <td
        tabIndex="-1"
        onBlur={this.onBlur}
        className={`${todayClass} ${thisMonthClass} ${highlightDayClass} ${highlightSummuryPillClass} tk-monthly-calendar__table-item ${noEventClass} ${
          checkIfWeekend(currentDay) && 'weekend-background'
        }`}
        onClick={e => onDayClick(e, currentDay, events)}
        onKeyPress={e => onDayClick(e, currentDay, events)}
      >
        <div className="tk-monthly-calendar__item-number">
          <span className="tk-monthly-calendar__item-text">
            {currentDay.locale('sv').format('dddd, ')}
          </span>
          {displayDay}
          <span className="tk-monthly-calendar__item-text">
            {currentDay.locale('sv').format(' MMMM')}
          </span>
        </div>
        {weekNummberDisplay != '' && (
          <div className="tv-display-mobile-hide tk-monthly-calendar__table-item-week-number">
            {weekNummberDisplay}
          </div>
        )}
        <IfComponent
          condition={events.length > 0 && isClientPortal}
          whenTrue={
            <div
              className="tk-monthly-calendar__item__header"
              role="button"
              tabIndex={0}
              onKeyPress={() => {}}
              onClick={e => {
                e.stopPropagation();
                onAssignmentSummuryPillClick(events, currentDay);
              }}
            >
              <div className="tk-monthly-calendar__item-count ">
                {`${events.length}  ${Translate({
                  content: 'calendar.assignments',
                })}`}
              </div>
              <div className="tk-monthly-calendar__item-notification">
                {events.filter(
                  item => item.assignment?.UnreadNotifications != null,
                ).length !== 0 && <i className="fa fa-bell" />}
              </div>
            </div>
          }
          whenFalse={
            <div className="tk-monthly-calendar__item-empty">
              {Translate({ content: 'calendar.no_assignments' })}
            </div>
          }
        />
        <IfComponent
          condition={futureDay && isClientPortal}
          whenTrue={
            <div
              className="tk-monthly-calendar__item-create-order tv-display-mobile-hide"
              onClick={e => {
                e.stopPropagation();
                onCreateOrder(currentDay);
              }}
            >
              {' '}
              <i className="fa fa-plus mr-2"></i>
              {'  '}
              {Translate({
                content: 'calendar.addNew',
              })}
            </div>
          }
        />
        <IfComponent
          condition={isToday && isClientPortal}
          whenTrue={
            <div
              className="tk-monthly-calendar__item-create-order tv-display-mobile-hide"
              onClick={e => {
                e.stopPropagation();
                onCreateDirectBooking();
              }}
            >
              {' '}
              <i className="fa fa-plus mr-2"></i>
              {'  '}
              {Translate({
                content: 'sideBar.bookNow',
              })}
            </div>
          }
        />
        <AvailabilityIdicator
          onClick={e =>
            onClickAvailability(e, currentDay, availabilityStatus.UNAVAILABLE)
          }
          availability={unavailabilities.length}
          className="tk-monthly-calendar__item-unavailability-btn"
          type={availabilityStatus.UNAVAILABLE}
        />
        <AvailabilityIdicator
          onClick={e =>
            onClickAvailability(e, currentDay, availabilityStatus.AVAILABLE)
          }
          availability={filteredAvailabilities.length}
          className="tk-monthly-calendar__item-availability-btn"
          type={availabilityStatus.AVAILABLE}
        />
      </td>
    );
  }
}

ItemDay.propTypes = propTypes;
ItemDay.defaultProps = defaultProps;

export default ItemDay;
