import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { generateUniqueId } from '../../utils';
import './style.scss';
import moment from 'moment';
import ReactDom from 'react-dom';
import classNames from 'classnames';
import raf from 'raf';
import { getXTimefromSpecificDate } from '../../../Share/utils/dateUtil';

const scrollTo = (element, to, duration) => {
  // jump to target if duration zero
  if (duration <= 0) {
    raf(() => {
      element.scrollTop = to;
    });
    return;
  }
  const difference = to - element.scrollTop;
  const perTick = (difference / duration) * 10;

  raf(() => {
    element.scrollTop += perTick;
    if (element.scrollTop === to) return;
    scrollTo(element, to, duration - 10);
  });
};
function generateDuration(startTime, endTime, showOnlyDuration) {
  const duration = moment.duration(endTime.diff(startTime));

  // duration in hours
  const hours = parseInt(duration.asHours());

  // duration in minutes
  const minutes = parseInt(duration.asMinutes()) % 60;

  if (showOnlyDuration) {
    return `${hours === 0 ? '' : `${hours}h `}${
      minutes === 0 ? '' : `${minutes}m`
    }`;
  }

  return `(${hours === 0 ? '' : `${hours}h `}${
    minutes === 0 ? '' : `${minutes}m`
  })`;
}

function generateTimeList(
  allowNextDay,
  startFromDayBegin,
  initalTime,
  steps,
  isNotRanged,
  baseTime,
  showOnlyDuration,
  rangeEndTime = '',
) {
  const arr = [];

  const start = moment();
  const remainder = start.minute() % steps;
  const dateTime = moment(start).subtract(remainder, 'minutes');

  let currentTime = isNotRanged ? moment(dateTime) : moment(baseTime);

  if (startFromDayBegin) {
    currentTime = moment().hours(0).minutes(0).seconds(0);
  }
  const endTime = rangeEndTime || moment().hours(23).minutes(59).seconds(59);
  let i = 0;
  const timeDifference = moment.duration(
    moment(endTime).diff(moment(currentTime)),
  );
  const diff = parseInt(timeDifference.asHours() * 2, 10);

  while (allowNextDay ? i <= 24 * (60 / steps) - 1 : i < diff) {
    if (startFromDayBegin && i == 0) {
      arr.push({
        id: i,
        value: currentTime.format('HH:mm'),
        duration: generateDuration(baseTime, currentTime, showOnlyDuration),
        stringValue: JSON.stringify({
          id: i,
          value: currentTime.format('HH:mm'),
        }),
      });
    }

    arr.push({
      id: i,
      value: currentTime.format('HH:mm'),
      duration: generateDuration(baseTime, currentTime),
      stringValue: JSON.stringify({
        id: i,
        value: currentTime.format('HH:mm'),
      }),
    });

    currentTime = moment(currentTime).add(steps, 'minute');
    i += 1;
  }

  return arr;
}

function isValidFormat(timeString) {
  if (!timeString.includes(':')) return false;
  const timeParts = timeString.split(':');
  if (timeParts[0] < 0 || timeParts[0] > 23) return false;
  if (timeParts[1] < 0 || timeParts[1] > 59) return false;
  return true;
}

function setValidFormat(timeString) {
  try {
    if (timeString.includes(':')) {
      const resetValue = [];
      const timeParts = timeString.split(':');
      if (timeParts[0] < 0) resetValue.push('00');
      else if (timeParts[0] > 23) resetValue.push('23');
      else resetValue.push(timeParts[0]);
      if (timeParts[1] < 0) resetValue.push('00');
      else if (timeParts[1] > 59) resetValue.push('59');
      else resetValue.push(timeParts[1]);
      if (resetValue[0].length === 1) resetValue[0] = `0${resetValue[0]}`;
      if (resetValue[1].length === 1) resetValue[1] = `0${resetValue[1]}`;
      return resetValue.join(':');
    } else if (timeString.length > 2 || timeString.length === 2) {
      if (timeString.length == 3) {
        timeString = `0${timeString}`;
      }
      return setValidFormat(
        `${timeString.substring(0, 2)}:${
          timeString.length > 2 ? timeString.substring(2) : '00'
        }`,
      );
    }
    return setValidFormat(`0${timeString[0]}:00`);
  } catch (ex) {
    return '12:00';
  }
}

const TimePicker = props => {
  const [dropDownList, setDropDownList] = useState(
    generateTimeList(
      props.allowNextDay,
      props.startFromDayBegin,
      props.defaultValue,
      props.minuteStep,
      props.isNotRanged,
      props.durationCalculate,
      props.showOnlyDuration,
      props.rangeEndTime,
    ),
  );

  const [selectedValue, setSelectedValue] = useState(dropDownList[0]);

  const inputRef = useRef(null);
  const dropDownRef = useRef(null);

  const onInputValueChange = () => {
    const [hours, minutes] = inputRef.current.value.split(':');
    const timeValue = moment(props.defaultValue)
      .set('hour', hours)
      .set('minute', minutes.substring(0, 2));

    props.onChange(timeValue);
  };
  const onSelectChange = e => {
    try {
      inputRef.current.value = e.target.innerText;
      setSelectedValue(
        JSON.parse(e.target.getAttribute('data-dropdown-value')),
      );
      e.target.parentNode.classList.remove('active');
      onInputValueChange();
      inputRef.current.focus();
    } catch (ex) {}
  };

  const scrollToSelected = (duration, dropDownElement) => {
    const select = dropDownElement;
    const list = dropDownElement;
    if (!list) {
      return;
    }
    if (!dropDownList.length) {
      return;
    }

    let index =
      dropDownList.filter(x =>
        x.value.includes(inputRef.current.value.split(':')[0] || '00'),
      )[0]?.id || selectedValue.id;
    if (index < 0) {
      index = 0;
    }
    const topOption = dropDownElement.children[index];
    const to = topOption.offsetTop;
    scrollTo(select, to, duration);
  };

  const onInputBlur = e => {
    const inputDisplayValue = isValidFormat(e.target.value)
      ? e.target.value
      : setValidFormat(e.target.value);

    const oldValue = props.defaultValue.format('HH:mm');

    if (inputDisplayValue !== oldValue) {
      inputRef.current.value = inputDisplayValue;
      onInputValueChange();
    }
  };

  const openDropDown = e => {
    const dropDownElement = e.target.parentElement;
    try {
      if (!dropDownElement.classList.contains('active')) {
        dropDownElement.classList.add('active');
        scrollToSelected(120, dropDownElement.querySelector('ul'));
      } else {
        dropDownElement.classList.remove('active');
      }
    } catch (ex) {}
  };

  const scrollList = e => {
    const dropDownElement = e.target.parentElement;
    dropDownElement.classList.add('active');
    scrollToSelected(120, dropDownElement.querySelector('ul'));
  };

  useEffect(() => {
    inputRef.current.value = moment(props.defaultValue).format('HH:mm');
  }, [props.defaultValue]);

  useEffect(() => {
    setDropDownList(
      generateTimeList(
        props.allowNextDay,
        props.startFromDayBegin,
        props.defaultValue,
        props.minuteStep,
        props.isNotRanged,
        props.durationCalculate,
        props.showOnlyDuration,
        props.rangeEndTime,
      ),
    );
  }, [props.defaultValue, props.durationCalculate]);

  const onBlur = event => {
    const { currentTarget } = event;
    setTimeout(() => {
      if (currentTarget.classList.contains('active')) {
        currentTarget.classList.remove('active');
      }
    }, 250);
  };
  return (
    <div
      className={`dropdown tv-timepicker ${props.className}`}
      onBlur={onBlur}
    >
      <input
        type="text"
        className="rc-time-picker-input"
        ref={inputRef}
        onBlur={onInputBlur}
        onFocus={openDropDown}
        onInput={scrollList}
        maxLength="5"
        minLength="5"
      />
      <span className="rc-time-picker-select-option" onClick={openDropDown} />
      <ul ref={dropDownRef} className="panel">
        {dropDownList.map(x => (
          <li
            key={x.stringValue}
            onClick={onSelectChange}
            data-dropdown-value={x.stringValue}
          >
            {`${props.showOnlyDuration ? '' : x.value} ${'  '} ${
              !props.isNotRanged ? x.duration : ''
            }`}
          </li>
        ))}
      </ul>
    </div>
  );
};

TimePicker.propTypes = {
  defaultValue: PropTypes.shape({}),
  disabled: PropTypes.bool,
  className: PropTypes.string,
  minuteStep: PropTypes.number,
  onChange: PropTypes.func,
  isNotRanged: PropTypes.bool,
  startFromDayBegin: PropTypes.bool,
  allowNextDay: PropTypes.bool,
  showOnlyDuration: PropTypes.bool,
};

TimePicker.defaultProps = {
  defaultValue: new Date(),
  disabled: false,
  isNotRanged: true,
  className: '',
  minuteStep: 1,
  allowNextDay: false,
  startFromDayBegin: false,
  showOnlyDuration: false,
  onChange: () => {},
};

export default TimePicker;
