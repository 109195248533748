/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { useState, useRef } from 'react';
import PropTypes, { bool } from 'prop-types';
import { ValidatorForm } from 'react-form-validator-core';
import { isEqual } from 'lodash/core';

import './style.scss';
import { generateUniqueId } from '../../utils';
import { isIE } from '../../../Share/utils/';
import { Translate } from '..';
import { subscribe } from '../../../ClientPortal/actions/eventBus';
import TransvoiceCheckbox from '../TransvoiceCheckbox';
import history from '../../../Share/utils/history';
import { DEFAULT_EMPTY_NAME, DEFAULT_NULL_VALUE } from '../../constants';
import { IfComponent, TextField, Modal } from '../../../Share/components';

const propTypes = {
  handleSelectRate: PropTypes.func,
  onRateChange: PropTypes.func,
  className: PropTypes.string,
  commentBoxClass: PropTypes.string,
  ratingClassName: PropTypes.string,
  orderIdentifier: PropTypes.string,
  rate: PropTypes.number,
  comment: PropTypes.string,
  isDisabled: PropTypes.bool,
  display: PropTypes.bool,
  displaySendButton: bool,
  displayCommentBox: PropTypes.bool,
  redirectToReportQuality: PropTypes.bool,
  assignment: PropTypes.object,
  showRedirectCheckBox: PropTypes.bool,
  ratingReasons: PropTypes.array,
  ratingReason: PropTypes.string,
  children: PropTypes.node,
  DisplayRateValue: PropTypes.bool,
  displayTextCount: PropTypes.bool,
};

const defaultProps = {
  handleSelectRate: () => {},
  orderIdentifier: '',
  onRateChange: () => {},
  className: '',
  commentBoxClass: '',
  ratingClassName: '',
  children: null,
  rate: 0,
  comment: '',
  isDisabled: false,
  display: true,
  displaySendButton: true,
  displayCommentBox: true,
  redirectToReportQuality: false,
  assignment: {},
  showRedirectCheckBox: false,
  ratingReasons: [],
  ratingReason: null,
  DisplayRateValue: true,
  displayTextCount: false,
};

const votes = [5, 4, 3, 2, 1];

const rateColors = {
  0: '',
  1: '#ff9b4e',
  2: '#ff9b4e',
  3: '#ff9b4e',
  4: '#ff9b4e',
  5: '#ff9b4e',
};

const defaultFirstValue = {
  name: 'Välj',
  value: '',
};

const Rating = ({
  handleSelectRate,
  className,
  ratingClassName,
  commentBoxClass,
  containerClassName,
  rate,
  isDisabled,
  children,
  displaySendButton,
  orderIdentifier,
  onRateChange,
  comment,
  displayCommentBox,
  redirectToReportQuality,
  assignment,
  showRedirectCheckBox,
  ratingReasons,
  ratingReason,
  onCreateDeviation,
}) => {
  const ref = useRef();
  const [rateValue, setRate] = useState(rate || 0);
  const [isRated, setIsRated] = useState(isDisabled);
  const [commentValue, setComment] = useState(comment);

  const [ratingReasonValue, setRatingReason] = useState(ratingReason);

  const [redirectToReportQualityValue, setRedirectToReportQuality] = useState(
    redirectToReportQuality,
  );

  if (!isEqual(ratingReasons[0], defaultFirstValue)) {
    ratingReasons.unshift(defaultFirstValue);
  }

  subscribe('orderRatefailure', _orderIdentifier => {
    if (_orderIdentifier === orderIdentifier) {
      setIsRated(false);
      setRate(0);
      onRateChange({ rate: 0 });
    }
  });

  const handleCreateReportQuality = () => {
    if (redirectToReportQualityValue) {
      const data = {
        ComplaintReasonDeliveryIdentifier: ratingReasonValue,
        DeviationDescription: commentValue,
        ContactPersonIdentifier:
          assignment.ContactPerson.ContactPersonIdentifier,
        OrderIdentifier: assignment.OrderIdentifier,
      };
      onCreateDeviation(data, () => {});
    }
  };

  const resetComent = rateVal => {};

  const getValuefromList = (filterText, list) => {
    if (filterText == null) return null;
    const filteredItems = list.filter(x => x.value === filterText);
    return filteredItems ? filteredItems[0] : null;
  };

  return (
    <>
      <ValidatorForm
        className={`tv-reportquality-form ${containerClassName}`}
        onSubmit={() => {
          handleSelectRate(rateValue, commentValue, ratingReasonValue);
          setIsRated(true);
          handleCreateReportQuality();
        }}
      >
        <div className={ratingClassName}>
          {children}
          <div className={`${className} `}>
            <h6>Betygsätt</h6>
            <div className=" d-flex flex-lg-nowrap flex-wrap justify-content-between">
              <div className={`rating  ${rateColors[rateValue]}`}>
                {votes.map((item, i) => {
                  const uniqueId = `${generateUniqueId()}${i.toString()}`;
                  return (
                    <React.Fragment key={uniqueId}>
                      <input
                        defaultChecked={item === rateValue}
                        key={`${uniqueId}-radio`}
                        id={`${uniqueId}-radio`}
                        data-hightlight={item === rateValue}
                        disabled={isRated}
                        onClick={() => {
                          resetComent(item);
                          onRateChange({
                            rate: item,
                            comment: item > 2 ? null : commentValue,
                          });
                          setRate(item);
                        }}
                        type="radio"
                        name="rating"
                        value={item}
                      />
                      <label
                        className={`full ${rateColors[item]}`}
                        htmlFor={`${uniqueId}-radio`}
                        key={`${uniqueId}-lable`}
                      />
                    </React.Fragment>
                  );
                })}
              </div>
              {displaySendButton &&
              !isRated &&
              !(rateValue <= 2 && rateValue > 0) ? (
                <button
                  type="submit"
                  className="tv-rate-submit-btn tv-buttons__element tv-buttons__element--primary"
                >
                  <Translate content="timesheet.submit" />
                </button>
              ) : (
                <div style={{ minWidth: '100px', marginLeft: '32px' }} />
              )}
            </div>
          </div>
        </div>

        {displayCommentBox ? (
          !isRated && rateValue > 0 ? (
            <div className={commentBoxClass}>
              <span className="rating-label">
                <Translate content="reason.writeAComment" />
              </span>
              <TextField
                multiline
                className={
                  ('tv-form__control',
                  'tv-single-assign-form__textfield',
                  'tv-single-assign-form__textarea',
                  'tv-single-assign-form__control')
                }
                placeholder={Translate({
                  content: 'timesheet.descripeYourRating',
                })}
                cols="10"
                rows="3"
                onChange={event => {
                  setComment(event.target.value);
                  onRateChange({
                    rate: rateValue,
                    comment: commentValue,
                  });
                }}
                value={commentValue == null ? '' : commentValue}
                maxLength="1000"
                validators={redirectToReportQualityValue ? ['required'] : []}
                errorMessages={[
                  Translate({
                    content: 'validator.isRequired',
                    params: {
                      name: Translate({
                        content: 'reason.reasonForAddedRating',
                      }),
                    },
                  }),
                ]}
              />
            </div>
          ) : commentValue !== null ? (
            <div className="comment-text icon-comment-2 col-12">
              {commentValue}
            </div>
          ) : (
            <div />
          )
        ) : (
          <div />
        )}

        <div className="footer-wrapper">
          {showRedirectCheckBox &&
          !isRated &&
          rateValue <= 2 &&
          rateValue > 0 ? (
            <div
              onClick={() =>
                handleSelectRate(
                  rateValue,
                  commentValue,
                  ratingReasonValue,
                  true,
                )
              }
              className="feedback-create-deviation"
            >
              {Translate({
                content: 'reportQuality.isADeviation',
              })}
            </div>
          ) : (
            ''
          )}
          {displaySendButton && !isRated && rateValue <= 2 && rateValue > 0 ? (
            <button
              type="submit"
              className="tv-rate-submit-btn tv-buttons__element tv-buttons__element--primary send-feedback-btn"
            >
              <Translate content="reason.submit" />
            </button>
          ) : null}
        </div>
      </ValidatorForm>
    </>
  );
};
Rating.propTypes = propTypes;
Rating.defaultProps = defaultProps;

export default Rating;
