import React from 'react';
import PropTypes from 'prop-types';
import ToggleButton from '../ToggleButton';

const propTypes = {
  identifier: PropTypes.string,
  className: PropTypes.string,
  content: PropTypes.string,
  callback: PropTypes.func,
  isSelected: PropTypes.bool,
};
const defaultProps = {
  identifier: '',
  className: 'tv-cancelassignment__reasons-list-item',
  content: '',
  callback: () => {},
  isSelected: false,
};
class ReasonItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectItem: this.props.isSelected,
    };
  }

  handleToggleReasonItem = (identifier) => {
    const value = !this.state.selectItem;
    this.setState({
      selectItem: value,
    });
    this.props.callback(identifier, value);
  }

  render() {
    const {
      identifier, content, className, isSelected,
    } = this.props;
    return (
      <ToggleButton
        isSelected={isSelected}
        handleClick={() => { this.handleToggleReasonItem(identifier); }}
        text={content}
        className={className}
      />

    );
  }
}

ReasonItem.propTypes = propTypes;
ReasonItem.defaultProps = defaultProps;

export default ReasonItem;
