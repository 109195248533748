import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  generateDefaultSession,
  Capitalize,
  assignmentTimeFormat,
  getDateFormated,
} from '../../../Share/utils';
import { GLOBAL_DATE_FORMAT } from '../../../Share/constants';
import { cloneDeep } from 'lodash';
import FileUpload from './FileUpload';

import {
  Translate,
  IfComponent,
  TransvoiceButton,
  RouteLeavingGuard,
} from '../../../Share/components';
import history from '../../../Share/utils/history';
import { convertStringToDate } from '../../../Share/utils/dateUtil';
import './style.scss';

const propTypes = {
  onChange: PropTypes.func,
  certificationLevels: PropTypes.string,
  yourOrderNumber: PropTypes.string,
  yourReferenceNumber: PropTypes.string,
  typeOfAssignment: PropTypes.string,
  addressLine: PropTypes.string,
  district: PropTypes.string,
  city: PropTypes.string,
  postalCode: PropTypes.string,
  language: PropTypes.string,
  genderValue: PropTypes.string,
  contactPerson: PropTypes.string,
  additionalRequirements: PropTypes.string,
  contactEmail: PropTypes.string,
  contactDirectNumber: PropTypes.string,
  assignmentDescription: PropTypes.string,
  orderContactPerson: PropTypes.string,
  orderContactNumber: PropTypes.string,
  orderContactDirectNumber: PropTypes.string,
  orderEmail: PropTypes.string,
  isSameAbove: PropTypes.bool,
  dateRange: PropTypes.shape({}),
  isAllowLowerLevel: PropTypes.bool,
  languages: PropTypes.arrayOf(PropTypes.shape({})),
  services: PropTypes.arrayOf(PropTypes.shape({})),
  contactPersons: PropTypes.arrayOf(PropTypes.shape({})),
  files: PropTypes.arrayOf(PropTypes.shape({})),
  assignmentSubskill: PropTypes.string,
  requestedInterpreter: PropTypes.string,
  showEditButton: PropTypes.bool,
  for: PropTypes.string,
  isDirty: PropTypes.bool,
  showBasicPreview: PropTypes.bool,
};

const defaultProps = {
  onChange: () => {},
  certificationLevels: '',
  yourOrderNumber: '',
  yourReferenceNumber: '',
  typeOfAssignment: '',
  addressLine: '',
  district: '',
  city: '',
  postalCode: '',
  language: '',
  genderValue: 0,
  contactPerson: '',
  additionalRequirements: '',
  contactEmail: '',
  contactDirectNumber: '',
  assignmentDescription: '',
  orderContactPerson: '',
  orderContactNumber: '',
  orderContactDirectNumber: '',
  orderEmail: '',
  isSameAbove: false,
  dateRange: {},
  isAllowLowerLevel: false,
  languages: [],
  services: [],
  contactPersons: [],
  files: [],
  assignmentSubskill: '',
  requestedInterpreter: '',
  showEditButton: false,
  for: 'single',
  isDirty: true,
  showBasicPreview: false,
};

class SingleAssignmentFormPreview extends Component {
  state = {};

  handleChange = event => {
    event.persist();
    this.props.onChange({ [event.target.name]: event.target.value });
  };

  handleSessionChange = (e, key, i) => {
    const { dateRange } = this.props;
    dateRange[key] = e;
    this.props.onChange({ dateRange });
  };

  handleSessionDateChange = (e, i) => {
    const { dateRange } = this.props;
    const { key } = dateRange.ranges;
    if (key && e[key]) {
      const { startDate, endDate } = e[key];
      if (startDate && endDate) {
        this.handleSessionChange(
          {
            startDate: moment(startDate),
            endDate: moment(endDate),
            key,
          },
          'ranges',
        );
      }
    }
  };

  handleFileDrop = file => {
    if (file) {
      const { files } = this.props;
      files.push(file);
      this.props.onChange({ file });
    }
  };

  handleFileRemove = i => {
    if (i > -1) {
      const { files } = this.props;
      files.splice(i, 1);
      this.props.onChange({ files });
    }
  };
  shouldAdditionalDatadisplay = (
    previewCerLevel,
    genderString,
    requestedInterpreter,
  ) =>
    (previewCerLevel !== '' &&
      previewCerLevel !== Translate({ content: 'general.none' })) ||
    (genderString !== '' &&
      genderString !== Translate({ content: 'general.none' })) ||
    requestedInterpreter !== '';
  iconForTheServiceType = serviceName => {
    let iconClass = 'map-marker';
    if (serviceName.includes('Tele')) {
      iconClass = 'phone-square';
    }
    if (serviceName.includes('Kont')) {
      iconClass = 'map-marker';
    }
    if (serviceName.includes('Video')) {
      iconClass = 'video';
    }
    if (serviceName.includes('Skype')) {
      iconClass = 'skype';
    }
    return iconClass;
  };
  previewOrderTime = sess => {
    let session = cloneDeep(sess);

    const beginningTime = moment({
      h: session.startTime.hours(),
      m: session.startTime.minutes(),
    });
    const endTime = moment({
      h: session.endTime.hours(),
      m: session.endTime.minutes(),
    });
    session.ranges.startDate = session.ranges.startDate.clone();
    session.ranges.endDate = session.ranges.endDate.clone();
    if (
      (beginningTime.isAfter(endTime) || endTime.isSame(beginningTime)) &&
      session.ranges.endDate.isSame(session.ranges.startDate, 'day')
    ) {
      session.ranges.endDate.add(1, 'day');
    }
    const startDate = getDateFormated(
      convertStringToDate(session.ranges.startDate),
      'DD MMMM',
    );
    const endDate = getDateFormated(
      convertStringToDate(session.ranges.endDate),
      'DD MMMM',
    );
    const startTimeStr = moment(session.startTime).format('HH:mm');
    const endTimeStr = moment(session.endTime).format('HH:mm');
    let orderDateTimeString = `${startTimeStr} - ${endTimeStr}, ${startDate}`;

    if (startDate !== endDate) {
      orderDateTimeString = `${startTimeStr}, ${startDate} - ${endTimeStr}, ${endDate}`;
    }
    return orderDateTimeString;
  };
  render() {
    const {
      dateRange,
      certificationLevels,
      yourReferenceNumber,
      typeOfAssignment,
      addressLine,
      district,
      city,
      postalCode,
      genderValue,
      contactPerson,
      additionalRequirements,
      contactEmail,
      contactDirectNumber,
      assignmentDescription,
      orderContactNumber,
      orderContactPerson,
      orderEmail,
      orderContactDirectNumber,
      languages,
      language,
      isSameAbove,
      files,
      isAllowLowerLevel,
      isAllowOnlyLevel,
      services,
      contactPersons,
      assignmentSubskill,
      requestedInterpreter,
      requestedInterpreterPreferedLevel,
      showEditButton,
      showBasicPreview,
      meetingLink,
      phoneNumber,
      genderRequirement,
      meetingPassCode,
      isBankIDRequired,
      meetingPlatform,
      alternativeLanguage,
      clientTelephoneNo,
    } = this.props;
    const filterService = services.filter(
      obj => obj.ServiceIdentifier === typeOfAssignment.value,
    )[0];
    const previewTypeOfAssignment = typeOfAssignment?.value
      ? typeOfAssignment.name
      : Translate({ content: 'general.none' });

    const filterCompetence =
      filterService && filterService.AvailableCompetences
        ? filterService.AvailableCompetences.filter(
            obj => obj.CompetenceLevelIdentifier === certificationLevels.value,
          )[0]
        : null;
    const previewCerLevel = filterCompetence
      ? filterCompetence.CompetenceLevelName
      : Translate({ content: 'general.none' });

    const filterContact = contactPersons.filter(
      obj => obj.value === contactPerson,
    )[0];
    const previewContact = filterContact
      ? filterContact.name
      : Translate({ content: 'general.none' });

    const filterContactOrder = contactPersons.filter(
      obj => obj.value === orderContactPerson,
    )[0];
    const previewContactOrder = filterContactOrder
      ? filterContactOrder.name
      : Translate({ content: 'general.none' });

    let genderString = Translate({ content: 'general.none' });
    if (genderValue?.value === '1') {
      genderString = Translate({ content: 'general.male' });
    } else if (genderValue?.value === '2') {
      genderString = Translate({ content: 'general.female' });
    }
    let requestedInterpreterPreferedLevelString = 'Önskemål';
    if (requestedInterpreterPreferedLevel == 2) {
      requestedInterpreterPreferedLevelString = 'Önskemål';
    } else if (requestedInterpreterPreferedLevel == 1) {
      requestedInterpreterPreferedLevelString = 'Krävs';
    } else if (requestedInterpreterPreferedLevel == 3) {
      requestedInterpreterPreferedLevelString = 'Vidtalad';
    }
    const { isDirty } = this.props;

    let competencePreference = '';
    if (isAllowLowerLevel && !isAllowOnlyLevel)
      competencePreference = 'Krav på lägsta nivå';
    if (!isAllowLowerLevel && isAllowOnlyLevel) competencePreference = 'Endast';
    if (!isAllowLowerLevel && !isAllowOnlyLevel)
      competencePreference = Translate({
        content: 'singleAssignment.notARequirment',
      });

    const calculateEndTime = function (sess) {};
    return (
      <React.Fragment>
        <div className="d-flex flex-column pr-3">
          <div className="tv-single-assign-form__preview-sub-container">
            <div className="tv-single-assign-form__preview-headings">
              <Translate content="singleAssignment.bookingInformation" />
            </div>
            <div className="w-100 mt-2">
              <InformationItem
                label="Datum"
                content={this.previewOrderTime(dateRange)}
              />
              <InformationItem
                label={Translate({
                  content: 'report.skill',
                })}
                content={Capitalize(
                  language?.value
                    ? language.name
                    : Translate({
                        content: 'general.none',
                      }),
                )}
              />
              {/* TODO: Change */}
              {alternativeLanguage.value && (
                <InformationItem
                  label="Alternativt språk"
                  content={Capitalize(
                    alternativeLanguage?.value
                      ? alternativeLanguage.name
                      : Translate({
                          content: 'general.none',
                        }),
                  )}
                />
              )}
              <InformationItem
                label="Bokning"
                content={previewTypeOfAssignment}
              />
              {!filterService?.IsPerformedRemotely && (
                <InformationItem
                  label={Translate({
                    content: 'singleAssignment.location',
                  })}
                  content={`${addressLine}, ${postalCode} ${city} ${district}`}
                />
              )}
              {meetingLink && (
                <InformationItem
                  label={Translate({
                    content: 'singleAssignment.videoLink',
                  })}
                  content={meetingLink}
                />
              )}
              {meetingPlatform.value !== null && (
                <InformationItem
                  label={Translate({
                    content: 'singleAssignment.platform',
                  })}
                  content={meetingPlatform.name}
                />
              )}
              {phoneNumber && (
                <InformationItem
                  label={Translate({
                    content: 'singleAssignment.telephoneNumber',
                  })}
                  content={phoneNumber}
                />
              )}
              {meetingPassCode && (
                <InformationItem
                  label={Translate({
                    content: 'singleAssignment.passCodeHeader',
                  })}
                  content={meetingPassCode}
                />
              )}
              {previewTypeOfAssignment.includes('video') &&
                isBankIDRequired != null && (
                  <InformationItem
                    label={Translate({
                      content: 'taskDetail.bankId',
                    })}
                    content={isBankIDRequired ? 'Ja' : 'Nei'}
                  />
                )}
              <InformationItem
                label={Translate({
                  content: 'singleAssignment.contactPerson',
                })}
                content={previewContact}
              />
              <InformationItem
                label={Translate({
                  content: 'singleAssignment.contactOrderPerson',
                })}
                content={previewContactOrder || previewContact}
              />
              {clientTelephoneNo !== '' && (
                <InformationItem
                  label={Translate({
                    content: 'messageService.telephoneNo',
                  })}
                  content={clientTelephoneNo}
                />
              )}
            </div>
          </div>
          {((genderString != '' &&
            Translate({ content: 'general.none' }) !== genderString) ||
            (previewCerLevel != '' &&
              Translate({ content: 'general.none' }) !== previewCerLevel) ||
            (requestedInterpreter != '' &&
              Translate({ content: 'general.none' }) !==
                requestedInterpreter) ||
            !showBasicPreview) && (
            <>
              <hr className="my-0" />
              <div className="tv-single-assign-form__preview-sub-container">
                <div className="tv-single-assign-form__preview-headings">
                  <Translate content="singleAssignment.additionBookingInfo" />
                </div>
                <div className="w-100 mt-2">
                  <InformationItem
                    label={Translate({
                      content: 'singleAssignment.genderRequirement',
                    })}
                    content={genderString}
                    conditions={
                      genderString != '' &&
                      Translate({ content: 'general.none' }) !== genderString
                        ? genderRequirement
                          ? 'Krävs'
                          : 'Önskemål'
                        : ''
                    }
                  />
                  <InformationItem
                    label={Translate({
                      content: 'singleAssignment.certificationLevel',
                    })}
                    content={`${Capitalize(previewCerLevel)} `}
                    conditions={
                      previewCerLevel !== '' &&
                      Translate({ content: 'general.none' }) !== previewCerLevel
                        ? competencePreference
                        : ''
                    }
                  />
                  <InformationItem
                    label={Translate({
                      content: 'singleAssignment.requestedInterpreterLabel',
                    })}
                    content={
                      requestedInterpreter === ''
                        ? Translate({
                            content: 'general.none',
                          })
                        : requestedInterpreter
                    }
                    conditions={
                      requestedInterpreter && requestedInterpreter !== ''
                        ? requestedInterpreterPreferedLevelString
                        : ''
                    }
                  />
                </div>
              </div>
            </>
          )}{' '}
          {((additionalRequirements && additionalRequirements != '') ||
            (yourReferenceNumber && yourReferenceNumber != '') ||
            (assignmentDescription && assignmentDescription !== '')) && (
            <>
              <hr className="my-0" />
              <div className="tv-single-assign-form__preview-sub-container">
                <div className="tv-single-assign-form__preview-headings">
                  <Translate content="singleAssignment.extraBookingInformation" />
                </div>

                <div className="w-100 mt-2 mb-3">
                  <div className="row">
                    <div className="tv-workassignment__modal-info-label col-12">
                      <Translate content="singleAssignment.additionalRequirment" />
                    </div>
                    <div className="pl-2 text-wrap tv-workassignment__modal-info-content">
                      {additionalRequirements &&
                      additionalRequirements != '' ? (
                        additionalRequirements
                      ) : (
                        <Translate content="general.none" />
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="tv-workassignment__modal-info-label col-12">
                      <Translate content="singleAssignment.yourReferenceNumber" />
                    </div>
                    <div className="pl-2 text-wrap tv-workassignment__modal-info-content">
                      {yourReferenceNumber && yourReferenceNumber != '' ? (
                        yourReferenceNumber
                      ) : (
                        <Translate content="general.none" />
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="tv-workassignment__modal-info-label col-12">
                      <Translate content="taskDetail.infoForInterpreter" />
                    </div>
                    <div className="pl-2 text-wrap tv-workassignment__modal-info-content">
                      {assignmentDescription && assignmentDescription !== '' ? (
                        assignmentDescription
                      ) : (
                        <Translate content="general.none" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </React.Fragment>
    );
  }
}

SingleAssignmentFormPreview.propTypes = propTypes;
SingleAssignmentFormPreview.defaultProps = defaultProps;

export default SingleAssignmentFormPreview;

function InformationItem({ label, content, conditions }) {
  return (
    <div className="row">
      <div
        className={`tv-workassignment__modal-info-label col-4 col-lg-4 ${
          conditions ? 'mb-1' : ''
        }`}
      >
        {label}
      </div>
      <div
        className={`tv-workassignment__modal-info-content ${
          conditions ? 'col-5 mb-1 col-lg-4' : 'col-7 col-lg-7'
        }  `}
        title={content}
      >
        {content}
      </div>
      {conditions && (
        <div className="tv-workassignment__modal-info-condition  col-6  col-lg-3">
          {conditions}
        </div>
      )}
    </div>
  );
}
