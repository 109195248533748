import { Translate } from '../components';

export const APP_PORTAL = process.env.REACT_APP_PORTAL;
export const ENV_CLIENT = 'client-portal';
export const ENV_INTERPRETER = 'interpreter-portal';

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const GLOBAL_DATE_FORMAT = 'DD MMMM YYYY';
export const timezoneBE = 'Europe/Stockholm';

export const resourceRequestStatus = {
  UNSENT: 1,
  SENT: 2,
  ACCEPTED: 3,
  CANCELLED: 4,
  DECLINED: 5,
  EXPIRED: 6,
};

export const availabilityStatus = {
  AVAILABLE: 1,
  AVAILABLE_WITHOUT_INQUIRY: 2,
  UNAVAILABLE: 3,
};

export const status = {
  available: {
    name: 'available',
    titleButtonCancel: Translate({ content: 'status.reject' }),
    titleButtonCancelClient: Translate({ content: 'status.cancel' }),
    titleButtonSubmit: Translate({ content: 'status.accept' }),
    titleButtonCancelOnSite: Translate({ content: 'status.reject' }),
    titleButtonSubmitOnSite: Translate({ content: 'status.accept' }),
    color: '#FF9B4E',
  },
  accepted: {
    name: 'accepted',
    titleButtonCancel: Translate({ content: 'timesheet.cantCompleted' }),
    titleButtonCancelOnSite: Translate({
      content: 'timesheet.cantCompleted',
    }),
    titleButtonCancelClient: Translate({ content: 'status.cancel' }),
    titleButtonSubmit: Translate({ content: 'status.startWorking' }),
    titleButtonSubmitOnSite: Translate({ content: 'status.timeReport' }),
    color: '#6478E6',
  },
  working: {
    name: 'working',
    titleButtonCancel: Translate({ content: 'timesheet.cantCompleted' }),
    titleButtonCancelOnSite: '',
    titleButtonCancelClient: '',
    titleButtonSubmit: Translate({ content: 'general.done' }),
    titleButtonSubmitOnSite: Translate({ content: 'status.timeReport' }),
    color: '#FBBCDB',
  },
  fullfilled: {
    name: 'fullfilled',
    titleButtonCancel: '',
    titleButtonCancelOnSite: '',
    titleButtonCancelClient: '',
    titleButtonSubmit: Translate({ content: 'status.timeReport' }),
    titleButtonSubmitOnSite: Translate({ content: 'status.timeReport' }),
    color: '#18AD79',
  },
  submitted: {
    name: 'submitted',
    titleButtonCancel: '',
    titleButtonCancelOnSite: '',
    titleButtonCancelClient: '',
    titleButtonSubmit: Translate({ content: 'status.timeReport' }),
    titleButtonSubmitOnSite: Translate({ content: 'status.timeReport' }),
    color: '#50C427',
  },
  done: {
    name: 'done',
    titleButtonCancel: '',
    titleButtonCancelOnSite: '',
    titleButtonCancelClient: '',
    titleButtonSubmit: Translate({ content: 'status.timeReport' }),
    titleButtonSubmitOnSite: Translate({ content: 'status.timeReport' }),
    color: '#ffa500',
  },
  cancelled: {
    name: 'cancelled',
    titleButtonCancel: '',
    titleButtonCancelOnSite: '',
    titleButtonCancelClient: '',
    titleButtonSubmit: '',
    titleButtonSubmitOnSite: '',
    color: '#74737B',
  },
  rejected: {
    name: 'rejected',
    titleButtonCancel: '',
    titleButtonCancelOnSite: '',
    titleButtonCancelClient: '',
    titleButtonSubmit: '',
    titleButtonSubmitOnSite: '',
    color: '#E94242',
  },
  losted: {
    name: 'losted',
    titleButtonCancel: '',
    titleButtonCancelOnSite: '',
    titleButtonCancelClient: '',
    titleButtonSubmit: '',
    titleButtonSubmitOnSite: '',
    color: '#FA0960',
  },
  newregistered: {
    name: 'Nyregistrerad',
    titleButtonCancel: '',
    titleButtonCancelOnSite: '',
    titleButtonCancelClient: '',
    titleButtonSubmit: '',
    titleButtonSubmitOnSite: '',
    color: '#ffa500',
  },
  underInvestigation: {
    name: 'Under utredning',
    titleButtonCancel: '',
    titleButtonCancelOnSite: '',
    titleButtonCancelClient: '',
    titleButtonSubmit: '',
    titleButtonSubmitOnSite: '',
    color: '#007bff',
  },
  completed: {
    name: 'Avslutad',
    titleButtonCancel: '',
    titleButtonCancelOnSite: '',
    titleButtonCancelClient: '',
    titleButtonSubmit: '',
    titleButtonSubmitOnSite: '',
    color: '#008000',
  },
  all: {
    name: 'all-assignments',
    titleButtonCancel: '',
    titleButtonCancelOnSite: '',
    titleButtonCancelClient: '',
    titleButtonSubmit: '',
    titleButtonSubmitOnSite: '',
    color: '#FA0960',
  },
  ordered: {
    name: 'ordered',
    titleButtonCancel: '',
    titleButtonCancelOnSite: '',
    titleButtonCancelClient: '',
    titleButtonSubmit: '',
    titleButtonSubmitOnSite: '',
    color: '#6478E6',
  },
  notPerformed: {
    name: 'notPerformed',
    titleButtonCancel: '',
    titleButtonCancelClient: '',
    titleButtonSubmit: '',
    titleButtonCancelOnSite: '',
    titleButtonSubmitOnSite: '',
    color: '#FF9B4E',
  },
  interrupted: {
    name: 'interrupted',
    titleButtonCancel: '',
    titleButtonCancelOnSite: '',
    titleButtonCancelClient: '',
    titleButtonSubmit: '',
    titleButtonSubmitOnSite: '',
    color: '#74737B',
  },
  getByStatusString: strStatus => {
    if (strStatus === 'available') return status.available;
    if (strStatus === 'accepted') return status.accepted;
    if (strStatus === 'working') return status.working;
    if (strStatus === 'fullfilled') return status.fullfilled;
    if (strStatus === 'submitted') return status.submitted;
    if (strStatus === 'done') return status.done;
    if (strStatus === 'cancelled') return status.cancelled;
    if (strStatus === 'rejected') return status.rejected;
    if (strStatus === 'losted') return status.losted;
    if (strStatus === 'all-assignments') return status.all;
    if (strStatus === 'ordered') return status.ordered;
    if (strStatus === 'notPerformed') return status.notPerformed;
    if (strStatus === 'interrupted') return status.interrupted;

    return status.available;
  },
};

export const statusMapping = {
  all: status.all.name,
  open: status.available.name,
  unknown: status.losted.name,
  booked: status.accepted.name,
  cancelled: status.cancelled.name,
  rejected: status.rejected.name,
  performed: status.fullfilled.name,
};

export const subStatus = {
  searchingForInterpreter: 'searching-for-interpreter',
  interpreterAccepted: 'interpreter-accepted',
  orderInProgress: 'order-in-progress',
  assigningInterpreter: 'assigning-interpreter',
  awaitingtimeReport: 'awaiting-time-report',
  timeReportReceived: 'time-report-received',
  deviationRegistered: 'deviation-registered',
  orderCancelled: 'order-cancelled',
  orderRejected: 'order-rejected',
  unexpectedError: 'unexpected-event-occured',
  unknownStatus: 'unknown-status',
};

export const breakTimes = [
  { value: 0, name: '0 min' },
  { value: 2, name: '2 min' },
  { value: 5, name: '5 min' },
  { value: 10, name: '10 min' },
  { value: 20, name: '20 min' },
  { value: 30, name: '30 min' },
  { value: 40, name: '40 min' },
  { value: 50, name: '50 min' },
  { value: 60, name: '60 min' },
];

export const LOCAL_STORE_TOKEN = 'token';
export const LOCAL_STORE_EMAIL = 'email';
export const LOCAL_STORE_INTERPRETER_ID = 'interpreterId';
export const LOCAL_STORE_RESOURCE_IDENTIFIER = 'resourceIdentifier';
export const LOCAL_STORE_LOCATION_PHONE_ID = 'locationPhoneId';
export const LOCAL_STORE_LOCATION_ONSITE_ID = 'locationOnSiteId';
export const LOCAL_STORE_IS_SYNC_WITH_GOOGLE_CALENDAR =
  'isSyncWithGoogleCalendar';
export const LOCAL_STORE_ASSIGNMENT_LIST = 'assignmentList';
export const LOCAL_STORE_SKILLS = 'skills';
export const LOCAL_STORE_SUBSTITUE_SKILLS = 'substituteSkills';
export const LOCAL_STORE_CONTACT_PERSONS = 'contactPersons';
export const LOCAL_STORE_CUSTOMER_NUMBER = 'customerNumber';
export const LOCAL_STORE_CUSTOMER_PASSWORD_HASH = 'customerPasswordHash';
export const LOCAL_STORE_SERVICES = 'services';
export const LOCAL_STORE_REASONS = 'reasons';
export const LOCAL_STORE_COMPLAINT_REASONS = 'complaintReasons';
export const LOCAL_WEB_SESSION_ID = 'LOCAL_WEB_SESSION_ID';

export const timeJob = {
  JOB_TIMER_MY_ASSIGNMENT: 30,
  JOB_TIMER_ASSIGNMENT_REQUEST: 30,
};

export const DEFAULT_EMPTY_NAME = '-';
export const DEFAULT_EMPTY_VALUE = 'none';
export const DEFAULT_NULL_VALUE = null;

export const SINGLE_FORM = 1;
export const MULTI_FORM = 2;

export const acceptedFileType = {
  'application/msword': 'doc',
  'application/pdf': 'pdf',
  'image/jpg': 'image',
  'image/jpeg': 'image',
  'image/png': 'image',
  'image/bmp': 'image',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'docx',
};

export const errorCode = {
  invalidApiKey: 1001,
  invalidResourceNumber: 1002,
  invalidToken: 1003,
  invalidCustomerNumber: 1004,

  mandatoryFieldMissing: 2001,
  stringLengthExceedsLimit: 2002,
  invalidDateTimeString: 2003,
  unexpectedDataType: 2004,
  invalidBase64String: 2005,
  invalidEmailFormat: 2006,
  invalidPhoneNumber: 2007,
  invalidPostalCode: 2008,

  workAssignmentNotFound: 3001,
  resourceRequestNotFound: 3002,
  attachmentNotFound: 3003,
  resourceRequestExpire: 3004,
  resourceRequestAlreadyAccepted: 3005,
  resourceRequestAlreadyDeclined: 3006,
  unableToUpdateTimeReport: 3007,
  articleNotFound: 3008,
  invalidAvailability: 3009,
  unableToCancelWorkAssignment: 3010,
  unableToUpdateExpenses: 3011,
  workAssignmentAlreadyCancelled: 3012,
  fileNotFound: 3013,
  invalidResourceType: 3014,
  workAssignmentAlreadyComplete: 3015,
  unableToCompleteWorkAssingmentWithAnomalyType: 3016,
  invalidCalendarSyncSourceType: 3017,

  CrmUnresponsive: 4001,
  billingUnresponsive: 4002,

  orderNotFound: 5001,
  unableToCancelOrder: 5100,
  orderAlreadyCancelled: 5101,
  cancellationReasonNotFound: 5102,
  postalCodeNotConnectedToCounty: 5200,
  invalidService: 5201,
  invalidSkill: 5202,
  invalidPhoneFormat: 5203,
  missingContactPersonOrderer: 5204,
  missingContactPersonUponInterpretation: 5205,
  mandatoryContactPersonOrdererFieldsMissing: 5206,
  mandatoryContactPersonUponInterpretationFieldsMissing: 5207,
  missingAttachmentInformation: 5208,
  missingOrderGroupingFields: 5209,
  missingOrdererPhoneNumber: 5210,
  missingContactPersonUponInterpretationPhone: 5211,
  missingOrderOccasion: 5212,

  unhandledCriticalError: 9999,
};

export const COMPENSATION_FILETYPE = 'Lönespecifikation';
export const COMPENSATION_FILETYPE_2 = 'Uppdragsspecifikation';
export const COMPENSATION_FILETYPE_3 = 'Självfaktura';
export const COMPENSATION_DICTIONARIES_FILETYPE = 'Ordlista';
export const COMPENSATION_DICTIONARIES_FILETYPE_2 = 'Ordlistor';
export const COMPENSATION_FEESLIST_FILETYPE = 'Arvodeslista';
export const COMPENSATION_TIMEGAP = -1; // year
export const COMPENSATION_FILE_CLASSNAME = 'tv-file__details-text';

export const TRANSVOICE_EMAIL_INFO = 'info@transvoice.se';
export const TRANSVOICE_EMAIL_REPORT = 'kvalitet@transvoice.se';

export const DIALOG_GOOGLE_WIDTH = 387;
export const DIALOG_GOOGLE_HEIGHT = 150;

export const NUMBER_ROW_OF_MONTHLY_CALENDAR = 6;
export const NUMBER_COL_OF_MONTHLY_CALENDAR = 7; // from sun, mon, ... sat
export const TOTAL_DAY_OF_MONTHLY_CALENDAR =
  NUMBER_ROW_OF_MONTHLY_CALENDAR * NUMBER_COL_OF_MONTHLY_CALENDAR;

export const TIME_RANGE_GET_DATA = TOTAL_DAY_OF_MONTHLY_CALENDAR;

export const DAY_MAP = {
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSADAY: 'thursday',
  FRIDAY: 'friday',
  SATURDAY: 'saturday',
  SUNDAY: 'sunday',
};

export const RATING_VALUES = {
  NOT_RATED: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
};

export const FILTER_TYPES = {
  STATUS_FILTER: 'STATUS_FILTER',
  TYPE_FILTER: 'TYPE_FILTER',
  RATING_FILTER: 'RATING_FILTER',
};

export const ASSIGNMENT_TYPES = {
  IN_PERSON: 'Kontakttolkning',
  TELEPHONE: 'Telefontolkning',
  SKYPE: 'Skypetolkning',
  VIDEO: 'Videotolkning',
  ALL: 'Välj',
};

export const PAGE_TYPES = {
  ASSIGNMENT: 'ASSIGNMENT',
  DASHBOARD: 'DASHBOARD',
};

export const MONTHS = [
  'Januari',
  'Februari',
  'Mars',
  'April',
  'Maj',
  'Juni',
  'Juli',
  'Augusti',
  'September',
  'Oktober',
  'November',
  'December',
];

export const TOP_SKILLS = [
  'Arabiska',
  'Dari',
  'Persiska',
  'Ryska',
  'Somaliska',
  'Spanska',
  'Tigrinja',
];

export const barChartColors = [
  '#6478E6',
  '#E94242',
  '#18AD79',
  '#FF9B4E',
  '#74737b',
  '#008000',
  '#fbbcdb',
  '#ffa500',
  '#696969',
  '#9c69ad',
  '#0056b3',
];

export const sampleOrders = [
  {
    AdditionalRequirements: null,
    AddressPerformingEffective: 'Östermalmsgatan 26A, 114 26, Stockholm',
    ArticleName: 'Kontakttolkning',
    AssignmentStatus: 'Tillsatt',
    Attachments: null,
    CanBeRated: false,
    CanBeUpdated: true,
    CanCancelOrder: true,
    CanCreateDeviation: true,
    CancellationStatus: null,
    Category: 'Annat',
    CompetenceLevel: null,
    CompetenceLevelIdentifier: null,
    ConsolidatedOrderStatus: {
      ConsolidatedOrderStatusMessages: [
        {
          SortOrder: 1,
          StatusMessage: 'Accepterat av tolk',
          StatusMessageIdentifier: 'interpreter-accepted',
        },
      ],
      StatusIdentifier: 'booked',
      StatusName: 'Bokad',
      SubStatusIdentifier: 'interpreter-accepted',
    },
    ContactPerson: {
      ContactPersonDirectPhoneNumber: null,
      ContactPersonEmail: 'jane.doe@gmail.com',
      ContactPersonFirstName: 'Jane',
      ContactPersonIdentifier: '8261f87c-f078-4174-a007-e2b854cad598',
      ContactPersonLastName: 'Doe',
      ContactPersonMobilePhoneNumber: '070-462 18 52',
      ContactPersonName: 'Jane Doe',
      ContactPersonTitle: null,
    },
    ContactPersonOrderer: {
      ContactPersonOrdererDirectPhoneNumber: null,
      ContactPersonOrdererEmail: null,
      ContactPersonOrdererFirstName: 'Anna',
      ContactPersonOrdererIdentifier: '8261f87c-f078-4174-a007-e2b854cad598',
      ContactPersonOrdererLastName: ' Andersson',
      ContactPersonOrdererMobilePhoneNumber: '070-462 18 52',
      ContactPersonOrdererName: 'Anna Andersson',
      ContactPersonOrdererTitle: null,
    },
    Customer: {
      CustomerCityDistrict: null,
      CustomerDirectPhoneNumber: null,
      CustomerName: 'Region Halland demo',
      CustomerSwitchboardPhoneNumber: '08-120 800 00',
    },
    CustomerOrderNumber: null,
    CustomerReference: null,
    DatetimeCompleted: null,
    DatetimeFrom: '2024-01-10 09:30:00',
    DatetimeSubmitted: null,
    DatetimeTo: '2024-01-10 10:30:00',
    DoRequireCompetenceLevelOrHigher: null,
    DoRequireCorrectGender: null,
    DoRequireExactCompetenceLevel: null,
    Expenses: null,
    GenderIdPreferred: null,
    HasDeviation: false,
    IsAutoAssignOrder: false,
    IsAwaitingCustomerConfirmation: false,
    IsDeviatingAddressPerforming: false,
    IsHomeAddress: false,
    IsPerformedRemotely: false,
    IsPhoneAssignment: true,
    LMANumber: null,
    MeetingDetails: {
      DigitalMeetingPlatformDescription: null,
      DigitalMeetingPlatformIdentifier: null,
      DigitalMeetingPlatformName: null,
      DoRequireBankIdVerification: 'False',
      MeetingPassCode: null,
      MeetingPhoneNumber: null,
      MeetingUrl: null,
    },
    NotesResource: null,
    NumberOfUnreadNotifications: 0,
    OrderGroup: {
      OrderGroupDescription: null,
      OrderGroupIdentifier: null,
    },
    OrderIdentifier: '0512eb67-5073-4699-8464-1589448ac43f',
    OrderNumber: 'KC1235',
    OrderStatus: 'Accept',
    OrderStatusIdentifier: 'af72855a-cf3b-4ccf-b763-cefd68a6fa92',
    PhoneNumberOrder: '0707680504',
    PostalAddressPerforming: {
      AddressRow1: 'Sveavägen 159',
      AddressRow2: null,
      Attention: null,
      CareOf: null,
      City: 'Stockholm',
      IsHomeAddress: false,
      PostalCode: 11346,
    },
    PreferredResourceCustomer: null,
    PreferredResourceLevel: null,
    RatingCustomer: null,
    RatingCustomerComment: null,
    ReplacedByDatetimeFrom: null,
    ReplacedByDatetimeTo: null,
    ReplacedByOrderIdentifier: null,
    ReplacedByOrderNumber: null,
    ReplacesDatetimeFrom: null,
    ReplacesDatetimeTo: null,
    ReplacesOrderIdentifier: null,
    ReplacesOrderNumber: null,
    RequestGroup: {
      ResourceRequestGroupIdentifier: null,
    },
    Resource: {
      IsAppointed: true,
      IsAwaitingCustomerConfirmation: false,
      IsAwaitingResourceRequestResponse: false,
      IsBasedOnSkillSubstitute: false,
      IsInhouseCallcenterResource: false,
      ResourceAgencyNumber: null,
      ResourceAgencyNumberAppointed: null,
      ResourceCompetenceLevelName: 'Auktoriserad sjukvårdstolk',
      ResourceEmailAddress: 'venuka.s@aventude.com',
      ResourceFullName: 'Venuka Sampath (Test)',
      ResourceGenderName: 'Man',
      ResourceNumber: '26692',
      ResourceNumberAppointed: '26692',
    },
    ServiceAlternatives: null,
    ServiceIdentifier: 'ab54be2e-8af8-45d5-aab5-bbceb934be8b',
    ServiceIdentifierOrdered: 'ab54be2e-8af8-45d5-aab5-bbceb934be8b',
    Skill: 'Finska',
    SkillIdentifier: 'ddf6410f-1ec0-4fee-9f18-c605189d5cb0',
    SkillSubstitute: '',
    SkillSubstituteIdentifier: null,
    SpecificTravelInstructions: null,
    TimeReport: {
      BreakTime: null,
      BreakTimeAdjusted: null,
      DatetimeAdjustedFrom: null,
      DatetimeAdjustedTo: null,
      DatetimeOriginalFrom: null,
      DatetimeOriginalTo: null,
      DatetimeTravelFromAssignmentFrom: null,
      DatetimeTravelFromAssignmentTo: null,
      DatetimeTravelToAssignmentFrom: null,
      DatetimeTravelToAssignmentTo: null,
      Downtime: null,
      DowntimeAdjusted: null,
      TimeReportComment: null,
      TravelDistanceFrom: null,
      TravelDistanceTo: null,
      TraveltimeFromAssignment: null,
      TraveltimeFromAssignmentAdjusted: null,
      TraveltimeToAssignment: null,
      TraveltimeToAssignmentAdjusted: null,
    },
    UnreadNotifications: null,
  },
  {
    AdditionalRequirements: null,
    AddressPerformingEffective: 'Bränningevägen 2, 120 54, Stockholm.',
    ArticleName: 'Kontakttolkning',
    AssignmentStatus: 'Levererat/avslutat',
    Attachments: null,
    CanBeRated: false,
    CanBeUpdated: false,
    CanCancelOrder: false,
    CanCreateDeviation: true,
    CancellationStatus: 'Avbeställt',
    Category: 'Annat',
    CompetenceLevel: null,
    CompetenceLevelIdentifier: null,
    ConsolidatedOrderStatus: {
      ConsolidatedOrderStatusMessages: [
        {
          SortOrder: 1,
          StatusMessage:
            'Ni avbokade uppdraget 2024-01-05 05:39. Avbeställningen kommer ej att debiteras.',
          StatusMessageIdentifier: 'order-cancelled',
        },
      ],
      StatusIdentifier: 'cancelled',
      StatusName: 'Avbokad',
      SubStatusIdentifier: 'cancelled',
    },
    ContactPerson: {
      ContactPersonDirectPhoneNumber: null,
      ContactPersonEmail: 'jane.doe@gmail.com',
      ContactPersonFirstName: 'Jane',
      ContactPersonIdentifier: '8261f87c-f078-4174-a007-e2b854cad598',
      ContactPersonLastName: 'Doe',
      ContactPersonMobilePhoneNumber: '070-462 18 52',
      ContactPersonName: 'Jane Doe',
      ContactPersonTitle: null,
    },
    ContactPersonOrderer: {
      ContactPersonOrdererDirectPhoneNumber: null,
      ContactPersonOrdererEmail: 'tharukakalu@gmail.com',
      ContactPersonOrdererFirstName: 'Anna',
      ContactPersonOrdererIdentifier: 'f2ff2fb4-e042-4da2-b4e3-af279e5a848e',
      ContactPersonOrdererLastName: 'Andersson',
      ContactPersonOrdererMobilePhoneNumber: null,
      ContactPersonOrdererName: 'Anna Andersson',
      ContactPersonOrdererTitle: null,
    },
    Customer: {
      CustomerCityDistrict: null,
      CustomerDirectPhoneNumber: null,
      CustomerName: 'Region Halland demo',
      CustomerSwitchboardPhoneNumber: '08-120 800 00',
    },
    CustomerOrderNumber: null,
    CustomerReference: null,
    DatetimeCompleted: '2024-01-05 05:39:14',
    DatetimeFrom: '2024-01-10 15:00:00',
    DatetimeSubmitted: null,
    DatetimeTo: '2024-01-10 16:00:00',
    DoRequireCompetenceLevelOrHigher: null,
    DoRequireCorrectGender: null,
    DoRequireExactCompetenceLevel: null,
    Expenses: null,
    GenderIdPreferred: null,
    HasDeviation: false,
    IsAutoAssignOrder: false,
    IsAwaitingCustomerConfirmation: false,
    IsDeviatingAddressPerforming: false,
    IsHomeAddress: false,
    IsPerformedRemotely: false,
    IsPhoneAssignment: true,
    LMANumber: null,
    MeetingDetails: {
      DigitalMeetingPlatformDescription: null,
      DigitalMeetingPlatformIdentifier: null,
      DigitalMeetingPlatformName: null,
      DoRequireBankIdVerification: 'False',
      MeetingPassCode: null,
      MeetingPhoneNumber: null,
      MeetingUrl: null,
    },
    NotesResource: null,
    NumberOfUnreadNotifications: 0,
    OrderGroup: {
      OrderGroupDescription: null,
      OrderGroupIdentifier: null,
    },
    OrderIdentifier: '6ed771e4-c239-4a36-b264-703a15d699vg',
    OrderNumber: 'KC1234',
    OrderStatus: 'Avbeställd',
    OrderStatusIdentifier: '4c106c32-4dd9-4992-93be-98d0de82b569',
    PhoneNumberOrder: null,
    PostalAddressPerforming: {
      AddressRow1: 'Sveavägen 159',
      AddressRow2: null,
      Attention: null,
      CareOf: null,
      City: 'Stockholm',
      IsHomeAddress: false,
      PostalCode: 11346,
    },
    PreferredResourceCustomer: null,
    PreferredResourceLevel: null,
    RatingCustomer: null,
    RatingCustomerComment: null,
    ReplacedByDatetimeFrom: null,
    ReplacedByDatetimeTo: null,
    ReplacedByOrderIdentifier: null,
    ReplacedByOrderNumber: null,
    ReplacesDatetimeFrom: null,
    ReplacesDatetimeTo: null,
    ReplacesOrderIdentifier: null,
    ReplacesOrderNumber: null,
    RequestGroup: {
      ResourceRequestGroupIdentifier: null,
    },
    Resource: {
      IsAppointed: false,
      IsAwaitingCustomerConfirmation: false,
      IsAwaitingResourceRequestResponse: false,
      IsBasedOnSkillSubstitute: null,
      IsInhouseCallcenterResource: false,
      ResourceAgencyNumber: null,
      ResourceAgencyNumberAppointed: null,
      ResourceCompetenceLevelName: null,
      ResourceEmailAddress: null,
      ResourceFullName: null,
      ResourceGenderName: null,
      ResourceNumber: null,
      ResourceNumberAppointed: null,
    },
    ServiceAlternatives: null,
    ServiceIdentifier: 'ab54be2e-8af8-45d5-aab5-bbceb934be8b',
    ServiceIdentifierOrdered: 'ab54be2e-8af8-45d5-aab5-bbceb934be8b',
    Skill: 'Engelska',
    SkillIdentifier: 'ddf6410f-1ec0-4fee-9f18-c605189d5cb0',
    SkillSubstitute: '',
    SkillSubstituteIdentifier: null,
    SpecificTravelInstructions: null,
    TimeReport: {
      BreakTime: null,
      BreakTimeAdjusted: null,
      DatetimeAdjustedFrom: null,
      DatetimeAdjustedTo: null,
      DatetimeOriginalFrom: null,
      DatetimeOriginalTo: null,
      DatetimeTravelFromAssignmentFrom: null,
      DatetimeTravelFromAssignmentTo: null,
      DatetimeTravelToAssignmentFrom: null,
      DatetimeTravelToAssignmentTo: null,
      Downtime: null,
      DowntimeAdjusted: null,
      TimeReportComment: null,
      TravelDistanceFrom: null,
      TravelDistanceTo: null,
      TraveltimeFromAssignment: null,
      TraveltimeFromAssignmentAdjusted: null,
      TraveltimeToAssignment: null,
      TraveltimeToAssignmentAdjusted: null,
    },
    UnreadNotifications: null,
  },
];

export const sampleNotifications = [
  {
    DatetimeSent: '2024-01-10 10:30:00',
    Headline: 'Avbokat uppdrag',
    IsRead: false,
    Message:
      'Din bokning KC1234, 2024-01-10 15:00 har blivit avbokad av klienten. Klicka på notisen för uppdragsdetaljer.  ',
    NotificationIdentifier: '2c12e0a5-117a-4f57-976a-80wb2197cae3',
    NotificationTypeIdentifier: 'Assignment',
    OrderIdentifiers: [
      {
        OrderIdentifier: '6ed771e4-c239-4a36-b264-703a15d699vg',
        OrderNumber: 'KC1234',
      },
    ],
    ResourceRequestIdentifier: null,
    WorkAssignmentIdentifiers: null,
  },
];
