/* eslint-disable max-len */
import axios from 'axios';
import { errorInterceptor, responseInterceptor } from './apiInterceptor';

const apiKey =   process.env.REACT_APP_REPORT_API_KEY;
const apiVersion = process.env.REACT_APP_REPORT_API_VERSION;
const reportUserAxios = axios.create({
  baseURL: process.env.REACT_APP_REPORT_API,
});
// api interceptors
reportUserAxios.interceptors.response.use(responseInterceptor, errorInterceptor);

// eslint-disable-next-line import/prefer-default-export
export function getUserData(userId) {
  return reportUserAxios.get(`/api/${apiVersion}/user/${userId}/data`, {
    headers: {
      customerNumber: 0,
      apiKey,
    },
  });
}

