import React, { Component } from 'react';
import { Translate } from '../../../Share/components';


class Logout extends Component {
  render() {
    return (
      <div>
        <Translate content="settings.logOut" />...
      </div>
    );
  }
}

export default Logout;
