import React from 'react';
import PropTypes from 'prop-types';

import ReasonItem from './ReasonItem';

const propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  callback: PropTypes.func,
};

const defaultProps = {
  items: [],
  callback: () => {},
};

class ReasonList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelected: {
        0: true,
      },
    };
  }

  handleSelectReasonItem = (e) => {
    const value = this.state.isSelected[e];
    const listSelected = this.state.isSelected;
    Object.keys(listSelected).forEach((key) => {
      listSelected[key] = false;
    });
    this.setState({
      isSelected: {
        ...this.state.isSelected,
        [e]: !value,
      },
    });
  }

  render() {
    const {
      items, callback,
    } = this.props;
    const {
      isSelected,
    } = this.state;
    return (
      <div className="tv-cancelassignment__reasons-list tv-display-flex">
        {
          items.map((item, i) => (
            <ReasonItem
              key={item.identifier}
              className="tv-cancelassignment__reasons-list-item tv-display-flex"
              content={item.content}
              identifier={item.identifier}
              callback={(index, v) => { callback(index, v); this.handleSelectReasonItem(i); }}
              isSelected={isSelected[i]}
            />
          ))
        }
      </div>
    );
  }
}

ReasonList.propTypes = propTypes;
ReasonList.defaultProps = defaultProps;

export default ReasonList;
