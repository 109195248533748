import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
  DropdownMenu,
  FilterButton,
  CalendarSmall,
  TransvoiceButton,
  Translate,
} from '../../../Share/components';

const propTypes = {
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  ranges: PropTypes.shape({}),
};

const defaultProps = {
  onChange: () => {},
  onClick: () => {},
  ranges: {
    startDate: moment(),
    endDate: moment(),
    key: 'selection',
  },
};

class DateRangeFilter extends Component {
  constructor(props) {
    super(props);
    const title = this.getDateRangeString();
    this.state = {
      title,
    };
  }

  getDateRangeString = () => {
    let dateRangeString = Translate({ content: 'main.dateRange' });
    const { ranges } = this.props;
    const start = moment(ranges.startDate).clone();
    const end = moment(ranges.endDate).clone();
    if (start.diff(end, 'days') === 0) {
      dateRangeString = moment(start.clone()).locale('sv').format('DD MMMM');
    } else {
      dateRangeString = `${moment(start.clone())
        .locale('sv')
        .format('DD MMMM')}  -  ${moment(end.clone())
        .locale('sv')
        .format('DD MMMM')}`;
    }
    return dateRangeString;
  };

  handleClick = () => {
    const title = this.getDateRangeString();
    this.setState({ title });
    if (this.filterElement && this.filterElement.onHiddenMenu) {
      this.filterElement.onHiddenMenu();
    }
    const { onClick } = this.props;
    onClick();
  };

  render() {
    const { onChange, ranges } = this.props;
    const { title } = this.state;
    const button = (
      <div className="tv-workassignment__date_picker">
        <div className="tv-workassignment__date_picker-title">
          {Translate({ content: 'myfilter.date' })}
        </div>
        <input
          className="tv-workassignment__date_picker-input"
          type="text"
          value={title}
        />
        {/* <i className="tv-workassignment__date_picker-icon icon-arrow-more-2" /> */}
        {/* <FilterButton text={`${Translate({ content: 'status.filter' })}: <strong>${title}</strong>`}  */}
      </div>
    );
    return (
      <div className="tv-workassignment__control-panel tv-workassignment__filter-date-range">
        <DropdownMenu
          button={button}
          ref={el => {
            this.filterElement = el;
          }}
        >
          <div className="tv-workassignment__filter-date-range__content">
            <CalendarSmall
              ranges={ranges}
              onEventChange={onChange}
              showToggle={false}
              isMinDate={false}
            />
            <TransvoiceButton
              type="primary"
              onClick={this.handleClick}
              text={Translate({ content: 'general.done' })}
            />
          </div>
        </DropdownMenu>
      </div>
    );
  }
}

DateRangeFilter.propTypes = propTypes;
DateRangeFilter.defaultProps = defaultProps;

export default DateRangeFilter;
