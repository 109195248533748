import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Translate, IfComponent } from "../../../Share/components";
import { convertStringToDate } from "../../../Share/utils/dateUtil";
import { DEFAULT_EMPTY_VALUE } from "../../../Share/constants";

const propTypes = {
    total: PropTypes.number,
    language: PropTypes.string,
    languages: PropTypes.arrayOf(PropTypes.shape({})),
    services: PropTypes.arrayOf(PropTypes.shape({})),
    certificationLevels: PropTypes.string,
    dateRange: PropTypes.shape({}),
    yourOrderNumber: PropTypes.string,
    yourReferenceNumber: PropTypes.string,
    typeOfAssignment: PropTypes.string,
    isSelected: PropTypes.bool,
    isError: PropTypes.bool,
    onDeleteClick: PropTypes.func,
    onClick: PropTypes.func,
};

const defaultProps = {
    total: null,
    language: "",
    languages: [],
    services: [],
    dateRange: {},
    yourOrderNumber: "",
    yourReferenceNumber: "",
    certificationLevels: DEFAULT_EMPTY_VALUE,
    typeOfAssignment: "",
    isSelected: false,
    isError: false,
    onDeleteClick: () => {},
    onClick: () => {},
};

const MultipleAssignmentTab = ({
    isSelected,
    isError,
    onClick,
    onDeleteClick,
    language,
    certificationLevels,
    total,
    yourOrderNumber,
    yourReferenceNumber,
    dateRange,
    languages,
    services,
    typeOfAssignment,
}) => {
    const firstSerialDate = dateRange;
    const controlClassName = classNames({
        "tv-position-rel": true,
        "tv-multiple-assign-form__panel-tab": true,
        "tv-multiple-assign-form__panel-tab--selected": isSelected,
        "tv-multiple-assign-form__panel-tab--error": isError,
    });

    // const cerlevels = () => {
    //   if (certificationLevels !== DEFAULT_EMPTY_VALUE) {
    //     const filterAssignType = services.length > 0 ?
    //       services.filter(obj => obj.ServiceIdentifier === typeOfAssignment)[0] : null;
    //     if (!filterAssignType) return DEFAULT_EMPTY_NAME;
    //     const certArray = filterAssignType.AvailableCompetences ?
    //       filterAssignType.AvailableCompetences.filter(obj => obj.CompetenceLevelIdentifier === certificationLevels) : [];
    //     return certArray.length > 0 ?
    //       certArray[0].CompetenceLevelName : DEFAULT_EMPTY_NAME;
    //   }
    //   return DEFAULT_EMPTY_NAME;
    // };

    const typText = () => {
        const service = typeOfAssignment
            ? (services || []).filter(
                  (s) => s.ServiceIdentifier === typeOfAssignment
              )[0]
            : undefined;
        return service && service.ServiceName ? service.ServiceName : "";
    };

    return (
        <div
            onClick={onClick}
            role="button"
            tabIndex="-1"
            className={controlClassName}
            onKeyDown={() => {}}
        >
            <IfComponent
                condition={total > 1 && !isSelected}
                whenTrue={
                    <button
                        onClick={onDeleteClick}
                        type="button"
                        className="tv-multiple-assign-form__button-delete"
                    >
                        <Translate content="multipleAssignment.delete" />
                    </button>
                }
            />
            <h2 className="tv-multiple-assign-form__panel-title">
                {languages.filter(obj => obj.SkillIdentifier === language)[0].SkillName}
            </h2>
            <ul className="tv-multiple-assign-form__attributes">
                <li className="level">
                    <Translate content="singleAssignment.typeOfAssignment" />:{" "}
                    <strong>{typText()}</strong>
                </li>
                {/* <li className="category">
          <Translate content="singleAssignment.referenceNumber" />: <strong>{yourReferenceNumber}</strong>
        </li> */}
                <li className="datetime">
                    <Translate content="singleAssignment.dateAndTime" />:
                    <strong>
                        {` ${convertStringToDate(
                            firstSerialDate.startTime
                        ).format("HH:mm")} to ${convertStringToDate(
                            firstSerialDate.endTime
                        ).format("HH:mm")} - ${convertStringToDate(
                            firstSerialDate.ranges.startDate
                        ).format("YYYY")}, ${convertStringToDate(
                            firstSerialDate.ranges.startDate
                        ).format("MMMM Do")} to ${convertStringToDate(
                            firstSerialDate.ranges.endDate
                        ).format("MMMM Do")}`}
                    </strong>
                </li>
            </ul>
        </div>
    );
};

MultipleAssignmentTab.propTypes = propTypes;
MultipleAssignmentTab.defaultProps = defaultProps;

export default MultipleAssignmentTab;
