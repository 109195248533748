import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Avatar from '../Avatar';
import {
  onUpdateSearchByOrder,
  getCustomer,
  logout,
  autoAssignOpeningHours,
} from '../../actions';
import './style.scss';
import history from '../../../Share/utils/history';
import { Translate, Logo, TransvoiceButton } from '../../../Share/components';
import BookNowModal from '../BookNowModal';
import NotificationContainer from '../NotificationContainer';
import AssignmentTypeSelection from '../AssignmentTypeSelection';
import { QuickOrder } from '../QuickOrder';
import MobileAppInfoModal from './MobileAppInfoModal';
import OnDemand from '../OnDemand';
import { AcoliAvailableModal, AcoliSuccessModal } from '../NewAssignment';
import MessageServiceModal from '../MessageServiceModal';

const propTypes = {
  interpreterId: PropTypes.string,
  mapOnUpdateSearchByOrder: PropTypes.func,
  profile: PropTypes.shape({}),
  bookDirectOpeningHours: PropTypes.shape({}),
  displayKPIReports: PropTypes.bool,
  showBookDirectModal: PropTypes.bool,
  onGetCustomer: PropTypes.func,
  onLogout: PropTypes.func,
  getAutoAssignOpeningHours: PropTypes.func,
  onClickNavigationButton: PropTypes.func,
  CanCreateAutoAssignOrder: PropTypes.bool,
};

const defaultProps = {
  interpreterId: '',
  mapOnUpdateSearchByOrder: () => {},
  profile: {},
  displayKPIReports: false,
  showBookDirectModal: false,
  CanCreateAutoAssignOrder: false,
  onGetCustomer: () => {},
  bookDirectOpeningHours: {},
  getAutoAssignOpeningHours: () => {},
  onLogout: () => {},
  onClickNavigationButton: () => {},
};

const INITAL_STATES = {
  searchOrder: '',
  isSearchInMenu: false,
  bookNowModalVisible: false,
  showBookingOptions: false,
  showQuickOrder: false,
  showOnDemand: false,
  showMessageService: false,
};

class RightStatus extends Component {
  constructor(props) {
    super(props);
    this.state = INITAL_STATES;
    history.listen(location => {
      if (location.pathname !== '/search-result') {
        if (this.ismounted) {
          this.setState({
            isSearchInMenu: false,
          });
        }
      }
    });
  }

  componentDidMount() {
    this.ismounted = true;
  }

  componentDidUpdate(prevProps) {
    const { showBookDirectModal, getAutoAssignOpeningHours } = this.props;
    if (
      prevProps.showBookDirectModal !== showBookDirectModal &&
      showBookDirectModal
    ) {
      getAutoAssignOpeningHours({
        DateFrom: moment().format('YYYY-MM-DD'),
        DateTo: moment().format('YYYY-MM-DD'),
      });
    }
  }

  componentWillUnmount() {
    this.ismounted = false;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.searchOrder === '' && !prevState.isSearchInMenu) {
      return {
        searchOrder: '',
      };
    }
    return null;
  }

  // eslint-disable-next-line react/sort-comp
  handleUpdateOrder = event => {
    this.setState({
      searchOrder: event.target.value,
      isSearchInMenu: true,
    });
  };

  handleBookNowModal = () => {
    const { bookNowModalVisible } = this.state;
    this.setState({
      bookNowModalVisible: !bookNowModalVisible,
    });
  };

  handleSubmitSearch = () => {
    const { mapOnUpdateSearchByOrder } = this.props;
    this.setState({
      isSearchInMenu: false,
    });
    mapOnUpdateSearchByOrder(this.state.searchOrder);
    history.push('/search-result');
  };

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.handleSubmitSearch();
    }
  }
  onClickNavigationButton = event => {
    this.props.onClickNavigationButton(event);
  };

  handleShowBookingOptions = () => {
    this.setState({
      showBookingOptions: !this.state.showBookingOptions,
    });
  };

  hideBookingOptions = () => {
    this.setState({
      showBookingOptions: false,
    });
  };

  handleOutsideClick = e => {
    const { currentTarget } = e;
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        this.hideBookingOptions();
      }
    }, 300);
  };

  render() {
    const {
      searchOrder,
      showBookingOptions,
      showQuickOrder,
      showOnDemand,
      showMessageService,
    } = this.state;
    const {
      showBookDirectModal,
      handleBookDirectModal,
      CanCreateAutoAssignOrder,
      bookDirectOpeningHours,
    } = this.props;
    const setNavLinkIsActive = (match, location) =>
      match || /submit-time/.test(location.pathname);
    return (
      <div className="tv-right-status">
        <Logo />
        <div className="flex-grow-1" />
        <div className="nav-container">
          <div className=" navigation">
            <nav className="navbar-expand">
              <div className="navbar-nav">
                <NavLink
                  onClick={this.onClickNavItem}
                  isActive={setNavLinkIsActive}
                  exact
                  activeClassName="nav-active"
                  to="/"
                  className="nav-item"
                >
                  <Translate content="sideBar.dashboard" />
                </NavLink>
                <NavLink
                  isActive={setNavLinkIsActive}
                  exact
                  activeClassName="nav-active"
                  to="/message-service"
                  className="nav-item"
                >
                  Meddelandeservice
                </NavLink>
                <NavLink
                  isActive={setNavLinkIsActive}
                  exact
                  activeClassName="nav-active"
                  to="/frasbank"
                  className="nav-item"
                >
                  Frasbank
                </NavLink>
                <NavLink
                  isActive={setNavLinkIsActive}
                  exact
                  activeClassName="nav-active"
                  to="/my-assignment"
                  className="nav-item"
                >
                  {Translate({
                    content: 'sideBar.myAssignment',
                  })}
                </NavLink>
                <NavLink
                  isActive={setNavLinkIsActive}
                  exact
                  activeClassName="nav-active"
                  to="/reports-dashboard/"
                  className="nav-item"
                >
                  {Translate({
                    content: 'report.statistics',
                  })}
                </NavLink>
                <NavLink
                  isActive={setNavLinkIsActive}
                  exact
                  activeClassName="nav-active"
                  to={{
                    pathname: '/report-quality',
                    state: { isGeneral: true },
                  }}
                  className="nav-item"
                >
                  <Translate content="sideBar.reportQuality" />
                </NavLink>
                <NavLink
                  isActive={setNavLinkIsActive}
                  exact
                  activeClassName="nav-active"
                  to="/contact-transvoice"
                  className="nav-item"
                >
                  <span>
                    <Translate content="sideBar.contactTransvoice" />
                  </span>
                </NavLink>
              </div>
            </nav>
          </div>
          <div
            className="tv-bookDirect-button-nav__wrapper"
            onBlur={this.handleOutsideClick}
          >
            <button
              className="booking-button tv-display-mobile-hide"
              onClick={this.handleShowBookingOptions}
            >
              {Translate({
                content: 'sideBar.startBooking',
              })}
            </button>

            {showBookingOptions && (
              <AssignmentTypeSelection
                onDirectBook={() => {
                  handleBookDirectModal();
                  this.hideBookingOptions();
                }}
                onPreBook={() => {
                  this.setState({
                    showQuickOrder: true,
                  });
                  this.hideBookingOptions();
                }}
                onDemand={() => {
                  this.setState({
                    showOnDemand: true,
                  });
                  this.hideBookingOptions();
                }}
                onMessageService={() => {
                  this.setState({
                    showMessageService: true,
                  });
                  this.hideBookingOptions();
                }}
              />
            )}
          </div>
          <NotificationContainer />
          <div className=" mobile-nav position-relative text-right">
            <Avatar
              interpreterId={this.props.interpreterId}
              profile={this.props.profile}
              getCustomer={this.props.onGetCustomer}
              logout={this.props.onLogout}
              visibleName={false}
            />
            <button
              onClick={this.onClickNavigationButton}
              className="menu-button fa fa-bars"
            />
          </div>
        </div>
        <MobileAppInfoModal />
        {showBookDirectModal && !isEmpty(bookDirectOpeningHours) && (
          <BookNowModal
            isVisible={showBookDirectModal}
            onCloseClicked={handleBookDirectModal}
            bookDirectOpeningHours={bookDirectOpeningHours}
          />
        )}

        {showOnDemand && (
          <OnDemand
            onCloseClicked={() => {
              this.setState({ showOnDemand: false });
            }}
            isVisible={showOnDemand}
          />
        )}

        {/* <AcoliAvailableModal /> */}

        {/* <AcoliSuccessModal /> */}

        {showMessageService && (
          <MessageServiceModal
            isVisible={showMessageService}
            onCloseClicked={() => {
              this.setState({ showMessageService: false });
            }}
          />
        )}

        {showQuickOrder && (
          <QuickOrder
            onResetDate={() => {}}
            onClose={() => {
              this.setState({
                showQuickOrder: false,
              });
            }}
            showQuickOrder={showQuickOrder}
          />
        )}
      </div>
    );
  }
}

RightStatus.propTypes = propTypes;
RightStatus.defaultProps = defaultProps;

const mapStateToProps = state => ({
  interpreterId: state.authentication.interpreterId,
  profile: state.user.profile,
  searchOrder: state.workAssignment.searchOrder,
  bookDirectOpeningHours: state.workAssignment.bookDirectOpeningHours,
});

const mapDispatchToProps = dispatch => ({
  mapOnUpdateSearchByOrder: searchOrder => {
    dispatch(onUpdateSearchByOrder(searchOrder));
  },
  onGetCustomer: () => {
    dispatch(getCustomer());
  },
  onLogout: () => {
    dispatch(logout());
  },
  getAutoAssignOpeningHours: dateObj => {
    dispatch(autoAssignOpeningHours(dateObj));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RightStatus);
