import React, { useState, forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const NotificationMenuDropdown = props => {
  const { children, button, className, buttonClass, visible, setVisible } = props;

  const toggleMenu = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    setVisible(visible);
  }, [visible]);

  const onBlur = event => {
    const { currentTarget } = event;
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        // toggleMenu();
        setVisible(false);
      }
    }, 250);
  };

  return (
    <span onBlur={onBlur} className={className}>
      <div
        role="button"
        tabIndex="-1"
        onClick={toggleMenu}
        onKeyDown={() => {}}
        className={buttonClass}
      >
        {button}
      </div>
      {visible && children}
    </span>
  );
};

NotificationMenuDropdown.propTypes = {
  children: PropTypes.node,
  button: PropTypes.node,
  className: PropTypes.string,
  buttonClass: PropTypes.string,
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
};

NotificationMenuDropdown.defaultProps = {
  children: null,
  button: null,
  className: 'tv-filter-list__element',
  buttonClass: 'tv-notification_header',
  visible: false,
  setVisible: () => {},
};

export default NotificationMenuDropdown;
