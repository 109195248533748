import React from 'react';

const SortButton = ({ sort }) => {
  let sortByStyle = 'none';
  if (sort === 1) sortByStyle = 'asc';
  if (sort === 2) sortByStyle = 'desc';
  return (
    <div
      className={`tv-reportquality__item-sort-buttons tv-reportquality__item-sort-buttons-${sortByStyle}`}
    >
      <div className="fa fa-sort-up"></div>
      <div className="fa fa-sort-down"></div>
    </div>
  );
};

export default SortButton;
