import React from "react";

import PropTypes from "prop-types";

const propTypes = {
    className: PropTypes.string,
    Text: PropTypes.string,
    title: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

const defaultProps = {
    className: "",
    Text: "",
    title: "",
    onClick: () => {},
    disabled: false,
};

const ArrowButton = ({ className, Text, title, disabled, onClick }) => {
    return (
        <button
            onClick={onClick}
            className={className}
            title={title}
            disabled={disabled}
        >
            {Text}
        </button>
    );
};

ArrowButton.propTypes = propTypes;
ArrowButton.defaultProps = defaultProps;

export default ArrowButton;
